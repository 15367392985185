// BEGIN /src/features/account/conversations.slice.tsx:
import { createEntityAdapter, createSlice, createAsyncThunk, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { selectContactById } from './contacts.slice';
import { RootState } from 'src/store';
import { mergeExistingOrNewConversationAndGetUpdatedConversationObject } from 'src/utils/reducerHelpers';
import { FollowUpSettings } from 'src/types/followUpSettings';
import { enqueueSnackbar } from 'notistack';
export interface Message {
  id: string;
  sender_id: string;
  content: string;
  created_at: string;
  updated_at: string;
  status: string;
  subject: string;
  call_status: string;
  deliver_after: string;
  conversation_id: string;
  sender_role: string;
  sender_name: string;
  memory: Record<string, any>;
  raw_content: string | null;
  delivered_at: string;
  hide_from_context: boolean;
  marked_to_be_deleted: boolean;
  metadata: Record<string, any>;
}

export interface Entity {
  id: string;
  name: string;
  identifier?: string;
  role: string;
  tag?: string;
  contact?: any;
  contact_id?: string;
}

export interface Conversation {
  id: string;
  channel_id: string;
  status: string;
  subject: string;
  call_status: string;
  latest_message_at: string;
  latest_delivery_at: string;
  delivery_state: string;
  delivery_delay: number;
  next_autocomplete_scheduled_at: string;
  retries: number;
  participating_entities: Entity[];
  memory: Record<string, any>;
  created_at: string;
  updated_at: string;
  messages: Message[];
  autocomplete_status: string;
  read: boolean;
  message_count: number;
  loading: boolean;
  error: any;
  account_id: string;
  follow_up: FollowUpSettings;
  user_messages_count?: number;
  last_user_message_received_at?: string;
  delivered_assistant_messages_since_last_user_message?: number;
}

const conversationsAdapter = createEntityAdapter<Conversation>({
  selectId: (conversation) => conversation.id,
  sortComparer: (a, b) => {
    // Fallback to 'updated_at' if 'latest_message_at' is null
    const dateA = a.latest_message_at || a.updated_at;
    const dateB = b.latest_message_at || b.updated_at;

    return dateB.localeCompare(dateA);
  },
});

// Extending EntityState to include additional properties
interface ConversationsState extends EntityState<Conversation> {
  loading: boolean;
  error: any;
  initialLoad: boolean;
  openConversationId: string | null;
  totalConversations: number;
  hasMore: boolean;
  lastCursor: string | null;
  limit: number;
}

const initialState: ConversationsState = conversationsAdapter.getInitialState({
  loading: false,
  error: null,
  initialLoad: false,
  openConversationId: null,
  totalConversations: 0,
  hasMore: false,
  lastCursor: null,
  limit: 25
});

interface FetchConversationsParams {
  conversationId?: string;
  status?: string;
  subjectContains?: string;
  queryString?: string;
  participatingEntityIdentifiers?: string[];
  contactId?: string;
  memory_contains?: string | object;
  conversationLatestDeliveryAtBefore?: Date;
  conversationLatestMessageCreatedAtBefore?: Date;
  conversationLastUpdatedBefore?: Date;
  limit?: number;
  messagesLimit?: number;
  cursor?: string | null;
  channel_id?: string;
  user_not_responded_to?: boolean;
  has_draft_messages?: boolean;
}

interface FetchConversationWithMessagesParams {
  conversationId: string;
  messagesLimit?: number;
}

export const fetchConversationsWithMessages = createAsyncThunk(
  'conversations/fetchConversationsWithMessages',
  async ({ conversationId, messagesLimit = 1, status = "all_active", ...restParams }: FetchConversationsParams) => {
    const queryParams = new URLSearchParams({
      limit: (restParams.limit || 25).toString(),
      messages_limit: messagesLimit.toString(),
      status: status || "all_active",
      ...(conversationId && { id: conversationId }),
      ...(restParams.queryString && { query_string: restParams.queryString }),
      ...(restParams.subjectContains && { subject_contains: restParams.subjectContains }),
      ...(restParams.participatingEntityIdentifiers && { participating_entity_identifiers: JSON.stringify(restParams.participatingEntityIdentifiers) }),
      ...(restParams.contactId && { contact_id: restParams.contactId }),
      ...(restParams.memory_contains && {
        memory_contains: typeof restParams.memory_contains === 'string'
          ? restParams.memory_contains
          : JSON.stringify(restParams.memory_contains)
      }),
      ...(restParams.channel_id && { channel_id: restParams.channel_id }),
      ...(restParams.user_not_responded_to && { user_not_responded_to: restParams.user_not_responded_to.toString() }),
      ...(restParams.has_draft_messages && { has_draft_messages: restParams.has_draft_messages.toString() }),
      ...(restParams.conversationLatestDeliveryAtBefore && { conversation_latest_delivery_at_before: restParams.conversationLatestDeliveryAtBefore.toISOString() }),
      ...(restParams.conversationLatestMessageCreatedAtBefore && { conversation_latest_message_created_at_before: restParams.conversationLatestMessageCreatedAtBefore.toISOString() }),
      ...(restParams.conversationLastUpdatedBefore && { conversation_last_updated_before: restParams.conversationLastUpdatedBefore.toISOString() }),
    }).toString();
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/?${queryParams}`;
    const response = await httpClient.get(url);
    return response.data;
  }
)

export const fetchConversationWithMessages = createAsyncThunk(
  'conversations/fetchConversationWithMessages',
  async ({ conversationId, messagesLimit = 30 }: FetchConversationWithMessagesParams) => {
    const queryParams = new URLSearchParams({
      messages_limit: messagesLimit.toString(),
    }).toString();
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}?${queryParams}`;
    const response = await httpClient.get(url);
    const conversation = response.data
    return conversation;
  }
);


export const selectConversationsWithContacts = (state: RootState) => state.conversations.ids.map(id => {
  const conversation = selectConversationById(state, id);
  if (!conversation) return null; // Ensure conversation exists
  return {
    ...conversation,
    participating_entities: conversation.participating_entities.map(entity => {
      const contact = selectContactById(state, entity.contact_id);
      return {
        ...entity,
        contact: contact ? { ...contact, initialLoad: true, loading: false } : { id: entity.contact_id, initialLoad: false, loading: true }
      };
    })
  };
}).filter(conversation => conversation !== null); // Filter out null entries

export const updateConversations = (conversations) => ({
  type: 'conversations/update',
  payload: conversations
});

export const processPusherConversationUpdated = createAsyncThunk(
  'conversations/processPusherConversationUpdated',
  async (data: { object: any }, thunkAPI) => {
    return data.object;
  }
)

export const processPusherConversationCreated = createAsyncThunk(
  'conversations/processPusherConversationCreated',
  async (data: { object: any }, thunkAPI) => {
    return data.object;
  }
)

export const processPusherConversationDeleted = createAsyncThunk(
  'conversations/processPusherConversationDeleted',
  async (data: { object: any }, thunkAPI) => {
    return data.object;
  }
)

export const updateConversationStatus = createAsyncThunk(
  'conversations/updateStatus',
  async ({ conversationId, status, channelId }: { conversationId: string, status: string, channelId: string }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}`;
    const body = { status: status };
    const response = await httpClient.patch(url, body);
    return response.data;
  }
)

export const conversationAutocomplete = createAsyncThunk(
  'conversations/conversationAutocomplete',
  async ({ channelId, conversationId }: { channelId: string; conversationId: string }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}/autocomplete`;
    const body = { channelId };
    const response = await httpClient.post(url, body);
    return response.data;
  }
)

export const conversationAutocompleteNow = createAsyncThunk(
  'conversations/conversationAutocompleteNow',
  async ({ channelId, conversationId }: { channelId: string; conversationId: string }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}/autocomplete_now`;
    const body = { channelId };
    const response = await httpClient.post(url, body);
    return response.data;
  }
)

export const updateConversationDeliveryDelay = createAsyncThunk(
  'conversations/updateDeliveryDelay',
  async ({ conversationId, delivery_delay, channelId, status }: { conversationId: string, delivery_delay: number, channelId: string, status: string }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}`;
    const body = { delivery_delay: delivery_delay };
    const response = await httpClient.patch(url, body);
    return response.data;
  }
)

export const updateConversationDeliveryState = createAsyncThunk(
  'conversations/updateDeliveryState',
  async ({ conversationId, deliveryState, channelId }: { conversationId: string, deliveryState: string, channelId: string }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}`;
    const body = { delivery_state: deliveryState };
    const response = await httpClient.patch(url, body);
    return response.data;
  }
)

export const fetchMessages = createAsyncThunk(
  'conversations/fetchMessages',
  async ({ conversationId, channelId }: { conversationId: string; channelId: string }) => {
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.chat_message_thread}?channel_id=${channelId}&conversation_id=${conversationId}`;
    const response = await httpClient.get(url);
    return response.data;
  }
)

export const sendMessage = createAsyncThunk(
  'conversations/sendMessage',
  async ({ conversation, message, sendingEntity }: {
    conversation: any;
    message: string;
    sendingEntity: any;
  }) => {
    try {
      if (message == null || message === '') {
        throw new Error('Message cannot be empty.');
      }
      const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_chat_post_message}`;
      const data = {
        conversation_id: conversation?.id,
        content: message,
        from_role: sendingEntity.role,
        from_identifier: sendingEntity.identifier,
        from_name: sendingEntity.name,
        channel_id: conversation.channel_id
      };
      const response = await httpClient.post(url, data);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
)

export const initiateCall = createAsyncThunk(
  'conversations/initiateCall',
  async ({ channelId, fromOppyPhoneNumber, toAdminPhoneNumber, connectToNumber, callerName, contactName, conversationId }: {
    channelId: string;
    fromOppyPhoneNumber: string;
    toAdminPhoneNumber: string;
    connectToNumber: string;
    callerName: string;
    contactName: string;
    conversationId?: string;
  }) => {
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}/${channelId}/initiate_call`;
    const response = await httpClient.post(url, {
      from_oppy_number: fromOppyPhoneNumber,
      to_admin_number: toAdminPhoneNumber,
      connect_to_number: connectToNumber,
      caller_name: callerName,
      contact_name: contactName,
      conversation_id: conversationId
    });
    return {
      success: response.data.success,
      conversation_id: response.data.conversation_id,
      ...response.data
    };
  }
);

export const updateConversation = createAsyncThunk(
  'conversations/updateConversation',
  async (
    {
      conversationId,
      channelId,
      conversationData,
    }: { conversationId: string; channelId: string; conversationData: Partial<Conversation> },
    thunkAPI
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}`;
    const body = {
      ...conversationData
    };
    // // extract each key from the body and put in an array
    // const keys = Object.keys(body);
    // // log the keys
    // console.log("conversationData updateConversation to run for keys", keys);
    const response = await httpClient.patch(url, body);
    return response.data.conversation;
  }
);

export const getConversationFollowUp = createAsyncThunk(
  'conversations/getFollowUp',
  async (conversationId: string, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}/follow_up`;
      const response = await httpClient.get(url);
      return {
        conversationId,
        followUp: response.data.follow_up
      };
    } catch (error) {
      console.error('Error fetching follow-up settings:', error);
      return rejectWithValue(error.response?.data || 'Failed to fetch follow-up settings');
    }
  }
);

export const updateConversationFollowUp = createAsyncThunk(
  'conversations/updateFollowUp',
  async (
    {
      conversationId,
      followUpSettings,
    }: { conversationId: string; followUpSettings: FollowUpSettings },
    { rejectWithValue }
  ) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}/follow_up`;

      // Handle each property individually to ensure proper null/undefined handling
      const formData = new URLSearchParams();

      // Handle each property individually to ensure proper null/undefined handling
      if (followUpSettings.enabled !== undefined) {
        formData.append('enabled', followUpSettings.enabled.toString());
      }

      if (followUpSettings.delay_minutes !== undefined) {
        formData.append('delay_minutes', followUpSettings.delay_minutes.toString());
      }

      if (followUpSettings.prompt !== undefined) {
        formData.append('prompt', followUpSettings.prompt);
      }

      if (followUpSettings.respect_business_hours !== undefined) {
        formData.append('respect_business_hours', followUpSettings.respect_business_hours.toString());
      }

      // Handle max_message_attempts specially - send 'null' as string when it's null
      if (followUpSettings.max_message_attempts !== undefined) {
        if (followUpSettings.max_message_attempts === null) {
          formData.append('max_message_attempts', 'null');
        } else {
          formData.append('max_message_attempts', followUpSettings.max_message_attempts.toString());
        }
      }

      if (followUpSettings.total_message_attempts !== undefined) {
        formData.append('total_message_attempts', followUpSettings.total_message_attempts.toString());
      }

      if (followUpSettings.schedule_type !== undefined) {
        formData.append('schedule_type', followUpSettings.schedule_type);
      }

      if (followUpSettings.skip_weekends !== undefined) {
        formData.append('skip_weekends', followUpSettings.skip_weekends.toString());
      }

      if (followUpSettings.limit_follow_up_attempts !== undefined) {
        formData.append('limit_follow_up_attempts', followUpSettings.limit_follow_up_attempts.toString());
      }

      const response = await httpClient.patch(url, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return {
        conversationId,
        followUp: response.data.follow_up
      };
    } catch (error) {
      console.error('Error updating follow-up settings:', error);
      return rejectWithValue(error.response?.data || 'Failed to update follow-up settings');
    }
  }
);

export const enableConversationFollowUp = createAsyncThunk(
  'conversations/enableFollowUp',
  async (conversationId: string, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}/follow_up/enable`;
      const response = await httpClient.post(url);
      return {
        conversationId,
        followUp: response.data.follow_up
      };
    } catch (error) {
      console.error('Error enabling follow-up:', error);
      return rejectWithValue(error.response?.data || 'Failed to enable follow-up');
    }
  }
);

export const disableConversationFollowUp = createAsyncThunk(
  'conversations/disableFollowUp',
  async (conversationId: string, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/${conversationId}/follow_up/disable`;
      const response = await httpClient.post(url);
      return {
        conversationId,
        followUp: response.data.follow_up
      };
    } catch (error) {
      console.error('Error disabling follow-up:', error);
      return rejectWithValue(error.response?.data || 'Failed to disable follow-up');
    }
  }
);

export const deleteConversations = createAsyncThunk(
  'conversations/deleteConversations',
  async (conversationIds: string[], { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/comms-api/conversations/bulk_delete`;
      const response = await httpClient.post(url, { conversation_ids: conversationIds });

      if (response.data.success) {
        return {
          successfulDeletes: response.data.successful_deletes,
          failedDeletes: response.data.failed_deletes.map(f => f.id)
        };
      } else {
        return rejectWithValue('Failed to delete conversations');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete conversations');
    }
  }
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    upsert: (state, action) => {
      return conversationsAdapter.upsertOne(state, action.payload);
    },
    removeConversation: conversationsAdapter.removeOne,
    toggleOpenConversation: (state, action) => {
      const targetConversationId = action.payload;
      state.openConversationId = targetConversationId;
    },
    markConversationAsRead: (state, action) => {
      const targetConversationId = action.payload;
      const index = state.ids.findIndex(id => id === targetConversationId);
      if (index > -1) {
        state.entities[targetConversationId].read = true;
      }
    },
    addMessage: (state, action) => {
      const message = action.payload;
      const conversationId = message.conversation_id;
      if (state.entities[conversationId]) {
        const messages = state.entities[conversationId].messages || [];
        const messageExists = messages.some(msg => msg.id === message.id);
        if (!messageExists) {
          state.entities[conversationId].messages = [...messages, { ...message }];
        } else {
          // Create a new array with the updated message
          const updatedMessages = messages.map(msg =>
            msg.id === message.id ? { ...message } : msg
          );
          state.entities[conversationId].messages = updatedMessages;
        }
      }
    },
    addMessagesToConversation: (state, action) => {
      const { conversationId, messages } = action.payload;
      if (state.entities[conversationId]) {
        const existingMessages = state.entities[conversationId].messages || [];
        const newMessages = messages.filter(msg => !existingMessages.find(existingMsg => existingMsg.id === msg.id))
          .map(msg => ({ ...msg })); // Create a deep copy of each message
        state.entities[conversationId].messages = [...existingMessages, ...newMessages];
      }
    },
    removeMessageFromConversation: (state, action) => {
      const { conversationId, messageId } = action.payload;
      if (state.entities[conversationId]) {
        state.entities[conversationId].messages = state.entities[conversationId].messages.filter(msg => msg.id !== messageId);
      }
    },
    decrementMessageCount: (state, action) => {
      const { conversationId } = action.payload;
      if (state.entities[conversationId]) {
        state.entities[conversationId].message_count -= 1;
      }
    },
    upsertConversation: conversationsAdapter.upsertOne,
    removeConversationById: conversationsAdapter.removeOne,
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
    },
    resetPagination(state) {
      state.lastCursor = null;
      conversationsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversationsWithMessages.pending, (state, action) => {
        state.loading = true;
        state.initialLoad = true;
        state.error = null;
      })
      .addCase(fetchConversationsWithMessages.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload || !Array.isArray(action.payload.conversations)) {
          return;
        }

        conversationsAdapter.setAll(state, action.payload.conversations);

        // Update pagination state
        state.totalConversations = action.payload.total_count || action.payload.conversations.length;
        state.hasMore = action.payload.has_more || false;
        const lastConversation = action.payload.conversations[action.payload.conversations.length - 1];
        state.lastCursor = lastConversation ? lastConversation.latest_message_at : null;
      })
      .addCase(fetchConversationsWithMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.initialLoad = false;
      })
      .addCase(fetchConversationWithMessages.pending, (state, action) => {
        const conversationId = action.meta.arg.conversationId;
        if (state.ids.includes(conversationId)) {
          state.entities[conversationId].loading = true;
        }
      })
      .addCase(fetchConversationWithMessages.fulfilled, (state, action) => {
        const conversation = action.payload;
        const conversationId = conversation?.id;
        if (!conversationId) {
          return;
        };
        const existingConversation = state.entities[conversationId];

        // Create a safe copy of the conversation with properly copied message objects
        const safeConversation = {
          ...conversation,
          messages: Array.isArray(conversation.messages)
            ? conversation.messages.map(msg => ({ ...msg }))
            : []
        };

        const updatedConversation = mergeExistingOrNewConversationAndGetUpdatedConversationObject(
          existingConversation,
          safeConversation
        );

        // Filter out marked-for-deletion messages and ensure we're working with copies
        updatedConversation.messages = updatedConversation.messages
          .filter(message => !message.marked_to_be_deleted)
          .map(msg => ({ ...msg }));

        conversationsAdapter.upsertOne(state, updatedConversation);
        state.entities[conversation.id].loading = false;
        state.entities[conversation.id].error = null;
      })
      .addCase(updateConversationStatus.pending, (state, action) => {
        const conversationId = action?.meta?.arg?.conversationId;
        if (state.ids.includes(conversationId)) {
          state.entities[conversationId].status = 'loading'
        }
      })
      .addCase(updateConversationStatus.fulfilled, (state, action) => {
        const conversation = action.payload.conversation;
        if (state.ids.includes(conversation.id)) {
          state.entities[conversation.id].status = conversation.status;
        }
      })
      .addCase(conversationAutocomplete.pending, (state, action) => {
        const conversationId = action?.meta?.arg?.conversationId;
        if (state.ids.includes(conversationId)) {
          state.entities[conversationId].autocomplete_status = 'loading'
        }
      })
      .addCase(conversationAutocomplete.fulfilled, (state, action) => {
        // const conversation = action.payload.conversation;
        // if (state.ids.includes(conversation.id)) {
        //   state.entities[conversation.id].autocomplete_status = 'queued'
        // }
      })
      .addCase(conversationAutocompleteNow.pending, (state, action) => {
        const conversationId = action?.meta?.arg?.conversationId;
        if (state.ids.includes(conversationId)) {
          state.entities[conversationId].autocomplete_status = 'loading'
        }
      })
      .addCase(conversationAutocompleteNow.fulfilled, (state, action) => {
        // const { conversation_id, message } = action.payload;
        // if (state.ids.includes(conversation_id)) {
        //   const conversation = state.entities[conversation_id];
        //   const messageExists = conversation.messages?.find(msg => msg.id === message.id);
        //   if (!messageExists) {
        //     conversation.messages = [...(conversation.messages || []), message];
        //     conversation.autocomplete_status = 'pending';
        //   }
        // } 
      })
      .addCase(conversationAutocompleteNow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        const index = state.ids.findIndex(id => id === action.meta.arg.conversationId);
        if (index > -1) {
          state.entities[state.ids[index]].autocomplete_status = 'error'
        }
      })
      .addCase(updateConversationDeliveryDelay.fulfilled, (state, action) => {
        // const conversation = action.payload.conversation;
        // if (state.ids.includes(conversation.id)) {
        //   state.entities[conversation.id].delivery_delay = conversation.delivery_delay;
        // }
      })
      .addCase(updateConversationDeliveryState.pending, (state, action) => {
        const conversationId = action?.meta?.arg?.conversationId;
        if (state.ids.includes(conversationId)) {
          state.entities[conversationId].delivery_state = 'loading'
        }
      })
      .addCase(updateConversationDeliveryState.fulfilled, (state, action) => {
        const conversation = action.payload.conversation;
        if (state.ids.includes(conversation.id)) {
          state.entities[conversation.id].delivery_state = conversation.delivery_state;
        }
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const conversation = action.payload.conversation;
        if (state.ids.includes(conversation.id)) {
          state.entities[conversation.id] = {
            ...state.entities[conversation.id],
            messages: action.payload.messages
          };
        }
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const conversationId = action.payload.conversation_id;
        if (state.ids.includes(conversationId)) {
          const conversation = state.entities[conversationId];
          const messageExists = conversation.messages.some(msg => msg.id === action.payload.message.id);
          if (!messageExists) {
            conversation.messages.push(action.payload.message);
          }
        }
      })
      .addCase(initiateCall.pending, (state, action) => {
        const conversationId = action.meta.arg.conversationId;
        if (state.entities[conversationId]) {
          state.entities[conversationId].call_status = 'initiating';
        }
      })
      .addCase(initiateCall.fulfilled, (state, action) => {
        const { conversation_id, call_status } = action.payload;
        if (state.entities[conversation_id]) {
          state.entities[conversation_id].call_status = call_status;
        }
      })
      .addCase(initiateCall.rejected, (state, action) => {
        const conversationId = action.meta.arg.conversationId;
        if (state.entities[conversationId]) {
          state.entities[conversationId].call_status = 'failed';
        }
      })
      .addCase(updateConversation.pending, (state, action) => {
        const conversationId = action?.meta?.arg?.conversationId;
        if (state.ids.includes(conversationId)) {
          state.entities[conversationId].status = 'loading';
        }
      })
      .addCase(updateConversation.fulfilled, (state, action) => {
        const updatedConversation = action.payload;
        // find the conversation and update it except for its messages
        // const conversation = state.entities[updatedConversation.id];
        // if (conversation) {
        //   conversation.follow_up = updatedConversation.follow_up;
        //   conversation.status = updatedConversation.status;
        //   conversation.delivery_delay = updatedConversation.delivery_delay;
        //   conversation.memory = updatedConversation.memory;
        // }
      })
      .addCase(updateConversation.rejected, (state, action) => {
        console.log("updateConversation.rejected", action);
      })
      .addCase(getConversationFollowUp.fulfilled, (state, action) => {
        const { conversationId, followUp } = action.payload;
        if (state.entities[conversationId]) {
          state.entities[conversationId].follow_up = followUp;
        }
      })
      .addCase(getConversationFollowUp.rejected, (state, action) => {
        console.error('Failed to fetch follow-up settings:', action.payload);
      })
      .addCase(updateConversationFollowUp.pending, (state, action) => {
        const conversationId = action.meta.arg.conversationId;
        if (state.entities[conversationId]) {
          state.entities[conversationId].loading = true;
        }
      })
      .addCase(updateConversationFollowUp.fulfilled, (state, action) => {
        const { conversationId, followUp } = action.payload;
        if (state.entities[conversationId]) {
          state.entities[conversationId].follow_up = followUp;
          state.entities[conversationId].loading = false;
        }
      })
      .addCase(updateConversationFollowUp.rejected, (state, action) => {
        const conversationId = action.meta.arg.conversationId;
        if (state.entities[conversationId]) {
          state.entities[conversationId].loading = false;
          state.entities[conversationId].error = action.payload || 'Failed to update follow-up settings';
        }
        console.error('Failed to update follow-up settings:', action.payload);
      })
      .addCase(enableConversationFollowUp.pending, (state, action) => {
        const conversationId = action.meta.arg;
        if (state.entities[conversationId]) {
          state.entities[conversationId].loading = true;
        }
      })
      .addCase(enableConversationFollowUp.fulfilled, (state, action) => {
        const { conversationId, followUp } = action.payload;
        if (state.entities[conversationId]) {
          state.entities[conversationId].follow_up = followUp;
          state.entities[conversationId].loading = false;
        }
      })
      .addCase(enableConversationFollowUp.rejected, (state, action) => {
        const conversationId = action.meta.arg;
        if (state.entities[conversationId]) {
          state.entities[conversationId].loading = false;
          state.entities[conversationId].error = action.payload || 'Failed to enable follow-up';
        }
        console.error('Failed to enable follow-up:', action.payload);
      })
      .addCase(disableConversationFollowUp.pending, (state, action) => {
        const conversationId = action.meta.arg;
        if (state.entities[conversationId]) {
          state.entities[conversationId].loading = true;
        }
      })
      .addCase(disableConversationFollowUp.fulfilled, (state, action) => {
        const { conversationId, followUp } = action.payload;
        if (state.entities[conversationId]) {
          state.entities[conversationId].follow_up = followUp;
          state.entities[conversationId].loading = false;
        }
      })
      .addCase(disableConversationFollowUp.rejected, (state, action) => {
        const conversationId = action.meta.arg;
        if (state.entities[conversationId]) {
          state.entities[conversationId].loading = false;
          state.entities[conversationId].error = action.payload || 'Failed to disable follow-up';
        }
        console.error('Failed to disable follow-up:', action.payload);
      })
      .addCase(deleteConversations.fulfilled, (state, action) => {
        const { successfulDeletes } = action.payload;
        conversationsAdapter.removeMany(state, successfulDeletes);
      })
      .addCase(deleteConversations.rejected, (state, action) => {
        state.error = action.payload || 'Failed to delete conversations';
      });
  },
});
export const {
  selectAll: selectAllConversations,
  selectById: selectConversationById,
  selectIds: selectConversationIds,
} = conversationsAdapter.getSelectors<RootState>((state) => state.conversations);

export default conversationsSlice.reducer;
export const { toggleOpenConversation, addMessage, addMessagesToConversation, removeConversation, removeMessageFromConversation, upsertConversation, decrementMessageCount, removeConversationById, setLimit, resetPagination } = conversationsSlice.actions;

// Add new selectors
export const selectHasMore = (state: RootState) => state.conversations.hasMore;
export const selectLastCursor = (state: RootState) => state.conversations.lastCursor;
export const selectLimit = (state: RootState) => state.conversations.limit;
//END /src/features/account/conversations.slice.tsx
