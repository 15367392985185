import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import FollowUpSettingsForm from '../FollowUpSettingsForm';
import { FollowUpSettings } from 'src/types/followUpSettings';
import {
  getConversationFollowUp,
  updateConversationFollowUp,
  enableConversationFollowUp,
  disableConversationFollowUp
} from 'src/features/account/conversations.slice';

interface ConversationFollowUpSettingsProps {
  conversationId: string;
}

const DEFAULT_PROMPT = "Monitor this conversation and determine if a follow-up is needed. Consider: user engagement, unanswered questions, and potential next steps. If appropriate, draft and send a follow-up message.";

const ConversationFollowUpSettings: React.FC<ConversationFollowUpSettingsProps> = ({ conversationId }) => {
  const dispatch = useAppDispatch();
  const conversation = useAppSelector(state => state.conversations.entities[conversationId]);
  const [followUpSettings, setFollowUpSettings] = useState<FollowUpSettings>({
    enabled: conversation?.follow_up?.enabled ?? false,
    delay_minutes: conversation?.follow_up?.delay_minutes ?? 1440,
    prompt: conversation?.follow_up?.prompt ?? DEFAULT_PROMPT,
    respect_business_hours: conversation?.follow_up?.respect_business_hours ?? false,
    max_message_attempts: conversation?.follow_up?.max_message_attempts ?? 5,
    total_message_attempts: conversation?.follow_up?.total_message_attempts ?? 0,
    schedule_type: conversation?.follow_up?.schedule_type ?? 'fixed',
    skip_weekends: conversation?.follow_up?.skip_weekends ?? false,
    limit_follow_up_attempts: conversation?.follow_up?.max_message_attempts !== null
  });
  const [initialFollowUpSettings, setInitialFollowUpSettings] = useState<FollowUpSettings>({
    enabled: conversation?.follow_up?.enabled ?? false,
    delay_minutes: conversation?.follow_up?.delay_minutes ?? 1440,
    prompt: conversation?.follow_up?.prompt ?? DEFAULT_PROMPT,
    respect_business_hours: conversation?.follow_up?.respect_business_hours ?? false,
    max_message_attempts: conversation?.follow_up?.max_message_attempts ?? 5,
    total_message_attempts: conversation?.follow_up?.total_message_attempts ?? 0,
    schedule_type: conversation?.follow_up?.schedule_type ?? 'fixed',
    skip_weekends: conversation?.follow_up?.skip_weekends ?? false,
    limit_follow_up_attempts: conversation?.follow_up?.max_message_attempts !== null
  });
  const [saving, setSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // Fetch follow-up settings when component mounts
  useEffect(() => {
    if (conversationId) {
      dispatch(getConversationFollowUp(conversationId));
    }
  }, [dispatch, conversationId]);

  // Initialize follow-up settings from conversation.follow_up
  useEffect(() => {
    if (conversation) {
      const followUp = conversation.follow_up || {};
      const newSettings = {
        enabled: followUp.enabled ?? false,
        delay_minutes: followUp.delay_minutes ?? 1440,
        prompt: followUp.prompt ?? DEFAULT_PROMPT,
        respect_business_hours: followUp.respect_business_hours ?? false,
        max_message_attempts: followUp.max_message_attempts ?? 5,
        total_message_attempts: followUp.total_message_attempts ?? 0,
        schedule_type: followUp.schedule_type ?? 'fixed',
        skip_weekends: followUp.skip_weekends ?? false,
        limit_follow_up_attempts: followUp.max_message_attempts !== null
      };
      setFollowUpSettings(newSettings);
      setInitialFollowUpSettings(newSettings);
    } else {
      // console.log('Conversation not available.');
    }
  }, [conversation]);

  // Handle immediate enable/disable toggle
  const handleToggleEnable = async (enabled: boolean) => {
    try {
      setSaving(true);
      if (enabled) {
        await dispatch(enableConversationFollowUp(conversationId)).unwrap();
      } else {
        await dispatch(disableConversationFollowUp(conversationId)).unwrap();
      }
      // Update local state to match the change
      setFollowUpSettings(prev => ({ ...prev, enabled }));
      setInitialFollowUpSettings(prev => ({ ...prev, enabled }));
    } catch (error) {
      console.error('Failed to toggle follow-up settings:', error);
      // Revert the UI state if the API call fails
      setFollowUpSettings(prev => ({ ...prev, enabled: !enabled }));
    } finally {
      setSaving(false);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);

      // Create a copy of settings without changing the enabled status
      // since that's handled separately by handleToggleEnable
      await dispatch(
        updateConversationFollowUp({
          conversationId,
          followUpSettings,
        })
      ).unwrap();

      // Update initialFollowUpSettings to match the current settings after saving
      setInitialFollowUpSettings({ ...followUpSettings });
      setIsExpanded(false);
    } catch (error) {
      console.error('Failed to update follow-up settings:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <FollowUpSettingsForm
      followUpSettings={followUpSettings}
      setFollowUpSettings={setFollowUpSettings}
      handleSave={handleSave}
      saving={saving}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      initialFollowUpSettings={initialFollowUpSettings}
      handleToggleEnable={handleToggleEnable}
    />
  );
};

export default ConversationFollowUpSettings; 
