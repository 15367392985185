import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Select, MenuItem, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Conversation } from 'src/features/account/conversations.slice';
import {
  fetchConversationsWithMessages,
  resetPagination,
  selectHasMore,
  selectLimit,
  setLimit
} from 'src/features/account/conversations.slice';
import { selectIsFilterActive } from 'src/features/account/filteredConversations.slice';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';

interface ConversationsPaginationProps {
  totalItems: number;
  onPageChange: (page: number) => void;
  pageCache: Record<number, Conversation[]>;
  setPageCache: React.Dispatch<React.SetStateAction<Record<number, Conversation[]>>>;
  currentPageProp?: number;
  loading?: boolean;
}

const ConversationsPagination: React.FC<ConversationsPaginationProps> = ({
  totalItems,
  onPageChange,
  pageCache,
  setPageCache,
  loading = false,
  currentPageProp = 1,
}) => {
  const dispatch = useAppDispatch();
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const filterObject = useAppSelector((state) => state.filteredConversations.filterObject);
  const hasMore = useAppSelector(selectHasMore);
  const limit = useAppSelector(selectLimit);
  const [currentPage, setCurrentPage] = useState(currentPageProp);

  useEffect(() => {
    if (currentPage !== currentPageProp) {
      setCurrentPage(currentPageProp);
    }
  }, [currentPageProp, currentPage]);

  useEffect(() => {
    setPageCache({});
    setCurrentPage(1);
  }, [filterObject, limit, setPageCache]);

  const handleNavigateToPage = useCallback((page: number) => {
    if (page === currentPage || loading) return;
    onPageChange(page);
  }, [currentPage, onPageChange, loading]);

  const handleRowsPerPageChange = useCallback((value: string) => {
    if (loading) return;
    const newLimit = parseInt(value, 10);
    if (newLimit !== limit) {
      dispatch(setLimit(newLimit));
      setCurrentPage(1);
      setTimeout(() => onPageChange(1), 0);
      if (isFilterActive) {
        dispatch(fetchConversationsWithMessages({ ...filterObject, limit: newLimit, cursor: null, messagesLimit: 3 }));
      } else {
        dispatch(resetPagination());
        dispatch(fetchConversationsWithMessages({ limit: newLimit, messagesLimit: 3 }));
      }
    }
  }, [dispatch, isFilterActive, filterObject, loading, limit, onPageChange]);

  const pageCount = Math.ceil(totalItems / limit);
  const startItem = currentPage === 1 ? 1 : (currentPage - 1) * limit + 1;
  const endItem = Math.min((currentPage) * limit, totalItems);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mr: 2 }}>
        <Typography variant="body2">Conv. per page:</Typography>
        <Select
          value={limit.toString()}
          onChange={(event) => handleRowsPerPageChange(event.target.value)}
          size="small"
          disabled={loading}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Typography variant="body2">
          {totalItems === 0 ? 'No items' : `${startItem}-${endItem} of ${totalItems}`}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={() => handleNavigateToPage(1)}
          disabled={currentPage === 1 || loading}
          size="small"
          title="Go to first page"
        >
          <KeyboardDoubleArrowLeft />
        </IconButton>

        <IconButton
          onClick={() => handleNavigateToPage(currentPage - 1)}
          disabled={currentPage === 1 || loading}
          size="small"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <Typography variant="body2">
          {currentPage} / {pageCount}
          {loading && <span className="spinner-border spinner-border-sm"></span>}
        </Typography>

        <IconButton
          onClick={() => handleNavigateToPage(currentPage + 1)}
          disabled={!hasMore || loading}
          size="small"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ConversationsPagination; 