// BEGIN /src/components/Channels/ConversationsList.tsx
import { useAppDispatch, useAppSelector } from "src/store";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box, CircularProgress, Hidden, IconButton, Skeleton, Typography, duration,
  Checkbox, Tooltip, MenuItem, Menu, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions
} from "@mui/material";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ViewAgenda, ViewStream, ViewList, Send, AutoAwesome, PlayArrow, Pause, Sort, Delete } from '@mui/icons-material';
import ConversationCreateDialogV2 from "./ConversationCreateDialogV2";
import emptyConversationsListSvg from "src/assets/svg/Oppy_careers_Inspector-Appraiser.svg";
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import ConversationCard from "./ConversationCard";
import { fetchConversationsWithMessages, selectConversationsWithContacts, updateConversationDeliveryState, sendMessage, deleteConversations, selectLimit, selectHasMore } from "src/features/account/conversations.slice";
import { selectAllContacts } from "src/features/account/contacts.slice";
import { AddComment, Help } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { selectAllFilteredConversations, selectIsFilterActive, fetchFilteredConversations, setFilter, priorityStatuses, selectPriorityConversations } from "src/features/account/filteredConversations.slice";
import ConversationListView from './ConversationListView';
import { deliverMessageNow } from "src/features/account/messages.slice";
import { conversationAutocompleteNow } from "src/features/account/conversations.slice";
import { useSnackbar } from 'notistack';
import GenerateRepliesConfirmationDialog from './GenerateRepliesConfirmationDialog';
import { httpClient } from 'src/libs';
import ConversationFilters from './ConversationFilters';
import { filterConversations, sortWithPriorityFirst } from '../../utils/conversationUtils';
import { alpha } from '@mui/material/styles';
import ConversationsPagination from '../Conversations/ConversationsPagination';
import { Conversation } from 'src/features/account/conversations.slice';

interface ConversationsListV2Props {
  conversationsListStyle?: 'open' | 'closed';
  handleConversationsListToggleClick: () => void;
}

const getStyles = (darkMode: boolean) => ({
  mainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexFlow: 'column',
    flexGrow: 1,
    borderRadius: 3,
    height: '100vh',
    overflowY: 'auto',
    minWidth: '320px',
    position: 'relative',
    bgcolor: darkMode ? 'grey.900' : 'background.default',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      bgcolor: darkMode ? 'grey.800' : 'grey.100'
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: darkMode ? 'grey.700' : 'grey.300',
      borderRadius: '4px',
    }
  },
  topBar: {
    position: 'absolute',
    top: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2,
    background: darkMode ? 'grey.900' : '#FAFAFA',
    width: '100%',
    borderBottom: 1,
    borderColor: darkMode ? 'grey.700' : 'grey.200',
    transition: 'all 0.3s ease',
    zIndex: 1,
    boxShadow: darkMode ? 'none' : '0 1px 2px rgba(0,0,0,0.05)',
    '& .MuiTypography-root': {
      color: darkMode ? 'grey.100' : 'text.primary',
      transition: 'color 0.3s ease'
    }
  },
  topBarTitleAndFilterContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2
  },
  topBarAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    '& .MuiIconButton-root': {
      color: darkMode ? 'grey.300' : 'text.secondary',
      transition: 'all 0.2s ease',
      '&:hover': {
        color: 'primary.main',
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    },
    '& .MuiButton-root': {
      color: darkMode ? 'grey.300' : 'text.primary',
      borderColor: darkMode ? 'grey.700' : 'grey.300',
      '&:hover': {
        borderColor: 'primary.main',
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    }
  },
  conversationsContainer: {
    mt: 12,
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    overflowY: 'scroll',
    display: 'flex',
    flexFlow: 'row',
    flexDirection: 'row',
    gap: '20px',
    p: 2,
    alignItems: 'stretch',
    flexWrap: 'wrap',
    transition: 'all 0.3s ease',
    bgcolor: darkMode ? 'grey.900' : 'background.default',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      bgcolor: darkMode ? 'grey.800' : 'grey.100'
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: darkMode ? 'grey.700' : 'grey.300',
      borderRadius: '4px',
      '&:hover': {
        bgcolor: darkMode ? 'grey.600' : 'grey.400'
      }
    }
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 3,
    color: darkMode ? 'error.light' : 'error.main',
    transition: 'all 0.3s ease',
    bgcolor: darkMode ? 'rgba(244, 67, 54, 0.1)' : 'rgba(244, 67, 54, 0.05)',
    borderRadius: 1,
    '& .MuiTypography-root': {
      color: 'inherit'
    }
  },
  conversationGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    padding: '8px',
    borderRadius: 1,
    bgcolor: darkMode ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.02)'
  },
  groupHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    px: 1
  },
  conversationCardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    width: '100%',
    justifyContent: 'flex-start'
  },
  emptyStateBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .MuiCard-root': {
      minWidth: 275,
      bgcolor: darkMode ? 'grey.800' : 'background.paper',
      color: darkMode ? 'grey.300' : 'text.primary',
      transition: 'all 0.3s ease',
      boxShadow: darkMode ? 'none' : 1,
      border: 1,
      borderColor: darkMode ? 'grey.700' : 'grey.200'
    }
  },
  emptyStateContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 3,
    '& img': {
      width: '50%',
      marginBottom: 2,
      opacity: darkMode ? 0.8 : 1,
      transition: 'opacity 0.3s ease'
    },
    '& .MuiTypography-root': {
      color: darkMode ? 'grey.400' : 'text.secondary',
      textAlign: 'center',
      transition: 'color 0.3s ease'
    },
    '& .MuiButton-root': {
      mt: 2,
      color: darkMode ? 'primary.light' : 'primary.main',
      borderColor: darkMode ? 'primary.light' : 'primary.main',
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    }
  },
  dayTypography: {
    m: 2,
    mt: 3,
    ml: 0,
    mb: 0,
    alignItems: 'center',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    fontWeight: 500,
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em'
  },
  dayCountTypography: {
    ml: 1,
    display: 'inline',
    fontWeight: 'normal',
    color: darkMode ? 'grey.400' : 'text.secondary',
    transition: 'all 0.3s ease',
    fontSize: '0.75rem'
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '16px 24px'
  },
  conversationsEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: 2
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: 2
  },
  cardContainer: {
    width: '100%',
    padding: '16px 24px',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'flex-start'
  },
  container: {
    // Add any necessary styles for the container
  },
  header: {
    // Add any necessary styles for the header
  },
  heading: {
    // Add any necessary styles for the heading
  },
  actions: {
    // Add any necessary styles for the actions
  },
  selectionActionsContainer: {
    // Add any necessary styles for the selection actions container
  },
  cardsContainer: {
    // Add any necessary styles for the cards container
  },
  noConversations: {
    // Add any necessary styles for the no conversations message
  }
});

type SortField = 'updated_at' | 'subject' | 'user_messages_count' | 'last_user_message_received_at';
type SortOrder = 'asc' | 'desc';
type ViewModeType = 'full' | 'small' | 'list';

// @ts-ignore - Component returns React element
const ConversationsListV2: React.FC<ConversationsListV2Props> = ({ conversationsListStyle = 'open', handleConversationsListToggleClick }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const styles = getStyles(darkMode);
  const [focusedConversation, setFocusedConversation] = useState<any | null>(null);
  const [createConversationOpen, setCreateConversationOpen] = useState(false);
  const { loading: conversationsLoading, error: conversationsLoadError, initialLoad: conversationsInitialLoad } = useAppSelector(state => state.conversations);
  const contacts = useAppSelector(selectAllContacts);
  const contactsInitialLoad = useAppSelector(state => state.contacts.initialLoad);
  const contactsError = useAppSelector(state => state.contacts.error);
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const unfilteredConversations = useAppSelector(selectConversationsWithContacts);
  const filteredConversations = useAppSelector(selectAllFilteredConversations);
  const channels = useAppSelector(state => state.channels?.channels || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCache, setPageCache] = useState<Record<number, Conversation[]>>({});
  const totalConversations = useAppSelector(state => state.conversations.totalConversations);
  const priorityConversations = useAppSelector(selectPriorityConversations);

  // Modify the conversations useMemo
  const conversations = useMemo(() => {
    const baseConversations = isFilterActive ? filteredConversations : unfilteredConversations;

    // Only merge when no filter is active
    if (!isFilterActive && priorityConversations?.length > 0) {
      // Create a map for O(1) lookups
      const conversationMap = new Map(baseConversations.map(c => [c.id, c]));

      // Create a new array instead of modifying baseConversations
      const combinedConversations = [...baseConversations];

      // Add priority conversations that aren't already in the list
      priorityConversations.forEach(pConv => {
        if (!conversationMap.has(pConv.id)) {
          combinedConversations.push(pConv);
        }
      });

      return combinedConversations;
    }

    return baseConversations;
  }, [isFilterActive, filteredConversations, unfilteredConversations, priorityConversations]);
  const [viewMode, setViewMode] = useState<ViewModeType>('full');
  // Helper function to convert viewMode to ConversationCard variant type
  const getConversationCardVariant = (mode: ViewModeType): 'full' | 'small' => {
    if (mode === 'small') return 'small';
    return 'full'; // Default to 'full' for any other value
  };
  // Add state for sorting
  const [sortField, setSortField] = useState<SortField>('updated_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(null);
  // Add state for selection
  const [selectedConversations, setSelectedConversations] = useState<string[]>([]);
  const [isDelivering, setIsDelivering] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPausingResuming, setIsPausingResuming] = useState(false);
  const [currentDeliveryStateAction, setCurrentDeliveryStateAction] = useState<'play' | 'pause' | null>(null);
  const [deliveryProgress, setDeliveryProgress] = useState(0);
  const [pauseResumeProgress, setPauseResumeProgress] = useState(0);
  const [totalToDeliver, setTotalToDeliver] = useState(0);
  const [totalToPauseResume, setTotalToPauseResume] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [pausedResumedCount, setPausedResumedCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [showGenerateRepliesDialog, setShowGenerateRepliesDialog] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string>('all_active');
  // Use state for showSelectionColumn to avoid redeclaration issues
  const [showSelectionColumn, setShowSelectionColumn] = useState<boolean>(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const filterObject = useAppSelector((state) => state.filteredConversations.filterObject);
  const limit = useAppSelector(selectLimit);
  const hasMore = useAppSelector(selectHasMore);

  // Get all conversations with pending assistant messages
  const conversationsWithPendingAssistant = useMemo(() => {
    return conversations.filter(conversation =>
      conversation.messages?.some(message =>
        message.status === 'pending_delivery' &&
        message.sender_role === 'assistant'
      )
    );
  }, [conversations]);

  // Handle select all checkbox
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all conversations, not just those with pending messages
      setSelectedConversations(conversations.map(conv => conv.id));
    } else {
      setSelectedConversations([]);
    }
  };

  // Handle individual conversation selection
  const handleSelectConversation = (conversationId: string) => {
    setSelectedConversations(prev => {
      if (prev.includes(conversationId)) {
        return prev.filter(id => id !== conversationId);
      } else {
        return [...prev, conversationId];
      }
    });
  };

  // Handle selecting all conversations within a specific group
  const handleSelectGroup = (groupLabel: string) => {
    // Get all conversation IDs from the specific group
    const groupConversationIds = groupedConversations[groupLabel]?.conversations.map(conv => conv.id) || [];

    // If all conversations in the group are already selected, deselect them
    const allInGroupSelected = groupConversationIds.every(id => selectedConversations.includes(id));

    if (allInGroupSelected) {
      // Deselect all conversations in this group
      setSelectedConversations(prev =>
        prev.filter(id => !groupConversationIds.includes(id))
      );
    } else {
      // Select all conversations in this group (while keeping other selections intact)
      setSelectedConversations(prev => {
        // Filter out any conversations from this group that might already be selected
        const filteredPrev = prev.filter(id => !groupConversationIds.includes(id));
        // Return combined array of previously selected conversations (from other groups) plus all from this group
        return [...filteredPrev, ...groupConversationIds];
      });
    }
  };

  // Handle bulk delivery of selected messages
  const handleDeliverSelected = async () => {
    if (selectedConversations.length === 0) return;

    setIsDelivering(true);
    setIsGenerating(false);
    let pendingMessages: { messageId: string, channelId: string }[] = [];

    // Collect all pending assistant messages from selected conversations
    selectedConversations.forEach(conversationId => {
      const conversation = conversations.find(conv => conv.id === conversationId);
      if (conversation) {
        conversation.messages.forEach(message => {
          if (message.status === 'pending_delivery' && message.sender_role === 'assistant') {
            pendingMessages.push({
              messageId: message.id,
              channelId: conversation.channel_id
            });
          }
        });
      }
    });

    const totalMessages = pendingMessages.length;
    setTotalToDeliver(totalMessages);
    setDeliveredCount(0);

    // Track successful deliveries in a local variable
    let successCount = 0;

    // Process messages one by one
    for (let i = 0; i < pendingMessages.length; i++) {
      try {
        await dispatch(deliverMessageNow({
          messageId: pendingMessages[i].messageId,
          channelId: pendingMessages[i].channelId
        }));
        successCount++;
        setDeliveredCount(successCount);
        setDeliveryProgress(Math.round(((i + 1) / pendingMessages.length) * 100));
      } catch (error) {
        console.error('Error delivering message:', error);
        enqueueSnackbar(`Failed to deliver message: ${error.message}`, {
          variant: 'error'
        });
      }
    }

    enqueueSnackbar(`Delivered ${successCount} of ${totalMessages} messages`, {
      variant: successCount === totalMessages ? 'success' : 'warning'
    });

    setIsDelivering(false);
    setSelectedConversations([]);

    // Refresh conversations
    dispatch(fetchConversationsWithMessages({}));
  };

  // Handle generating replies for multiple selected conversations
  const handleGenerateRepliesForSelected = () => {
    if (selectedConversations.length === 0) return;
    // Show the confirmation dialog instead of processing immediately
    setShowGenerateRepliesDialog(true);
  };

  // This will be called after the user confirms in the dialog
  const processGenerateRepliesForSelected = async () => {
    if (selectedConversations.length === 0) return;

    // Set a loading state
    setIsGenerating(true);
    setIsDelivering(false);

    const totalConversations = selectedConversations.length;
    setTotalToDeliver(totalConversations);
    setDeliveredCount(0);

    // Track successful generations in a local variable
    let successCount = 0;

    // Process conversations one by one
    for (let i = 0; i < selectedConversations.length; i++) {
      const conversationId = selectedConversations[i];
      const conversation = conversations.find(conv => conv.id === conversationId);

      if (conversation) {
        try {
          await dispatch(conversationAutocompleteNow({
            conversationId: conversation.id,
            channelId: conversation.channel_id
          }));
          successCount++;
          setDeliveredCount(successCount);
          setDeliveryProgress(Math.round(((i + 1) / selectedConversations.length) * 100));
        } catch (error) {
          console.error('Error generating reply:', error);
          enqueueSnackbar(`Failed to generate reply for conversation: ${error.message}`, {
            variant: 'error'
          });
        }
      }
    }

    enqueueSnackbar(`Requested replies for ${successCount} of ${totalConversations} conversations`, {
      variant: successCount === totalConversations ? 'success' : 'warning'
    });

    setIsGenerating(false);
    // Keep conversations selected so user can see which ones got replies

    // Refresh conversations after a short delay to see the updated statuses
    setTimeout(() => {
      dispatch(fetchConversationsWithMessages({}));
    }, 1000);
  };

  // Handle posting function messages to all selected conversations
  const processFunctionMessageForSelected = async (functionMessageText: string, mediaUrls: string[] = []) => {
    if (selectedConversations.length === 0) return;

    // Set a loading state
    setIsGenerating(true);
    setIsDelivering(false);
    setTotalToDeliver(selectedConversations.length);
    setDeliveredCount(0);

    // Process conversations one by one
    for (let i = 0; i < selectedConversations.length; i++) {
      const conversationId = selectedConversations[i];
      const conversation = conversations.find(conv => conv.id === conversationId);

      if (conversation) {
        try {
          // Send a function message to the conversation using the API directly
          // rather than using the existing sendMessage action
          const url = `${process.env.REACT_APP_API_V3_URL}/chat/private/messages/post_message`;
          const data = {
            conversation_id: conversation.id,
            content: functionMessageText,
            from_role: 'function',
            channel_id: conversation.channel_id,
            media_urls: mediaUrls.length > 0 ? mediaUrls : undefined
          };

          await httpClient.post(url, data);

          // After posting the function message, trigger the autocomplete
          await dispatch(conversationAutocompleteNow({
            conversationId: conversation.id,
            channelId: conversation.channel_id
          }));

          setDeliveredCount(prev => prev + 1);
          setDeliveryProgress(Math.round(((i + 1) / selectedConversations.length) * 100));
        } catch (error) {
          console.error('Error posting function message:', error);
          enqueueSnackbar(`Failed to post function message to conversation: ${error.message}`, {
            variant: 'error'
          });
        }
      }
    }

    enqueueSnackbar(`Posted function message to ${deliveredCount} of ${totalToDeliver} conversations`, {
      variant: deliveredCount === totalToDeliver ? 'success' : 'warning'
    });

    setIsGenerating(false);
    // Keep conversations selected so user can see which ones got messages

    // Refresh conversations after a short delay to see the updated statuses
    setTimeout(() => {
      dispatch(fetchConversationsWithMessages({}));
    }, 1000);
  };

  // Handle bulk update of delivery states for selected conversations
  const handleUpdateDeliveryStateForSelected = async (newState: 'play' | 'pause') => {
    if (selectedConversations.length === 0) return;

    // Set a loading state
    setIsPausingResuming(true);
    setCurrentDeliveryStateAction(newState);
    setIsDelivering(false);
    setIsGenerating(false);

    const totalConversations = selectedConversations.length;
    setTotalToPauseResume(totalConversations);
    setPausedResumedCount(0);
    setPauseResumeProgress(0);

    // Track successful updates in a local variable
    let successCount = 0;

    // Process conversations one by one
    for (let i = 0; i < selectedConversations.length; i++) {
      const conversationId = selectedConversations[i];
      const conversation = conversations.find(conv => conv.id === conversationId);

      if (conversation) {
        try {
          await dispatch(updateConversationDeliveryState({
            conversationId: conversation.id,
            deliveryState: newState,
            channelId: conversation.channel_id
          }));
          successCount++;
          setPausedResumedCount(successCount);
          setPauseResumeProgress(Math.round(((i + 1) / selectedConversations.length) * 100));
        } catch (error) {
          console.error(`Error updating delivery state to ${newState}:`, error);
          enqueueSnackbar(`Failed to update delivery state: ${error.message}`, {
            variant: 'error'
          });
        }
      }
    }

    enqueueSnackbar(`Updated delivery state to ${newState} for ${successCount} of ${totalConversations} conversations`, {
      variant: successCount === totalConversations ? 'success' : 'warning'
    });

    setIsPausingResuming(false);
    setCurrentDeliveryStateAction(null);
    // Keep conversations selected so user can see which ones were updated

    // Refresh conversations after a short delay to see the updated statuses
    setTimeout(() => {
      dispatch(fetchConversationsWithMessages({}));
    }, 1000);
  };

  const handleBulkDelete = async () => {
    if (selectedConversations.length === 0) {
      enqueueSnackbar('No conversations selected', { variant: 'warning' });
      return;
    }
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const result = await dispatch(deleteConversations(selectedConversations)).unwrap();

      if (result.successfulDeletes.length > 0) {
        enqueueSnackbar(`Successfully deleted ${result.successfulDeletes.length} conversations`, { variant: 'success' });
      }

      if (result.failedDeletes.length > 0) {
        enqueueSnackbar(`Failed to delete ${result.failedDeletes.length} conversations`, { variant: 'error' });
      }

      setSelectedConversations([]); // Clear selection after deletion
    } catch (error) {
      enqueueSnackbar('Error deleting conversations', { variant: 'error' });
    }
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    if (focusedConversation) {
      navigate(`?conversation_id=${focusedConversation.id}&channel_id=${focusedConversation.channel_id}`)
    }
  }, [focusedConversation]);

  // Add contacts as a dependency to useEffect to trigger re-render when contacts change
  useEffect(() => {
    if (!focusedConversation && conversations.length > 0) {
      setFocusedConversation(conversations[0]);
    }
    if (conversations.length === 0 && !conversationsLoading && conversationsInitialLoad) {
      setCreateConversationOpen(true);
    }
  }, [conversations, conversationsLoading, contacts, conversationsInitialLoad]);


  const displayOrder = ["Priority", "Pending Messages", "Today", "Yesterday", "Last 7 Days", "Older", "Closed"];

  // Filter conversations based on the active filter
  const filteredAndSortedConversations = useMemo(() => {
    // First filter based on active filter
    const filtered = filterConversations(conversations, activeFilter);

    // Then apply priority sorting to ensure priority items are at the top
    const prioritySorted = sortWithPriorityFirst(filtered);

    // Finally apply regular sort
    return prioritySorted.sort((a, b) => {
      if (sortField === 'updated_at') {
        return sortOrder === 'desc'
          ? new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
          : new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      } else if (sortField === 'subject') {
        return sortOrder === 'desc'
          ? b.subject?.localeCompare(a.subject || '')
          : a.subject?.localeCompare(b.subject || '');
      } else if (sortField === 'user_messages_count') {
        const countA = a.user_messages_count || 0;
        const countB = b.user_messages_count || 0;
        return sortOrder === 'desc' ? countB - countA : countA - countB;
      } else if (sortField === 'last_user_message_received_at') {
        const timeA = a.last_user_message_received_at ? new Date(a.last_user_message_received_at).getTime() : 0;
        const timeB = b.last_user_message_received_at ? new Date(b.last_user_message_received_at).getTime() : 0;
        return sortOrder === 'desc' ? timeB - timeA : timeA - timeB;
      }
      return 0;
    });
  }, [conversations, activeFilter, sortField, sortOrder]);

  // Handle filter change
  const handleFilterChange = (filterId: string) => {
    setActiveFilter(filterId);
  };

  // Handle advanced filter change
  const handleAdvancedFilterChange = (advancedFilters: Record<string, any>) => {
    // Apply the advanced filters using Redux
    dispatch(setFilter(advancedFilters));
    dispatch(fetchFilteredConversations(advancedFilters));
  };

  // Update your conversationsGroupedByDay function to use sortedConversations
  const conversationsGroupedByDay = (conversations) => {
    const groupedConversations: {
      [key: string]: {
        conversations: any[];
        count: number;
      };
    } = displayOrder.reduce((accumulator, label) => {
      accumulator[label] = { conversations: [], count: 0 };
      return accumulator;
    }, {});

    // No need to add 'Pending Messages' separately since it's already in displayOrder

    // Helper function to check if a conversation should be prioritized
    const shouldPrioritize = (conversation: any): boolean => {
      return priorityStatuses.includes(conversation.status) ||
        (conversation.memory?.summary?.human_admin_help_needed === true) ||
        (conversation.memory?.summary?.assistant_score !== undefined && conversation.memory.summary.assistant_score < 60);
    };

    // Use the already sorted conversations if we're in card view
    const conversationsToGroup = viewMode !== 'list'
      ? filteredAndSortedConversations
      : [...filteredAndSortedConversations].sort((a, b) => {
        const statusPriorityA = shouldPrioritize(a) ? 0 : 1;
        const statusPriorityB = shouldPrioritize(b) ? 0 : 1;
        if (statusPriorityA !== statusPriorityB) {
          return statusPriorityA - statusPriorityB;
        }
        const timeA = new Date(a.latest_message_at || a.updated_at).getTime();
        const timeB = new Date(b.latest_message_at || b.updated_at).getTime();
        return timeB - timeA; // Descending order
      });

    conversationsToGroup.forEach(conversation => {
      const hasPendingAssistantMessages = conversation.messages.some(
        msg => msg.status === 'pending_delivery' && msg.sender_role === 'assistant'
      );

      // First check for conversations with pending assistant messages
      if (hasPendingAssistantMessages) {
        groupedConversations['Pending Messages'].conversations.push(conversation);
        groupedConversations['Pending Messages'].count++;
      }
      // Then check other conditions
      else if (conversation.delivery_state === 'paused' && conversation.messages.some(msg => msg.status === 'pending_delivery')) {
        groupedConversations['Priority'].conversations.push(conversation);
        groupedConversations['Priority'].count++;
      } else if (shouldPrioritize(conversation)) {
        groupedConversations['Priority'].conversations.push(conversation);
        groupedConversations['Priority'].count++;
      } else if (conversation.status === 'closed') {
        groupedConversations['Closed'].conversations.push(conversation);
        groupedConversations['Closed'].count++;
      } else {
        const label = dateTimeFormatter(conversation.latest_message_at || conversation.updated_at, 'humanized_time_period');
        groupedConversations[label].conversations.push(conversation);
        groupedConversations[label].count++;
      }
    });

    return groupedConversations;
  };

  const groupedConversations = conversationsGroupedByDay(filteredAndSortedConversations || []);

  const loadingSkeletons = Array.from(new Array(6)).map((_, index) => {
    // Calculate width and minWidth based on viewMode and screen size
    // Note: These values should correspond to the ConversationCard component
    const getCardStyles = () => {
      // On small screens, use full width
      if (window.innerWidth < 600) { // sm breakpoint
        return {
          width: "100%",
          minWidth: "100%"
        };
      }

      // Default sizes for larger screens
      let cardWidth = viewMode === 'full'
        ? "calc(33.333% - 16px)"
        : viewMode === "small"
          ? "calc(20% - 16px)"
          : "100%";

      let cardMinWidth = viewMode === 'full'
        ? "280px"
        : viewMode === "small"
          ? "220px"
          : "280px";

      // Adjust for screen sizes
      if (window.innerWidth < 1536 && window.innerWidth >= 1200) { // xl breakpoint
        cardWidth = viewMode === 'full'
          ? "calc(33.333% - 16px)"
          : viewMode === "small"
            ? "calc(25% - 16px)"
            : "100%";
      } else if (window.innerWidth < 1200 && window.innerWidth >= 900) { // lg breakpoint
        cardWidth = viewMode === 'full'
          ? "calc(50% - 16px)"
          : viewMode === "small"
            ? "calc(33.333% - 16px)"
            : "100%";
      } else if (window.innerWidth < 900 && window.innerWidth >= 600) { // md breakpoint
        cardWidth = viewMode === 'full'
          ? "calc(50% - 16px)"
          : viewMode === "small"
            ? "calc(50% - 16px)"
            : "100%";
      }

      return { width: cardWidth, minWidth: cardMinWidth };
    };

    const cardStyles = getCardStyles();
    const cardHeight = viewMode === 'small' ? '180px' : '300px';

    return (
      <Box key={index} sx={{
        borderRadius: '8px',
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: cardHeight,
        width: cardStyles.width,
        minWidth: cardStyles.minWidth,
        bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.background.paper,
        p: 1.5,
        boxShadow: darkMode ? 'none' : '0 2px 4px rgba(0,0,0,0.05)',
        border: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
        opacity: 0.9,
        position: 'relative',
      }}>
        {/* Simplified header section */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Skeleton variant="text" width={100} height={16} animation="wave" />
          <Skeleton variant="circular" width={20} height={20} animation="wave" />
        </Box>

        {/* Simplified subject */}
        <Skeleton variant="text" width="60%" height={20} animation="wave" sx={{ mb: 1 }} />

        {/* Simplified contact info box */}
        <Box sx={{
          p: 1,
          mb: 1,
          borderRadius: '6px',
          bgcolor: darkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.03)',
          border: `1px solid ${darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.06)'}`,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton variant="circular" width={24} height={24} animation="wave" />
            <Skeleton variant="text" width={120} height={16} animation="wave" />
          </Box>
        </Box>

        {/* Simplified message content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5, mb: 1 }}>
          <Skeleton variant="text" width="90%" height={14} animation="wave" />
          <Skeleton variant="text" width="75%" height={14} animation="wave" />
        </Box>

        {/* Simplified footer */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 1,
          borderTop: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'}`,
          mt: 'auto'
        }}>
          <Skeleton variant="circular" width={24} height={24} animation="wave" />
          <Skeleton variant="rounded" width={100} height={26} animation="wave" sx={{ borderRadius: '4px' }} />
        </Box>

        {/* Status indicator in top right */}
        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
          <Skeleton variant="circular" width={20} height={20} animation="wave" />
        </Box>

        {/* Selection checkbox in top left */}
        <Box sx={{ position: 'absolute', top: 10, left: 10 }}>
          <Skeleton variant="circular" width={24} height={24} animation="wave" />
        </Box>
      </Box>
    );
  });

  const toggleViewIcon =
    viewMode === 'full' ? <ViewStream /> :
      viewMode === 'small' ? <ViewAgenda /> :
        <ViewList />;

  const handleViewModeToggle = () => {
    setViewMode(prevMode => {
      if (prevMode === 'full') return 'small';
      if (prevMode === 'small') return 'list';
      return 'full';
    });
  };

  // Add sort menu handling
  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSortMenuAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  const handleSortChange = (field: SortField) => {
    if (sortField === field) {
      // Toggle sort order if clicking the same field
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default order
      setSortField(field);
      setSortOrder('desc');
    }
    handleSortMenuClose();
  };

  // Calculate cursor for pagination
  const calculateCursor = useCallback((targetPage: number, currentPage: number) => {
    if (targetPage === 1) return null;

    let cursor = null;

    // For forward navigation (next page)
    if (targetPage > currentPage) {
      // Get the last conversation from the current page for forward navigation
      const lastConversation = pageCache[currentPage]?.[pageCache[currentPage].length - 1];
      if (lastConversation?.latest_message_at) {
        cursor = lastConversation.latest_message_at;
      } else {
        // Fallback: try to find the last conversation in the store if page cache is empty
        const lastConversationInStore = conversations[conversations.length - 1]?.latest_message_at;
        cursor = lastConversationInStore || null;
      }
    }
    // For backward navigation (previous page)
    else if (targetPage < currentPage && targetPage > 1) {
      // Find a page we have in cache that is before the target page
      let foundPage = false;
      let referencePage = targetPage - 1;

      // Try to find a cached page to use as reference
      while (referencePage >= 1 && !foundPage) {
        if (pageCache[referencePage]?.length > 0) {
          const lastConversation = pageCache[referencePage][pageCache[referencePage].length - 1];
          cursor = lastConversation.latest_message_at;
          foundPage = true;
        } else {
          referencePage--;
        }
      }

      // If we couldn't find a reference page, return a special value
      if (!foundPage && referencePage === 0) {
        return { needsReset: true, targetPage };
      }
    }

    return cursor;
  }, [pageCache, conversations]);

  // Handle page change
  const handlePageChange = useCallback((page: number) => {
    if (page === currentPage) return;

    // Store the current page before changing it
    const previousPage = currentPage;
    setCurrentPage(page);

    // Always make the API call, don't check pageCache
    if (page === 1) {
      if (isFilterActive) {
        dispatch(fetchFilteredConversations({
          ...filterObject,
          cursor: null,
          limit,
          messagesLimit: 3
        }));
      } else {
        dispatch(fetchConversationsWithMessages({
          cursor: null,
          limit,
          messagesLimit: 3
        }));
      }
      return;
    }

    const cursorResult = calculateCursor(page, previousPage);

    if (cursorResult && typeof cursorResult === 'object' && 'needsReset' in cursorResult) {
      // If we need to reset, start from page 1
      setCurrentPage(1);
      if (isFilterActive) {
        dispatch(fetchFilteredConversations({
          ...filterObject,
          cursor: null,
          limit,
          messagesLimit: 3
        }));
      } else {
        dispatch(fetchConversationsWithMessages({
          cursor: null,
          limit,
          messagesLimit: 3
        }));
      }
      return;
    }

    // Convert cursor date to proper format if it exists
    const formattedCursor = cursorResult ? cursorResult : null;

    // Normal page navigation with cursor
    if (isFilterActive) {
      dispatch(fetchFilteredConversations({
        ...filterObject,
        cursor: formattedCursor,
        limit,
        messagesLimit: 3,
        conversationLatestMessageCreatedAtBefore: formattedCursor ? new Date(formattedCursor) : null
      }));
    } else {
      dispatch(fetchConversationsWithMessages({
        cursor: formattedCursor,
        limit,
        messagesLimit: 3,
        conversationLatestMessageCreatedAtBefore: formattedCursor ? new Date(formattedCursor) : null
      }));
    }
  }, [currentPage, isFilterActive, filterObject, limit, dispatch, calculateCursor]);

  // Update cache when conversations change
  useEffect(() => {
    if (conversations.length > 0 && !conversationsLoading) {
      // Store only the regular conversations in the cache, not priority ones
      const regularConversations = isFilterActive ?
        filteredConversations :
        unfilteredConversations;

      setPageCache(prev => ({
        ...prev,
        [currentPage]: regularConversations
      }));
    }
  }, [unfilteredConversations, filteredConversations, currentPage, conversationsLoading, isFilterActive]);

  // Clear cache when filters change
  useEffect(() => {
    setPageCache({});
    setCurrentPage(1);
  }, [filterObject, limit]);

  return (
    <Box className="conversations-list" sx={{ ...styles.mainContainer }}>
      {/* Selection toolbar with view mode controls */}
      <Box
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          py: 1,
          width: '100%',
          bgcolor: darkMode ? 'background.paper' : 'grey.100',
          borderBottom: 1,
          borderColor: 'divider',
          minHeight: '48px !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {showSelectionColumn && (
            <Checkbox
              indeterminate={selectedConversations.length > 0 && selectedConversations.length < conversations.length}
              checked={conversations.length > 0 && selectedConversations.length === conversations.length}
              onChange={handleSelectAll}
              inputProps={{ 'aria-label': 'select all conversations' }}
              size="small"
              color="primary"
              sx={{ p: 0.5, mr: 1 }}
            />
          )}
        </Box>

        {/* View Mode Controls */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={(e, newMode) => {
              if (newMode !== null) setViewMode(newMode);
            }}
            size="small"
            aria-label="view mode"
            sx={{ mr: 1 }}
          >
            <ToggleButton value="full" aria-label="full view">
              <ViewAgenda fontSize="small" />
            </ToggleButton>
            <ToggleButton value="small" aria-label="small view">
              <ViewStream fontSize="small" />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewList fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>

          <Tooltip title="Sort conversations">
            <IconButton
              size="small"
              onClick={handleSortMenuOpen}
              aria-label="sort"
            >
              <Sort fontSize="small" />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={sortMenuAnchorEl}
            open={Boolean(sortMenuAnchorEl)}
            onClose={handleSortMenuClose}
          >
            <MenuItem
              onClick={() => handleSortChange('updated_at')}
              selected={sortField === 'updated_at'}
            >
              Sort by Last Updated
            </MenuItem>
            <MenuItem
              onClick={() => handleSortChange('subject')}
              selected={sortField === 'subject'}
            >
              Sort by Subject
            </MenuItem>
            <MenuItem
              onClick={() => handleSortChange('user_messages_count')}
              selected={sortField === 'user_messages_count'}
            >
              Sort by User Messages Count
            </MenuItem>
            <MenuItem
              onClick={() => handleSortChange('last_user_message_received_at')}
              selected={sortField === 'last_user_message_received_at'}
            >
              Sort by Last User Message
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* ConversationFilters */}
      <ConversationFilters
        selectedFilter={activeFilter}
        onFilterChange={handleFilterChange}
        conversations={conversations}
        onAdvancedFilterChange={handleAdvancedFilterChange}
        channels={channels || []}
      />

      {/* Add ConversationsPagination after filters but before content */}
      {conversations.length > 0 && (
        <ConversationsPagination
          totalItems={totalConversations}
          onPageChange={handlePageChange}
          pageCache={pageCache}
          setPageCache={setPageCache}
          currentPageProp={currentPage}
          loading={conversationsLoading}
        />
      )}

      {/* Selection info and action buttons */}
      {selectedConversations.length > 0 && (
        <>
          <Box
            sx={{
              width: '100%',
              py: 1.5,
              px: 2,
              bgcolor: darkMode ? 'rgba(25, 118, 210, 0.08)' : 'rgba(25, 118, 210, 0.05)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid',
              borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              {`${selectedConversations.length} of ${conversations.length} selected`}
              <IconButton
                size="small"
                color="inherit"
                onClick={() => setSelectedConversations([])}
                sx={{ ml: 1, p: 0.5 }}
              >
                <Box component="span" sx={{
                  fontSize: '16px',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  bgcolor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                  '&:hover': {
                    bgcolor: darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
                  }
                }}>
                  ×
                </Box>
              </IconButton>
            </Typography>
          </Box>

          <Box
            sx={{
              width: '100%',
              p: 1.5,
              bgcolor: darkMode ? 'rgba(25, 118, 210, 0.04)' : 'rgba(25, 118, 210, 0.02)',
              display: 'flex',
              overflowX: 'auto',
              flexWrap: 'nowrap',
              gap: 1.5,
              minHeight: '64px',
              alignItems: 'center',
              '&::-webkit-scrollbar': {
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                borderRadius: '4px',
              }
            }}
          >
            {/* Action buttons */}
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<AutoAwesome />}
              onClick={handleGenerateRepliesForSelected}
              disabled={isDelivering || isGenerating}
              sx={{ minWidth: '140px' }}
            >
              Generate Replies
              {isGenerating && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {deliveredCount}/{totalToDeliver}
                  </Typography>
                  <CircularProgress size={16} color="inherit" />
                </Box>
              )}
            </Button>

            {conversationsWithPendingAssistant.some(conv => selectedConversations.includes(conv.id)) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Send />}
                onClick={handleDeliverSelected}
                disabled={isDelivering || isGenerating}
                sx={{ minWidth: '120px' }}
              >
                Deliver All
                {isDelivering && (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <Typography variant="caption" sx={{ mr: 1 }}>
                      {deliveredCount}/{totalToDeliver}
                    </Typography>
                    <CircularProgress size={16} color="inherit" />
                  </Box>
                )}
              </Button>
            )}

            <Button
              variant="outlined"
              color="error"
              startIcon={<Delete />}
              onClick={handleBulkDelete}
              disabled={isPausingResuming || isDelivering || isGenerating}
              sx={{ minWidth: '120px' }}
            >
              Delete All
            </Button>
          </Box>
        </>
      )}

      {/* Status message for when filtering is active */}
      {activeFilter !== 'all_active' && (
        <Box
          sx={{
            p: 2,
            mb: 2,
            borderRadius: '8px',
            bgcolor: theme => alpha(theme.palette.info.main, 0.1),
            color: 'info.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="body2">
            Showing {filteredAndSortedConversations.length} {activeFilter.replace('_', ' ')} conversations
          </Typography>
          <Button
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setActiveFilter('all_active')}
          >
            View All
          </Button>
        </Box>
      )}

      <Hidden mdDown={conversationsListStyle === 'closed'}>
        {/* Conversations view */}
        {conversationsLoading ? (
          <Box sx={styles.loadingContainer}>
            {loadingSkeletons}
          </Box>
        ) : conversations.length === 0 ? (
          <Box sx={styles.emptyContainer}>
            <img src={emptyConversationsListSvg} alt="No conversations found" style={{ maxWidth: '200px', opacity: 0.8 }} />
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
              No conversations found
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 3, textAlign: 'center' }}>
              Start a new conversation by clicking the button below
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddComment />}
              onClick={() => setCreateConversationOpen(true)}
            >
              New Conversation
            </Button>
          </Box>
        ) : viewMode === 'list' ? (
          <ConversationListView
            conversations={filteredAndSortedConversations}
            handleConversationsListToggleClick={handleConversationsListToggleClick}
            selectedConversations={selectedConversations}
            onSelectConversation={handleSelectConversation}
            showSelectionColumn={showSelectionColumn}
            initialSortField={sortField}
            initialSortOrder={sortOrder}
          />
        ) : (
          <Box sx={styles.cardContainer}>
            {Object.entries(groupedConversations)
              .filter(([label, group]) => group.count > 0)
              .map(([label, group]) => (
                <Box key={label} sx={{ width: '100%', mb: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 1,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
                      },
                      // Highlight if any conversation in the group is selected
                      bgcolor: group.conversations.some(conv => selectedConversations.includes(conv.id))
                        ? darkMode
                          ? 'rgba(25, 118, 210, 0.12)'
                          : 'rgba(25, 118, 210, 0.08)'
                        : 'transparent'
                    }}
                    onClick={() => handleSelectGroup(label)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '1rem',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                          color: 'primary.main',
                        },
                        // Visual indication if all conversations in the group are selected
                        color: group.conversations.every(conv =>
                          selectedConversations.includes(conv.id)
                        ) ? 'primary.main' : 'inherit'
                      }}
                    >
                      {label} ({group.count})
                    </Typography>
                    <Tooltip title="Click to select all conversations in this group">
                      <Box component="span" sx={{
                        ml: 1,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={group.conversations.every(conv => selectedConversations.includes(conv.id))}
                          indeterminate={
                            group.conversations.some(conv => selectedConversations.includes(conv.id)) &&
                            !group.conversations.every(conv => selectedConversations.includes(conv.id))
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectGroup(label);
                          }}
                        />
                      </Box>
                    </Tooltip>
                    {label === 'Pending Messages' && (
                      <Tooltip title="Conversations with pending assistant messages are prioritized">
                        <Send fontSize="small" color="warning" sx={{ ml: 1 }} />
                      </Tooltip>
                    )}
                  </Box>
                  <Box sx={styles.conversationCardsContainer}>
                    {(viewMode as string) === 'list' ? (
                      <ConversationListView
                        conversations={group.conversations}
                        handleConversationsListToggleClick={handleConversationsListToggleClick}
                        selectedConversations={selectedConversations}
                        onSelectConversation={handleSelectConversation}
                        showSelectionColumn={showSelectionColumn}
                      />
                    ) : (
                      group.conversations.map((conversation) => (
                        <ConversationCard
                          key={conversation.id}
                          conversation={conversation}
                          variant={viewMode as 'full' | 'small'}
                          handleConversationsListToggleClick={handleConversationsListToggleClick}
                          isSelectable={showSelectionColumn}
                          isSelected={selectedConversations.includes(conversation.id)}
                          onSelect={handleSelectConversation}
                          focused={focusedConversation?.id === conversation.id}
                        />
                      ))
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        )}
      </Hidden>

      <ConversationCreateDialogV2
        open={createConversationOpen}
        handleClose={() => setCreateConversationOpen(false)}
      />

      <GenerateRepliesConfirmationDialog
        open={showGenerateRepliesDialog}
        onClose={() => setShowGenerateRepliesDialog(false)}
        onConfirm={(functionMessage?: string, fileUrls?: string[]) => {
          setShowGenerateRepliesDialog(false);
          if (functionMessage) {
            processFunctionMessageForSelected(functionMessage, fileUrls);
          } else {
            processGenerateRepliesForSelected();
          }
        }}
        selectedConversations={selectedConversations.map(id => conversations.find(conv => conv.id === id)).filter(Boolean)}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Bulk Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete {selectedConversations.length} conversations? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConversationsListV2;
