import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Chip, useTheme, Tooltip } from '@mui/material';
import { Business, VerifiedUser, Person, Phone, Email } from '@mui/icons-material';
import { useAppSelector, useAppDispatch } from 'src/store';
import { selectConversationById } from 'src/features/account/conversations.slice';
import { getContact, selectContactById } from 'src/features/account/contacts.slice';
import ContactDialog from '../Contacts/ContactDialog';
import ChannelEditDialog from './ChannelEditDialog';

// Define props interface with optional parameters
interface ConversationContactsProps {
  conversation?: any;
  conversation_id?: string;
  sx?: any;
}

// Component takes either conversation object or conversation_id (at least one must be provided)
const ConversationContacts: React.FC<ConversationContactsProps> = ({
  conversation,
  conversation_id,
  sx = {}
}) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();

  // State for dialog controls
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [channelDialogOpen, setChannelDialogOpen] = useState(false);

  // Only fetch from store if we don't have the conversation object already
  const conversationFromStore = useAppSelector(
    state => !conversation && conversation_id ? selectConversationById(state, conversation_id) : null
  );

  // Use provided conversation object or fallback to store-fetched one
  const activeConversation = conversation || conversationFromStore;

  // Extract the user entity and contact ID (if available)
  const userEntity = activeConversation?.participating_entities?.find(entity =>
    entity.role === 'user' && entity.contact_id
  );
  const contactId = userEntity?.contact_id;

  // Try to get the contact from Redux store
  const contactFromStore = useAppSelector(state =>
    contactId ? selectContactById(state, contactId) : null
  );

  // Uneeded, and it is resulting in A LOT of requests to the API
  // useEffect(() => {
  //   if (contactId && !contactFromStore) {
  //     // Contact doesn't exist in store, dispatch action to fetch it
  //     // dispatch(getContact(contactId));
  //   }
  // }, [contactId, contactFromStore, dispatch]);

  // Root container styles
  const containerStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    overflowX: 'auto',
    ...sx,
  };

  // Card styles
  const cardStyles = {
    contactCard: {
      cursor: 'pointer',
      borderRadius: '6px',
      minWidth: '160px',
      maxWidth: '220px',
      border: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.08)'}`,
      padding: '8px',
      bgcolor: darkMode ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.8)',
      transition: 'all 0.2s ease-in-out',
      display: 'flex',
      flexDirection: 'row', // Changed to row to have avatar left, details right
      gap: '10px', // Space between avatar and details
      alignItems: 'flex-start', // Align items to the top
      '&:hover': {
        boxShadow: `0 2px 8px ${darkMode ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.1)'}`,
        borderColor: darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.15)',
        transform: 'translateY(-1px)',
      },
    },
    loadingCard: {
      cursor: 'pointer',
      borderRadius: '6px',
      minWidth: '160px',
      maxWidth: '220px',
      border: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.08)'}`,
      padding: '8px',
      bgcolor: darkMode ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.03)',
      display: 'flex',
      flexDirection: 'row', // Changed to row
      gap: '10px',
      alignItems: 'flex-start',
      opacity: 0.7,
    },
    assistantCard: {
      cursor: 'pointer',
      borderRadius: '6px',
      minWidth: '160px',
      maxWidth: '220px',
      border: `1px solid ${darkMode ? 'rgba(100,100,255,0.2)' : 'rgba(0,50,200,0.1)'}`,
      padding: '8px',
      bgcolor: darkMode ? 'rgba(30,30,80,0.3)' : 'rgba(240,245,255,0.8)',
      transition: 'all 0.2s ease-in-out',
      display: 'flex',
      flexDirection: 'row', // Changed to row
      gap: '10px',
      alignItems: 'flex-start',
      '&:hover': {
        boxShadow: `0 2px 8px ${darkMode ? 'rgba(0,0,100,0.5)' : 'rgba(0,0,200,0.1)'}`,
        borderColor: darkMode ? 'rgba(100,100,255,0.3)' : 'rgba(0,50,200,0.2)',
        transform: 'translateY(-1px)',
      },
    },
    avatar: {
      width: 36,
      height: 36,
      border: `1px solid ${darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'}`,
      backgroundColor: darkMode ? theme.palette.grey[800] : theme.palette.grey[200],
      // Removed marginBottom as avatar is now in its own column
    },
    assistantAvatar: {
      width: 36,
      height: 36,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'}`,
      // Removed marginBottom
    },
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minWidth: 0, // Important for text overflow to work properly
    },
    name: {
      fontWeight: 600,
      fontSize: '0.9rem',
      lineHeight: 1.2,
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: darkMode ? theme.palette.grey[300] : theme.palette.grey[900],
      marginBottom: '2px',
    },
    detail: {
      fontSize: '0.7rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: darkMode ? theme.palette.grey[400] : theme.palette.grey[600],
      marginBottom: '2px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    roleChip: {
      height: '18px',
      fontSize: '0.65rem',
      marginTop: '4px',
      alignSelf: 'flex-start', // Align chip to the left in details column
    },
  };

  // If no conversation data is available, show a placeholder
  if (!activeConversation) {
    return <Box sx={containerStyles}>
      <Box sx={cardStyles.contactCard}>
        <Typography sx={cardStyles.name}>No contact data</Typography>
      </Box>
    </Box>;
  }

  const assistantEntity = activeConversation.participating_entities?.find(entity =>
    entity.role === 'assistant'
  );

  // Combine contact data from store with entity data
  const userContact = contactId ? {
    id: contactId,
    // Prefer data from Redux store if available
    name: contactFromStore?.name || userEntity?.name || '',
    firstName: contactFromStore?.first_name,
    lastName: contactFromStore?.last_name,
    // Use entity identifier as fallback for phone
    phone: contactFromStore?.phone_number || userEntity?.identifier,
    // Use memory data as fallback for email
    email: contactFromStore?.email || activeConversation.memory?.user_details?.email,
    picture: contactFromStore?.picture_url || activeConversation.memory?.user_details?.picture_url,
    role: 'user',
    // Allow us to know if we're showing placeholder data
    isLoading: !userEntity && contactId
  } : null;

  // Get assistant information
  const assistantContact = assistantEntity ? {
    id: assistantEntity.id,
    name: assistantEntity.name,
    team: '',
    agency: '',
    role: 'assistant'
  } : null;

  // Generate display name based on available data
  const getDisplayName = (contact) => {
    if (contact.firstName && contact.lastName) {
      return `${contact.firstName} ${contact.lastName}`;
    }
    return contact.name || 'Contact';
  };

  // Handle opening contact dialog
  const handleOpenContactDialog = () => {
    if (contactId) {
      setContactDialogOpen(true);
    }
  };

  // Handle opening channel/assistant dialog  
  const handleOpenChannelDialog = () => {
    if (activeConversation.channel_id) {
      setChannelDialogOpen(true);
    }
  };

  return (
    <Box sx={containerStyles}>
      {/* User Contact Card */}
      {userContact && (
        <Tooltip title={`${getDisplayName(userContact)} - Contact`} arrow>
          <Box
            sx={userContact.isLoading ? cardStyles.loadingCard : cardStyles.contactCard}
            onClick={handleOpenContactDialog}
          >
            {/* Avatar column */}
            <Avatar sx={cardStyles.avatar} src={userContact.picture}>
              {getDisplayName(userContact).charAt(0) || <Person />}
            </Avatar>

            {/* Details column */}
            <Box sx={cardStyles.detailsContainer}>
              <Typography sx={cardStyles.name} noWrap>
                {getDisplayName(userContact)}
                {userContact.isLoading && " (Loading...)"}
              </Typography>

              {userContact.phone && (
                <Typography sx={cardStyles.detail} noWrap>
                  <Phone sx={{ fontSize: '12px', color: darkMode ? '#aaa' : '#666' }} />
                  {userContact.phone}
                </Typography>
              )}

              {userContact.email && (
                <Typography sx={cardStyles.detail} noWrap>
                  <Email sx={{ fontSize: '12px', color: darkMode ? '#aaa' : '#666' }} />
                  {userContact.email}
                </Typography>
              )}

              <Chip
                label="Contact"
                size="small"
                color="primary"
                variant="outlined"
                sx={cardStyles.roleChip}
              />
            </Box>
          </Box>
        </Tooltip>
      )}

      {/* Assistant Card */}
      {assistantContact && (
        <Tooltip title={`${assistantContact.name} - ${assistantContact.team}`} arrow>
          <Box
            sx={cardStyles.assistantCard}
            onClick={handleOpenChannelDialog}
          >
            {/* Avatar column */}
            <Avatar sx={cardStyles.assistantAvatar}>
              {assistantContact.name ? assistantContact.name.charAt(0) : <VerifiedUser />}
            </Avatar>

            {/* Details column */}
            <Box sx={cardStyles.detailsContainer}>
              <Typography sx={cardStyles.name} noWrap>
                {assistantContact.name}
              </Typography>

              <Typography sx={cardStyles.detail} noWrap>
                <Business fontSize="small" sx={{ fontSize: '12px' }} />
                {assistantContact.team}
              </Typography>

              {assistantContact.agency && (
                <Typography sx={cardStyles.detail} noWrap>
                  {assistantContact.agency}
                </Typography>
              )}

              <Chip
                label="Assistant"
                size="small"
                color="secondary"
                variant="outlined"
                sx={cardStyles.roleChip}
              />
            </Box>
          </Box>
        </Tooltip>
      )}

      {/* Contact Dialog */}
      {contactDialogOpen && (
        <ContactDialog
          contactId={contactId}
          open={contactDialogOpen}
          onClose={() => setContactDialogOpen(false)}
        />
      )}

      {/* Channel/Assistant Dialog */}
      {channelDialogOpen && (
        <ChannelEditDialog
          open={channelDialogOpen}
          handleClose={() => setChannelDialogOpen(false)}
          handleBack={() => setChannelDialogOpen(false)}
          channelId={activeConversation.channel_id}
        />
      )}
    </Box>
  );
};

export default ConversationContacts;
