import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchFilteredContacts, setFilter } from 'src/features/account/filteredContacts.slice';
import { selectIsFilterActive } from 'src/features/account/filteredContacts.slice';
import { resetPagination, selectLimit } from 'src/features/account/contacts.slice';
import debounce from 'lodash/debounce';
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Collapse,
  Paper,
  Fade,
  Chip,
  Button,
  Divider,
  Tooltip,
  Badge,
  InputBase,
  ClickAwayListener,
  alpha,
  Zoom,
  Alert,
  Snackbar,
  MenuItem,
  Popover,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  InputAdornment,
  Slider,
  SelectChangeEvent,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterSelector from './FilterSelector';
import FilterChip from './FilterChip';
import { FilterOption, FilterValue } from 'src/components/Contacts/types';

const drawerWidth = 360; // Increased width for better spacing
const DEBOUNCE_DELAY = 700; // Increased to 700ms as requested

const ContactsFilterBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading);
  const [inputValue, setInputValue] = useState('');
  // State for Popover anchor
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // 1) Define new state to track the score range slider value:
  const [scoreRange, setScoreRange] = useState<number[]>([0, 100]);
  const limit = useAppSelector(selectLimit);
  // Add state for hover detection on filter badge
  const [isHovered, setIsHovered] = useState(false);

  const initialFilters = {
    first_name: null,
    last_name: null,
    name: null,
    status: null,
    tags: [], // Keep as []
    company_name: null,
    address: null,
    created_at_before: null,
    created_at_after: null,
    last_contacted_at_before: null,
    last_contacted_at_after: null,
    zip: null,
    state: null,
    phone_number: null,
    email: null,
    without_phone_number: false,
    without_email: false,
    company_size: null,
    company_industry: null,
    company_website: null,
    company_address: null,
    stage: null,
    notes: null,
    favorite: null,
    has_no_conversations: null,
    score_greater_than: null,
    score_less_than: null,
    limit: 10, // Default limit value
  };
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const filterError = useAppSelector(state => state.filteredContacts.error);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const drawerContentRef = useRef<HTMLDivElement>(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<FilterValue[]>([]);
  const [expandedFilterId, setExpandedFilterId] = useState<string | null>(null);
  const [filterSelectorOpen, setFilterSelectorOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [animateFilters, setAnimateFilters] = useState(false);
  const [miniFilterBarVisible, setMiniFilterBarVisible] = useState(false);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  // Show error snackbar when filter error occurs
  useEffect(() => {
    if (filterError) {
      setErrorSnackbarOpen(true);
    }
  }, [filterError]);

  // Create a debounced version of filter application
  const debouncedApplyFilters = useCallback((filters: FilterValue[]) => {
    const filterParams: Record<string, any> = {};

    filters.forEach(filter => {
      // Handle special case for email_not_contains to ensure partial matches work
      if (filter.option.id === 'email_not_contains' && Array.isArray(filter.value)) {
        // Send each term separately to ensure proper %LIKE% matching on the backend
        filterParams[filter.option.id] = filter.value;
      }
      // Handle score range - convert to separate greater/less than parameters
      else if (filter.option.id === 'score' && typeof filter.value === 'object' && filter.value !== null) {
        const scoreRange = filter.value as { min: number; max: number };
        // Using separate parameters for score range instead of trying to combine them
        filterParams.score_greater_than = scoreRange.min;
        filterParams.score_less_than = scoreRange.max;
      }
      // Handle other array-type filters
      else if (Array.isArray(filter.value)) {
        filterParams[filter.option.id] = filter.value.join(',');
      }
      // Handle non-array filters
      else {
        filterParams[filter.option.id] = filter.value;
      }
    });

    dispatch(setFilter(filterParams));
  }, [dispatch]);

  // Create the debounced function
  const debouncedFilterFunction = useMemo(() =>
    debounce((filters: FilterValue[]) => {
      debouncedApplyFilters(filters);
    }, DEBOUNCE_DELAY),
    [debouncedApplyFilters, DEBOUNCE_DELAY]
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedFilterFunction.cancel();
      setIsDrawerOpen(false);
    };
  }, [debouncedFilterFunction]);

  // Set mini filter bar visible based on active filters
  useEffect(() => {
    setMiniFilterBarVisible(activeFilters.length > 0 && !isDrawerOpen);
  }, [activeFilters.length, isDrawerOpen]);

  const handleFilterSelect = (option: FilterOption | null) => {
    if (!option) return;


    let defaultValue: any;
    switch (option.id) {
      case 'has_no_conversations':
        defaultValue = true;
        break;
      case 'email':
      case 'email_not_contains':
      case 'tags':
      case 'tags_not_contains':
      case 'metadata_contains':
      case 'metadata_not_contains':
        defaultValue = [];
        break;
      case 'limit':
        defaultValue = 50;
        break;
      case 'score':
        defaultValue = { min: 0, max: 100 };
        break;
      case 'favorite':
        defaultValue = true;
        break;
      case 'without_phone_number':
      case 'without_email':
        defaultValue = true;
        break;
      default:
        defaultValue = '';
    }

    setActiveFilters(prev => {
      const newFilters = [...prev, { option, value: defaultValue }];
      debouncedFilterFunction(newFilters);
      return newFilters;
    });

    // Automatically expand the newly added filter
    setExpandedFilterId(option.id);
    setFilterSelectorOpen(false);
  };

  const handleFilterChange = (index: number, newValue: any) => {
    setActiveFilters(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], value: newValue };
      debouncedFilterFunction(updated);
      return updated;
    });
  };

  const handleFilterRemove = (filterId: string) => {
    setActiveFilters(prev => {
      const updated = prev.filter(filter => filter.option.id !== filterId);
      debouncedFilterFunction(updated);
      return updated;
    });
    setExpandedFilterId(null);
  };

  // Function to clear all filters
  const handleClearFilters = () => {
    setActiveFilters([]);
    setExpandedFilterId(null);
    dispatch(resetPagination());
    setInputValue('');
    dispatch(setFilter({}));
    dispatch(fetchFilteredContacts({ limit }));
  };

  const handleFilterClick = (filterId: string) => {
    setExpandedFilterId(expandedFilterId === filterId ? null : filterId);
  };

  const handleFilterBlur = (filterId: string) => {
    setActiveFilters(prev => {
      const filterIndex = prev.findIndex(f => f.option.id === filterId);
      if (filterIndex === -1) return prev;

      const filter = prev[filterIndex];
      const isEmpty = Array.isArray(filter.value)
        ? !filter.value.length || filter.value.every(v => !v.trim())
        : typeof filter.value === 'string' && !filter.value.trim();

      if (isEmpty) {
        const updated = prev.filter(f => f.option.id !== filterId);
        debouncedFilterFunction(updated);
        setExpandedFilterId(null);
        return updated;
      }
      return prev;
    });
  };

  const handleOpenFilterSelector = () => {
    setFilterSelectorOpen(true);
  };

  const handleCloseFilterSelector = () => {
    setFilterSelectorOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Function to handle keyboard shortcuts
  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Escape key closes drawer
    if (e.key === 'Escape') {
      setIsDrawerOpen(false);
    }

    // Ctrl+F opens the filter drawer
    if (e.ctrlKey && e.key === 'f') {
      e.preventDefault();
      setIsDrawerOpen(true);
    }
  };

  // Determine the active filter count per group
  const getFilterCountsByGroup = () => {
    const counts: Record<string, number> = {};
    activeFilters.forEach(filter => {
      const group = filter.option.group;
      counts[group] = (counts[group] || 0) + 1;
    });
    return counts;
  };

  const filterCountsByGroup = getFilterCountsByGroup();

  // Get color for filter group
  const getFilterGroupColor = (group: string) => {
    switch (group) {
      case 'Details':
        return theme.palette.primary.main;
      case 'Company':
        return theme.palette.success.main;
      case 'Activity':
        return theme.palette.info.main;
      case 'Advanced':
        return theme.palette.warning.main;
      default:
        return theme.palette.grey[500];
    }
  };

  // Active Filters Summary Component
  const ActiveFiltersSummary = () => {
    if (activeFilters.length === 0) return null;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          flexWrap: 'wrap',
          maxWidth: '100%',
          mt: 1,
          mb: 2
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
          Active filters:
        </Typography>

        {Object.entries(filterCountsByGroup).map(([group, count]) => (
          <Chip
            key={group}
            size="small"
            label={`${group}: ${count}`}
            sx={{
              backgroundColor: alpha(getFilterGroupColor(group), 0.15),
              color: getFilterGroupColor(group),
              borderRadius: 1,
              height: 24,
              fontWeight: 500,
            }}
          />
        ))}

        {activeFilters.length > 0 && (
          <Tooltip title="Clear all filters">
            <IconButton
              size="small"
              onClick={handleClearFilters}
              sx={{
                p: 0.5,
                bgcolor: 'rgba(255,255,255,0.1)',
                '&:hover': {
                  bgcolor: 'rgba(255,255,255,0.2)'
                }
              }}
            >
              <ClearAllIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  // Filter group divider component
  const FilterGroupDivider = ({ group, count }: { group: string, count: number }) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      mt: 2,
      mb: 1,
      pl: 1
    }}>
      <Typography
        variant="subtitle2"
        sx={{
          color: getFilterGroupColor(group),
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {group}
        {count > 0 && (
          <Chip
            size="small"
            label={count}
            sx={{
              ml: 1,
              height: 20,
              minWidth: 20,
              bgcolor: alpha(getFilterGroupColor(group), 0.15),
              color: getFilterGroupColor(group),
            }}
          />
        )}
      </Typography>
      <Divider sx={{ ml: 1, flex: 1 }} />
    </Box>
  );

  // Main filter selector UI
  const FilterSelectorUI = () => (
    <Box
      sx={{
        position: 'relative',
        mt: 1,
        mb: 2,
      }}
    >
      <Paper
        elevation={0}
        onClick={handleOpenFilterSelector}
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          border: '1px solid',
          borderColor: filterSelectorOpen
            ? `${theme.palette.primary.main}`
            : 'divider',
          borderRadius: 1.5,
          cursor: 'pointer',
          bgcolor: filterSelectorOpen
            ? alpha(theme.palette.primary.main, 0.08)
            : 'background.paper',
          '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.08),
            borderColor: alpha(theme.palette.primary.main, 0.3),
          },
          transition: 'all 0.2s ease',
        }}
      >
        <AddIcon sx={{ ml: 1, mr: 1, color: 'text.secondary' }} />
        <Typography
          variant="body1"
          sx={{
            color: 'text.secondary',
            fontWeight: 400,
          }}
        >
          Add a filter
        </Typography>
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            size="small"
            sx={{ mr: 0.5 }}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenFilterSelector();
            }}
          >
            <FilterListIcon fontSize="small" />
          </IconButton>
        </Box>
      </Paper>

      {filterSelectorOpen && (
        <Paper
          elevation={5}
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            mt: 0.5,
            p: 2,
            zIndex: 1300,
            maxHeight: '60vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
            borderRadius: 1.5,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1.5,
              }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search filters"
                inputProps={{ 'aria-label': 'search filters' }}
                value={searchQuery}
                onChange={handleSearchChange}
                autoFocus
              />
            </Paper>
          </Box>

          <Box sx={{
            overflowY: 'auto',
            maxHeight: 'calc(60vh - 70px)',
            pr: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.2)
                : alpha(theme.palette.common.black, 0.15),
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.3)
                : alpha(theme.palette.common.black, 0.25),
            }
          }}>
            <FilterSelector
              onFilterSelect={handleFilterSelect}
              selectedFilters={activeFilters.map(f => f.option.id)}
              searchQuery={searchQuery}
            />
          </Box>
        </Paper>
      )}
    </Box>
  );

  // Main drawer content
  const drawer = (
    <ClickAwayListener onClickAway={() => filterSelectorOpen && handleCloseFilterSelector()}>
      <Box
        ref={drawerContentRef}
        sx={{
          width: drawerWidth,
          p: 2.5,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.default',
          position: 'relative',
        }}
        onKeyDown={handleKeyDown}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterAltIcon sx={{ mr: 1, color: isFilterActive ? 'primary.main' : 'text.secondary' }} />
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Filters
            </Typography>
          </Box>
          <IconButton
            onClick={() => setIsDrawerOpen(false)}
            size="small"
            sx={{
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: alpha(theme.palette.common.white, 0.15),
              },
              width: 32,
              height: 32,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <FilterSelectorUI />

        <ActiveFiltersSummary />

        {activeFilters.length > 0 && (
          <Box sx={{
            flex: 1,
            overflowY: 'auto',
            pr: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.2)
                : alpha(theme.palette.common.black, 0.15),
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.3)
                : alpha(theme.palette.common.black, 0.25),
            }
          }}>
            {/* Group and display filters by category */}
            {['Details', 'Company', 'Activity', 'Advanced'].map(group => {
              const groupFilters = activeFilters.filter(f => f.option.group === group);
              if (groupFilters.length === 0) return null;

              return (
                <React.Fragment key={group}>
                  <FilterGroupDivider
                    group={group}
                    count={groupFilters.length}
                  />
                  <Stack spacing={1.5} sx={{ ml: 1 }}>
                    {groupFilters.map((filter, idx) => {
                      const index = activeFilters.findIndex(f => f.option.id === filter.option.id);
                      const isExpanded = expandedFilterId === filter.option.id;

                      return (
                        <Fade key={filter.option.id} in={true} timeout={300}>
                          <Box sx={{
                            position: 'relative',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                            },
                          }}>
                            <Paper
                              elevation={isExpanded ? 2 : 0}
                              sx={{
                                p: 1.5,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: isExpanded
                                  ? getFilterGroupColor(group)
                                  : alpha(theme.palette.divider, 0.8),
                                bgcolor: isExpanded
                                  ? alpha(getFilterGroupColor(group), 0.07)
                                  : 'transparent',
                                transition: 'all 0.2s ease',
                              }}
                            >
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                  {/* Filter header with label and value summary */}
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      mb: isExpanded ? 1 : 0
                                    }}
                                    onClick={() => handleFilterClick(filter.option.id)}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: 500,
                                        color: isExpanded ? getFilterGroupColor(group) : 'text.primary',
                                      }}
                                    >
                                      {filter.option.label}
                                    </Typography>

                                    {!isExpanded && filter.value && (
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                          opacity: 0.8,
                                          maxWidth: 150,
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          fontWeight: 400,
                                        }}
                                      >
                                        : {Array.isArray(filter.value)
                                          ? filter.value.join(', ')
                                          : filter.option.id === 'score' && typeof filter.value === 'object'
                                            ? `${(filter.value as any).min}-${(filter.value as any).max}`
                                            : String(filter.value)}
                                      </Typography>
                                    )}
                                  </Box>

                                  {/* Expanded filter input */}
                                  <Collapse in={isExpanded} timeout={200}>
                                    <Box sx={{ mt: 1 }}>
                                      <FilterChip
                                        filter={filter}
                                        onChange={(newValue) => handleFilterChange(index, newValue)}
                                        onBlur={() => handleFilterBlur(filter.option.id)}
                                        hideDelete
                                        expanded
                                      />
                                    </Box>
                                  </Collapse>
                                </Box>

                                {/* Delete button */}
                                <IconButton
                                  size="small"
                                  onClick={() => handleFilterRemove(filter.option.id)}
                                  sx={{
                                    ml: 0.5,
                                    color: 'text.secondary',
                                    '&:hover': {
                                      color: 'error.main',
                                      bgcolor: alpha(theme.palette.error.main, 0.1),
                                    },
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Paper>
                          </Box>
                        </Fade>
                      );
                    })}
                  </Stack>
                </React.Fragment>
              );
            })}
          </Box>
        )}

        {activeFilters.length === 0 && (
          <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.7,
            p: 3,
          }}>
            <TuneIcon sx={{ fontSize: 48, mb: 2, color: 'text.secondary' }} />
            <Typography variant="h6" align="center" sx={{ mb: 1, color: 'text.secondary' }}>
              No filters applied
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
              Add filters to narrow down your contacts list
            </Typography>
          </Box>
        )}

        {activeFilters.length > 0 && (
          <Box sx={{ mt: 2, pt: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
            <Button
              fullWidth
              variant="outlined"
              color="inherit"
              onClick={handleClearFilters}
              startIcon={<ClearAllIcon />}
              sx={{
                borderRadius: 1.5,
                textTransform: 'none',
                fontWeight: 500,
                color: 'text.secondary',
              }}
            >
              Clear all filters
            </Button>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );

  // Mini filter bar - shown below main toolbar when filters are active
  const MiniFilterBar = () => {
    // Memoize the filtered and sliced active filters to prevent recalculation
    const visibleFilters = useMemo(() => {
      return activeFilters.slice(0, isLargeScreen ? 5 : (isTablet ? 2 : 3));
    }, [activeFilters, isLargeScreen, isTablet]);

    if (!miniFilterBarVisible) return null;

    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        mt: 1,
        mb: 2,
        flexWrap: 'wrap',
        maxWidth: '100%',
      }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 500 }}>
          Filters:
        </Typography>

        {visibleFilters.map((filter) => (
          <Chip
            key={filter.option.id}
            size="small"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {filter.option.label}
                </Typography>
                <Typography variant="caption" sx={{ ml: 0.5, opacity: 0.8 }}>
                  : {Array.isArray(filter.value)
                    ? filter.value.join(', ')
                    : filter.option.id === 'score' && typeof filter.value === 'object'
                      ? `${(filter.value as any).min}-${(filter.value as any).max}`
                      : String(filter.value).substring(0, 20)}
                </Typography>
              </Box>
            }
            onDelete={() => handleFilterRemove(filter.option.id)}
            sx={{
              height: 28,
              borderRadius: 1,
              bgcolor: alpha(getFilterGroupColor(filter.option.group), 0.1),
              color: getFilterGroupColor(filter.option.group),
              '& .MuiChip-deleteIcon': {
                color: getFilterGroupColor(filter.option.group),
                '&:hover': {
                  color: theme.palette.error.main,
                },
              },
            }}
          />
        ))}

        {activeFilters.length > (isLargeScreen ? 5 : (isTablet ? 2 : 3)) && (
          <Chip
            size="small"
            label={`+${activeFilters.length - (isLargeScreen ? 5 : (isTablet ? 2 : 3))} more`}
            onClick={() => setIsDrawerOpen(true)}
            sx={{
              height: 28,
              borderRadius: 1,
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
          />
        )}

        <Tooltip title="Clear all filters">
          <IconButton
            size="small"
            onClick={handleClearFilters}
            sx={{
              p: 0.5,
              color: 'text.secondary',
              '&:hover': {
                color: theme.palette.error.main,
              },
            }}
          >
            <ClearAllIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Refresh filters">
          <IconButton
            size="small"
            onClick={handleRefreshFilters}
            sx={{
              p: 0.5,
              color: 'text.secondary',
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit filters">
          <IconButton
            size="small"
            onClick={() => setIsDrawerOpen(true)}
            sx={{
              p: 0.5,
              ml: 'auto',
              color: theme.palette.primary.main,
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            <TuneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  // Handle form submission 
  const handleSubmit = () => {
    // Just reuse the debouncedFilterFunction to avoid code duplication
    debouncedFilterFunction(activeFilters);
  };

  // Function to handle key press events in input fields
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      // Just use the debounced function directly
      debouncedFilterFunction(activeFilters);
    }
  };

  // Error snackbar to display API error messages
  const renderErrorSnackbar = () => (
    <Snackbar
      open={errorSnackbarOpen}
      autoHideDuration={6000}
      onClose={() => setErrorSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={() => setErrorSnackbarOpen(false)}
        severity="error"
        sx={{ width: '100%' }}
      >
        {filterError && filterError.includes('score is invalid')
          ? 'Score filter format is invalid. Please use a valid range.'
          : filterError || 'An error occurred with your filter'}
      </Alert>
    </Snackbar>
  );

  // Format phone number helper
  const formatPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/g, '');
  };

  // Function to close popovers
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Function to refresh filtered contacts
  const handleRefreshFilters = () => {
    if (isFilterActive) {
      // Use the active filters by calling the debounced function
      debouncedFilterFunction(activeFilters);
    }
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 1 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Badge
          badgeContent={activeFilters.length}
          color="primary"
          invisible={activeFilters.length === 0}
          overlap="circular"
        >
          <IconButton
            ref={filterButtonRef}
            onClick={() => setIsDrawerOpen(true)}
            color={isFilterActive ? "primary" : "default"}
            sx={{
              position: 'relative',
              bgcolor: isFilterActive ? alpha(theme.palette.primary.main, 0.1) : undefined,
              transition: 'all 0.2s ease',
              '&:hover': {
                bgcolor: isFilterActive
                  ? alpha(theme.palette.primary.main, 0.15)
                  : alpha(theme.palette.action.hover, 0.8),
              },
            }}
          >
            {contactsLoading ? (
              <CircularProgress size={24} />
            ) : (
              <FilterListIcon />
            )}
          </IconButton>
        </Badge>

        {isFilterActive && isHovered && !contactsLoading && (
          <Tooltip title="Refresh filters">
            <IconButton
              onClick={handleRefreshFilters}
              size="small"
              sx={{
                opacity: 0.8,
                transition: 'all 0.2s',
                '&:hover': {
                  opacity: 1,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <MiniFilterBar />
      {renderErrorSnackbar()}

      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        variant="temporary"
        sx={{
          '& .MuiDrawer-paper': {
            width: isMobile ? '100%' : drawerWidth,
            height: isMobile ? '90vh' : '100%',
            borderTopLeftRadius: isMobile ? theme.spacing(2) : 0,
            borderTopRightRadius: isMobile ? theme.spacing(2) : 0,
            boxShadow: '0 0 20px rgba(0,0,0,0.2)',
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default ContactsFilterBar;

// Added refresh icon functionality that appears on hover when filters are active
// This allows users to quickly re-apply filters without having to open the filter drawer
