import React, { useMemo } from 'react';
import { useAppSelector } from 'src/store';
import { selectAllConversations, Message } from 'src/features/account/conversations.slice';
import { Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, Legend } from 'recharts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MessageIcon from '@mui/icons-material/Message';
import GroupIcon from '@mui/icons-material/Group';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

interface DailyRoles {
  [date: string]: {
    assistant: number;
    user: number;
    function: number;
    total: number;
  }
}

interface MessageTimelineData {
  date: string;
  assistant: number;
  user: number;
  function: number;
}

const ConversationsMetrics = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const conversations = useAppSelector(selectAllConversations);

  const styles = {
    root: {
      width: '100%',
      p: 3,
      bgcolor: darkMode ? 'grey.900' : 'background.default',
    },
    card: {
      height: '100%',
      bgcolor: darkMode ? 'grey.800' : 'background.paper',
      boxShadow: darkMode ? 'none' : theme.shadows[4],
      border: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[200]}`,
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-4px)',
      },
    },
    metricValue: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    metricLabel: {
      color: darkMode ? theme.palette.grey[400] : theme.palette.grey[600],
      fontSize: '0.875rem',
    },
    icon: {
      fontSize: '2.5rem',
      color: theme.palette.primary.main,
      opacity: 0.8,
    },
  };

  const metrics = useMemo(() => {
    const now = new Date();
    
    return {
      totalConversations: conversations.length,
      activeConversations: conversations.filter(c => c.status !== 'closed').length,
      avgResponseTime: conversations.reduce((acc, conv) => {
        if (conv.messages && conv.messages.length > 1) {
          let totalResponseTime = 0;
          let responseCount = 0;
          
          for (let i = 1; i < conv.messages.length; i++) {
            const timeDiff = new Date(conv.messages[i].created_at).getTime() - 
                           new Date(conv.messages[i-1].created_at).getTime();
            totalResponseTime += timeDiff;
            responseCount++;
          }
          return acc + (totalResponseTime / responseCount);
        }
        return acc;
      }, 0) / conversations.filter(c => c.messages && c.messages.length > 1).length / 1000,
      
      messageCount: conversations.reduce((acc, conv) => acc + conv.message_count, 0),
      pendingDeliveryCount: conversations.reduce((acc, conv) => {
        return acc + (conv.messages?.filter(m => m.status === 'pending_delivery')?.length || 0);
      }, 0),
      statusDistribution: conversations.reduce((acc, conv) => {
        acc[conv.status] = (acc[conv.status] || 0) + 1;
        return acc;
      }, {}),
      deliveryStateDistribution: conversations.reduce((acc, conv) => {
        acc[conv.delivery_state] = (acc[conv.delivery_state] || 0) + 1;
        return acc;
      }, {}),
      messagesByDayAndRole: conversations.reduce<DailyRoles>((acc, conv) => {
        conv.messages?.forEach((msg: Message) => {
          const date = new Date(msg.created_at).toLocaleDateString();
          if (!acc[date]) {
            acc[date] = { assistant: 0, user: 0, function: 0, total: 0 };
          }
          if (msg.sender_role === 'assistant' || msg.sender_role === 'user' || msg.sender_role === 'function') {
            acc[date][msg.sender_role] += 1;
            acc[date].total += 1;
          }
        });
        return acc;
      }, {}),
    };
  }, [conversations]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];
  const ROLE_COLORS = {
    assistant: '#00C49F',
    user: '#0088FE',
    function: '#FFBB28'
  };

  const statusChartData = Object.entries(metrics.statusDistribution).map(([status, count]) => ({
    name: status,
    value: count,
  }));

  const messageTimelineData: MessageTimelineData[] = Object.entries(metrics.messagesByDayAndRole)
    .map(([date, roles]) => ({
      date,
      assistant: roles.assistant,
      user: roles.user,
      function: roles.function
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const deliveryStateData = Object.entries(metrics.deliveryStateDistribution).map(([state, count]) => ({
    name: state,
    value: count,
  }));

  return (
    <Box sx={styles.root}>
      <Grid container spacing={3}>
        {/* Key Metrics Cards */}
        <Grid item xs={12} md={3}>
          <Card sx={styles.card}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography sx={styles.metricValue}>{metrics.totalConversations}</Typography>
                  <Typography sx={styles.metricLabel}>Total Conversations</Typography>
                </Box>
                <MessageIcon sx={styles.icon} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={styles.card}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography sx={styles.metricValue}>{metrics.activeConversations}</Typography>
                  <Typography sx={styles.metricLabel}>Active Conversations</Typography>
                </Box>
                <AutorenewIcon sx={styles.icon} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={styles.card}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography sx={styles.metricValue}>
                    {metrics.avgResponseTime.toFixed(1)}s
                  </Typography>
                  <Typography sx={styles.metricLabel}>Avg Response Time</Typography>
                </Box>
                <AccessTimeIcon sx={styles.icon} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={styles.card}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography sx={styles.metricValue}>{metrics.messageCount}</Typography>
                  <Typography sx={styles.metricLabel}>Total Messages</Typography>
                </Box>
                <GroupIcon sx={styles.icon} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Message Timeline Chart */}
        <Grid item xs={12} md={8}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Message Activity Timeline
              </Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={messageTimelineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="assistant"
                      stroke={ROLE_COLORS.assistant}
                      fill={ROLE_COLORS.assistant}
                      fillOpacity={0.3}
                    />
                    <Area
                      type="monotone"
                      dataKey="user"
                      stroke={ROLE_COLORS.user}
                      fill={ROLE_COLORS.user}
                      fillOpacity={0.3}
                    />
                    <Area
                      type="monotone"
                      dataKey="function"
                      stroke={ROLE_COLORS.function}
                      fill={ROLE_COLORS.function}
                      fillOpacity={0.3}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Status Distribution */}
        <Grid item xs={12} md={4}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Conversation Status Distribution
              </Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={statusChartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                      label
                    >
                      {statusChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Delivery State Distribution */}
        <Grid item xs={12}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Delivery State Distribution
              </Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={deliveryStateData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill={theme.palette.primary.main}>
                      {deliveryStateData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Pending Delivery Count */}
        <Grid item xs={12} md={3}>
          <Card sx={styles.card}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography sx={styles.metricValue}>{metrics.pendingDeliveryCount}</Typography>
                  <Typography sx={styles.metricLabel}>Pending Delivery</Typography>
                </Box>
                <PauseCircleIcon sx={styles.icon} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Message Roles Chart */}
        <Grid item xs={12}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Daily Messages by Role
              </Typography>
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={messageTimelineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="assistant" stackId="a" fill={ROLE_COLORS.assistant} name="Assistant" />
                    <Bar dataKey="user" stackId="a" fill={ROLE_COLORS.user} name="User" />
                    <Bar dataKey="function" stackId="a" fill={ROLE_COLORS.function} name="Function" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConversationsMetrics; 
