import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Checkbox,
  Typography,
  Chip,
  useTheme,
  Avatar,
  Tooltip,
  IconButton,
  Badge,
  TableSortLabel,
  alpha,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import { dateTimeFormatter } from 'src/libs/dateTimeFormatter';
import ConversationStatusIndicator from './ConversationStatusIndicator';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import { toggleOpenConversation, deleteConversations } from 'src/features/account/conversations.slice';
import { AutoAwesome, MarkEmailRead, MarkEmailUnread, PauseCircleOutline, PlayArrow, Send } from '@mui/icons-material';
import ConversationRowItem from './ConversationRowItem';
import { filterConversations, sortWithPriorityFirst } from '../../utils/conversationUtils';
import { useSnackbar } from 'notistack';

// Define sort direction type
type Order = 'asc' | 'desc';

interface ConversationListViewProps {
  conversations: any[];
  handleConversationsListToggleClick: () => void;
  selectedConversations: string[];
  onSelectConversation: (conversationId: string) => void;
  showSelectionColumn: boolean;
  initialSortField?: string;
  initialSortOrder?: 'asc' | 'desc';
}

const ConversationListView: React.FC<ConversationListViewProps> = ({
  conversations,
  handleConversationsListToggleClick,
  selectedConversations,
  onSelectConversation,
  showSelectionColumn,
  initialSortField = 'updated_at',
  initialSortOrder = 'desc'
}) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  // Initialize sort state with props
  const [orderBy, setOrderBy] = useState<string>(initialSortField);
  const [order, setOrder] = useState<Order>(initialSortOrder);
  // State for the active filter
  const [activeFilter, setActiveFilter] = useState<string>('all_active');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Update sort state when props change
  useEffect(() => {
    setOrderBy(initialSortField);
    setOrder(initialSortOrder);
  }, [initialSortField, initialSortOrder]);

  const handleRowClick = (conversationId: string, channelId: string) => {
    dispatch(toggleOpenConversation(conversationId));
    navigate(`?conversation_id=${conversationId}&channel_id=${channelId}`);
    if (handleConversationsListToggleClick) handleConversationsListToggleClick();
  };

  // Handle sort request
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle filter change
  const handleFilterChange = (filterId: string) => {
    setActiveFilter(filterId);
  };

  // Filter conversations based on active filter
  const filteredConversations = React.useMemo(() => {
    return filterConversations(conversations, activeFilter);
  }, [conversations, activeFilter]);

  // Sort function for conversations
  const sortedConversations = React.useMemo(() => {
    // First apply priority sorting to put important conversations at the top
    const prioritySorted = sortWithPriorityFirst(filteredConversations);
    
    // Then apply the user's selected sort
    return [...prioritySorted].sort((a, b) => {
      if (orderBy === 'user_messages_count') {
        return order === 'asc' 
          ? (a.user_messages_count || 0) - (b.user_messages_count || 0)
          : (b.user_messages_count || 0) - (a.user_messages_count || 0);
      }
      
      if (orderBy === 'last_user_message_received_at') {
        // Handle null values by treating them as older than any date
        if (!a.last_user_message_received_at) return order === 'asc' ? -1 : 1;
        if (!b.last_user_message_received_at) return order === 'asc' ? 1 : -1;
        
        return order === 'asc'
          ? new Date(a.last_user_message_received_at).getTime() - new Date(b.last_user_message_received_at).getTime()
          : new Date(b.last_user_message_received_at).getTime() - new Date(a.last_user_message_received_at).getTime();
      }
      
      if (orderBy === 'updated_at') {
        return order === 'asc'
          ? new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
          : new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      }
      
      // Default sort by subject
      if (orderBy === 'subject') {
        const subjectA = a.subject || '';
        const subjectB = b.subject || '';
        return order === 'asc'
          ? subjectA.localeCompare(subjectB)
          : subjectB.localeCompare(subjectA);
      }
      
      return 0;
    });
  }, [filteredConversations, order, orderBy]);

  const getSubjectComponent = (conversation: any) => {
    // Check if conversation has pending messages
    const hasPendingMessages = conversation.messages?.some(
      (message: any) => message.status === 'pending_delivery'
    );
    
    // Check if conversation has pending assistant messages specifically
    const hasPendingAssistantMessages = conversation.messages?.some(
      (message: any) => message.status === 'pending_delivery' && message.sender_role === 'assistant'
    );
    
    // Determine the badge status
    let badgeContent = null;
    if (hasPendingAssistantMessages) {
      badgeContent = <AutoAwesome fontSize="small" color="warning" />;
    } else if (hasPendingMessages) {
      badgeContent = <Send fontSize="small" color="info" />;
    } else if (!conversation.read) {
      badgeContent = <MarkEmailUnread fontSize="small" color="error" />;
    } else {
      badgeContent = <MarkEmailRead fontSize="small" color="disabled" />;
    }
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Badge
          badgeContent={badgeContent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ 
            '& .MuiBadge-badge': {
              transform: 'scale(0.8) translate(-50%, -50%)',
              backgroundColor: 'transparent',
              color: hasPendingAssistantMessages ? 'warning.main' : 'inherit'
            }
          }}
        >
          <Typography 
            variant="body1" 
            sx={{ 
              fontWeight: !conversation.read ? 600 : 400,
              color: !conversation.read ? (darkMode ? 'common.white' : 'common.black') : 'inherit',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '250px'
            }}
          >
            {conversation.subject || "Messages"}
          </Typography>
        </Badge>
      </Box>
    );
  };

  // Find contact from participating entities
  const getContactInfo = (conversation: any) => {
    if (!conversation.participating_entities || conversation.participating_entities.length === 0) {
      return { name: '(No contacts)', avatar: '#' };
    }
    
    // Find the user entity (typically the client/customer)
    const userEntity = conversation.participating_entities.find(
      (entity: any) => entity.role !== 'assistant' && entity.role !== 'function'
    );
    
    if (!userEntity) {
      return { name: '(No contacts)', avatar: '#' };
    }
    
    const name = userEntity.name || userEntity.identifier || 'Unknown';
    const avatar = name.charAt(0).toUpperCase();
    
    return { name, avatar };
  };

  // Get the latest message preview
  const getLatestMessage = (conversation: any) => {
    if (conversation.messages && conversation.messages.length > 0) {
      // Sort messages by creation date
      const sortedMessages = [...conversation.messages].sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      
      // Find the first non-function message
      const lastMessage = sortedMessages.find(msg => msg.sender_role !== 'function');
      
      if (lastMessage) {
        let content = lastMessage.content || '';
        // Truncate and sanitize content
        content = content.replace(/(\r\n|\n|\r)/gm, " ").trim();
        return content.length > 60 ? content.substring(0, 60) + '...' : content;
      }
    }
    return '(No messages)';
  };

  // Generate status display with delivery state
  const getStatusDisplay = (conversation: any) => {
    const status = conversation.status || 'unknown';
    const deliveryState = conversation.delivery_state || 'unknown';
    
    // Determine colors based on status and delivery state with direct color values for safety
    const getStatusColor = () => {
      switch (status) {
        case 'active':
          return theme.palette.success?.main || '#4caf50';
        case 'pending_user_reply':
          return theme.palette.info?.main || '#2196f3';
        case 'standby':
          return theme.palette.info?.main || '#2196f3';
        case 'closed':
          return theme.palette.error?.main || '#f44336';
        case 'needs_attention':
          return theme.palette.error?.main || '#f44336';
        case 'awaiting_reply':
          return theme.palette.warning?.main || '#ff9800';
        default:
          return theme.palette.grey[500] || '#9e9e9e';
      }
    };

    const getDeliveryStateColor = () => {
      switch (deliveryState) {
        case 'play':
          return theme.palette.success?.main || '#4caf50';
        case 'pause':
          return theme.palette.warning?.main || '#ff9800';
        case 'delivered':
          return theme.palette.success?.main || '#4caf50';
        case 'delivering':
          return theme.palette.info?.main || '#2196f3';
        default:
          return theme.palette.grey[500] || '#9e9e9e';
      }
    };

    // Format status text for display
    const formatStatus = (text: string) => {
      if (text === 'awaiting_reply') return 'Awaiting User Reply';
      
      return text.replace(/_/g, ' ')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {/* Status chip */}
        <Chip
          size="small"
          label={formatStatus(status)}
          sx={{
            bgcolor: getStatusColor(),
            color: 'white',
            fontWeight: 'bold',
            fontSize: '0.825rem',
            height: '26.4px',
            border: 'none',
            '& .MuiChip-label': {
              px: 1.2
            }
          }}
        />
        
        {/* Delivery state indicator */}
        {deliveryState === 'pause' && (
          <Tooltip title="Delivery paused">
            <PauseCircleOutline fontSize="small" sx={{ color: getDeliveryStateColor() }} />
          </Tooltip>
        )}
        {deliveryState === 'play' && (
          <Tooltip title="Delivery active">
            <PlayArrow fontSize="small" sx={{ color: getDeliveryStateColor() }} />
          </Tooltip>
        )}
      </Box>
    );
  };

  const handleBulkDelete = async () => {
    if (selectedConversations.length === 0) {
      enqueueSnackbar('No conversations selected', { variant: 'warning' });
      return;
    }
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const result = await dispatch(deleteConversations(selectedConversations)).unwrap();
      
      if (result.successfulDeletes.length > 0) {
        enqueueSnackbar(`Successfully deleted ${result.successfulDeletes.length} conversations`, { variant: 'success' });
      }
      
      if (result.failedDeletes.length > 0) {
        enqueueSnackbar(`Failed to delete ${result.failedDeletes.length} conversations`, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error deleting conversations', { variant: 'error' });
    }
    setDeleteDialogOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {showSelectionColumn && selectedConversations.length > 0 && (
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
          borderRadius: 1,
          mb: 2
        }}>
          <Typography>
            {selectedConversations.length} conversations selected
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleBulkDelete}
            sx={{ ml: 'auto' }}
          >
            Delete Selected
          </Button>
        </Box>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selectedConversations.length} conversations? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer 
        component={Paper} 
        sx={{ 
          height: 'calc(100vh - 130px - 64px)', // Adjust height since we removed filters
          overflow: 'auto',
          backgroundColor: darkMode ? theme.palette.grey[900] : theme.palette.background.paper,
          boxShadow: 'none',
          border: 'none',
          width: '100%',
          maxWidth: '100%',
          '& .MuiTableCell-root': {
            border: 'none',
            borderBottom: darkMode 
              ? '1px solid rgba(255, 255, 255, 0.05)' 
              : '1px solid rgba(0, 0, 0, 0.03)'
          },
          '& .MuiTableRow-root:hover': {
            backgroundColor: darkMode 
              ? 'rgba(255, 255, 255, 0.04)' 
              : 'rgba(0, 0, 0, 0.02)'
          }
        }}
      >
      <Table stickyHeader size="small" aria-label="conversations table">
        <TableHead>
          <TableRow>
            {showSelectionColumn && (
              <TableCell 
                padding="checkbox" 
                sx={{ 
                  bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                  borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                  width: '48px'
                }}
              >
                <Checkbox 
                  indeterminate={selectedConversations.length > 0 && selectedConversations.length < conversations.length}
                  checked={conversations.length > 0 && selectedConversations.length === conversations.length}
                  onChange={(event) => {
                    if (event.target.checked) {
                      // Select all conversations
                      const allConversationIds = conversations.map(conv => conv.id);
                      // Update the parent component's state
                      allConversationIds.forEach(id => {
                        if (!selectedConversations.includes(id)) {
                          onSelectConversation(id);
                        }
                      });
                    } else {
                      // Deselect all conversations
                      selectedConversations.forEach(id => {
                        onSelectConversation(id);
                      });
                    }
                  }}
                />
              </TableCell>
            )}
            <TableCell 
              sx={{ 
                width: '120px',
                minWidth: '120px',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px'
              }}
            >
              Status
            </TableCell>
            <TableCell 
              sx={{ 
                width: '180px',
                minWidth: '180px',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px 12px'
              }}
            >
              Contact
            </TableCell>
            <TableCell 
              sx={{ 
                width: '220px',
                minWidth: '220px',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px 12px'
              }}
            >
              <TableSortLabel
                active={orderBy === 'subject'}
                direction={orderBy === 'subject' ? order : 'asc'}
                onClick={() => handleRequestSort('subject')}
              >
                Subject
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{ 
                width: '100%',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px 12px'
              }}
            >
              Preview
            </TableCell>
            {/* User Messages Count Column */}
            <TableCell 
              sx={{ 
                width: '100px',
                minWidth: '100px',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px 12px',
                textAlign: 'center'
              }}
            >
              <TableSortLabel
                active={orderBy === 'user_messages_count'}
                direction={orderBy === 'user_messages_count' ? order : 'asc'}
                onClick={() => handleRequestSort('user_messages_count')}
              >
                User Msgs
              </TableSortLabel>
            </TableCell>
            {/* Last User Message Column */}
            <TableCell 
              sx={{ 
                width: '140px',
                minWidth: '140px',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px 12px',
                textAlign: 'right'
              }}
            >
              <TableSortLabel
                active={orderBy === 'last_user_message_received_at'}
                direction={orderBy === 'last_user_message_received_at' ? order : 'asc'}
                onClick={() => handleRequestSort('last_user_message_received_at')}
              >
                Last User Msg
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{ 
                width: '120px',
                minWidth: '120px',
                bgcolor: darkMode ? theme.palette.grey[800] : theme.palette.grey[100],
                fontWeight: 600,
                borderBottom: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
                padding: '8px 12px',
                textAlign: 'right'
              }}
            >
              <TableSortLabel
                active={orderBy === 'updated_at'}
                direction={orderBy === 'updated_at' ? order : 'asc'}
                onClick={() => handleRequestSort('updated_at')}
              >
                Updated
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedConversations.map((conversation) => {
            const isSelected = selectedConversations.includes(conversation.id);
            const contactInfo = getContactInfo(conversation);
            
            return (
              <ConversationRowItem
                key={conversation.id}
                conversation={conversation}
                selected={isSelected}
                onSelect={() => {
                  dispatch(toggleOpenConversation(conversation.id));
                  navigate(`?conversation_id=${conversation.id}&channel_id=${conversation.channel_id}`);
                  if (handleConversationsListToggleClick) handleConversationsListToggleClick();
                }}
                onSelectCheckbox={() => onSelectConversation(conversation.id)}
                showCheckbox={showSelectionColumn}
                disableClick={false}
              />
            );
          })}
          {conversations.length === 0 && (
            <TableRow>
              <TableCell colSpan={showSelectionColumn ? 8 : 7} align="center" sx={{ py: 4 }}>
                <Typography variant="body1" color="text.secondary">
                  No conversations found matching your filters.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
};

export default ConversationListView; 
