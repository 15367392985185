import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
  Tooltip,
  Divider,
  Paper,
  useTheme,
  alpha,
  TextField,
  IconButton,
  Collapse,
  InputAdornment
} from '@mui/material';
import { AutoAwesome, PlayArrow, Pause, Warning, ExpandLess, ExpandMore, Send, AttachFile, TipsAndUpdates } from '@mui/icons-material';
import { dateTimeFormatter } from 'src/libs/dateTimeFormatter';

interface GenerateRepliesConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (functionMessage?: string, fileUrls?: string[]) => void;
  selectedConversations: any[];
}

const GenerateRepliesConfirmationDialog: React.FC<GenerateRepliesConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  selectedConversations
}) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [functionMessage, setFunctionMessage] = useState('');
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  // Count how many conversations have each delivery state
  const stats = useMemo(() => {
    const paused = selectedConversations.filter(conv => conv.delivery_state === 'pause').length;
    const play = selectedConversations.filter(conv => conv.delivery_state === 'play').length;
    
    return { 
      total: selectedConversations.length,
      paused,
      play
    };
  }, [selectedConversations]);

  // Get contact name for a conversation
  const getContactInfo = (conversation: any) => {
    const contacts = conversation.contacts || [];
    if (contacts.length > 0) {
      return contacts.map(contact => contact.first_name ? `${contact.first_name} ${contact.last_name || ''}` : contact.phone).join(', ');
    }
    return '(No contacts)';
  };

  // Handle file attachment
  const handleMediaUpload = () => {
    if (!window.cloudinary) {
      console.error('Cloudinary library not loaded.');
      return;
    }
    window.cloudinary.openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        multiple: true,
        resourceType: 'auto',
        sources: ['local', 'camera'],
        clientAllowedFormats: [
          'png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico',
          'mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'mkv',
          'mp3', 'wav', 'm4a', 'flac',
          'pdf'
        ],
        maxFileSize: 100 * 1024 * 1024,
        cropping: false,
        showPoweredBy: false,
      },
      (error: any, result: any) => {
        if (!error && result.event === 'success') {
          const { public_id, resource_type, format } = result.info;
          let transformation = '';
          let adjusted_resource_type = resource_type;
          let adjusted_format = format;

          if (resource_type === 'image') {
            transformation = 'c_limit,w_1600,h_1600,q_auto:eco,f_auto';
          } else if (resource_type === 'video') {
            transformation = 'c_limit,w_1280,h_720,q_auto:eco,f_auto';
          } else if (resource_type === 'raw' && format === 'pdf') {
            transformation = 'c_limit,w_800,q_auto:eco,f_auto,page=1';
            adjusted_resource_type = 'image';
            adjusted_format = 'jpg';
          }
          const transformedUrl = transformation
            ? `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/${adjusted_resource_type}/upload/${transformation}/${public_id}.${adjusted_format}`
            : result.info.secure_url;

          setFileUrls(prev => [...prev, transformedUrl]);
        } else if (error) {
          console.error('Upload error:', error);
        }
      }
    );
  };

  // Handle confirmation with either normal or function message approach
  const handleConfirm = () => {
    // If there's a function message, we'll use that approach
    if (functionMessage.trim()) {
      // The parent component will need to handle this differently
      onConfirm(functionMessage, fileUrls);
    } else {
      // Regular autocomplete approach
      onConfirm();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          bgcolor: darkMode ? theme.palette.grey[900] : theme.palette.background.paper
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AutoAwesome color="secondary" />
          <Typography variant="h6">
            Generate Replies for {stats.total} Conversation{stats.total !== 1 ? 's' : ''}
          </Typography>
        </Box>
      </DialogTitle>
      
      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            You're about to generate AI replies for the following conversations:
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
            {stats.play > 0 && (
              <Tooltip title="These conversations will receive and send messages immediately upon generation">
                <Chip 
                  icon={<PlayArrow />} 
                  label={`${stats.play} with immediate delivery`}
                  color="success"
                  sx={{ 
                    fontWeight: 500,
                    '& .MuiChip-icon': { color: 'inherit' }
                  }}
                />
              </Tooltip>
            )}
            
            {stats.paused > 0 && (
              <Tooltip title="These conversations will receive messages as drafts that you can review before sending">
                <Chip 
                  icon={<Pause />} 
                  label={`${stats.paused} with draft delivery`}
                  color="warning"
                  sx={{ 
                    fontWeight: 500,
                    '& .MuiChip-icon': { color: 'inherit' }
                  }}
                />
              </Tooltip>
            )}
          </Box>
          
          {stats.play > 0 && (
            <Paper 
              variant="outlined" 
              sx={{ 
                p: 2, 
                mb: 2, 
                bgcolor: alpha(theme.palette.warning.main, 0.05),
                borderColor: theme.palette.warning.main
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Warning color="warning" />
                <Typography variant="subtitle1" color="warning.main" fontWeight={600}>
                  Important Note
                </Typography>
              </Box>
              <Typography variant="body2">
                {stats.play} conversation{stats.play !== 1 ? 's' : ''} will have {stats.play !== 1 ? 'their' : 'its'} message{stats.play !== 1 ? 's' : ''} generated and <strong>sent immediately</strong> because {stats.play !== 1 ? 'they' : 'it'} {stats.play !== 1 ? 'are' : 'is'} in "Play" delivery state. The remaining {stats.paused} conversation{stats.paused !== 1 ? 's' : ''} will receive draft messages that you can review before sending.
              </Typography>
            </Paper>
          )}
        </Box>
        
        <Typography variant="subtitle1" gutterBottom>
          Selected Conversations:
        </Typography>
        
        <Paper 
          variant="outlined" 
          sx={{ 
            maxHeight: '300px', 
            overflow: 'auto',
            borderRadius: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px'
            },
            '&::-webkit-scrollbar-track': {
              bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              '&:hover': {
                bgcolor: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'
              }
            }
          }}
        >
          <List disablePadding>
            {selectedConversations.map((conversation, index) => (
              <React.Fragment key={conversation.id}>
                {index > 0 && <Divider component="li" />}
                <ListItem sx={{ py: 1 }}>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            fontWeight: 600,
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {getContactInfo(conversation)}
                        </Typography>
                        
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ 
                            maxWidth: '250px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {conversation.subject || '(No subject)'}
                        </Typography>
                        
                        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="caption" color="text.secondary">
                            {dateTimeFormatter(conversation.latest_delivery_at || conversation.updated_at, "day_month_ordinal_humanized_time_period")}
                          </Typography>
                          
                          <Tooltip title={
                            conversation.delivery_state === 'play' 
                              ? "This conversation will send messages immediately upon generation" 
                              : "This conversation will generate messages as drafts for your review"
                          }>
                            <Chip
                              size="small"
                              icon={conversation.delivery_state === 'play' ? <PlayArrow fontSize="small" /> : <Pause fontSize="small" />}
                              label={conversation.delivery_state === 'play' ? "Immediate" : "Draft"}
                              color={conversation.delivery_state === 'play' ? "success" : "warning"}
                              sx={{ 
                                height: '24px',
                                '& .MuiChip-label': { 
                                  px: 1,
                                  fontSize: '0.75rem',
                                  fontWeight: 500
                                },
                                '& .MuiChip-icon': {
                                  fontSize: '0.875rem',
                                  marginLeft: '4px'
                                }
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
        
        {/* Advanced Options Section */}
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Button
            onClick={() => setAdvancedOpen(prev => !prev)}
            endIcon={advancedOpen ? <ExpandLess /> : <ExpandMore />}
            sx={{
              alignSelf: 'flex-start',
              color: darkMode ? 'grey.300' : 'text.primary',
              '&:hover': {
                bgcolor: darkMode ? 'grey.800' : 'grey.100',
              }
            }}
          >
            Advanced Options
          </Button>
          
          <Collapse in={advancedOpen} sx={{ width: '100%' }}>
            <Paper 
              variant="outlined" 
              sx={{ 
                p: 2, 
                mt: 1, 
                mb: 2, 
                bgcolor: darkMode ? alpha(theme.palette.info.main, 0.1) : alpha(theme.palette.info.main, 0.05),
                borderColor: darkMode ? theme.palette.info.dark : theme.palette.info.light
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom color="info.main">
                  <TipsAndUpdates fontSize="small" sx={{ mr: 1, verticalAlign: 'text-bottom' }} />
                  Function Message (Advanced)
                </Typography>
                <Typography variant="body2" paragraph>
                  Instead of letting Oppy generate responses automatically, you can provide specific instructions as a "function message" that guides how responses should be created.
                </Typography>
                <Typography variant="body2">
                  For example: "Generate a follow-up message asking about their recent purchase experience" or "Create a short, friendly check-in message with an emoji".
                </Typography>
              </Box>
              
              <TextField
                label="Function Message (Instructions for Oppy)"
                value={functionMessage}
                onChange={(e) => setFunctionMessage(e.target.value)}
                multiline
                rows={3}
                fullWidth
                placeholder="Enter instructions for how Oppy should generate messages..."
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleMediaUpload} edge="start">
                        <AttachFile />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{ mb: 1 }}
              />
              
              {fileUrls.length > 0 && (
                <Typography variant="caption" color="text.secondary">
                  {fileUrls.length} attachment{fileUrls.length !== 1 ? 's' : ''} will be included
                </Typography>
              )}
            </Paper>
          </Collapse>
        </Box>
        
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            <strong>What will happen when you click {functionMessage ? '"Post Function Message"' : '"Generate Replies"'}:</strong>
          </Typography>
          
          {functionMessage ? (
            <>
              <Typography variant="body2" paragraph>
                • Your function message will be posted to all selected conversations as an internal note.
              </Typography>
              <Typography variant="body2" paragraph>
                • Oppy will use your instructions to guide response generation for each conversation.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" paragraph>
                • For the <strong>{stats.paused}</strong> conversation{stats.paused !== 1 ? 's' : ''} in "Paused" state: Messages will be generated as drafts. You'll be able to review and edit them before sending.
              </Typography>
              
              <Typography variant="body2" paragraph>
                • For the <strong>{stats.play}</strong> conversation{stats.play !== 1 ? 's' : ''} in "Play" state: Messages will be generated and sent immediately without requiring your review.
              </Typography>
            </>
          )}
          
          <Typography variant="body2">
            To change how messages are delivered, you can update the delivery state of a conversation using the delivery state toggle in each conversation.
          </Typography>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button 
          onClick={handleConfirm} 
          variant="contained" 
          color="secondary"
          startIcon={functionMessage ? <Send /> : <AutoAwesome />}
        >
          {functionMessage ? `Post Function Message (${stats.total})` : `Generate Replies (${stats.total})`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateRepliesConfirmationDialog; 
