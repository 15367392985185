import Box from '@mui/system/Box';
import React, { useEffect, useState } from 'react';
import AccountNotifications from 'src/components/Channels/AccountNotifications';
import Inspector from 'src/components/Inspector';
import { useAppDispatch, useAppSelector } from 'src/store';
import UpgradePlanPopup from 'src/components/Nav/UpgradePlanPopup';
import { getUserSubscription } from 'src/features/auth/auth.slice';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import useAuth from 'src/hooks/useAuth';
import DarkNav from 'src/components/NavV2/darkNav';
import { fetchConversationsWithMessages } from 'src/features/account/conversations.slice';
import { fetchChannels } from 'src/features/account/channels.slice';
import { getContacts } from 'src/features/account/contacts.slice';
import ChannelsListDialog from 'src/components/Channels/ChannelsListDialog';
import ChannelCreateDialog from 'src/components/Channels/ChannelCreateDialog';
import { toggleCreateChannelDialog } from 'src/features/theme/theme.slice';
import ContactsUploaderDialog from 'src/components/Contacts/uploader/ContactsUploaderDialog';
import { useContext } from 'react';
import { PusherContext } from 'src/services/auth.service';
import { fetchPriorityConversations } from 'src/features/account/filteredConversations.slice';

const Layout = ({ components }: { components: React.ReactNode[] }) => {
  const { fullScreen } = useAppSelector(state => state.theme)
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { logOut, isLoggedIn, authLoading } = useAuth();
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const [openUpgradePlanPopup, setOpenUpgradePlanPopup] = useState(false);
  const { channelsLoading, channels, initialLoad } = useAppSelector((state) => state.channels);

  const goToLogin = () => {
    window.location.href = '/login';
  };

  const pusher = useContext(PusherContext);

  useEffect(() => {
    const handleFocus = () => {
      pusher.connect();
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [pusher, dispatch]);
  useEffect(() => {
    dispatch(fetchPriorityConversations());
    dispatch(fetchConversationsWithMessages({ limit: 25, messagesLimit: 3 }));
    dispatch(fetchChannels());
  }, [dispatch]);

  useEffect(() => {
    if (!channelsLoading && initialLoad && channels.length === 0) {
      dispatch(toggleCreateChannelDialog())
    }
  }, [channelsLoading, channels, initialLoad])

  const userLogOut = async () => {
    dispatch(trackAnalytics({ action_name: "user_logged_out", payload: { description: "user logged out" } }))
    logOut(goToLogin);
  };

  const upgradePlan = () => {
    if (subscriptionFeatures?.higher_tiers.length > 0) {
      setOpenUpgradePlanPopup(true);
    }
  };

  useEffect(() => {
    if (!subscriptionFeatures) {
      dispatch(getUserSubscription())
    }
  }, []);

  if (authLoading) {
    return;
  }


  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        height: '100vh',
      }}>
        <DarkNav
          upgradePlan={upgradePlan}
          user={user}
          userLogOut={userLogOut}
        />
        <Box sx={{
          flexGrow: 1,
          overflow: 'auto',
          width: { xs: '100%', sm: 'calc(100% - 260px)' },
        }}>
          {React.Children.map(components, (component, index) => (
            <React.Fragment key={index}>{component}</React.Fragment>
          ))}
        </Box>
        <AccountNotifications />
        <Inspector />
        <ChannelsListDialog />
        <ChannelCreateDialog fullScreen={fullScreen} />
        <ContactsUploaderDialog />
        <UpgradePlanPopup isOpen={openUpgradePlanPopup} onClose={() => setOpenUpgradePlanPopup(false)} subscriptionFeatures={undefined} />
      </Box>
    </>
  );
};

export default Layout;

function fetchContacts(): any {
  throw new Error('Function not implemented.');
}
