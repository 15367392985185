import { Box, CircularProgress, Divider, Icon, IconButton, InputLabel, Menu, MenuItem, Select, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { FormControl } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import SettingsIcon from '@mui/icons-material/Settings';
import FunctionsIcon from '@mui/icons-material/Functions';
import { useRef } from 'react';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { fetchConversationWithMessages, updateConversationDeliveryDelay, updateConversationDeliveryState, updateConversationStatus } from '../../features/account/conversations.slice';
import useDebounce from 'src/hooks/useDebounce';
import ConversationStatusIndicator from './ConversationStatusIndicator';
import { useAppSelector } from 'src/store';
import { toggleAdvancedMode } from 'src/features/user/user.slice';
import { useTheme } from '@mui/material/styles';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const StyledDialogButtonCancel = styled(Button)(({ theme }) => ({
  fontSize: "15px",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary
}));

const StyledDialogButtonProceed = styled(Button)(({ theme }) => ({
  fontSize: "15px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.primary
}));

const ConversationOptionsMenu = ({ sx = {}, conversation }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [delayValue, setDelayValue] = useState(conversation.delivery_delay / 1000);
  const debouncedDelayValue = useDebounce(delayValue, 1000);
  const [deliveryStateUpdateInProgress, setDeliveryStateUpdateInProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const open = Boolean(anchorEl);
  const settingsButtonRef = useRef(null);
  const dispatch = useDispatch();
  const advancedMode = useAppSelector(state => state.user.advancedMode);
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  useEffect(() => {
    setDeliveryStateUpdateInProgress(false);
  }, [conversation.delivery_state]);

  useEffect(() => {
    setDelayValue(conversation.delivery_delay / 1000);
  }, [conversation.delivery_delay]);

  useEffect(() => {
    if (debouncedDelayValue !== conversation.delivery_delay / 1000) {
      handleDelayChange(debouncedDelayValue); // Update this line
    }
  }, [debouncedDelayValue]); // Add this useEffect

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  }

  const handleDialogConfirm = () => {
    handleCloseDialog();
    setAnchorEl(settingsButtonRef.current);
    handleConfirmation();
  }

  const marks = [
    {
      value: 60,
      label: '1',
    },
    {
      value: 180,
      label: '3 ',
    },
    {
      value: 300,
      label: '5',
    },
    {
      value: 600,
      label: '10 min',
    },
    {
      value: 1800,
      label: '30 min',
    }
  ];

  function valuetext(value) {
    // if value is less than 60 then return value + ' seconds' else return value/60 + ' minutes rounded to the nearest 10th'
    if (value < 60) {
      return `${value} seconds`;
    } else {
      return `${Math.round(value / 60 * 10) / 10} minutes`;
    }
  }

  const handleReloadClick = () => {
    dispatch(fetchConversationWithMessages({ conversationId: conversation.id }));
    handleClose();
  }

  const handleDelayChange = (newValue: number) => {
    const valueInMilliseconds = newValue * 1000;
    dispatch(updateConversationDeliveryDelay({
      conversationId: conversation.id, delivery_delay: valueInMilliseconds,
      channelId: conversation.channel_id,
      status: conversation.status
    }));
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setDelayValue(newValue);
    }
  };

  const handlePlayDeliveryState = () => {
    handleClose();
    setShowDialog(conversation.undelivered_count > 0);
  }

  const handleConfirmation = async () => {
    const value = deliveryStateNextValue();
    dispatch(updateConversationDeliveryState({ conversationId: conversation.id, deliveryState: value, channelId: conversation.channel_id }));
    setDeliveryStateUpdateInProgress(true);
  }

  const deliveryStateNextValue = () => {
    if (conversation.delivery_state === 'play') {
      return 'pause';
    } else if (conversation.delivery_state === 'pause') {
      return 'play';
    }
    return 'play';
  }

  const handleDeliveryStateChange = () => {
    const value = deliveryStateNextValue();

    if ((conversation.delivery_state === 'pause' && value === 'play') && conversation.undelivered_count > 0) {
      handlePlayDeliveryState();
      return;
    }
    handleConfirmation();
  }

  const handleConversationStatusChange = (newStatus) => {
    dispatch(updateConversationStatus({ conversationId: conversation.id, status: newStatus, channelId: conversation.channel_id }));
  };

  const deliveryStateNextTooltip = () => {
    if (deliveryStateNextValue() === 'play') {
      return 'Resume Assistant Reply';
    } else if (deliveryStateNextValue() === 'pause') {
      return 'Pause Assistant Reply';
    }
    return 'Resume Assistant Reply';
  }

  return (
    <>
      <IconButton
        sx={{ 
          ...sx,
          color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
          backgroundColor: 'transparent', 
          borderRadius: '50%',
          padding: '4px',
          '&:hover': {
            backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
            color: darkMode ? '#fff' : '#000'
          }
        }}
        ref={settingsButtonRef}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault(); // The crucial change: prevent default before stopPropagation
          e.stopPropagation(); 
          handleClick(e);
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <SettingsIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          dense: true,
          sx: { 
            minWidth: '280px', 
            py: 0.5,
            bgcolor: darkMode ? 'grey.800' : 'background.paper',
          }
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: '8px',
            overflow: 'visible',
            mt: 1.5,
            boxShadow: darkMode ? 
              '0 4px 20px rgba(0, 0, 0, 0.5)' : 
              '0 4px 15px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <MenuItem 
          onClick={(e) => {
            e.stopPropagation();
            dispatch(toggleAdvancedMode());
          }}
          sx={{ py: 1 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <Typography variant="body2">
              {advancedMode ? "Show Simple Chat" : "Show Advanced Chat"}
            </Typography>
            <Icon color={advancedMode ? "primary" : "disabled"} sx={{ ml: 1 }}>
              {advancedMode ? "toggle_on" : "toggle_off"}
            </Icon>
          </Box>
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem 
          onClick={(e) => {
            e.stopPropagation();
            handleReloadClick();
          }}
          sx={{ py: 1 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <Typography variant='body2'>
              Reload
            </Typography>
            <IconButton size="small" sx={{ p: 0.5 }}><Icon sx={{ fontSize: 18 }} color='primary'>refresh</Icon></IconButton>
          </Box>
        </MenuItem>

        {/* menu item that shows a delivery state icon for play, pause and stop based on conversation.delivery_state */}
        <MenuItem sx={{ py: 1 }} onClick={(e) => e.stopPropagation()}>
          {conversation.delivery_state &&
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <Typography variant="body2">
                  {conversation.delivery_state === 'play' &&
                    'Assistant reply is ON'
                  }
                  {conversation.delivery_state === 'pause' &&
                    'Assistant reply is PAUSED'
                  }
                  {conversation.delivery_state === 'stop' &&
                    'Assistant reply is OFF'
                  }
                </Typography>
                {!deliveryStateUpdateInProgress ?
                  <Tooltip title={deliveryStateNextTooltip()}>
                    <IconButton 
                      size="small" 
                      sx={{ p: 0.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeliveryStateChange();
                      }}
                    >
                      {conversation.delivery_state === 'pause' &&
                        <PlayArrowIcon sx={{ fontSize: 18 }} color='primary' />
                      }
                      {conversation.delivery_state === 'play' &&
                        <PauseIcon sx={{ fontSize: 18 }} color='primary' />
                      }
                      {conversation.delivery_state === 'stop' &&
                        <StopIcon sx={{ fontSize: 18 }} color='primary' />
                      }
                    </IconButton>
                  </Tooltip>
                  :
                  <CircularProgress size={18} />
                }
              </Box>
            </>
          }
        </MenuItem>

        <Box 
          sx={{ width: '100%', px: 2, py: 1.5 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant='body2' id="non-linear-slider" gutterBottom>
            Delivery Delay: {valuetext(delayValue)}
          </Typography>
          <Slider
            aria-label="Custom marks"
            defaultValue={delayValue}
            getAriaValueText={valuetext}
            min={0}
            max={1800} // this is 30 minutes in seconds
            marks={marks}
            onChange={handleChange}
            sx={{
              color: 'primary.main',
              '& .MuiSlider-thumb': {
                height: 16,
                width: 16,
              }
            }}
          />
        </Box>

        <MenuItem 
          onClick={(e) => e.stopPropagation()}
          sx={{ py: 1 }}
        >
          {conversation.status &&
            <FormControl fullWidth size="small">
              <InputLabel id="status-simple-select-label">Status</InputLabel>
              <Select
                labelId="status-simple-select-label"
                id="status-simple-select"
                size="small"
                value={conversation.status}
                label="Status"
                onChange={(e) => {
                  e.stopPropagation();
                  handleConversationStatusChange(e.target.value);
                }}
              >
                <MenuItem value={'standby'}>Standby</MenuItem>
                <MenuItem value={'pending_assistant_reply'}>Pending Assistant Reply</MenuItem>
                <MenuItem value={'pending_user_reply'}>Pending User Reply</MenuItem>
                <MenuItem value={'needs_attention'}>Needs Attention</MenuItem>
                <Divider />
                <MenuItem value={'closed'}>Closed</MenuItem>
                <MenuItem disabled value={'processing_autocomplete'}>Processing Autocomplete</MenuItem>
                <MenuItem disabled value={'error'}>Error</MenuItem>
              </Select>
            </FormControl>
          }
        </MenuItem>
      </Menu>
      <Dialog
        maxWidth="sm"
        open={showDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={(e) => e.stopPropagation()}
      >
        <StyledDialogTitle id="alert-dialog-title">
          Are you sure?
        </StyledDialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "18px" }}
          >
            <strong>
              You have {conversation.undelivered_count} unsent messages that will be delivered immediately upon
              re-enabling this conversation.
            </strong>
            <br /><br />
            You can cancel this and delete some of the undelivered messages if you don't want to send all these messages
            right now, then come back here and enable auto-reply.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledDialogButtonCancel onClick={handleCloseDialog}>
            Cancel
          </StyledDialogButtonCancel>
          <StyledDialogButtonProceed onClick={handleDialogConfirm} autoFocus>
            Proceed
          </StyledDialogButtonProceed>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConversationOptionsMenu;
