import React, { useState, useRef, useEffect, ReactElement, useMemo } from 'react';
import { 
  Box, Avatar, Typography, Chip, IconButton, Collapse, Tooltip, useTheme, 
  TextField, Button, alpha, Icon, CircularProgress, Paper, Divider, Checkbox,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableCell, TableRow
} from '@mui/material';
import { 
  ExpandMore, ExpandLess, Circle, Edit, Delete, Send, PlayArrow, 
  ArrowBack, Person, Computer, Code, ArrowDownward, Email, Phone, AutoAwesome, 
  PauseCircleOutline, PlayCircleOutline, MailOutline, CheckCircle, Error, HourglassEmptyOutlined,
  Check
} from '@mui/icons-material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useColorMode } from 'src/theme/ThemeProvider';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { deleteMessage, deliverMessageNow, updateMessage } from "src/features/account/messages.slice";
import { decrementMessageCount, sendMessage, conversationAutocompleteNow, toggleOpenConversation } from "src/features/account/conversations.slice";
import ContactDialog from '../Contacts/ContactDialog';
import { isPriorityConversation, getPriorityBorderStyle } from '../../utils/conversationUtils';

// Define the Conversation type inline
interface Conversation {
  id: string;
  channel_id: string;
  subject?: string;
  status?: string;
  delivery_state?: string;
  created_at?: string;
  updated_at?: string;
  memory?: Record<string, any>;
  last_message?: {
    body?: {
      text?: string;
    }
  };
  user_messages_count?: number;
  last_user_message_received_at?: string;
  messages?: Array<{
    id: string;
    content: string;
    sender_role: string;
    sender_name: string;
    created_at: string;
    status?: string;
  }>;
  participating_entities?: Array<{
    id?: string;
    role: string;
    name?: string;
    email?: string;
    phone?: string;
    contact_id?: string;
  }>;
}

// Simple text renderer
const SimpleMarkdownRenderer = ({ children, darkMode = false }: { children: string; darkMode?: boolean }): ReactElement => {
  // Basic formatting - replace newlines with <br /> tags
  const formattedText = children.split('\n').map((line, i) => (
    <React.Fragment key={i}>
      {line}
      <br />
    </React.Fragment>
  ));
  
  return (
    <Box sx={{ 
      overflowWrap: 'anywhere',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      color: darkMode ? 'text.primary' : 'text.primary',
      fontSize: '0.9rem'
    }}>
      {formattedText}
    </Box>
  );
};

interface ConversationRowItemProps {
  conversation: Conversation;
  selected?: boolean;
  onSelect?: () => void;
  onSelectCheckbox?: () => void;
  showCheckbox?: boolean;
  disableClick?: boolean;
}

const ConversationRowItem: React.FC<ConversationRowItemProps> = ({
  conversation,
  selected = false,
  onSelect = () => {},
  onSelectCheckbox = () => {},
  showCheckbox = false,
  disableClick = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const [replyMessage, setReplyMessage] = useState('');
  const [editedMessageContent, setEditedMessageContent] = useState('');
  const [deliverNowButtonDisabled, setDeliverNowButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const replyInputRef = useRef<HTMLTextAreaElement>(null);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';
  const { enqueueSnackbar } = useSnackbar();

  // Check if this is a priority conversation
  const isPriority = isPriorityConversation(conversation);

  // Focus on reply input when reply mode is activated
  useEffect(() => {
    if (replyMode && replyInputRef.current) {
      replyInputRef.current.focus();
    }
  }, [replyMode]);

  const getStatusColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'success.main';
      case 'pending_user_reply':
        return 'info.main';
      case 'standby':
        return 'info.main';
      case 'closed':
        return 'error.main';
      case 'needs_attention':
        return 'error.main';
      case 'awaiting_reply':
        return 'warning.main';
      default:
        return 'text.secondary';
    }
  };

  const getDeliveryStateLabel = (state: string) => {
    switch (state) {
      case 'pause':
        return 'Paused';
      case 'play':
        return 'Active';
      case 'pending':
        return 'Pending';
      case 'delivered':
        return 'Delivered';
      case 'failed':
        return 'Failed';
      case 'delivering':
        return 'Delivering';
      default:
        return state || 'Unknown';
    }
  };

  const getDeliveryStateColor = (state: string, theme: any) => {
    switch (state?.toLowerCase()) {
      case 'pause':
        return theme.palette.warning.main;
      case 'play':
        return theme.palette.success.main;
      case 'delivered':
        return theme.palette.success.main;
      case 'delivering':
        return theme.palette.info.main;
      default:
        return theme.palette.grey[500];
    }
  };

  // Helper function to get background color for delivery state
  const getDeliveryStateBgColor = (state: string) => {
    const color = getDeliveryStateColor(state, theme);
    return alpha(color, 0.15);
  };

  const getDeliveryStateStyle = (state: string) => {
    const color = getDeliveryStateColor(state, theme);
    const bgColor = getDeliveryStateBgColor(state);
    
    const baseStyle = {
      bgcolor: bgColor,
      color: color,
      borderColor: color,
      border: '1px solid',
      fontWeight: 'medium',
      height: '24px',
      borderRadius: '12px',
      transition: 'all 0.2s ease',
      '& .MuiChip-label': {
        fontSize: '0.75rem',
        padding: '0 8px',
        fontWeight: 500
      }
    };
    
    // Add animations based on state if needed
    if (state === 'delivering') {
        return {
          ...baseStyle,
          animation: 'pulse 1.5s infinite',
          '@keyframes pulse': {
            '0%': { opacity: 0.7 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.7 }
          }
        };
    }
    return baseStyle;
  };

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const handleRowClick = () => {
    if (!disableClick) {
      onSelect();
      dispatch(toggleOpenConversation(conversation.id));
      navigate(`?conversation_id=${conversation.id}&channel_id=${conversation.channel_id}`);
    }
  };

  // Contact dialog handling
  const handleContactClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setContactDialogOpen(true);
  };

  const handleCloseContactDialog = () => {
    setContactDialogOpen(false);
  };

  // Message actions
  const handleEditMessage = (e: React.MouseEvent, message: any) => {
    e.stopPropagation(); // Prevent row click
    setEditMode(true);
    setEditedMessageContent(message.content);
  };

  const handleSaveEdit = (e: React.MouseEvent, messageId: string) => {
    e.stopPropagation();
    if (editedMessageContent.trim()) {
      setLoading(true);
      dispatch(updateMessage({ 
        messageId, 
        content: editedMessageContent, 
        channelId: conversation.channel_id 
      }));
      setLoading(false);
      setEditMode(false);
      enqueueSnackbar('Message updated', { variant: 'success' });
    }
  };

  const handleCancelEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setEditMode(false);
  };

  const handleDeleteMessage = (e: React.MouseEvent, messageId: string) => {
    e.stopPropagation();
    setLoading(true);
    dispatch(deleteMessage({ 
      messageId: messageId, 
      channelId: conversation.channel_id,
      conversationId: conversation.id
    }));
    dispatch(decrementMessageCount({ conversationId: conversation.id }));
    setLoading(false);
    enqueueSnackbar('Message deleted', { variant: 'success' });
  };

  const handleSendNow = (e: React.MouseEvent, messageId: string) => {
    e.stopPropagation();
    setLoading(true);
    setDeliverNowButtonDisabled(true);
    dispatch(deliverMessageNow({ 
      messageId, 
      channelId: conversation.channel_id 
    }));
    
    // Set a timeout to re-enable the button
    setTimeout(() => {
      setDeliverNowButtonDisabled(false);
      setLoading(false);
    }, 5000);
    
    enqueueSnackbar('Message delivery initiated', { variant: 'success' });
  };

  const handleReplyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setReplyMode(true);
  };

  const handleCancelReply = (e: React.MouseEvent) => {
    e.stopPropagation();
    setReplyMode(false);
    setReplyMessage('');
  };

  const handleSendReply = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (replyMessage.trim()) {
      setLoading(true);
      
      // Get user entity
      const userEntity = conversation.participating_entities.find((entity: any) => entity.role === 'user');
      
      if (!userEntity) {
        enqueueSnackbar('Error: Could not identify user entity', { variant: 'error' });
        setLoading(false);
        return;
      }

      const data = {
        conversation,
        message: replyMessage,
        sendingEntity: userEntity
      };

      dispatch(sendMessage(data));
      
      // Simulate completion
      setTimeout(() => {
        enqueueSnackbar('Message sent successfully', { variant: 'success' });
        setReplyMessage('');
        setReplyMode(false);
        setLoading(false);
      }, 1500);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendReply(e as unknown as React.MouseEvent);
    }
  };

  // Find all messages in chronological order
  const allMessages = conversation.messages && conversation.messages.length > 0
    ? [...conversation.messages].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
    : [];

  // Latest message is the last one chronologically
  const latestMessage = allMessages.length > 0 ? allMessages[allMessages.length - 1] : null;

  // Fix the contact entity identification logic
  const contactEntity = useMemo(() => {
    if (!conversation.participating_entities || conversation.participating_entities.length === 0) {
      return null;
    }
    
    // First try to find a contact that is not assistant, user, or function
    const contact = conversation.participating_entities.find(
      entity => entity.role !== 'assistant' && entity.role !== 'user' && entity.role !== 'function'
    );
    
    if (contact) return contact;
    
    // If no appropriate contact found, try to get the client entity
    const client = conversation.participating_entities.find(entity => entity.role === 'client');
    if (client) return client;
    
    // Fallback to any non-assistant entity
    const nonAssistant = conversation.participating_entities.find(entity => entity.role !== 'assistant');
    if (nonAssistant) return nonAssistant;
    
    // Last resort, return the first entity
    return conversation.participating_entities[0];
  }, [conversation.participating_entities]);

  const getInitials = (name: string) => {
    if (!name) return '?';
    
    // Handle phone numbers or identifiers that might be in place of a name
    if (name.includes('+') || name.includes('@')) {
      return name.charAt(0).toUpperCase();
    }
    
    const parts = name.trim().split(' ');
    if (parts.length === 1) return parts[0].charAt(0).toUpperCase();
    return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase();
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    try {
      return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
    } catch (error) {
      return dateString;
    }
  };

  // Determine if it's the user's message
  const isUserMessage = (message: any) => {
    const userEntity = conversation.participating_entities.find((entity: any) => entity.role === 'user');
    return message.sender_id === userEntity?.id;
  };

  // Check if message can be delivered now
  const canDeliverNow = (message: any) => {
    return message.status === 'pending_delivery' && 
           conversation.delivery_state !== 'delivered' && 
           conversation.delivery_state !== 'delivering';
  };

  // Check if message is unsent and can be edited
  const canEditMessage = (message: any) => {
    return message.status === 'pending_delivery' || 
           !message.delivered_at ||
           message.status === 'undelivered';
  };
  
  // Format date and time
  const dateTimeFormatter = (dateString: string) => {
    if (!dateString) return '';
    try {
      return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
    } catch (error) {
      return dateString;
    }
  };

  const getMessageStatusText = (message: any) => {
    if (message.status === "pending_delivery") {
      return conversation.delivery_state === "pause" 
        ? "Sending paused" 
        : "Send @ " + dateTimeFormatter(message.deliver_after);
    }
    if (message.status === "undelivered" || message.metadata?.ErrorCode) {
      return "undelivered";
    }
    if (message.status === "delivered") {
      return "Delivered @ " + dateTimeFormatter(message.delivered_at);
    }
    return message.status;
  };

  const getMessageStatusColor = (message: any) => {
    if (message.status === "undelivered" || message.metadata?.ErrorCode) {
      return "error.main";
    }
    if (message.status === "delivered") {
      return "success.main";
    }
    if (message.status === "pending_delivery") {
      return conversation.delivery_state === "pause" ? "warning.main" : "info.main";
    }
    return "text.secondary";
  };

  // Check if conversation has any pending assistant messages
  const hasPendingAssistantMessages = useMemo(() => {
    if (!conversation.messages || !Array.isArray(conversation.messages)) return false;
    
    return conversation.messages.some(message => 
      message.status === 'pending_delivery' && 
      message.sender_role === 'assistant'
    );
  }, [conversation.messages]);

  const getContactInfo = (conversation: any) => {
    if (!conversation.participating_entities || conversation.participating_entities.length === 0) {
      return null;
    }
    
    // Find user entity (client/customer) that is not assistant or function
    const userEntity = conversation.participating_entities.find(
      entity => entity.role !== 'assistant' && entity.role !== 'function'
    );
    
    if (!userEntity) {
      return null;
    }

    return {
      name: userEntity.name || 'Unknown',
      identifier: userEntity.identifier || '',
      contactId: userEntity.contact_id,
      avatar: (userEntity.name || 'Unknown').charAt(0).toUpperCase(),
      role: userEntity.role
    };
  };

  const formatStatusText = (status: string) => {
    if (!status) return 'Unknown';
    
    if (status === 'awaiting_reply') return 'Awaiting User Reply';
    
    return status.replace(/_/g, ' ')
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
  };

  // Add a helper function to get the most recent non-function message
  const getLatestNonFunctionMessage = (conversation: any) => {
    if (!conversation.messages || conversation.messages.length === 0) {
      return null;
    }
    
    // Sort messages with most recent first
    const sortedMessages = [...conversation.messages].sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    
    // Find the first message that's not from function
    return sortedMessages.find(msg => msg.sender_role !== 'function');
  };

  // Add function to extract important memory details
  const getImportantMemoryDetails = (conversation: any) => {
    if (!conversation.memory) return [];
    
    const details = [];
    
    // Extract user interest/intent if available
    if (conversation.memory.user_intent) {
      details.push({
        label: 'Intent',
        value: conversation.memory.user_intent,
        emoji: '🎯'
      });
    }
    
    // Extract property info if available
    if (conversation.memory.property_details) {
      const price = conversation.memory.property_details.price;
      if (price) {
        details.push({
          label: 'Property',
          value: `$${Number(price).toLocaleString()}`,
          emoji: '🏠'
        });
      }
    }
    
    // Extract user location if available
    if (conversation.memory.user_details && conversation.memory.user_details.zip) {
      details.push({
        label: 'Location',
        value: conversation.memory.user_details.zip,
        emoji: '📍'
      });
    }
    
    // Extract current step if available
    if (conversation.memory.current_step) {
      details.push({
        label: 'Step',
        value: conversation.memory.current_step,
        emoji: '📋'
      });
    }
    
    return details;
  };

  // Modify the renderContact function to add a click handler
  const renderContact = (conversation: any) => {
    const contactEntity = conversation?.participating_entities?.find(
      (entity) => entity.role === 'user'
    );

    if (!contactEntity) {
  return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 24, height: 24, mr: 1, bgcolor: 'primary.main' }}>
            <Person fontSize="small" />
          </Avatar>
          <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
            (No contact)
          </Typography>
        </Box>
      );
    }

    let displayName = '(Unnamed)';
    if (contactEntity.name) {
      displayName = contactEntity.name;
    } else if (contactEntity.phone) {
      displayName = contactEntity.phone;
    } else if (contactEntity.email) {
      displayName = contactEntity.email;
    }

    // Generate initials for the avatar
    const initials = displayName
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);

    const handleContactClick = (e: React.MouseEvent) => {
      e.stopPropagation(); // Prevent row click
      if (contactEntity.contact_id) {
        // Navigate to contact page - adjust the path as needed
        window.open(`/contacts/${contactEntity.contact_id}`, '_blank');
      } else {
        enqueueSnackbar('No contact ID available', { variant: 'warning' });
      }
    };

    return (
            <Box 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
        onClick={handleContactClick}
      >
            <Avatar 
              sx={{ 
            width: 24, 
            height: 24, 
            mr: 1, 
            fontSize: '12px',
            bgcolor: stringToColor(displayName)
          }}
        >
          {initials}
            </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {displayName}
              </Typography>
          {contactEntity.email && contactEntity.phone && (
            <Typography variant="caption" color="text.secondary">
              {contactEntity.phone}
            </Typography>
          )}
            </Box>
          </Box>
    );
  };

  const renderStatus = (conversation: any) => {
    const statusColors: Record<string, string> = {
      active: 'success',
      open: 'success',
      closed: 'error',
      pending: 'warning',
      needs_attention: 'error',
      awaiting_reply: 'warning',
      standby: 'info',
      pending_user_reply: 'info',
      resolved: 'info'
    };

    const deliveryStateColors: Record<string, string> = {
      delivered: 'success',
      sent: 'info',
      failed: 'error',
      pending: 'warning',
      play: 'success',
      pause: 'warning',
      delivering: 'info'
    };

    const statusLabel = conversation.status || 'unknown';
    const deliveryState = conversation.delivery_state || 'unknown';

    // The color for the status chip
    const statusColor = statusColors[statusLabel] || 'default';
    const deliveryColor = deliveryStateColors[deliveryState] || 'default';

    // Safe access to theme colors with fallbacks
    const getThemePaletteColor = (colorName: string) => {
      const fallbackColors = {
        success: '#4caf50',
        error: '#f44336',
        warning: '#ff9800',
        info: '#2196f3',
        default: '#757575'
      };
      
      return theme.palette[colorName]?.main || fallbackColors[colorName] || fallbackColors.default;
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Chip
          label={formatStatusText(statusLabel)}
          color={statusColor as any}
                  size="small"
          variant="filled" 
                  sx={{ 
            height: '22px', // 10% larger
            bgcolor: getThemePaletteColor(statusColor),
            color: 'white',
                    '& .MuiChip-label': {
              px: 1.1, // 10% larger
              fontSize: '0.77rem', // 10% larger
              fontWeight: 600
                    }
                  }}
                />
        
        <Tooltip title={`Delivery state: ${deliveryState}`} arrow>
                <Chip 
            label={deliveryState}
            variant="filled"
                  size="small"
            sx={{ 
              height: '20px',
              bgcolor: getThemePaletteColor(deliveryColor),
              color: 'white',
              '& .MuiChip-label': { 
                px: 0.5,
                fontSize: '0.65rem',
                fontWeight: 500
              }
            }}
          />
            </Tooltip>
          </Box>
    );
  };

  // Utility function to consistently generate a color from a string
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  return (
    <TableRow
      hover
      selected={selected}
      onClick={handleRowClick}
              sx={{ 
        cursor: disableClick ? 'default' : 'pointer',
        backgroundColor: selected ? (isDarkMode ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.primary.main, 0.05)) : 'transparent',
        '&:hover': {
          backgroundColor: selected
            ? isDarkMode ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.primary.main, 0.07)
            : isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)'
        },
        // Apply flashing red border if it's a priority conversation
        ...(isPriority && {
          position: 'relative',
          borderLeft: `4px solid ${theme.palette.error.main}`,
          ...getPriorityBorderStyle(isDarkMode)
        })
      }}
    >
      {showCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
                  onClick={(e) => {
                    e.stopPropagation();
              onSelectCheckbox();
            }}
          />
        </TableCell>
      )}

      {/* Status cell */}
      <TableCell 
              sx={{ 
          width: '120px', 
          minWidth: '120px', 
          p: 1
        }}
      >
        {renderStatus(conversation)}
      </TableCell>

      {/* Contact cell */}
      <TableCell 
                      sx={{
          width: '180px', 
          minWidth: '180px', 
          p: 1
        }}
      >
        {renderContact(conversation)}
      </TableCell>

      {/* Subject cell */}
      <TableCell 
                            sx={{
          width: '220px', 
          minWidth: '220px', 
          p: 1 
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography 
            variant="body2" 
                              sx={{
              fontWeight: 500,
              mb: 0.5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical'
            }}
          >
            {conversation.subject || '(No subject)'}
          </Typography>
          
          {/* Display creation date */}
                              <Typography 
                                variant="caption" 
            color="text.secondary"
                                sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical'
            }}
          >
            Created: {conversation.created_at ? format(new Date(conversation.created_at), 'MMM d, yyyy') : 'Unknown'}
                              </Typography>
        </Box>
      </TableCell>
                              
      {/* Message Preview cell - enhanced with latest non-function message */}
      <TableCell sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          {/* Latest message preview */}
                              <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical'
            }}
          >
            {(() => {
              const latestMessage = getLatestNonFunctionMessage(conversation);
              if (latestMessage) {
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography 
                      component="span" 
                                variant="caption" 
                                sx={{ 
                        fontWeight: 'bold', 
                        mr: 0.5, 
                        color: latestMessage.sender_role === 'assistant' ? 'info.main' : 'success.main'
                                }}
                              >
                      {latestMessage.sender_role === 'assistant' ? 'Assistant: ' : 'User: '}
                              </Typography>
                    {latestMessage.content.length > 60 
                      ? latestMessage.content.substring(0, 60) + '...' 
                      : latestMessage.content}
                    </Box>
                  );
              }
              return '(No messages)';
            })()}
          </Typography>

          {/* Memory details as chips */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
            {getImportantMemoryDetails(conversation).map((detail, index) => (
              <Chip
                key={index}
                size="small"
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Typography component="span" variant="caption">{detail.emoji}</Typography>
                    <Typography component="span" variant="caption">{detail.value}</Typography>
                  </Box>
                }
                  sx={{ 
                  height: '20px',
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                  '& .MuiChip-label': { px: 0.8, py: 0 }
                }}
              />
            ))}
          </Box>
          
          {/* Summary of current state and next steps */}
          {conversation.memory && conversation.memory.conversation_summary && (
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ mt: 0.5, fontStyle: 'italic' }}
            >
              {conversation.memory.conversation_summary.length > 80 
                ? conversation.memory.conversation_summary.substring(0, 80) + '...' 
                : conversation.memory.conversation_summary}
                </Typography>
              )}
                </Box>
      </TableCell>

      {/* User Messages Count cell */}
      <TableCell 
        align="center" 
                sx={{ 
          width: '80px', 
          minWidth: '80px',
          p: 1 
        }}
      >
        <Chip
          label={conversation.user_messages_count || '0'}
          size="small"
                  sx={{
            backgroundColor: theme => theme.palette.mode === 'dark' ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.primary.main, 0.1),
            color: 'primary.main',
            fontWeight: 'bold',
            height: '22px'
          }}
        />
      </TableCell>

      {/* Last User Message cell */}
      <TableCell 
        align="right" 
        sx={{ 
          width: '140px', 
          minWidth: '140px',
          p: 1 
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {conversation.last_user_message_received_at
            ? format(new Date(conversation.last_user_message_received_at), 'MMM d, h:mm a')
            : 'Never'}
        </Typography>
      </TableCell>

      {/* Updated At cell */}
      <TableCell 
        align="right" 
          sx={{ 
          width: '120px', 
          minWidth: '120px',
          p: 1 
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {conversation.updated_at
            ? format(new Date(conversation.updated_at), 'MMM d, h:mm a')
            : 'Unknown'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ConversationRowItem; 
