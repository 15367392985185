import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import analyticsMiddleware from './features/analytics/analytics.middleware';
import authReducer from './features/auth/auth.slice';
import messageReducer from './features/auth/message.slice';
import onBoardingSlice from './features/onboarding/onboarding.slide';
import bookingReducer from './features/services/booking.slice';
import photographerReducer from './features/services/photographer.slice';
import userReducer from './features/user/user.slice';
import galleryReducer from './features/services/gallery.slice';
import themeReducer from './features/theme/theme.slice';
import channelsReducer from './features/account/channels.slice';
import conversationsReducer from './features/account/conversations.slice';
import messagesReducer from './features/account/messages.slice';
import contactsReducer from './features/account/contacts.slice';
import faqsReducer from './features/account/faqs.slice';
import filteredContactsReducer from './features/account/filteredContacts.slice';
import filteredConversationsReducer from './features/account/filteredConversations.slice';
import notesReducer from './features/notes/notes.slice';
import notificationsReducer from './features/account/notifications.slice';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  onBoarding: onBoardingSlice,
  message: messageReducer,
  photographerServices: photographerReducer,
  bookingServices: bookingReducer,
  galleryServices: galleryReducer,
  theme: themeReducer,
  channels: channelsReducer,
  conversations: conversationsReducer,
  messages: messagesReducer,
  contacts: contactsReducer,
  filteredContacts: filteredContactsReducer,
  faqs: faqsReducer,
  filteredConversations: filteredConversationsReducer,
  notes: notesReducer,
  notifications: notificationsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(analyticsMiddleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

// BEGIN frontend/src/store.ts
