import { ReactNode } from 'react';

export type FilterGroupType = 'Details' | 'Company' | 'Activity' | 'Advanced';

export interface ScoreRange {
  min: number;
  max: number;
}

export type FilterValueType = 
  | string 
  | string[] 
  | boolean 
  | number 
  | ScoreRange
  | Date;

export interface FilterOption {
  id: FilterId;
  label: string;
  group: FilterGroupType;
  icon?: ReactNode;
  placeholder?: string;
  helperText?: string;
  validation?: {
    min?: number;
    max?: number;
    pattern?: RegExp;
    errorMessage?: string;
  };
}

// All possible filter IDs
export type FilterId = 
  | 'name'
  | 'first_name'
  | 'last_name'
  | 'phone_number'
  | 'email'
  | 'email_not_contains'
  | 'address'
  | 'tags'
  | 'tags_not_contains'
  | 'stage'
  | 'source'
  | 'without_phone_number'
  | 'without_email'
  | 'favorite'
  | 'company_name'
  | 'company_size'
  | 'company_industry'
  | 'company_website'
  | 'company_address'
  | 'created_at_before'
  | 'created_at_after'
  | 'updated_at_before'
  | 'updated_at_after'
  | 'last_contacted_at_before'
  | 'last_contacted_at_after'
  | 'metadata_contains'
  | 'metadata_not_contains'
  | 'has_no_conversations'
  | 'score'
  | 'limit'
  | 'inactive'
  | 'next_task'
  | 'last_activity'
  | 'last_communication'
  | 'timeframe';

export interface FilterValue {
  option: FilterOption;
  value: FilterValueType;
}

export interface FilterState {
  [key: string]: FilterValueType;
}

export type FilterInputType = 'text' | 'array' | 'boolean' | 'number' | 'score' | 'date' | 'select';

// Helper type for filter configurations
export interface FilterConfig {
  id: FilterId;
  label: string;
  group: FilterGroupType;
  type: FilterInputType;
  options?: string[];
  defaultValue: FilterValueType;
  placeholder?: string;
  helperText?: string;
  validation?: {
    min?: number;
    max?: number;
    pattern?: RegExp;
    errorMessage?: string;
  };
  icon?: ReactNode;
}

// Type guard to check if a value is a ScoreRange
export function isScoreRange(value: FilterValueType): value is ScoreRange {
  return (
    typeof value === 'object' &&
    value !== null &&
    'min' in value &&
    'max' in value &&
    typeof value.min === 'number' &&
    typeof value.max === 'number'
  );
}

// Type guard to check if a value is a Date
export function isDate(value: FilterValueType): value is Date {
  return value instanceof Date;
} 
