import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Box } from '@mui/material';

/**
 * CustomMarkdown component prevents DOM nesting validation errors
 * that occur when Markdown renders p tags nested inside other p tags.
 * 
 * This happens when a Typography component (which renders as a p tag by default)
 * has a Markdown component inside it that also renders paragraphs.
 */
const CustomMarkdown: React.FC<{ children: string }> = ({ children }) => {
  // Default overrides to prevent DOM nesting issues
  const options = {
    overrides: {
      p: {
        component: Box,
        props: {
          component: 'div',
          sx: {
            mb: 1,
            mt: 1,
            lineHeight: 1.5,
          },
        },
      },
      // Ensure other block elements are rendered properly
      h1: {
        component: Box,
        props: {
          component: 'h1',
          sx: { mt: 2, mb: 1, fontSize: '2em', fontWeight: 'bold' },
        },
      },
      h2: {
        component: Box,
        props: {
          component: 'h2',
          sx: { mt: 2, mb: 1, fontSize: '1.5em', fontWeight: 'bold' },
        },
      },
      h3: {
        component: Box,
        props: {
          component: 'h3',
          sx: { mt: 1.5, mb: 1, fontSize: '1.25em', fontWeight: 'bold' },
        },
      },
      ul: {
        component: Box,
        props: {
          component: 'ul',
          sx: { pl: 2, mb: 1 },
        },
      },
      ol: {
        component: Box,
        props: {
          component: 'ol',
          sx: { pl: 2, mb: 1 },
        },
      },
    },
  };

  return <Markdown options={options}>{children || ''}</Markdown>;
};

export default CustomMarkdown; 