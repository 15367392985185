import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme, DialogContentText } from '@mui/material';


const ChannelDeleteDialog = ({ open, handleClose, channel, handleDeleteChannel }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        maxWidth="xs"
        key={channel.id}
      >
        <DialogTitle color="error">Permanently Delete {channel.config?.chatbot_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this channel? This is not reversible! You will lose this channel forever, as well as all the data associated with it, including messages, appointments, and conversations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteChannel}
            sx={{
              marginRight: 1,
              backgroundColor: theme.palette.warning.main,
              color: theme.palette.text.primary,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChannelDeleteDialog;
