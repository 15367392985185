/// i want to use this component to generate random key values for the form. When it is called, it should first set a random number and then return that value in the array of 100 items

const getRandomName = () => {
  const firstNames = [
    "Aiden",
    "Alex",
    "Amelia",
    "Angel",
    "Andy",
    "Anthony",
    "Arden",
    "Aria",
    "Ariel",
    "Ariel",
    "Arielo",
    "Arlo",
    "Asher",
    "Ashton",
    "Aspen",
    "Aubrey",
    "August",
    "Bailey",
    "Barbara",
    "Bennett",
    "Blake",
    "Bowie",
    "Briar",
    "Brielle",
    "Brook",
    "Cairo",
    "Callie",
    "Cameron",
    "Carter",
    "Casey",
    "Cassidy",
    "Charles",
    "Charlie",
    "Christopher",
    "Claire",
    "Cameron",
    "Daniel",
    "Dana",
    "Darcy",
    "David",
    "Donnie",
    "Dakota",
    "Dallas",
    "Daniel",
    "Darcy",
    "David",
    "Delilah",
    "Dexter",
    "Drew",
    "Dylan",
    "Elliot",
    "Ellis",
    "Eloise",
    "Emerson",
    "Emory",
    "Ethan",
    "Eva",
    "Evren",
    "Ezra",
    "Finley",
    "Finn",
    "Fiona",
    "Florian",
    "Flynn",
    "Flynn",
    "Frankie",
    "Garnet",
    "Gavin",
    "Genesis",
    "Georgia",
    "Grace",
    "Harley",
    "Harper",
    "Hayden",
    "Hazel",
    "Holden",
    "Ian",
    "Indigo",
    "Isabella",
    "Ivy",
    "John",
    "Joyce",
    "Joan",
    "Jade",
    "Jaden",
    "James",
    "Jasper",
    "Jennifer",
    "Jessica",
    "Jules",
    "Julian",
    "Justice",
    "Juan",
    "Judith",
    "Joseph",
    "Jose",
    "Kai",
    "Kara",
    "Karen",
    "Kendall",
    "Keira",
    "Kit",
    "Knight",
    "Lake",
    "Landry",
    "Lane",
    "Lara",
    "Larisa",
    "Laurisa",
    "Lawrence",
    "Leighton",
    "Leo",
    "Lennox",
    "Liam",
    "Lisa",
    "Luna",
    "Lyric",
    "Maggie",
    "Margaret",
    "Maria",
    "Marley",
    "Mark",
    "Marshall",
    "Mary",
    "Matthew",
    "Maya",
    "Mia",
    "Michael",
    "Milan",
    "Milo",
    "Morgan",
    "Nancy",
    "Nico",
    "Nolan",
    "Nora",
    "Nova",
    "Noah",
    "Ocean",
    "Olivia",
    "Orion",
    "Oscar",
    "Parker",
    "Pamela",
    "Pat",
    "Patty",
    "Pam",
    "Pablo",
    "Patricia",
    "Pax",
    "Penelope",
    "Peyton",
    "Phoenix",
    "Piper",
    "Presley",
    "Preston",
    "Quentin",
    "Quincy",
    "Quinn",
    "Remington",
    "Remy",
    "Reese",
    "Rhys",
    "Richard",
    "Riley",
    "Riley",
    "River",
    "Rivers",
    "Robin",
    "Robert",
    "Rory",
    "Rowan",
    "Rowyn",
    "Ruby",
    "Rylan",
    "Sadie",
    "Sage",
    "Sandy",
    "Sarah",
    "Sawyer",
    "Scout",
    "Silas",
    "Sinclair",
    "Sky",
    "Skyler",
    "Spencer",
    "Stevie",
    "Storm",
    "Stuart",
    "Susan",
    "Sutton",
    "Swanson",
    "Tatum",
    "Taylor",
    "Teagan",
    "Tessa",
    "Theo",
    "Thomas",
    "Thomas",
    "Tucker",
    "Tiger",
    "Timothy",
    "Tristan",
    "Una",
    "Uriel",
    "Uma",
    "Vance",
    "Vera",
    "Victor",
    "Violet",
    "Violet",
    "Violeta",
    "Vivian",
    "Viviana",
    "Wesley",
    "Whitman",
    "Willow",
    "William",
    "Williams",
    "Winchester",
    "Winter",
    "Wyatt",
    "Xander",
    "Xavier",
    "Xenia",
    "Yara",
    "Yasmine",
    "Yusuf",
    "Zachary",
    "Zane",
    "Zephyr",
    "Zimmerman",
    "Zoey"
  ]

const lastNames = [
    "Addams",
    "Agnes",
    "Aldrin",
    "Aladdin",
    "Anderson",
    "Ariel",
    "Arnold",
    "Babu",
    "Banks",
    "Banana",
    "Baratheon",
    "Bania",
    "Bauer",
    "Beesly",
    "Belfort",
    "Benes",
    "Bennett",
    "Bing",
    "Biggs",
    "Bluth",
    "Bookman",
    "Braddock",
    "Brown",
    "Buckett",
    "Bundy",
    "Buzz",
    "Byers",
    "Calloway",
    "Callahan",
    "Carmichael",
    "Carter",
    "Cobblepot",
    "Costanza",
    "Crane",
    "Cunningham",
    "Dawson",
    "Davis",
    "Dent",
    "Dinkins",
    "Dixon",
    "Doyle",
    "Dunphy",
    "Ellis",
    "Evans",
    "Eriksen",
    "Farkus",
    "Finster",
    "Fletcher",
    "Flower",
    "Forman",
    "Foreman",
    "Foley",
    "Frye",
    "Geller",
    "Gendason",
    "Genie",
    "Gibbs",
    "Gilbert",
    "Griffin",
    "Green",
    "Grey",
    "Gribble",
    "Grimes",
    "Gump",
    "Halpert",
    "Hargreeves",
    "Harper",
    "Henderson",
    "Hiccup",
    "Hill",
    "Hyde",
    "Irving",
    "Irwin",
    "Jasmine",
    "Jennings",
    "Johnson",
    "Jones",
    "Joyce",
    "Kahn",
    "Kaiba",
    "Keaton",
    "Kelso",
    "Knight",
    "Knope",
    "Kyle",
    "Lambert",
    "Lawrence",
    "Lightfoot",
    "Lippman",
    "Lomez",
    "Loeb",
    "Lockhart",
    "Mann",
    "Marshall",
    "Matthau",
    "Maverick",
    "McClane",
    "McFly",
    "McQueen",
    "Miller",
    "Moore",
    "Mosby",
    "Motou",
    "Mulder",
    "Mufasa",
    "Mulder",
    "Munster",
    "Murdoch",
    "Newman",
    "Nibble",
    "Nozicka",
    "Norton",
    "Newman",
    "O'Donnell",
    "Olsen",
    "Organa",
    "O'Hare",
    "Palmer",
    "Pan",
    "Parr",
    "Perry",
    "Peterman",
    "Pickles",
    "Pitt",
    "Platter",
    "Poppins",
    "Potter",
    "Powers",
    "Pritchett",
    "Pumbaa",
    "Quagmire",
    "Quincy",
    "Quinn",
    "Quinzel",
    "Ralph",
    "Rava",
    "Reynolds",
    "Ripley",
    "Rivers",
    "Ross",
    "Santana",
    "Scar",
    "Sheffield",
    "Shepherd",
    "Sheridan",
    "Sherman",
    "Shortman",
    "Simba",
    "Skywalker",
    "Slater",
    "Smith",
    "Snow",
    "Solo",
    "Soprano",
    "Sternin",
    "Stevens",
    "Stinson",
    "Stuart",
    "Swanson",
    "Svetka",
    "Tanner",
    "Taylor",
    "Thumper",
    "Timon",
    "Tiger",
    "Toothless",
    "Triton",
    "Travis",
    "Turner",
    "Ulrich",
    "Underwood",
    "Vance",
    "Vandelay",
    "Vaughn",
    "Venkman",
    "Wallace",
    "Wayne",
    "Weasley",
    "West",
    "Wheeler",
    "Whitman",
    "Wilhelm",
    "Williams",
    "Whatley",
    "Wilson",
    "Xavier",
    "York",
    "Yang",
    "Young",
    "Zazu"
  ]

  return firstNames[Math.floor(Math.random() * firstNames.length)] + " " + lastNames[Math.floor(Math.random() * lastNames.length)];
}

export default getRandomName;
