import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Autocomplete,
  useTheme,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateContact, selectContactById, getContact } from 'src/features/account/contacts.slice';
import { fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { enqueueSnackbar } from 'notistack';

interface BulkTagDialogProps {
  open: boolean;
  onClose: () => void;
  selectedContactIds: string[];
}

const BulkTagDialog: React.FC<BulkTagDialogProps> = ({
  open,
  onClose,
  selectedContactIds,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const contacts = useAppSelector((state) =>
    selectedContactIds
      .map((id) => selectContactById(state, id))
      .filter((contact) => contact !== undefined)
  );

  const handleTagChange = (event, newValue) => {
    setTags(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue !== '' && !tags.includes(trimmedValue)) {
        setTags([...tags, trimmedValue]);
        setInputValue('');
      }
    }
  };

  const handleSubmit = () => {
    if (tags.length === 0) {
      enqueueSnackbar('Please add at least one tag', { variant: 'error' });
      return;
    }

    setIsSubmitting(true);

    Promise.all(
      selectedContactIds.map((contactId) => {
        const contact = contacts.find((c) => c?.id === contactId);
        if (!contact) {
          console.error(`Contact with ID ${contactId} not found.`);
          return Promise.resolve(); // Skip updating this contact
        }

        // Extract existing tags, ensuring it's always an array
        const existingTags = Array.isArray(contact.tags) ? contact.tags : [];

        // Combine existing tags with new tags, removing duplicates
        const updatedTags = Array.from(new Set([...existingTags, ...tags]));

        // Dispatch the update action with updated tags
        return dispatch(updateContact({
          contactId,
          updates: { tags: updatedTags }
        }));
      })
    )
      .then(() => {
        setIsSubmitting(false);
        enqueueSnackbar(`Successfully added tags to ${selectedContactIds.length} contacts`, { variant: 'success' });
        // Refresh contacts list after update
        dispatch(fetchFilteredContacts({}));
        onClose();
        // Reset the form
        setTags([]);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error('Failed to update contacts:', error);
        enqueueSnackbar('Failed to update contacts', { variant: 'error' });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          bgcolor: isDarkMode ? 'background.paper' : 'background.paper',
          borderRadius: 2,
          boxShadow: 10,
        }
      }}
    >
      <DialogTitle sx={{
        borderBottom: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
        pb: 2
      }}>
        Add Tags to {selectedContactIds.length} Contacts
      </DialogTitle>

      <DialogContent sx={{ py: 3 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Add tags to all selected contacts. Existing tags will be preserved.
        </Typography>

        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={tags}
          onChange={handleTagChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                key={option}
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                sx={{
                  borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                  m: 0.5,
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Tags"
              placeholder="Type tag and press Enter or Space to add"
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  transition: 'all 0.3s ease',
                }
              }}
            />
          )}
          sx={{ mb: 2 }}
        />

        <Box sx={{
          mt: 3,
          p: 2,
          bgcolor: isDarkMode ? 'rgba(0, 0, 0, 0.09)' : 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
          border: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}`
        }}>
          <Typography variant="caption" color="text.secondary">
            Tips:
          </Typography>
          <ul style={{ margin: '8px 0', paddingLeft: '20px' }}>
            <li>
              <Typography variant="caption" color="text.secondary">
                Enter multiple tags separated by Enter or Space
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="text.secondary">
                Tags will be added to existing tags on each contact
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="text.secondary">
                Duplicate tags will be automatically removed
              </Typography>
            </li>
          </ul>
        </Box>
      </DialogContent>

      <DialogActions sx={{
        px: 3,
        py: 2,
        borderTop: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
      }}>
        <Button
          onClick={onClose}
          disabled={isSubmitting}
          sx={{
            color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
            '&:hover': {
              color: isDarkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)',
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || tags.length === 0}
          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            px: 3,
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            },
            '&.Mui-disabled': {
              bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
            }
          }}
        >
          {isSubmitting ? 'Adding Tags...' : 'Add Tags'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkTagDialog; 
