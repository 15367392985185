/**
 * Utility functions for conversation management
 */

/**
 * Determines if a conversation should be treated as priority based on various conditions
 * @param conversation The conversation object to check
 * @returns boolean indicating if the conversation is priority
 */
export const isPriorityConversation = (conversation: any): boolean => {
  if (!conversation) return false;
  
  // Check for needs_attention status
  if (conversation.status === 'needs_attention') return true;
  
  // Check for error status
  if (conversation.status === 'error') return true;
  
  // Check for conversations with failed message deliveries
  const hasFailedMessages = conversation.messages?.some(
    (message: any) => message.status === 'failed'
  );
  if (hasFailedMessages) return true;
  
  // Check if a conversation has been awaiting reply for too long (over 24 hours)
  if (conversation.status === 'awaiting_reply' && conversation.last_user_message_received_at) {
    const lastMessageTime = new Date(conversation.last_user_message_received_at).getTime();
    const currentTime = new Date().getTime();
    const hoursDifference = (currentTime - lastMessageTime) / (1000 * 60 * 60);
    
    // If it's been more than 24 hours since the last user message and we're awaiting a reply
    if (hoursDifference > 24) return true;
  }
  
  // Check if the conversation has pending messages but delivery is paused
  const hasPendingMessages = conversation.messages?.some(
    (message: any) => message.status === 'pending_delivery'
  );
  if (hasPendingMessages && conversation.delivery_state === 'pause') return true;
  
  return false;
};

/**
 * Determines if a conversation has pending messages that need to be sent
 * @param conversation The conversation object to check
 * @returns boolean indicating if the conversation has unsent messages
 */
export const hasUnsentMessages = (conversation: any): boolean => {
  if (!conversation || !conversation.messages) return false;
  
  return conversation.messages.some(
    (message: any) => message.status === 'pending_delivery'
  );
};

/**
 * Filter conversations based on selected filter type
 * @param conversations Array of conversation objects
 * @param filterType The active filter type
 * @returns Filtered and sorted array of conversations
 */
export const filterConversations = (conversations: any[], filterType: string): any[] => {
  if (!conversations || !Array.isArray(conversations)) return [];
  
  // Create a copy of conversations to avoid mutating the original
  let filteredConversations = [...conversations];
  
  switch (filterType) {
    case 'priority':
      return filteredConversations.filter(conv => isPriorityConversation(conv));
      
    case 'engaged_users':
      return filteredConversations.filter(conv => {
        const userMessagesCount = conv.user_messages_count || 0;
        return userMessagesCount > 2 && conv.status !== 'closed';
      });
      
    case 'draft_messages':
      return filteredConversations.filter(conv => 
        conv.messages?.some(message => message.status === 'pending_delivery' || message.status === 'draft')
      );
      
    case 'draft_conversations':
      return filteredConversations.filter(conv => 
        conv.status === 'draft'
      );
      
    case 'pending_follow_up':
      return filteredConversations.filter(conv => 
        conv.messages?.some(message => message.status === 'pending_delivery' && message.message_type === 'follow_up' )
      );
    
    case 'pending_follow_up_within_12_hours':
      return filteredConversations.filter(conv => 
        conv.messages?.some(message => message.status === 'pending_delivery' && message.message_type === 'follow_up' && message.created_at > new Date(Date.now() - 12 * 60 * 60 * 1000))
      );
          
    case 'auto_reply_off':
      return filteredConversations.filter(conv => 
        conv.delivery_state === 'pause' || conv.autocomplete_status === 'disabled'
      );
      
    case 'manual_standby':
      return filteredConversations.filter(conv => 
        conv.status === 'standby'
      );
      
    case 'awaiting_reply':
      return filteredConversations.filter(conv => 
        conv.status === 'awaiting_reply' || conv.status === 'pending_user_reply'
      );
      
    case 'needs_attention':
      return filteredConversations.filter(conv => 
        conv.status === 'needs_attention' || conv.status === 'error'
      );
      
    case 'closed':
      return filteredConversations.filter(conv => 
        conv.status === 'closed'
      );
    
    case 'all_active':
    default:
      return filteredConversations.filter(conv => 
        conv.status !== 'closed'
      );
  }
};

/**
 * Sort conversations with priority ones first
 * @param conversations Array of conversation objects
 * @returns Sorted array with priority conversations first, then those with unsent messages
 */
export const sortWithPriorityFirst = (conversations: any[]): any[] => {
  if (!conversations || !Array.isArray(conversations)) return [];
  
  return [...conversations].sort((a, b) => {
    // Priority conversations come first
    const aPriority = isPriorityConversation(a);
    const bPriority = isPriorityConversation(b);
    
    if (aPriority && !bPriority) return -1;
    if (!aPriority && bPriority) return 1;
    
    // Then conversations with unsent messages
    const aHasUnsent = hasUnsentMessages(a);
    const bHasUnsent = hasUnsentMessages(b);
    
    if (aHasUnsent && !bHasUnsent) return -1;
    if (!aHasUnsent && bHasUnsent) return 1;
    
    // Otherwise, sort by updated_at (most recent first)
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });
};

/**
 * Generates a pulsating border animation style for priority conversations
 * @returns CSS style object for a pulsating red border
 */
export const getPriorityBorderStyle = (isDarkMode: boolean) => {
  return {
    animation: 'pulse-border 2s infinite',
    '@keyframes pulse-border': {
      '0%, 100%': { 
        borderColor: isDarkMode ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 0, 0, 0.4)',
        boxShadow: `0 0 5px ${isDarkMode ? 'rgba(255, 0, 0, 0.4)' : 'rgba(255, 0, 0, 0.3)'}`
      },
      '50%': { 
        borderColor: isDarkMode ? 'rgba(255, 0, 0, 0.9)' : 'rgba(255, 0, 0, 0.8)',
        boxShadow: `0 0 12px ${isDarkMode ? 'rgba(255, 0, 0, 0.7)' : 'rgba(255, 0, 0, 0.6)'}`
      }
    }
  };
}; 
