import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateChannelAdminSettings, fetchChannelAdminSettings } from 'src/features/account/channels.slice';
import { 
  Drawer, 
  Button, 
  TextField, 
  Box, 
  Typography, 
  CircularProgress, 
  List, 
  ListItem, 
  ListItemText, 
  IconButton, 
  Divider,
  Chip,
  Stack,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';

// These are the sensitive functions that only admins can access
const restrictedFunctions = [
  {
    name: "fetch_calendar_events_function",
    description: "Retrieves a list of calendar events based on date range, search query, or specific identifiers."
  },
  {
    name: "get_filtered_contacts_function",
    description: "Searches and retrieves contacts based on various filter criteria like name, email, phone, or tags."
  },
  {
    name: "find_or_create_contact_function",
    description: "Locates an existing contact or creates a new one if not found."
  },
  {
    name: "update_contact_function",
    description: "Modifies contact information including name, email, phone, and custom metadata."
  },
  {
    name: "create_new_conversation_with_contact_function",
    description: "Initiates a new conversation with a contact using their preferred communication method."
  },
  {
    name: "get_conversation_with_messages_function",
    description: "Retrieves a specific conversation along with its messages."
  },
  {
    name: "fetch_conversations_list_function",
    description: "Lists conversations and their messages matching given criteria."
  },
  {
    name: "post_message_to_conversation_function",
    description: "Posts a message to a different conversation."
  }
];

const UpdateAdminSettings = ({ channel, styles, open, setOpen }) => {
  const dispatch = useAppDispatch();
  const channelAdminSettings = useAppSelector(state => state.channels.channels.find(c => c.id === channel.id)?.admin_settings);
  const [drawerOpen, setDrawerOpen] = useState(open);
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const loading = channelAdminSettings?.loading || false;
  
  // Extract the admin settings data, handling different possible structures
  const adminSettingsData = channelAdminSettings?.admin_settings || channelAdminSettings;
  
  // Handle both API response formats with nested structures
  const adminEmails = (() => {
    // Check for nested admin_settings object first
    if (adminSettingsData?.admin_email) {
      return adminSettingsData.admin_email.map(email => ({ email }));
    }
    // Fall back to admin_emails array of objects
    else if (adminSettingsData?.admin_emails) {
      return adminSettingsData.admin_emails;
    }
    // Default to empty array
    return [];
  })();
  
  const adminPhones = (() => {
    // Check for nested admin_settings object first
    if (adminSettingsData?.admin_phone) {
      return adminSettingsData.admin_phone.map(phone => ({ phone }));
    }
    // Fall back to admin_phones array of objects
    else if (adminSettingsData?.admin_phones) {
      return adminSettingsData.admin_phones;
    }
    // Default to empty array
    return [];
  })();

  // Add state for tracking pending changes
  const [pendingEmailsToAdd, setPendingEmailsToAdd] = useState<string[]>([]);
  const [pendingEmailsToRemove, setPendingEmailsToRemove] = useState<string[]>([]);
  const [pendingPhonesToAdd, setPendingPhonesToAdd] = useState<string[]>([]);
  const [pendingPhonesToRemove, setPendingPhonesToRemove] = useState<string[]>([]);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  useEffect(() => {
    setDrawerOpen(open);
    if (open && channel.id) {
      dispatch(fetchChannelAdminSettings(channel.id));
      // Reset pending changes when opening drawer
      setPendingEmailsToAdd([]);
      setPendingEmailsToRemove([]);
      setPendingPhonesToAdd([]);
      setPendingPhonesToRemove([]);
      setHasPendingChanges(false);
    }
  }, [open, channel.id, dispatch]);

  // Check if there are any pending changes
  useEffect(() => {
    const hasChanges = 
      pendingEmailsToAdd.length > 0 || 
      pendingEmailsToRemove.length > 0 || 
      pendingPhonesToAdd.length > 0 || 
      pendingPhonesToRemove.length > 0;
    
    setHasPendingChanges(hasChanges);
  }, [pendingEmailsToAdd, pendingEmailsToRemove, pendingPhonesToAdd, pendingPhonesToRemove]);

  const handleAddEmail = () => {
    if (newEmail.trim()) {
      // Check if email is already in the list or pending removal
      const emailExists = adminEmails.some(item => item.email === newEmail) && 
                          !pendingEmailsToRemove.includes(newEmail);
      
      if (!emailExists && !pendingEmailsToAdd.includes(newEmail)) {
        setPendingEmailsToAdd([...pendingEmailsToAdd, newEmail]);
        
        // If this email was scheduled for removal, cancel that
        if (pendingEmailsToRemove.includes(newEmail)) {
          setPendingEmailsToRemove(pendingEmailsToRemove.filter(email => email !== newEmail));
        }
      }
      
      setNewEmail('');
    }
  };

  const handleKeyDownEmail = (e) => {
    if (e.key === 'Enter' && newEmail.trim()) {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (email: string) => {
    // If it's a pending addition, just remove from pending
    if (pendingEmailsToAdd.includes(email)) {
      setPendingEmailsToAdd(pendingEmailsToAdd.filter(e => e !== email));
    } 
    // Otherwise mark for removal
    else if (adminEmails.some(item => item.email === email) && !pendingEmailsToRemove.includes(email)) {
      setPendingEmailsToRemove([...pendingEmailsToRemove, email]);
      console.log(`Marked email '${email}' for removal`, pendingEmailsToRemove);
    }
  };

  const handleAddPhone = () => {
    if (newPhone.trim()) {
      // Check if phone is already in the list or pending removal
      const phoneExists = adminPhones.some(item => item.phone === newPhone) && 
                          !pendingPhonesToRemove.includes(newPhone);
      
      if (!phoneExists && !pendingPhonesToAdd.includes(newPhone)) {
        setPendingPhonesToAdd([...pendingPhonesToAdd, newPhone]);
        
        // If this phone was scheduled for removal, cancel that
        if (pendingPhonesToRemove.includes(newPhone)) {
          setPendingPhonesToRemove(pendingPhonesToRemove.filter(phone => phone !== newPhone));
        }
      }
      
      setNewPhone('');
    }
  };

  const handleKeyDownPhone = (e) => {
    if (e.key === 'Enter' && newPhone.trim()) {
      e.preventDefault();
      handleAddPhone();
    }
  };

  const handleRemovePhone = (phone: string) => {
    // If it's a pending addition, just remove from pending
    if (pendingPhonesToAdd.includes(phone)) {
      setPendingPhonesToAdd(pendingPhonesToAdd.filter(p => p !== phone));
    } 
    // Otherwise mark for removal
    else if (adminPhones.some(item => item.phone === phone) && !pendingPhonesToRemove.includes(phone)) {
      setPendingPhonesToRemove([...pendingPhonesToRemove, phone]);
      console.log(`Marked phone '${phone}' for removal`, pendingPhonesToRemove);
    }
  };

  const handleSaveChanges = () => {
    const data: {
      admin_emails_to_add?: string,
      admin_emails_to_remove?: string,
      admin_phone_numbers_to_add?: string,
      admin_phone_numbers_to_remove?: string
    } = {};

    if (pendingEmailsToAdd.length > 0) {
      data.admin_emails_to_add = pendingEmailsToAdd.join(',');
    }
    
    if (pendingEmailsToRemove.length > 0) {
      data.admin_emails_to_remove = pendingEmailsToRemove.join(',');
    }
    
    if (pendingPhonesToAdd.length > 0) {
      data.admin_phone_numbers_to_add = pendingPhonesToAdd.join(',');
    }
    
    if (pendingPhonesToRemove.length > 0) {
      data.admin_phone_numbers_to_remove = pendingPhonesToRemove.join(',');
    }

    if (Object.keys(data).length > 0) {
      dispatch(updateChannelAdminSettings({
        channelId: channel.id,
        data
      }));

      // Clear pending changes
      setPendingEmailsToAdd([]);
      setPendingEmailsToRemove([]);
      setPendingPhonesToAdd([]);
      setPendingPhonesToRemove([]);
    }
  };

  // Helper function to determine if an item is pending removal
  const isPendingRemoval = (item: string, type: 'email' | 'phone') => {
    return type === 'email' 
      ? pendingEmailsToRemove.includes(item)
      : pendingPhonesToRemove.includes(item);
  };

  console.log('Raw Admin Settings:', channelAdminSettings);
  console.log('Admin Settings Data:', adminSettingsData);
  console.log('Emails:', adminEmails);
  console.log('Phones:', adminPhones);

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setOpen(false)}
      >
        <Box p={2} width="500px" sx={{ bgcolor: 'background.paper' }}>
          <Typography variant="h6" gutterBottom>Admin Settings</Typography>
          
          {/* Explainer Text */}
          <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: 'info.light', color: 'info.contrastText' }}>
            <Box display="flex" alignItems="flex-start" mb={1}>
              <InfoIcon sx={{ mr: 1, color: 'info.main' }} />
              <Typography variant="subtitle2" fontWeight="bold">
                What are admin settings?
              </Typography>
            </Box>
            <Typography variant="body2" paragraph>
              Admin settings allow you to specify which email addresses and phone numbers are recognized as administrators for this Oppy channel. Users contacting from these addresses or numbers will have enhanced permissions and access to sensitive functions.
            </Typography>
            <Typography variant="body2">
              When a user contacts your Oppy from an admin email or phone, they will be automatically recognized as admins and given access to restricted capabilities.
            </Typography>
          </Paper>

          {/* Sensitive Functions List */}
          <Accordion sx={{ mb: 3 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <LockIcon sx={{ mr: 1, color: 'warning.main' }} />
                <Typography variant="subtitle1">Admin-Only Functions</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="text.secondary" paragraph>
                Admins have exclusive access to these sensitive functions that provide additional capabilities and access to private data:
              </Typography>
              <List dense sx={{ bgcolor: 'background.default', borderRadius: 1 }}>
                {restrictedFunctions.map((func, index) => (
                  <ListItem key={index} divider={index < restrictedFunctions.length - 1}>
                    <ListItemText 
                      primary={func.name.replace(/_function$/, '')}
                      secondary={func.description}
                      primaryTypographyProps={{ variant: 'body2', fontWeight: 'medium' }}
                      secondaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          
          <Box mb={3}>
            <Typography variant="subtitle1">Email Addresses</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                label="Add Email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                onKeyDown={handleKeyDownEmail}
                onBlur={() => newEmail.trim() && handleAddEmail()}
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
              />
              <IconButton 
                color="primary" 
                onClick={handleAddEmail}
                disabled={loading || !newEmail.trim()}
              >
                <AddIcon />
              </IconButton>
            </Box>
            
            <List dense>
              {adminEmails.length > 0 || pendingEmailsToAdd.length > 0 ? (
                <>
                  {/* Show existing emails */}
                  {adminEmails.map((item, index) => {
                    const isBeingRemoved = isPendingRemoval(item.email, 'email');
                    return (
                      <ListItem 
                        key={`existing-email-${index}`}
                        secondaryAction={
                          <IconButton 
                            edge="end" 
                            aria-label="delete" 
                            onClick={() => handleRemoveEmail(item.email)}
                            disabled={loading}
                            color={isBeingRemoved ? "error" : "default"}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                        sx={{
                          textDecoration: isBeingRemoved ? 'line-through' : 'none',
                          opacity: isBeingRemoved ? 0.5 : 1,
                          bgcolor: isBeingRemoved ? 'rgba(211, 47, 47, 0.05)' : 'transparent'
                        }}
                      >
                        <ListItemText 
                          primary={item.email} 
                          secondary={isBeingRemoved ? "Will be removed" : null}
                        />
                      </ListItem>
                    );
                  })}

                  {/* Show pending additions */}
                  {pendingEmailsToAdd.map((email, index) => (
                    <ListItem 
                      key={`new-email-${index}`}
                      secondaryAction={
                        <IconButton 
                          edge="end" 
                          aria-label="delete" 
                          onClick={() => handleRemoveEmail(email)}
                          disabled={loading}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      sx={{ color: 'primary.main' }}
                    >
                      <ListItemText 
                        primary={email} 
                        secondary="New (pending save)"
                      />
                    </ListItem>
                  ))}
                </>
              ) : (
                <ListItem>
                  <ListItemText primary="No admin emails configured" />
                </ListItem>
              )}
            </List>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Box mb={3}>
            <Typography variant="subtitle1">Phone Numbers</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                label="Add Phone"
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
                onKeyDown={handleKeyDownPhone}
                onBlur={() => newPhone.trim() && handleAddPhone()}
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
                placeholder="+1 (555) 123-4567"
              />
              <IconButton 
                color="primary" 
                onClick={handleAddPhone}
                disabled={loading || !newPhone.trim()}
              >
                <AddIcon />
              </IconButton>
            </Box>
            
            <List dense>
              {adminPhones.length > 0 || pendingPhonesToAdd.length > 0 ? (
                <>
                  {/* Show existing phones */}
                  {adminPhones.map((item, index) => {
                    const isBeingRemoved = isPendingRemoval(item.phone, 'phone');
                    return (
                      <ListItem 
                        key={`existing-phone-${index}`}
                        secondaryAction={
                          <IconButton 
                            edge="end" 
                            aria-label="delete" 
                            onClick={() => handleRemovePhone(item.phone)}
                            disabled={loading}
                            color={isBeingRemoved ? "error" : "default"}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                        sx={{
                          textDecoration: isBeingRemoved ? 'line-through' : 'none',
                          opacity: isBeingRemoved ? 0.5 : 1,
                          bgcolor: isBeingRemoved ? 'rgba(211, 47, 47, 0.05)' : 'transparent'
                        }}
                      >
                        <ListItemText 
                          primary={item.phone} 
                          secondary={isBeingRemoved ? "Will be removed" : null}
                        />
                      </ListItem>
                    );
                  })}

                  {/* Show pending additions */}
                  {pendingPhonesToAdd.map((phone, index) => (
                    <ListItem 
                      key={`new-phone-${index}`}
                      secondaryAction={
                        <IconButton 
                          edge="end" 
                          aria-label="delete" 
                          onClick={() => handleRemovePhone(phone)}
                          disabled={loading}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      sx={{ color: 'primary.main' }}
                    >
                      <ListItemText 
                        primary={phone} 
                        secondary="New (pending save)"
                      />
                    </ListItem>
                  ))}
                </>
              ) : (
                <ListItem>
                  <ListItemText primary="No admin phone numbers configured" />
                </ListItem>
              )}
            </List>
          </Box>
          
          {hasPendingChanges && (
            <Box mb={2} p={1} bgcolor="info.light" borderRadius={1}>
              <Typography variant="body2" color="info.dark">
                You have unsaved changes. Click Save to apply them.
              </Typography>
            </Box>
          )}
          
          {loading && (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress size={24} />
            </Box>
          )}
          
          <Stack direction="row" spacing={2} mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
              disabled={loading || !hasPendingChanges}
              startIcon={<SaveIcon />}
              fullWidth
            >
              Save Changes
            </Button>
            
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(false)}
              fullWidth
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default UpdateAdminSettings;
