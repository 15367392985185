import React, { useState, useRef, useEffect, PropsWithChildren } from 'react';
import {
  Box,
  Chip,
  Tooltip,
  IconButton,
  useTheme,
  Typography,
  alpha,
  Badge,
  Popover,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Paper,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Collapse,
  Autocomplete,
  ListSubheader
} from '@mui/material';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  PriorityHigh as PriorityHighIcon,
  Groups as GroupsIcon,
  EditNote as EditNoteIcon,
  AutoMode as AutoModeIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
  DoneAll as DoneAllIcon,
  Drafts as DraftsIcon,
  HourglassEmpty as HourglassEmptyIcon,
  ErrorOutline as ErrorOutlineIcon,
  Forum as ForumIcon,
  AllInbox as AllInboxIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  DateRange as DateRangeIcon,
  Memory as MemoryIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  PersonOff as PersonOffIcon,
  Code as CodeIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
import { filterConversations } from '../../utils/conversationUtils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchConversationsWithMessages } from "src/features/account/conversations.slice";
import { useAppDispatch, useAppSelector } from 'src/store';
import ContactsSearchAutocomplete from '../Contacts/ContactsSearchAutocomplete';
import { fetchFilteredConversations, setFilter, selectIsFilterActive } from 'src/features/account/filteredConversations.slice';

// Filter options with their properties
const filterOptions = [
  {
    id: 'all_active',
    label: 'All Active',
    icon: <AllInboxIcon />,
    color: 'default',
    tooltip: 'Show all active conversations',
    emoji: '📥'
  },
  {
    id: 'priority',
    label: 'Priority',
    icon: <PriorityHighIcon />,
    color: 'error',
    tooltip: 'Conversations requiring immediate attention',
    emoji: '🔴'
  },
  {
    id: 'engaged_users',
    label: 'Engaged Users',
    icon: <GroupsIcon />,
    color: 'success',
    tooltip: 'Conversations with actively engaged users',
    emoji: '👥'
  },
  {
    id: 'draft_messages',
    label: 'Draft Messages',
    icon: <DraftsIcon />,
    color: 'warning',
    tooltip: 'Conversations with pending delivery messages',
    emoji: '📝'
  },
  {
    id: 'draft_conversations',
    label: 'Draft Convos',
    icon: <EditNoteIcon />,
    color: 'info',
    tooltip: 'Conversations with no delivered messages',
    emoji: '✏️'
  },
  {
    id: 'scheduled_delivery',
    label: 'Scheduled Delivery',
    icon: <HourglassEmptyIcon />,
    color: 'info',
    tooltip: 'Conversations with pending delivery messages scheduled for future delivery',
    emoji: '⏳'
  },
  {
    id: 'manual_standby',
    label: 'Manual Mode',
    icon: <HourglassEmptyIcon />,
    color: 'secondary',
    tooltip: 'Conversations in manual mode or standby',
    emoji: '⏳'
  },
  {
    id: 'awaiting_reply',
    label: 'Awaiting User',
    icon: <ForumIcon />,
    color: 'warning',
    tooltip: 'Conversations waiting for user response',
    emoji: '💬'
  },
  {
    id: 'closed',
    label: 'Closed',
    icon: <ArchiveIcon />,
    color: 'default',
    tooltip: 'Closed conversations',
    emoji: '🗃️'
  },
  {
    id: 'pending_follow_up_within_12_hours',
    label: 'Pending Follow-Up (12h)',
    icon: <HourglassEmptyIcon />,
    color: 'info',
    tooltip: 'Conversations with pending follow-up messages within the last 12 hours',
    emoji: '⏳'
  }
];

const TIME_PRESETS = [
  { value: 'last_24h', label: 'Last 24 Hours' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_3_months', label: 'Last 3 Months' },
  { value: 'custom', label: 'Custom Date Range' }
];

const DATE_FIELD_OPTIONS = [
  { value: 'last_user_message_received_at_before', label: 'Last User Message Before' },
  { value: 'last_comms_from_contact_after', label: 'Last Comms From Contact After' },
  { value: 'last_sms_from_contact_after', label: 'Last SMS From Contact After' },
  { value: 'last_email_from_contact_after', label: 'Last Email From Contact After' },
  { value: 'last_chat_from_contact_after', label: 'Last Chat From Contact After' },
  { value: 'last_phone_call_from_contact_after', label: 'Last Phone Call From Contact After' },
  { value: 'conversation_latest_delivery_at_before', label: 'Latest Delivery Before' },
  { value: 'conversation_latest_message_created_at_before', label: 'Latest Message Created Before' },
  { value: 'conversation_last_updated_before', label: 'Last Updated Before' }
];

const statusOptions = [
  "Closed",
  "Error",
  "Awaiting Oppy",
  "Awaiting User",
  "Processing Reply",
  "Standby",
  "Needs Attention",
  "Draft",
  "Has Draft Messages (Pending Delivery)"
];

interface AdvancedFilters {
  subject_contains?: string;
  channel_id?: string;
  user_not_responded_to?: boolean;
  has_draft_messages?: boolean;
  memory_contains?: string;
  dateField?: string;
  dateValue?: Date | null;
  timePreset?: string;
  customDateStart?: Date | null;
  customDateEnd?: Date | null;
  status?: string;
  contactId?: string;
  queryString?: string;
  conversationLatestDeliveryAtBefore?: Date | null;
  conversationLatestMessageCreatedAtBefore?: Date | null;
  conversationLastUpdatedBefore?: Date | null;
  limit?: number;
  messagesLimit?: number;
}

interface ConversationFiltersProps {
  selectedFilter: string;
  onFilterChange: (filterId: string) => void;
  conversations: any[]; // Add conversations prop to calculate counts
  onAdvancedFilterChange?: (filters: Record<string, any>) => void;
  channels?: any[]; // Available channels for filtering
}

const ConversationFilters = ({
  selectedFilter,
  onFilterChange,
  conversations = [],
  onAdvancedFilterChange,
  channels = []
}: ConversationFiltersProps): JSX.Element => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();
  const isFilterActive = useAppSelector(selectIsFilterActive);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  // Advanced filters
  const [advancedFiltersAnchorEl, setAdvancedFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>({});
  const [showMemoryEditor, setShowMemoryEditor] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [showChannelSelector, setShowChannelSelector] = useState(false);

  // From ConversationsListFilterBar
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedContactId, setSelectedContactId] = useState("");
  const [subject, setSubject] = useState("");
  const [queryString, setQueryString] = useState("");
  const [latestDeliveryBefore, setLatestDeliveryBefore] = useState<Date | null>(null);
  const [latestMessageCreatedBefore, setLatestMessageCreatedBefore] = useState<Date | null>(null);
  const [lastUpdatedBefore, setLastUpdatedBefore] = useState<Date | null>(null);
  const [limit, setLimit] = useState(25);
  const [messagesLimit, setMessagesLimit] = useState(1);
  const [timeFilter, setTimeFilter] = useState<string>('');

  // Check if any advanced filters are applied
  const hasAdvancedFilters = Object.values(advancedFilters).some(
    value => value !== undefined && value !== '' && value !== null && value !== false
  );

  // Check if scrolling is needed and update arrow visibility
  const checkScrollPosition = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 5); // 5px buffer
    }
  };

  // Initialize scroll check
  useEffect(() => {
    checkScrollPosition();
    // Add resize listener to check scroll arrows on window resize
    window.addEventListener('resize', checkScrollPosition);
    return () => window.removeEventListener('resize', checkScrollPosition);
  }, []);

  // Scroll left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
      // After scrolling, check position again
      setTimeout(checkScrollPosition, 300);
    }
  };

  // Scroll right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
      // After scrolling, check position again
      setTimeout(checkScrollPosition, 300);
    }
  };

  // Handle advanced filters dialog
  const handleOpenAdvancedFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAdvancedFiltersAnchorEl(event.currentTarget);

    // Initialize advancedFilters with current values from state
    setAdvancedFilters({
      subject_contains: subject,
      status: selectedStatus,
      contactId: selectedContactId,
      queryString,
      conversationLatestDeliveryAtBefore: latestDeliveryBefore,
      conversationLatestMessageCreatedAtBefore: latestMessageCreatedBefore,
      conversationLastUpdatedBefore: lastUpdatedBefore,
      limit,
      messagesLimit,
      ...advancedFilters // Keep any other existing advanced filters
    });
  };

  const handleCloseAdvancedFilters = () => {
    setAdvancedFiltersAnchorEl(null);
  };

  // Helper for date presets
  const getDateFromPreset = (preset: string): Date | null => {
    const now = new Date();

    switch (preset) {
      case 'last_24h':
        return new Date(now.getTime() - 24 * 60 * 60 * 1000);
      case 'last_week':
        return new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      case 'last_month':
        return new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      case 'last_3_months':
        return new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
      default:
        // Handle month filters (e.g., "2024-01" for January 2024)
        if (typeof preset === 'string' && preset.match(/^\d{4}-\d{2}$/)) {
          const [year, month] = preset.split('-').map(Number);
          return new Date(year, month - 1, 1);
        }
        return null;
    }
  };

  const handleTimeFilterChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setTimeFilter(value);
    setLatestDeliveryBefore(getDateFromPreset(value));

    // Update advancedFilters with the new time filter
    setAdvancedFilters(prev => ({
      ...prev,
      timePreset: value,
      conversationLatestDeliveryAtBefore: getDateFromPreset(value)
    }));
  };

  // Generate past 12 months options
  const getMonthOptions = () => {
    const months = [];
    const now = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      months.push({
        value: date.toISOString().slice(0, 7),
        label: date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })
      });
    }
    return months;
  };

  const handleStatusSelect = (event, newValue) => {
    setSelectedStatus(newValue);
    setAdvancedFilters(prev => ({ ...prev, status: newValue }));
  };

  // Apply advanced filters
  const applyAdvancedFilters = () => {
    // Convert status to backend format
    const statusFilter = (() => {
      switch (advancedFilters.status) {
        case "Closed": return "closed";
        case "Error": return "error";
        case "Awaiting Oppy": return "pending_assistant_reply";
        case "Awaiting User": return "pending_user_reply";
        case "Processing Reply": return "processing_autocomplete";
        case "Standby": return "standby";
        case "Needs Attention": return "needs_attention";
        case "Draft": return "draft";
        case "Has Draft Messages (Pending Delivery)": return ""; // Empty as we'll use a different parameter
        default: return "";
      }
    })();

    const filterParams = {
      status: statusFilter,
      contactId: advancedFilters.contactId,
      subjectContains: advancedFilters.subject_contains,
      queryString: advancedFilters.queryString,
      conversationLatestDeliveryAtBefore: advancedFilters.conversationLatestDeliveryAtBefore,
      conversationLatestMessageCreatedAtBefore: advancedFilters.conversationLatestMessageCreatedAtBefore,
      conversationLastUpdatedBefore: advancedFilters.conversationLastUpdatedBefore,
      limit: advancedFilters.limit,
      messagesLimit: advancedFilters.messagesLimit,
      has_draft_messages: advancedFilters.status === "Has Draft Messages (Pending Delivery)" ? true : advancedFilters.has_draft_messages,
      channel_id: advancedFilters.channel_id,
      user_not_responded_to: advancedFilters.user_not_responded_to,
      memory_contains: advancedFilters.memory_contains || {},
    };

    // If we have memory_contains set, parse it as JSON
    if (advancedFilters.memory_contains) {
      try {
        const memoryObject = JSON.parse(advancedFilters.memory_contains);
        filterParams.memory_contains = memoryObject;
      } catch (e) {
        console.error('Invalid JSON for memory_contains', e);
        // Could show an error message to the user here
      }
    }

    // For custom date range handling
    if (advancedFilters.dateField && advancedFilters.timePreset === 'custom') {
      if (advancedFilters.customDateStart) {
        if (advancedFilters.dateField.includes('_before')) {
          filterParams[advancedFilters.dateField] = advancedFilters.customDateEnd || advancedFilters.customDateStart;
        } else if (advancedFilters.dateField.includes('_after')) {
          filterParams[advancedFilters.dateField] = advancedFilters.customDateStart;
        }
      }
    } else if (advancedFilters.dateField && advancedFilters.timePreset) {
      const dateValue = getDateFromPreset(advancedFilters.timePreset);
      if (dateValue) {
        filterParams[advancedFilters.dateField] = dateValue;
      }
    }

    // condense filterParams to only include non-null values
    const condensedFilterParams = Object.fromEntries(Object.entries(filterParams)
      .filter(([_, value]) => value !== null && value !== undefined && value !== ''));

    // Update local state
    setSelectedStatus(advancedFilters.status || "");
    setSelectedContactId(advancedFilters.contactId || "");
    setSubject(advancedFilters.subject_contains || "");
    setQueryString(advancedFilters.queryString || "");
    setLatestDeliveryBefore(advancedFilters.conversationLatestDeliveryAtBefore);
    setLatestMessageCreatedBefore(advancedFilters.conversationLatestMessageCreatedAtBefore);
    setLastUpdatedBefore(advancedFilters.conversationLastUpdatedBefore);
    setLimit(advancedFilters.limit || 25);
    setMessagesLimit(advancedFilters.messagesLimit || 1);

    // Callback to parent component if provided
    if (onAdvancedFilterChange) {
      // Dispatch comes from ConversationsListV2 and it happens in the function below
      onAdvancedFilterChange(condensedFilterParams);
    }

    // Close the popover after applying filters
    handleCloseAdvancedFilters();
  };

  const handleClearFilters = () => {
    // Reset local state
    setSelectedStatus("");
    setSelectedContactId("");
    setSubject("");
    setQueryString("");
    setLatestDeliveryBefore(null);
    setLatestMessageCreatedBefore(null);
    setLastUpdatedBefore(null);
    setLimit(25);
    setMessagesLimit(1);
    setTimeFilter("");

    // Reset advanced filters
    setAdvancedFilters({});

    // // Clear the filters and fetch unfiltered conversations
    // dispatch(fetchFilteredConversations({}));

    // Callback to parent component if provided
    if (onAdvancedFilterChange) {
      onAdvancedFilterChange({});
    }

    // Close the popover
    handleCloseAdvancedFilters();
  };

  // Handle change for advanced filter fields
  const handleAdvancedFilterChange = (field: keyof AdvancedFilters, value: any) => {
    setAdvancedFilters(prev => ({ ...prev, [field]: value }));
  };

  const handleTimePresetChange = (preset: string) => {
    setAdvancedFilters(prev => ({
      ...prev,
      timePreset: preset,
      // Clear custom dates if not using custom preset
      ...(preset !== 'custom' ? { customDateStart: null, customDateEnd: null } : {})
    }));
  };

  const advancedFiltersOpen = Boolean(advancedFiltersAnchorEl);
  const advancedFiltersId = advancedFiltersOpen ? 'advanced-filters-popover' : undefined;

  // Calculate conversation counts for each filter
  const filterCounts = filterOptions.reduce((counts, option) => {
    const filteredConvos = filterConversations(conversations, option.id);
    counts[option.id] = filteredConvos.length;
    return counts;
  }, {} as Record<string, number>);

  // Filter out options with zero conversations
  const visibleFilterOptions = filterOptions.filter(option =>
    filterCounts[option.id] > 0 || option.id === 'all_active' // Always show "All Active"
  );

  const styles = {
    filterIcon: {
      color: isFilterActive ? 'primary.main' : (darkMode ? 'grey.300' : 'inherit'),
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    },
    badge: {
      '& .MuiBadge-badge': {
        bgcolor: 'primary.main',
        color: 'common.white',
      }
    },
    popoverContent: {
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      color: darkMode ? 'grey.300' : 'text.primary',
      '& .MuiTextField-root': {
        '& .MuiInputBase-root': {
          color: darkMode ? 'grey.300' : 'text.primary',
        },
        '& .MuiInputLabel-root': {
          color: darkMode ? 'grey.400' : 'text.secondary',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: darkMode ? 'grey.700' : 'rgba(0, 0, 0, 0.42)',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottomColor: darkMode ? 'grey.500' : 'rgba(0, 0, 0, 0.87)',
        },
      },
      '& .MuiAutocomplete-root': {
        '& .MuiInputBase-root': {
          color: darkMode ? 'grey.300' : 'text.primary',
        },
        '& .MuiAutocomplete-popupIndicator': {
          color: darkMode ? 'grey.400' : 'inherit',
        },
        '& .MuiAutocomplete-clearIndicator': {
          color: darkMode ? 'grey.400' : 'inherit',
        },
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 1,
      mt: 2
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        mb: 2,
        pb: 0.5, // Add padding to prevent cut-off shadows
      }}
    >
      {/* Left scroll button */}
      {showLeftArrow && (
        <Box
          sx={{
            position: 'absolute',
            left: -12,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: darkMode
              ? `linear-gradient(to right, ${alpha(theme.palette.background.paper, 1)}, ${alpha(theme.palette.background.paper, 0)})`
              : `linear-gradient(to right, ${alpha(theme.palette.background.default, 1)}, ${alpha(theme.palette.background.default, 0)})`,
            paddingRight: '20px',
            paddingLeft: '4px',
          }}
        >
          <IconButton
            size="small"
            onClick={scrollLeft}
            sx={{
              bgcolor: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
              '&:hover': {
                bgcolor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
              }
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </Box>
      )}

      {/* Right scroll button */}
      {showRightArrow && (
        <Box
          sx={{
            position: 'absolute',
            right: -12,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: darkMode
              ? `linear-gradient(to left, ${alpha(theme.palette.background.paper, 1)}, ${alpha(theme.palette.background.paper, 0)})`
              : `linear-gradient(to left, ${alpha(theme.palette.background.default, 1)}, ${alpha(theme.palette.background.default, 0)})`,
            paddingLeft: '20px',
            paddingRight: '4px',
          }}
        >
          <IconButton
            size="small"
            onClick={scrollRight}
            sx={{
              bgcolor: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
              '&:hover': {
                bgcolor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
              }
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      )}

      {/* Filter container */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Scrollable filter container */}
        <Box
          ref={scrollContainerRef}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
            padding: '8px 4px',
            scrollbarWidth: 'none', // Firefox
            '&::-webkit-scrollbar': { display: 'none' }, // Chrome, Safari, Edge
            '& > *:not(:last-child)': { mr: 1 },
            px: 2,
            flexGrow: 1,
          }}
          onScroll={checkScrollPosition}
        >
          {visibleFilterOptions.map((option) => (
            <Tooltip key={option.id} title={option.tooltip} arrow placement="top">
              <Badge
                badgeContent={filterCounts[option.id]}
                color={option.color === 'default' ? 'default' :
                  option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'}
                sx={{
                  '& .MuiBadge-badge': {
                    right: -3,
                    top: 3,
                    border: `2px solid ${theme.palette.background.paper}`,
                    padding: '0 4px',
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                    color: option.color === 'default'
                      ? theme.palette.grey[800]
                      : theme.palette[option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success']?.main
                  }
                }}
              >
                <Chip
                  icon={option.icon}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" component="span" sx={{ mr: 0.5 }}>
                        {option.emoji}
                      </Typography>
                      {option.label}
                    </Box>
                  }
                  clickable
                  color={(option.color === 'default' ? 'default' :
                    option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success')}
                  variant={selectedFilter === option.id ? 'filled' : 'filled'}
                  onClick={() => onFilterChange(option.id)}
                  sx={{
                    fontWeight: selectedFilter === option.id ? 600 : 400,
                    px: 1,
                    transition: 'all 0.2s ease',
                    transform: selectedFilter === option.id ? 'scale(1.05)' : 'scale(1)',
                    m: 0.5,
                    bgcolor: option.color === 'default'
                      ? theme.palette.grey[700]
                      : theme.palette[option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success']?.main,
                    color: 'white',
                    boxShadow: selectedFilter === option.id
                      ? `0 2px 5px ${alpha(
                        option.color === 'default'
                          ? theme.palette.grey[500]
                          : (theme.palette[option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success']?.main ||
                            theme.palette.grey[500]),
                        0.5
                      )}`
                      : 'none',
                    borderColor: 'transparent',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      bgcolor: option.color === 'default'
                        ? theme.palette.grey[800]
                        : theme.palette[option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success']?.dark,
                      boxShadow: `0 2px 5px ${alpha(
                        option.color === 'default'
                          ? theme.palette.grey[500]
                          : (theme.palette[option.color as 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success']?.main ||
                            theme.palette.grey[500]),
                        0.5
                      )}`,
                    },
                    '& .MuiChip-label': {
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                    },
                    '& .MuiChip-icon': {
                      color: 'white',
                    }
                  }}
                />
              </Badge>
            </Tooltip>
          ))}
        </Box>

        {/* Advanced filters button */}
        <Tooltip title="Advanced Filters" arrow>
          <Badge
            variant="dot"
            invisible={!isFilterActive}
            color="primary"
            sx={{ mr: 1 }}
          >
            <IconButton
              onClick={handleOpenAdvancedFilters}
              sx={{
                ml: 1,
                bgcolor: advancedFiltersOpen || isFilterActive ?
                  (darkMode ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.primary.main, 0.1)) :
                  'transparent',
                '&:hover': {
                  bgcolor: darkMode ? alpha(theme.palette.primary.main, 0.25) : alpha(theme.palette.primary.main, 0.2)
                },
                color: isFilterActive ? theme.palette.primary.main : 'inherit'
              }}
              aria-describedby={advancedFiltersId}
            >
              <FilterListIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>

      {/* Advanced Filters Popover */}
      <Popover
        id={advancedFiltersId}
        open={advancedFiltersOpen}
        anchorEl={advancedFiltersAnchorEl}
        onClose={handleCloseAdvancedFilters}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 1,
            width: 400,
            p: 2,
            borderRadius: 2,
            boxShadow: 5,
          }
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Advanced Filters
          <IconButton size="small" onClick={handleCloseAdvancedFilters}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Typography>

        <Grid container spacing={2}>
          {/* Status Filter */}
          <Grid item xs={12}>
            <Autocomplete
              id="status-filter"
              options={statusOptions}
              getOptionLabel={(option) => option}
              onChange={handleStatusSelect}
              value={advancedFilters.status || null}
              isOptionEqualToValue={(option, value) => option === value}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" label="Filter by Status" placeholder="Select Status" />
              )}
            />
          </Grid>

          {/* Subject Contains */}
          <Grid item xs={12}>
            <TextField
              label="Subject Contains"
              variant="outlined"
              fullWidth
              size="small"
              value={advancedFilters.subject_contains || ''}
              onChange={(e) => handleAdvancedFilterChange('subject_contains', e.target.value)}
              InputProps={{
                endAdornment: advancedFilters.subject_contains ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => handleAdvancedFilterChange('subject_contains', '')}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>

          {/* Query String */}
          <Grid item xs={12}>
            <TextField
              label="Query String"
              variant="outlined"
              fullWidth
              size="small"
              value={advancedFilters.queryString || ''}
              onChange={(e) => handleAdvancedFilterChange('queryString', e.target.value)}
              InputProps={{
                endAdornment: advancedFilters.queryString ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => handleAdvancedFilterChange('queryString', '')}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>

          {/* Contact Filter */}
          <Grid item xs={12}>
            <ContactsSearchAutocomplete
              onSelectContact={(contact) => {
                setSelectedContactId(contact.id);
                handleAdvancedFilterChange('contactId', contact.id);
              }}
            />
          </Grid>

          {/* Channel Selection */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>From Oppy</InputLabel>
              <Select
                value={advancedFilters.channel_id || ''}
                label="From Oppy"
                onChange={(e) => handleAdvancedFilterChange('channel_id', e.target.value)}
                displayEmpty
              >
                {channels.map((channel) => (
                  <MenuItem key={channel.id} value={channel.id}>
                    {channel?.config?.chatbot_name || channel.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Date Filter Section */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                py: 1
              }}
              onClick={() => setShowDateSelector(!showDateSelector)}
            >
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                <DateRangeIcon sx={{ mr: 1 }} /> Date Filters
              </Typography>
              {showDateSelector ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Divider />

            <Collapse in={showDateSelector}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {/* Date Field Selection */}
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Date Field</InputLabel>
                      <Select
                        value={advancedFilters.dateField || ''}
                        label="Date Field"
                        onChange={(e) => handleAdvancedFilterChange('dateField', e.target.value)}
                      >
                        {DATE_FIELD_OPTIONS.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Time Preset Selection */}
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Time Period</InputLabel>
                      <Select
                        value={advancedFilters.timePreset || timeFilter}
                        label="Time Period"
                        onChange={(e) => {
                          handleTimePresetChange(e.target.value as string);
                          handleTimeFilterChange(e);
                        }}
                        disabled={!advancedFilters.dateField}
                      >
                        <MenuItem value="">
                          <em>No time filter</em>
                        </MenuItem>
                        <ListSubheader>Recent Periods</ListSubheader>
                        <MenuItem value="24h">Past 24 Hours</MenuItem>
                        <MenuItem value="7d">Past 7 Days</MenuItem>
                        <MenuItem value="30d">Past 30 Days</MenuItem>
                        <MenuItem value="60d">Past 60 Days</MenuItem>
                        <MenuItem value="90d">Past 90 Days</MenuItem>
                        <ListSubheader>Monthly</ListSubheader>
                        {getMonthOptions().map(month => (
                          <MenuItem key={month.value} value={month.value}>
                            {month.label}
                          </MenuItem>
                        ))}
                        <MenuItem value="custom">Custom Date Range</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Custom Date Range */}
                  {advancedFilters.timePreset === 'custom' && (
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <DatePicker
                              label="Start Date"
                              value={advancedFilters.customDateStart}
                              onChange={(date) => handleAdvancedFilterChange('customDateStart', date)}
                              slotProps={{ textField: { size: 'small', fullWidth: true } }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              label="End Date"
                              value={advancedFilters.customDateEnd}
                              onChange={(date) => handleAdvancedFilterChange('customDateEnd', date)}
                              slotProps={{ textField: { size: 'small', fullWidth: true } }}
                            />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Collapse>
          </Grid>

          {/* Memory Filter */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                py: 1
              }}
              onClick={() => setShowMemoryEditor(!showMemoryEditor)}
            >
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                <MemoryIcon sx={{ mr: 1 }} /> Memory Contains (JSON)
              </Typography>
              {showMemoryEditor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Divider />

            <Collapse in={showMemoryEditor}>
              <TextField
                multiline
                rows={4}
                fullWidth
                placeholder='{"key": "value"}'
                value={advancedFilters.memory_contains || ''}
                onChange={(e) => handleAdvancedFilterChange('memory_contains', e.target.value)}
                sx={{ mt: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CodeIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                Enter a valid JSON object to filter conversations by memory content
              </Typography>
            </Collapse>
          </Grid>

          {/* Limit Controls */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                py: 1
              }}
            >
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                <SettingsIcon sx={{ mr: 1 }} /> Results Settings
              </Typography>
            </Box>
            <Divider />

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Results Limit</InputLabel>
                  <Select
                    value={advancedFilters.limit || limit}
                    onChange={(e) => handleAdvancedFilterChange('limit', Number(e.target.value))}
                    label="Results Limit"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={250}>250</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Messages per Conversation</InputLabel>
                  <Select
                    value={advancedFilters.messagesLimit || messagesLimit}
                    onChange={(e) => handleAdvancedFilterChange('messagesLimit', Number(e.target.value))}
                    label="Messages per Conversation"
                  >
                    <MenuItem value={1}>1 message</MenuItem>
                    <MenuItem value={5}>5 messages</MenuItem>
                    <MenuItem value={10}>10 messages</MenuItem>
                    <MenuItem value={20}>20 messages</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Toggle Filters */}
          <Grid item xs={12} sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={advancedFilters.user_not_responded_to || false}
                  onChange={(e) => handleAdvancedFilterChange('user_not_responded_to', e.target.checked)}
                  color="primary"
                />
              }
              label="Only show conversations with no user response"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={advancedFilters.has_draft_messages || (advancedFilters.status === "Has Draft Messages (Pending Delivery)")}
                  onChange={(e) => handleAdvancedFilterChange('has_draft_messages', e.target.checked)}
                  color="primary"
                />
              }
              label="Show conversations with draft messages (pending delivery)"
            />
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleClearFilters}
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={applyAdvancedFilters}
              startIcon={<SearchIcon />}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
};

export default ConversationFilters; 
