import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Grid, CircularProgress, Select, MenuItem, FormControl, InputLabel, Tabs, Tab, Divider, IconButton, Typography, DialogActions } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Formik, Form, Field } from 'formik';
import { updateContact, updateContactState, selectContactById } from 'src/features/account/contacts.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';
import NotesList from '../Notes/NotesList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { timeZones } from 'src/utils/timezones';
import { isEqual } from 'lodash';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { parseISO, isValid } from 'date-fns';
import { selectFilteredContactById } from 'src/features/account/filteredContacts.slice';

interface ContactDialogProps {
  contactId: string;
  open: boolean;
  onClose: () => void;
  handleContactUpdate?: (updatedContact: any) => void;
}

const ContactDialog: React.FC<ContactDialogProps> = ({ contactId, open, onClose, handleContactUpdate }) => {
  const dispatch = useAppDispatch();
  const contact = useAppSelector((state) => selectFilteredContactById(state, contactId));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [hasExternalChanges, setHasExternalChanges] = useState(false);
  const [initialContact, setInitialContact] = useState<any>(null);
  const [allowExternalUpdates, setAllowExternalUpdates] = useState(false);
  const formRef = React.useRef(null);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    values.name = `${values.first_name} ${values.last_name}`.trim();

    const sanitizedValues = { ...values };

    // Convert Date objects to ISO strings before sending to the API
    if (sanitizedValues.last_contacted) {
      if (sanitizedValues.last_contacted instanceof Date && isValid(sanitizedValues.last_contacted)) {
        sanitizedValues.last_contacted = sanitizedValues.last_contacted.toISOString();
      } else if (typeof sanitizedValues.last_contacted === 'string' && isValid(parseISO(sanitizedValues.last_contacted))) {
        sanitizedValues.last_contacted = parseISO(sanitizedValues.last_contacted).toISOString();
      } else {
        delete sanitizedValues.last_contacted;
      }
    } else {
      delete sanitizedValues.last_contacted;
    }

    dispatch(updateContact({ contactId: contactId, updates: sanitizedValues }))
      .then(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (open && contact) {
      setInitialContact(contact);
      setHasExternalChanges(false);
      setAllowExternalUpdates(false);
    }
  }, [open, contact?.id]);

  useEffect(() => {
    if (initialContact && contact) {
      const hasChanges = !isEqual(initialContact, contact);
      setHasExternalChanges(hasChanges && !allowExternalUpdates);
    }
  }, [contact, initialContact, allowExternalUpdates]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const handleAcceptExternalChanges = () => {
    setAllowExternalUpdates(true);
    setHasExternalChanges(false);
    setInitialContact(contact);
  };

  const initialFormValues = contact ? {
    ...contact,
    // Strict date validation for last_contacted
    last_contacted: (() => {
      if (!contact.last_contacted) return null;

      try {
        // If it's already a Date object
        if (contact.last_contacted instanceof Date) {
          const date = new Date(contact.last_contacted);
          return isValid(date) ? date : null;
        }

        // If it's a string
        if (typeof contact.last_contacted === 'string') {
          // First try parsing as ISO
          const isoDate = parseISO(contact.last_contacted);
          if (isValid(isoDate)) {
            const date = new Date(isoDate);
            return isValid(date) ? date : null;
          }

          // If ISO parsing fails, try direct Date construction
          const date = new Date(contact.last_contacted);
          return isValid(date) ? date : null;
        }

        // If it's neither a Date nor a string, return null
        return null;
      } catch (error) {
        console.error('Error parsing last_contacted date:', error, contact.last_contacted);
        return null;
      }
    })(),
    assigned_to: contact.assigned_to || '',
    deal_size: contact.deal_size || '',
    score: contact.score || '',
    company_size: contact.company_size || '',
    vector_db_status: contact.vector_db_status || '',
    locality: contact.locality || '',
    zip: contact.zip || '',
    state: contact.state || '',
    country_code: contact.country_code || '',
    street_address_1: contact.street_address_1 || '',
    street_address_2: contact.street_address_2 || '',
    position: contact.position || '',
    stage: contact.stage || '',
    metadata: contact.metadata || {},
    picture_url: contact.picture_url || '',
    first_name: contact.first_name || '',
    last_name: contact.last_name || '',
    email: contact.email || '',
    phone_number: contact.phone_number || '',
    preferred_contact_method: contact.preferred_contact_method || '',
    company_name: contact.company_name || '',
    status: contact.status || '',
    shared_notes: contact.shared_notes || '',
    admin_notes: contact.admin_notes || '',
    address: contact.address || '',
    company_industry: contact.company_industry || '',
    company_website: contact.company_website || '',
    company_address: contact.company_address || '',
    home_address: contact.home_address || '',
    tags: contact.tags || '',
    timezone: contact.timezone || '',
  } : null;

  if (!contact || !initialFormValues) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={isLargeScreen ? "lg" : "sm"}
      scroll="paper"
    >
      <DialogTitle
        sx={{
          pb: 0,
          background: (theme) => theme.palette.background.default,
          borderBottom: 1,
          borderColor: 'divider',
          px: 3,
          py: 2,
          // Add subtle shadow to separate from content
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
        }}
      >
        {hasExternalChanges && (
          <Button
            variant='contained'
            color='warning'
            sx={{ mb: 1 }}
            onClick={handleAcceptExternalChanges}
          >
            Contact was updated externally. Click to load latest changes.
          </Button>
        )}
        <Typography
          component="div"
          variant="h5"
          sx={{
            mb: 2,
            fontWeight: 'medium',
            color: (theme) => theme.palette.text.primary
          }}
        >
          Edit {contact?.name || `${contact?.first_name} ${contact?.last_name}`?.trim() || 'Contact'}
        </Typography>
        {!isLargeScreen && (
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mb: -1 // Pull tabs closer to content
            }}
          >
            <Tab label="Details" />
            <Tab label="Notes" />
          </Tabs>
        )}
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: isLargeScreen ? 'row' : 'column',
          gap: 2
        }}>
          <Box sx={{
            flex: isLargeScreen ? '1 1 60%' : '1 1 auto',
            display: (!isLargeScreen && tabIndex === 1) ? 'none' : 'block'
          }}>
            <Formik
              innerRef={formRef}
              initialValues={allowExternalUpdates ? initialFormValues : initialContact}
              onSubmit={onSubmit}
              enableReinitialize={allowExternalUpdates}
            >
              {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="first_name"
                          label="First Name"
                          value={values.first_name || ''}
                          onChange={handleChange}
                          error={touched.first_name && Boolean(errors.first_name)}
                          helperText={touched.first_name && errors.first_name}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="last_name"
                          label="Last Name"
                          value={values.last_name || ''}
                          onChange={handleChange}
                          error={touched.last_name && Boolean(errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          label="Email"
                          value={values.email || ''}
                          onChange={handleChange}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="phone_number"
                          label="Phone Number"
                          value={values.phone_number || ''}
                          onChange={handleChange}
                          error={touched.phone_number && Boolean(errors.phone_number)}
                          helperText={touched.phone_number && errors.phone_number}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth disabled={isSubmitting}>
                          <InputLabel id="preferred-contact-method-label">Preferred Contact Method</InputLabel>
                          <Select
                            labelId="preferred-contact-method-label"
                            name="preferred_contact_method"
                            value={values.preferred_contact_method || ''}
                            onChange={handleChange}
                            label="Preferred Contact Method"
                          >
                            <MenuItem value="phone">Phone</MenuItem>
                            <MenuItem value="email">Email</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          {/* Only render the DateTimePicker if we have a valid date or null */}
                          {(values.last_contacted === null || (values.last_contacted && isValid(values.last_contacted))) && (
                            <DateTimePicker
                              label="Last Contacted"
                              value={values.last_contacted}
                              onChange={(newValue) => {
                                try {
                                  // Ensure we have a valid date before setting it
                                  if (newValue && isValid(newValue)) {
                                    const date = new Date(newValue);
                                    if (isValid(date)) {
                                      setFieldValue('last_contacted', date);
                                    } else {
                                      setFieldValue('last_contacted', null);
                                    }
                                  } else {
                                    setFieldValue('last_contacted', null);
                                  }
                                } catch (error) {
                                  console.error('Error handling date change:', error);
                                  setFieldValue('last_contacted', null);
                                }
                              }}
                              onError={(error) => {
                                if (error) {
                                  console.error('DateTimePicker error:', error);
                                  setFieldValue('last_contacted', null);
                                }
                              }}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: touched.last_contacted && Boolean(errors.last_contacted),
                                  helperText: touched.last_contacted && errors.last_contacted
                                },
                                field: {
                                  clearable: true,
                                  onClear: () => setFieldValue('last_contacted', null),
                                }
                              }}
                              format="yyyy-MM-dd HH:mm"
                              closeOnSelect
                              views={['year', 'month', 'day', 'hours', 'minutes']}
                              ampm={false}
                            />
                          )}
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="company_name"
                          label="Company Name"
                          value={values.company_name || ''}
                          onChange={handleChange}
                          error={touched.company_name && Boolean(errors.company_name)}
                          helperText={touched.company_name && errors.company_name}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="status"
                          label="Status"
                          value={values.status || ''}
                          onChange={handleChange}
                          error={touched.status && Boolean(errors.status)}
                          helperText={touched.status && errors.status}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="shared_notes"
                          label="Shared Notes"
                          placeholder="Enter shared notes. These notes will be shared with: all users and Oppies who have access to this contact."
                          value={values.shared_notes || ''}
                          onChange={handleChange}
                          error={touched.shared_notes && Boolean(errors.shared_notes)}
                          fullWidth
                          multiline
                          rows={4}
                          disabled={isSubmitting}
                          helperText="Great for non-sensitive contact info to reference or use to help conversations. These notes will be accessible to: admins, Oppies who have access to this contact, and the contact themselves."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="admin_notes"
                          label="Admin Notes"
                          placeholder="Only your eyes and those of your admin team will see these notes. These notes will not be shared with Oppies who have access to this contact unless you explicitly choose to do so."
                          value={values.admin_notes || ''}
                          onChange={handleChange}
                          error={touched.admin_notes && Boolean(errors.admin_notes)}
                          helperText="Only your eyes and those of your admin team will see these notes. These notes will not be shared with Oppies who have access to this contact unless you explicitly choose to do so."
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address"
                          label="Address"
                          value={values.address || ''}
                          onChange={handleChange}
                          error={touched.address && Boolean(errors.address)}
                          helperText={touched.address && errors.address}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="company_industry"
                          label="Industry"
                          value={values.company_industry || ''}
                          onChange={handleChange}
                          error={touched.company_industry && Boolean(errors.company_industry)}
                          helperText={touched.company_industry && errors.company_industry}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="company_website"
                          label="Company Website"
                          value={values.company_website || ''}
                          onChange={handleChange}
                          error={touched.company_website && Boolean(errors.company_website)}
                          helperText={touched.company_website && errors.company_website}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="company_address"
                          label="Company Address"
                          value={values.company_address || ''}
                          onChange={handleChange}
                          error={touched.company_address && Boolean(errors.company_address)}
                          helperText={touched.company_address && errors.company_address}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="home_address"
                          label="Home Address"
                          value={values.home_address || ''}
                          onChange={handleChange}
                          error={touched.home_address && Boolean(errors.home_address)}
                          helperText={touched.home_address && errors.home_address}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="tags"
                          label="Tags"
                          value={values.tags || ''}
                          onChange={handleChange}
                          error={touched.tags && Boolean(errors.tags)}
                          helperText={touched.tags && errors.tags}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={timeZones}
                          groupBy={(option) => option.continent}
                          getOptionLabel={(option) =>
                            typeof option === 'string'
                              ? option
                              : `${option.label}`
                          }
                          value={timeZones.find(tz => tz.value === values.timezone) || null}
                          onChange={(_, newValue) => {
                            setFieldValue('timezone', newValue?.value || '');
                          }}
                          disabled={isSubmitting}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Timezone"
                              error={touched.timezone && Boolean(errors.timezone)}
                              helperText={touched.timezone && errors.timezone}
                              fullWidth
                            />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.value === (typeof value === 'string' ? value : value.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="assigned_to"
                          label="Assigned To"
                          value={values.assigned_to || ''}
                          onChange={handleChange}
                          error={touched.assigned_to && Boolean(errors.assigned_to)}
                          helperText={touched.assigned_to && errors.assigned_to}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="deal_size"
                          label="Deal Size"
                          type="number"
                          value={values.deal_size || ''}
                          onChange={handleChange}
                          error={touched.deal_size && Boolean(errors.deal_size)}
                          helperText={touched.deal_size && errors.deal_size}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="score"
                          label="Score"
                          type="number"
                          value={values.score || ''}
                          onChange={handleChange}
                          error={touched.score && Boolean(errors.score)}
                          helperText={touched.score && errors.score}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider>
                          <Button
                            size="small"
                            onClick={handleToggleAdvanced}
                            endIcon={<ExpandMoreIcon />}
                          >
                            Advanced
                          </Button>
                        </Divider>
                      </Grid>
                      {showAdvanced && (
                        <>
                          {/* uneditable id field for contact id */}
                          <Grid item xs={12}>
                            <TextField
                              name="id"
                              label="Contact ID"
                              value={contact?.id}
                              fullWidth
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="company_size"
                              label="Company Size"
                              value={values.company_size || ''}
                              onChange={handleChange}
                              error={touched.company_size && Boolean(errors.company_size)}
                              helperText={touched.company_size && errors.company_size}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="stage"
                              label="Stage"
                              value={values.stage || ''}
                              onChange={handleChange}
                              error={touched.stage && Boolean(errors.stage)}
                              helperText={touched.stage && errors.stage}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="position"
                              label="Position"
                              type="number"
                              value={values.position || ''}
                              onChange={handleChange}
                              error={touched.position && Boolean(errors.position)}
                              helperText={touched.position && errors.position}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="street_address_1"
                              label="Street Address 1"
                              value={values.street_address_1 || ''}
                              onChange={handleChange}
                              error={touched.street_address_1 && Boolean(errors.street_address_1)}
                              helperText={touched.street_address_1 && errors.street_address_1}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="street_address_2"
                              label="Street Address 2"
                              value={values.street_address_2 || ''}
                              onChange={handleChange}
                              error={touched.street_address_2 && Boolean(errors.street_address_2)}
                              helperText={touched.street_address_2 && errors.street_address_2}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="locality"
                              label="City"
                              value={values.locality || ''}
                              onChange={handleChange}
                              error={touched.locality && Boolean(errors.locality)}
                              helperText={touched.locality && errors.locality}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="state"
                              label="State"
                              value={values.state || ''}
                              onChange={handleChange}
                              error={touched.state && Boolean(errors.state)}
                              helperText={touched.state && errors.state}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="zip"
                              label="Zip Code"
                              value={values.zip || ''}
                              onChange={handleChange}
                              error={touched.zip && Boolean(errors.zip)}
                              helperText={touched.zip && errors.zip}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="country_code"
                              label="Country Code"
                              value={values.country_code || ''}
                              onChange={handleChange}
                              error={touched.country_code && Boolean(errors.country_code)}
                              helperText={touched.country_code && errors.country_code}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="picture_url"
                              label="Picture URL"
                              value={values.picture_url || ''}
                              onChange={handleChange}
                              error={touched.picture_url && Boolean(errors.picture_url)}
                              helperText={touched.picture_url && errors.picture_url}
                              fullWidth
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="metadata"
                              label="Metadata"
                              value={JSON.stringify(values.metadata, null, 2)}
                              onChange={(e) => {
                                try {
                                  // Parse JSON input
                                  const parsedMetadata = JSON.parse(e.target.value);
                                  setFieldValue('metadata', parsedMetadata);
                                } catch (error) {
                                  console.error('Invalid JSON input:', error);
                                  // Optionally display error to user
                                }
                              }}
                              error={touched.metadata && Boolean(errors.metadata)}
                              helperText={touched.metadata && errors.metadata}
                              fullWidth
                              disabled={isSubmitting}
                              multiline
                              rows={6}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                  </Form>
                );
              }}
            </Formik>
          </Box>

          {isLargeScreen && (
            <Divider orientation="vertical" flexItem />
          )}

          <Box sx={{
            flex: isLargeScreen ? '1 1 40%' : '1 1 auto',
            display: (!isLargeScreen && tabIndex === 0) ? 'none' : 'block',
            height: isLargeScreen ? 'calc(100vh - 200px)' : 'auto',
            overflow: 'auto'
          }}>
            <NotesList ownerId={contactId} ownerType="Contact" />
          </Box>
        </Box>
      </DialogContent>
      {(tabIndex === 0 || isLargeScreen) && (
        <DialogActions
          sx={{
            px: 3,
            py: 2,
            background: (theme) => theme.palette.background.default,
            borderTop: 1,
            borderColor: 'divider',
            // Add subtle shadow to separate from content
            boxShadow: '0 -2px 4px rgba(0,0,0,0.05)',
            // Ensure actions stay at bottom
            position: 'sticky',
            bottom: 0,
            zIndex: 1
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting || contact?.loading}
            onClick={() => formRef.current?.handleSubmit()}
            sx={{
              minWidth: 120,
              height: 40
            }}
          >
            {isSubmitting || contact?.loading ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Updating...
              </>
            ) : (
              'Update Contact'
            )}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ContactDialog;
