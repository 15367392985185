import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useColorMode } from 'src/theme/ThemeProvider';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Contact } from 'src/types/contacts';
import {
  getContacts,
  resetPagination,
  selectHasMore as selectContactsHasMore,
  selectLimit,
  setLimit
} from 'src/features/account/contacts.slice';
import {
  fetchFilteredContacts,
  selectIsFilterActive,
  selectHasMore as selectFilteredHasMore
} from 'src/features/account/filteredContacts.slice';

interface ContactsPaginationProps {
  totalItems: number;
  onPageChange: (page: number) => void;
  pageCache: Record<number, Contact[]>;
  setPageCache: React.Dispatch<React.SetStateAction<Record<number, Contact[]>>>;
  currentPageProp?: number;
  loading?: boolean;
  viewMode?: 'list' | 'grid' | 'kanban';
}

const ContactsPagination: React.FC<ContactsPaginationProps> = ({
  totalItems,
  onPageChange,
  pageCache,
  setPageCache,
  loading = false,
  currentPageProp = 1,
  viewMode = 'list'
}) => {
  const dispatch = useAppDispatch();
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const filterObject = useAppSelector((state) => state.filteredContacts.filterObject);
  const hasMore = useAppSelector(isFilterActive ? selectFilteredHasMore : selectContactsHasMore);
  const limit = useAppSelector(selectLimit);
  const [currentPage, setCurrentPage] = useState(currentPageProp);

  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';

  // Sync internal state with prop when it changes
  useEffect(() => {
    // Only update internal state if prop has changed and differs from current state
    if (currentPage !== currentPageProp) {
      setCurrentPage(currentPageProp);
    }
  }, [currentPageProp, currentPage]);

  // Clear cache when filters or limit changes
  useEffect(() => {
    setPageCache({});
    setCurrentPage(1);
  }, [filterObject, limit, setPageCache]);

  // Handle change page navigation
  const handleNavigateToPage = useCallback((page: number) => {
    if (page === currentPage || loading) return;
    onPageChange(page);
  }, [currentPage, onPageChange, loading]);

  // Add handler for going to first page
  const handleGoToFirstPage = useCallback(() => {
    if (currentPage === 1 || loading) return;
    onPageChange(1);
  }, [currentPage, onPageChange, loading]);

  const handleRowsPerPageChange = useCallback((value: string) => {
    if (loading) return;

    let newLimit = parseInt(value, 10);

    // If view mode is grid, cap the limit at 100
    if (viewMode === 'grid' && newLimit > 100) {
      newLimit = 100;
    }

    // Only update if the limit has actually changed
    if (newLimit !== limit) {
      dispatch(setLimit(newLimit));

      // Update the internal state
      setCurrentPage(1);

      // Notify parent of change - but do this last to avoid synchronous cascading updates
      setTimeout(() => {
        onPageChange(1);
        // onSelectionChange?.([]);
      }, 0);

      // Reset pagination and fetch with new limit
      if (isFilterActive) {
        dispatch(fetchFilteredContacts({ ...filterObject, limit: newLimit, cursor: null } as any));
      } else {
        dispatch(resetPagination());
        dispatch(getContacts({ limit: newLimit }));
      }
    }
  }, [dispatch, isFilterActive, filterObject, loading, limit, onPageChange, viewMode]);

  // Effect to handle changing to grid view with limit > 100
  useEffect(() => {
    if (viewMode === 'grid' && limit > 100) {
      // If switching to grid view and limit is > 100, reset to 100
      handleRowsPerPageChange('100');
    }
  }, [viewMode, limit, handleRowsPerPageChange]);

  const pageCount = Math.ceil(totalItems / limit);
  const startItem = currentPage === 1 ? 1 : (currentPage - 1) * limit + 1;
  const endItem = Math.min((currentPage) * limit, totalItems);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 2,
        px: 2,
        mt: 1,
        borderTop: `1px solid ${isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider}`,
        backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.6) : theme.palette.background.paper,
        borderRadius: '0 0 8px 8px',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        gap: { xs: 2, sm: 0 },
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        gap: { xs: 1, sm: 0 },
        width: { xs: '100%', sm: 'auto' },
        justifyContent: { xs: 'space-between', sm: 'flex-start' },
      }}>
        <Typography variant="body2" sx={{ mr: 2, color: 'text.secondary' }}>
          Rows per page:
        </Typography>
        <Select
          value={limit.toString()}
          onChange={(event) => handleRowsPerPageChange(event.target.value)}
          size="small"
          sx={{
            height: 32,
            minWidth: 80,
            fontSize: '0.875rem',
            mr: { xs: 0, sm: 4 },
            '& .MuiSelect-select': {
              py: 0.5,
            },
          }}
          disabled={loading}
        >
          <MenuItem key="10" value={10}>10</MenuItem>
          <MenuItem key="25" value={25}>25</MenuItem>
          <MenuItem key="50" value={50}>50</MenuItem>
          <MenuItem key="100" value={100}>100</MenuItem>
          {viewMode !== 'grid' && [
            <MenuItem key="500" value={500}>500</MenuItem>,
            <MenuItem key="1000" value={1000}>1000</MenuItem>
          ]}
        </Select>

        <Typography variant="body2" sx={{
          color: 'text.secondary',
          display: { xs: 'none', sm: 'block' }
        }}>
          {totalItems === 0
            ? 'No items'
            : `${startItem}-${endItem} of ${totalItems}`}
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        width: { xs: '100%', sm: 'auto' },
        justifyContent: { xs: 'center', sm: 'flex-end' },
        mt: { xs: 1, sm: 0 },
      }}>
        <Typography variant="body2" sx={{
          color: 'text.secondary',
          display: { xs: 'block', sm: 'none' },
          mr: 2
        }}>
          {totalItems === 0
            ? 'No items'
            : `${startItem}-${endItem} of ${totalItems}`}
        </Typography>

        <IconButton
          onClick={handleGoToFirstPage}
          disabled={currentPage === 1 || loading}
          size="small"
          sx={{
            color: currentPage <= 1 || loading ? 'text.disabled' : 'primary.main',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
            },
            mr: 0.5
          }}
        >
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>

        <IconButton
          onClick={() => handleNavigateToPage(currentPage - 1)}
          disabled={currentPage === 1 || loading}
          size="small"
          sx={{
            color: currentPage <= 1 || loading ? 'text.disabled' : 'primary.main',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
            }
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <Typography variant="body2" sx={{ mx: 2, minWidth: '40px', textAlign: 'center' }}>
          {currentPage} / {pageCount}
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </Typography>

        <IconButton
          onClick={() => handleNavigateToPage(currentPage + 1)}
          disabled={!hasMore || loading}
          size="small"
          sx={{
            color: !hasMore || loading ? 'text.disabled' : 'primary.main',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
            }
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ContactsPagination; 
