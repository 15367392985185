import React, { useState, useEffect, useMemo, lazy, Suspense, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Card,
  CircularProgress,
  IconButton,
  LinearProgress,
  Chip,
  alpha,
  Paper,
  Badge,
  Fade,
  Zoom,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteContact, getContacts, selectLimit, resetPagination } from 'src/features/account/contacts.slice';
import ContactsList from './ContactsList';
import ContactsDataGrid from './ContactsDataGrid';
import { ContactCreateDialog } from './ContactCreateDialog';
import ContactsFilterBar from './ContactsFilterBar';
import ContactsUploaderDialog from './uploader/ContactsUploaderDialog';
import { setOpenDialog } from 'src/features/theme/theme.slice';
import { fetchFilteredContacts, selectAllFilteredContacts, selectIsFilterActive, setFilter } from 'src/features/account/filteredContacts.slice';
import { isEmpty } from 'lodash';
import { ViewAgenda, ViewStream, FilterList, FilterAltOutlined, PersonAdd, ImportContacts } from '@mui/icons-material';
import { getBranding } from 'src/utils/branding';
import BulkConversationCreateDialog from '../Channels/BulkConversationCreateDialog';
import ContactsKanban from './ContactsKanban';
import { useNavigate, useLocation } from 'react-router-dom';
import ContactsService from 'src/services/contacts.services';
import { useTheme } from '@mui/material/styles';
import { Contact } from 'src/types/contacts';
import ContactsPagination from './ContactsPagination';
import BulkTagDialog from './BulkTagDialog';

interface ContactsWrapperProps {
  contacts: Contact[];
}

const ContactsWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const [viewMode, setViewMode] = useState<'list' | 'grid' | 'kanban'>('list');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const [isBulkDialogOpen, setIsBulkDialogOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false);
  const [bulkTagDialogOpen, setBulkTagDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  // State to control loading bar visibility
  const [showLoadingBar, setShowLoadingBar] = React.useState(false);

  const contacts = useAppSelector(selectAllFilteredContacts);
  const totalContacts = useAppSelector(state => state.contacts.totalContacts);
  const totalFilteredContacts = useAppSelector(state => state.filteredContacts.totalContacts);
  const contactsLoading = useAppSelector(
    (state) => state.filteredContacts.contactsLoading || state.contacts.contactsLoading
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const filterObject = useAppSelector((state) => state.filteredContacts.filterObject);
  const limit = useAppSelector(selectLimit);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCache, setPageCache] = useState<Record<number, Contact[]>>({});

  // Use 'oppy_expressions_drunk' to match 404.tsx
  const oppyImage = getBranding('oppy_expressions_drunk');

  // Extract viewMode from URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const viewModeParam = params.get('viewMode');
    if (viewModeParam === 'list' || viewModeParam === 'grid' || viewModeParam === 'kanban') {
      setViewMode(viewModeParam as 'list' | 'grid' | 'kanban');
    }
  }, [location.search]);

  // initial load
  useEffect(() => {
    dispatch(getContacts({ limit }));
  }, [dispatch, limit]);

  // Update cache when contacts change
  useEffect(() => {
    // Only update cache if we have contacts and we're not in a loading state
    if (contacts.length > 0 && !contactsLoading) {
      // Add a ref to track if we already updated this page's cache to prevent infinite loops
      const currentContacts = pageCache[currentPage] || [];

      // Only update cache if the contacts have changed (different length or different IDs)
      if (currentContacts.length !== contacts.length ||
        !currentContacts.every((contact, index) => contact.id === contacts[index]?.id)) {
        setPageCache(prev => ({
          ...prev,
          [currentPage]: contacts
        }));
      }
    }
  }, [contacts, currentPage, contactsLoading, pageCache]);

  // Clear cache when filters change
  useEffect(() => {
    setPageCache({});
    setCurrentPage(1);
  }, [filterObject, limit]);

  // Function to clear cache and reset pagination
  const resetPaginationState = useCallback(() => {
    setPageCache({});
    setCurrentPage(1);
  }, []);

  // Effect: fetch contacts if any filters are active
  useEffect(() => {
    if (isFilterActive) {
      resetPaginationState();
      setShowLoadingBar(true);
      setSelectedContacts([]);
      dispatch(fetchFilteredContacts({
        ...filterObject,
        limit,
        cursor: null // Reset cursor when filters change
      } as any))
        .finally(() => {
          setShowLoadingBar(false);
          setIsInitialLoad(false);
        });
    }
  }, [dispatch, isFilterActive, filterObject, limit, resetPaginationState]);

  // Effect: Set initial load to false after first contacts load
  useEffect(() => {
    if (!contactsLoading && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [contactsLoading]);

  const handleViewModeChange = (event: any) => {
    const newMode = event.target.value;
    setViewMode(newMode);
    navigate(`?viewMode=${newMode}`);
  };

  const handleCreateContact = (newContact) => {
    dispatch(fetchFilteredContacts({}));
  };

  const handleOpenContactsUploaderDialog = () => {
    dispatch(setOpenDialog('ContactsUploaderDialog'));
  };

  // Calculate cursor for pagination
  const calculateCursor = useCallback((targetPage: number, currentPage: number) => {
    // If going to first page, no cursor needed
    if (targetPage === 1) return null;

    let cursor = null;

    // For forward navigation (next page)
    if (targetPage > currentPage) {
      // Get the last contact from the current page for forward navigation
      const lastContact = pageCache[currentPage]?.[pageCache[currentPage].length - 1];
      if (lastContact?.created_at) {
        cursor = lastContact.created_at;
      } else {
        // Fallback: try to find the last contact in the store if page cache is empty
        const lastContactInStore = contacts[contacts.length - 1]?.created_at;
        cursor = lastContactInStore || null;
      }
    }
    // For backward navigation (previous page)
    else if (targetPage < currentPage && targetPage > 1) {
      // Find a page we have in cache that is before the target page
      let foundPage = false;
      let referencePage = targetPage - 1;

      // Try to find a cached page to use as reference
      while (referencePage >= 1 && !foundPage) {
        if (pageCache[referencePage]?.length > 0) {
          const lastContact = pageCache[referencePage][pageCache[referencePage].length - 1];
          cursor = lastContact.created_at;
          foundPage = true;
        } else {
          referencePage--;
        }
      }

      // If we couldn't find a reference page, return a special value
      if (!foundPage && referencePage === 0) {
        return { needsReset: true, targetPage };
      }
    }

    return cursor;
  }, [pageCache, contacts]);

  const handlePageChange = useCallback((page: number) => {
    if (page === currentPage) return;

    // Store the current page before changing it
    const previousPage = currentPage;
    setCurrentPage(page);

    if (!isInitialLoad) {
      if (page === 1) {
        if (isFilterActive) {
          dispatch(fetchFilteredContacts({
            ...filterObject,
            limit,
            cursor: null
          } as any));
        } else {
          dispatch(resetPagination());
          dispatch(getContacts({ limit }));
        }
        return;
      }

      const cursorResult = calculateCursor(page, previousPage);

      if (cursorResult && typeof cursorResult === 'object' && 'needsReset' in cursorResult) {
        if (page === 2) {
          dispatch(resetPagination());
          dispatch(getContacts({ limit }));
          return;
        }

        setCurrentPage(1);
        dispatch(resetPagination());
        dispatch(getContacts({ limit }));
        return;
      }

      if (isFilterActive) {
        dispatch(fetchFilteredContacts({
          ...filterObject,
          limit,
          cursor: cursorResult
        } as any));
      } else {
        dispatch(getContacts({ limit, cursor: cursorResult }));
      }
    }
  }, [currentPage, isInitialLoad, isFilterActive, filterObject, limit, dispatch, calculateCursor]);

  // Function to clear filters and fetch default contacts
  const handleClearFilters = () => {
    resetPaginationState();
    dispatch(setFilter({}));
    dispatch(fetchFilteredContacts({
      limit,
      cursor: null
    } as any));
  }

  const handleContactsSelection = (selectedIds: string[]) => {
    console.log('Selected IDs:', selectedIds);
    const selected = contacts.filter((contact) => selectedIds.includes(contact.id.toString()));
    setSelectedContacts(selected);
  };

  const handleBulkMessageClick = () => {
    setIsBulkDialogOpen(true);
  };

  const handleBulkDelete = () => {
    if (window.confirm(`Are you sure you want to delete ${selectedContacts.length} selected contacts?`)) {
      Promise.all(selectedContacts.map(contact => dispatch(deleteContact(contact.id))))
        .then(() => {
          setSelectedContacts([]);
          dispatch(fetchFilteredContacts(filterObject));
        });
    }
  };

  // Function to handle bulk tag click
  const handleBulkTagClick = () => {
    setBulkTagDialogOpen(true);
  };

  // Function to handle bulk edit click
  const handleBulkEditClick = () => {
    setMultiEditDialogOpen(true);
    console.log('Bulk edit clicked');
  };

  // Get active filter count
  const getActiveFilterCount = (): number => {
    return Object.keys(filterObject || {}).length;
  };

  // Define styles
  const styles = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: 2,
      background: darkMode ? 'grey.900' : '#FAFAFA',
      color: darkMode ? 'grey.100' : 'text.primary',
      width: '100%',
      mb: 1,
      transition: 'all 0.3s ease',
      borderBottom: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
      borderRadius: '8px 8px 0 0',
    },
    pageTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      color: darkMode ? 'grey.100' : 'text.primary',
      '& .MuiBadge-badge': {
        fontSize: '0.7rem',
        height: '18px',
        minWidth: '18px',
      },
    },
    topBarAction: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiIconButton-root': {
        color: darkMode ? 'grey.300' : 'inherit',
        transition: 'all 0.2s ease',
        '&:hover': {
          bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
          transform: 'translateY(-1px)',
        },
      },
    },
    noContactsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
      color: darkMode ? 'grey.300' : 'text.primary',
      bgcolor: darkMode ? 'grey.900' : 'background.default',
      borderRadius: '8px',
      border: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
      p: 4,
    },
    oppyImage: {
      width: '200px',
      mb: 2,
      filter: darkMode ? 'brightness(0.8)' : 'none',
    },
    clearButton: {
      mt: 2,
      color: darkMode ? 'grey.300' : 'primary.main',
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
      },
    },
    wrapper: {
      p: 2,
      bgcolor: darkMode ? 'grey.900' : 'background.default',
      color: darkMode ? 'grey.100' : 'text.primary',
      height: '100vh', // Subtract header height
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      transition: 'all 0.3s ease',
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
    },
    gridContainer: {
      flex: 1,
      overflow: 'auto',
      minHeight: 0, // Important to make the flex layout work properly
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        display: 'block',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: darkMode
          ? alpha(theme.palette.background.paper, 0.3)
          : alpha(theme.palette.background.default, 0.3),
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: darkMode
          ? alpha(theme.palette.primary.main, 0.2)
          : alpha(theme.palette.primary.main, 0.2),
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: darkMode
            ? alpha(theme.palette.primary.main, 0.3)
            : alpha(theme.palette.primary.main, 0.3),
        },
      },
      scrollbarWidth: 'thin',
    },
    contentWrapper: {
      position: 'relative',
      borderRadius: '8px',
      border: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      overflow: 'hidden',
      boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0, // Important for flex child to respect parent height
    },
    paginationContainer: {
      mt: 'auto',
      borderTop: `1px solid ${darkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider}`,
      backgroundColor: darkMode ? alpha(theme.palette.background.paper, 0.6) : theme.palette.background.paper,
      borderRadius: '0 0 8px 8px',
    },
    filterSummary: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 1,
      p: 1.5,
      borderBottom: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
      bgcolor: darkMode ? alpha(theme.palette.primary.main, 0.05) : alpha(theme.palette.primary.main, 0.02),
    },
    bulkActions: {
      display: 'flex',
      gap: 2,
      mt: 2,
      '& .MuiButton-root': {
        borderRadius: '8px',
        fontWeight: 500,
        textTransform: 'none',
        boxShadow: 'none',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
      },
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '50vh',
      color: darkMode ? 'grey.300' : 'text.primary',
    },
    iconButton: {
      borderRadius: '8px',
      p: 1,
      mx: 0.5,
      transition: 'all 0.2s ease',
      '&:hover': {
        transform: 'translateY(-1px)',
      },
    },
    createButton: {
      borderRadius: '8px',
      textTransform: 'none',
      fontWeight: 500,
      px: 2,
      boxShadow: 'none',
      backgroundColor: darkMode ? alpha(theme.palette.primary.main, 0.8) : theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transform: 'translateY(-1px)',
      },
    },
    filterBadge: {
      '& .MuiBadge-badge': {
        bgcolor: isFilterActive ? theme.palette.primary.main : 'grey.500',
        color: 'white',
      },
    },
  };

  // Lazy load MultiEditDialog
  const MultiEditDialog = React.useMemo(
    () => lazy(() => import('./MultiEditDialog')),
    []
  );

  const handleSyncWithFub = () => {
    ContactsService.syncWithFub()
      .then(response => {
        console.log('Sync initiated:', response.data.message);
      })
      .catch(error => {
        console.error('Error syncing with FUB:', error);
      });
  };

  // Render active filter summary
  const FilterSummary = () => {
    if (!isFilterActive || !filterObject || Object.keys(filterObject).length === 0) {
      return null;
    }

    // Count filters by type to display a summary
    const filterCounts: Record<string, number> = {};
    Object.keys(filterObject).forEach(key => {
      const filterType = key.includes('_') ? key.split('_')[0] : key;
      filterCounts[filterType] = (filterCounts[filterType] || 0) + 1;
    });

    return (
      <Fade in={isFilterActive}>
        <Box sx={styles.filterSummary}>
          <Chip
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <FilterAltOutlined fontSize="small" />
                <Typography variant="body2" fontWeight={500}>
                  {Object.keys(filterObject).length} Active {Object.keys(filterObject).length === 1 ? 'Filter' : 'Filters'}
                </Typography>
              </Box>
            }
            sx={{
              bgcolor: darkMode
                ? alpha(theme.palette.primary.main, 0.15)
                : alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              height: 28,
              fontWeight: 500,
              borderRadius: '6px',
            }}
          />

          {contacts.length > 0 && totalFilteredContacts > 0 && (
            <Chip
              label={`${totalFilteredContacts} contact${totalFilteredContacts !== 1 ? 's' : ''} found`}
              size="small"
              sx={{
                bgcolor: darkMode
                  ? alpha(theme.palette.success.main, 0.15)
                  : alpha(theme.palette.success.main, 0.1),
                color: theme.palette.success.main,
                height: 28,
                fontWeight: 500,
                borderRadius: '6px',
              }}
            />
          )}

          <Button
            size="small"
            startIcon={<FilterList />}
            onClick={handleClearFilters}
            sx={{
              ml: 'auto',
              color: theme.palette.primary.main,
              borderRadius: '6px',
              fontSize: '0.8rem',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            Clear Filters
          </Button>
        </Box>
      </Fade>
    );
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Box sx={styles.pageTitle}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            Contacts
          </Typography>
          <Badge
            badgeContent={getActiveFilterCount()}
            color="primary"
            invisible={!isFilterActive}
            sx={styles.filterBadge}
          >
            <Badge
              badgeContent={contacts.length}
              color="success"
              max={999}
              invisible={!isFilterActive || contacts.length === 0}
              sx={{
                '& .MuiBadge-badge': {
                  right: -8,
                  top: 20,
                },
              }}
            >
              <Box sx={{ width: 24, height: 24 }} />
            </Badge>
          </Badge>
        </Box>
        <Box sx={styles.topBarAction}>
          <Select
            value={viewMode}
            onChange={handleViewModeChange}
            size="small"
            sx={{
              height: 36,
              minWidth: 120,
              fontSize: '0.875rem',
              mr: 2,
              '& .MuiSelect-select': {
                py: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              },
            }}
          >
            <MenuItem value="list">
              <ViewStream fontSize="small" />
              List View
            </MenuItem>
            <MenuItem value="grid">
              <ViewAgenda fontSize="small" />
              Grid View
            </MenuItem>
            <MenuItem value="kanban">
              <ViewStream fontSize="small" />
              Kanban View
            </MenuItem>
          </Select>
          <Button
            variant="contained"
            startIcon={<PersonAdd />}
            onClick={() => setIsCreateDialogOpen(true)}
            sx={styles.createButton}
          >
            Add Contact
          </Button>
          <IconButton color="inherit" onClick={handleOpenContactsUploaderDialog} sx={styles.iconButton}>
            <ImportContacts />
          </IconButton>
        </Box>
      </Box>

      <ContactsFilterBar />

      <Box sx={styles.contentContainer}>
        <Box sx={styles.contentWrapper}>
          {showLoadingBar && <LinearProgress sx={{ mb: 0, bgcolor: darkMode ? 'grey.800' : undefined }} />}

          <FilterSummary />

          {selectedContacts && selectedContacts.length > 0 && (
            <Box sx={styles.bulkActions}>
              <Button
                variant="contained"
                onClick={handleBulkMessageClick}
                sx={{
                  bgcolor: darkMode ? alpha(theme.palette.primary.main, 0.8) : theme.palette.primary.main,
                  '&:hover': {
                    bgcolor: theme.palette.primary.dark,
                  },
                }}
              >
                Send Bulk Message
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBulkTagClick}
                sx={{
                  bgcolor: darkMode ? alpha(theme.palette.primary.main, 0.8) : theme.palette.primary.main,
                  '&:hover': {
                    bgcolor: theme.palette.primary.dark,
                  },
                }}
              >
                Tag Selected ({selectedContacts.length})
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleBulkDelete}
                sx={{
                  bgcolor: darkMode ? alpha(theme.palette.error.main, 0.8) : theme.palette.error.main,
                  '&:hover': {
                    bgcolor: theme.palette.error.dark,
                  },
                }}
              >
                Delete Selected ({selectedContacts.length})
              </Button>
            </Box>
          )}

          {contactsLoading && contacts.length === 0 ? (
            <Box sx={styles.loadingContainer}>
              <CircularProgress sx={{ color: darkMode ? 'grey.300' : 'primary.main' }} />
            </Box>
          ) : !contactsLoading && contacts.length === 0 ? (
            <Box sx={styles.noContactsContainer}>
              <img src={oppyImage} alt="No contacts" style={{ width: '200px', marginBottom: '16px' }} />
              <Typography variant="h6" sx={{ color: darkMode ? 'grey.300' : 'text.primary', mb: 2 }}>
                {isEmpty(filterObject) ? "No contacts here yet." : "Couldn't find any contacts."}
              </Typography>
              <Typography variant="body1" sx={{ color: darkMode ? 'grey.400' : 'text.secondary', mb: 2 }}>
                {isEmpty(filterObject)
                  ? "Add your first contact to get started."
                  : "Try adjusting your search or clearing the filters."}
              </Typography>
              {isEmpty(filterObject) ? (
                <Button
                  variant="contained"
                  onClick={() => setIsCreateDialogOpen(true)}
                  sx={{
                    ...styles.createButton,
                    px: 3,
                    py: 1,
                  }}
                >
                  Add Contact
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClearFilters}
                  startIcon={<FilterList />}
                  sx={{
                    ...styles.clearButton,
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 500,
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </Box>
          ) : (
            <>
              <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', minHeight: 0 }}>
                {viewMode === 'list' ? (
                  <ContactsList
                    onSelectionChange={handleContactsSelection}
                    selectedContactIds={selectedContacts.map((contact) => contact.id)}
                  />
                ) : viewMode === 'grid' ? (
                  <ContactsDataGrid
                    onSelectionChange={handleContactsSelection}
                    selectedContactIds={selectedContacts.map((contact) => contact.id)}
                    currentPage={currentPage}
                  />
                ) : (
                  <ContactsKanban
                    contacts={contacts as Contact[]}
                    onSelectionChange={handleContactsSelection}
                    selectedContactIds={selectedContacts.map((contact) => contact.id)}
                  />
                )}
              </Box>
              <Box sx={styles.paginationContainer}>
                <ContactsPagination
                  totalItems={isFilterActive ? totalFilteredContacts : totalContacts}
                  onPageChange={handlePageChange}
                  pageCache={pageCache}
                  setPageCache={setPageCache}
                  loading={contactsLoading}
                  currentPageProp={currentPage}
                  viewMode={viewMode}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <ContactCreateDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        handleContactUpdate={handleCreateContact}
      />

      <ContactsUploaderDialog />

      {multiEditDialogOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <MultiEditDialog
            open={multiEditDialogOpen}
            onClose={() => setMultiEditDialogOpen(false)}
            selectedContactIds={selectedContacts.map(contact => contact.id.toString())}
          />
        </Suspense>
      )}

      <BulkConversationCreateDialog
        open={isBulkDialogOpen}
        handleClose={() => setIsBulkDialogOpen(false)}
        selectedContacts={selectedContacts}
      />

      <BulkTagDialog
        open={bulkTagDialogOpen}
        onClose={() => setBulkTagDialogOpen(false)}
        selectedContactIds={selectedContacts.map(contact => contact.id.toString())}
      />
    </Box>
  );
};

export default ContactsWrapper;
