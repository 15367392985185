import React, { useState } from 'react';
import { 
  Box, 
  Chip, 
  TextField, 
  Typography, 
  Switch,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
  Paper,
  alpha,
  Button,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FilterValue, FilterValueType, isScoreRange, isDate, ScoreRange } from 'src/components/Contacts/types';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

interface FilterChipProps {
  filter: FilterValue;
  onDelete?: () => void;
  onChange: (value: FilterValueType) => void;
  onClick?: () => void;
  hideDelete?: boolean;
  expanded?: boolean;
  onBlur?: () => void;
}

export const FilterChip: React.FC<FilterChipProps> = ({ 
  filter, 
  onDelete, 
  onChange, 
  onClick,
  hideDelete = false,
  expanded = false,
  onBlur,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputMode, setInputMode] = useState<'add' | 'edit'>('edit');
  const theme = useTheme();

  const getChipColor = () => {
    switch (filter.option.group) {
      case 'Details':
        return 'primary';
      case 'Company':
        return 'success';
      case 'Activity':
        return 'info';
      case 'Advanced':
        return 'warning';
      default:
        return 'default';
    }
  };

  const handleInputBlur = () => {
    if (onBlur) {
      // For array inputs in edit mode, don't trigger onBlur
      if (Array.isArray(filter.value) && inputMode === 'add') {
        return;
      }
      
      // For array inputs, check if empty array or all empty strings
      if (Array.isArray(filter.value) && (!filter.value.length || filter.value.every(v => !v.trim()))) {
        onBlur();
        return;
      }
      
      // For string inputs, check if empty or only whitespace
      if (typeof filter.value === 'string' && !filter.value.trim()) {
        onBlur();
        return;
      }
    }
  };

  // Handle adding a new item to array
  const handleAddItem = (e?: React.MouseEvent) => {
    // Stop event propagation to prevent blur
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!inputValue.trim()) return;
    
    if (Array.isArray(filter.value)) {
      onChange([...filter.value, inputValue.trim()]);
      setInputValue('');
      
      // Keep focus on the input field
      const inputElement = document.querySelector(`#filter-input-${filter.option.id}`) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  // Handle pressing Enter in array input
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      // Apply for array includes/excludes filters
      if (Array.isArray(filter.value)) {
        handleAddItem();
      } else if (typeof filter.value === 'string') {
        // For other text inputs, just prevent form submission
        // We don't apply the filter value here as it gets applied on change
      }
    }
  };
  
  // Add a mousedown handler to prevent blur when clicking the add button
  const handleAddButtonMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  // Handle removing an item from array
  const handleRemoveItem = (index: number) => {
    if (Array.isArray(filter.value)) {
      const newValue = [...filter.value];
      newValue.splice(index, 1);
      onChange(newValue);
    }
  };

  const renderInput = () => {
    const commonProps = {
      fullWidth: true,
      size: "small" as const,
      placeholder: filter.option.placeholder || `Enter ${filter.option.label.toLowerCase()}`,
      autoFocus: true,
      onBlur: handleInputBlur,
      sx: {
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'background.paper',
          borderRadius: 1.5,
          transition: 'all 0.2s ease',
          '& fieldset': {
            borderColor: 'divider',
          },
          '&:hover': {
            backgroundColor: theme => alpha(theme.palette.background.paper, 0.8),
            '& fieldset': {
              borderColor: theme => alpha(theme.palette.text.primary, 0.2),
            },
          },
          '&.Mui-focused': {
            backgroundColor: 'background.paper',
            '& fieldset': {
              borderWidth: '1px',
              borderColor: theme => theme.palette.primary.main,
            },
          },
        },
        '& .MuiInputBase-input': {
          padding: '8px 12px',
        },
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            {filter.option.helperText && (
              <Tooltip title={filter.option.helperText}>
                <IconButton edge="end" size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      },
    };

    // Handle array-based filters - completely redesigned
    if (Array.isArray(filter.value)) {
      return (
        <FormControl fullWidth>
          {/* Show existing values as chips */}
          {filter.value.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 0.5, 
              mb: 1,
              p: 1, 
              borderRadius: 1,
              bgcolor: theme => alpha(theme.palette.background.paper, 0.5),
            }}>
              {filter.value.map((item, index) => (
                <Chip
                  key={`${filter.option.id}-${item}-${index}`}
                  label={item}
                  size="small"
                  onDelete={() => handleRemoveItem(index)}
                  sx={{
                    borderRadius: 1,
                    bgcolor: theme => alpha(getFilterGroupColor(filter.option.group, theme), 0.1),
                    color: theme => getFilterGroupColor(filter.option.group, theme),
                    '& .MuiChip-deleteIcon': {
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'error.main',
                      },
                    },
                  }}
                />
              ))}
            </Box>
          )}
          
          {/* Input field with Add button */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              {...commonProps}
              id={`filter-input-${filter.option.id}`}
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={`Type and press + or Enter to add`}
              sx={{
                ...commonProps.sx,
                flex: 1,
              }}
            />
            <IconButton 
              onClick={handleAddItem}
              onMouseDown={handleAddButtonMouseDown}
              disabled={!inputValue.trim()}
              color="primary"
              sx={{
                bgcolor: alpha(getFilterGroupColor(filter.option.group, theme), 0.1),
                '&:hover': {
                  bgcolor: alpha(getFilterGroupColor(filter.option.group, theme), 0.2),
                },
                '&.Mui-disabled': {
                  bgcolor: 'action.disabledBackground',
                  color: 'action.disabled',
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          
          {filter.option.helperText && (
            <FormHelperText sx={{ ml: 1.5 }}>{filter.option.helperText}</FormHelperText>
          )}
        </FormControl>
      );
    }

    // Handle boolean filters
    if (typeof filter.value === 'boolean') {
      return (
        <Paper 
          variant="outlined" 
          sx={{ 
            p: 1.5,
            bgcolor: 'background.paper',
            borderRadius: 1.5,
            borderColor: 'divider',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2">{filter.value ? 'Yes' : 'No'}</Typography>
            <Switch
              checked={filter.value}
              onChange={(e) => onChange(e.target.checked)}
              size="small"
            />
            {filter.option.helperText && (
              <Tooltip title={filter.option.helperText}>
                <InfoIcon fontSize="small" sx={{ ml: 1, opacity: 0.7 }} />
              </Tooltip>
            )}
          </Box>
        </Paper>
      );
    }

    // Handle score range
    if (isScoreRange(filter.value)) {
      const currentValue = filter.value;
      return (
        <Paper 
          variant="outlined" 
          sx={{ 
            p: 1.5,
            bgcolor: 'background.paper',
            borderRadius: 1.5,
            borderColor: 'divider',
          }}
        >
          <FormControl fullWidth>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <TextField
                size="small"
                type="number"
                value={currentValue.min === 0 && document.activeElement === document.getElementById(`score-min-${filter.option.id}`) ? '' : currentValue.min}
                onChange={(e) => {
                  const min = e.target.value === '' ? 0 : Number(e.target.value);
                  const newValue: ScoreRange = { min, max: currentValue.max };
                  onChange(newValue);
                }}
                id={`score-min-${filter.option.id}`}
                sx={{ 
                  width: 80,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
                placeholder="Min"
                inputProps={{
                  min: filter.option.validation?.min,
                  max: filter.option.validation?.max,
                }}
              />
              <Typography variant="body2">to</Typography>
              <TextField
                size="small"
                type="number"
                value={currentValue.max === 0 && document.activeElement === document.getElementById(`score-max-${filter.option.id}`) ? '' : currentValue.max}
                onChange={(e) => {
                  const max = e.target.value === '' ? 0 : Number(e.target.value);
                  const newValue: ScoreRange = { min: currentValue.min, max };
                  onChange(newValue);
                }}
                id={`score-max-${filter.option.id}`}
                sx={{ 
                  width: 80,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
                placeholder="Max"
                inputProps={{
                  min: filter.option.validation?.min,
                  max: filter.option.validation?.max,
                }}
              />
            </Box>
            {filter.option.helperText && (
              <FormHelperText sx={{ ml: 1.5, mt: 1 }}>{filter.option.helperText}</FormHelperText>
            )}
          </FormControl>
        </Paper>
      );
    }

    // Handle date filters
    if (isDate(filter.value)) {
      return (
        <Paper 
          variant="outlined" 
          sx={{ 
            p: 1.5,
            bgcolor: 'background.paper',
            borderRadius: 1.5,
            borderColor: 'divider',
          }}
        >
          <FormControl fullWidth>
            <DatePicker
              value={filter.value}
              onChange={(newValue) => onChange(newValue || new Date())}
              slotProps={{
                textField: {
                  ...commonProps,
                  error: false,
                },
              }}
            />
            {filter.option.helperText && (
              <FormHelperText sx={{ ml: 1.5, mt: 1 }}>{filter.option.helperText}</FormHelperText>
            )}
          </FormControl>
        </Paper>
      );
    }

    // Default text input
    return (
      <FormControl fullWidth>
        <TextField
          {...commonProps}
          value={filter.value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          error={!!filter.option.validation?.pattern && !filter.option.validation.pattern.test(filter.value as string)}
          helperText={filter.option.validation?.errorMessage}
          InputProps={{
            ...commonProps.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {filter.value && (
                  <IconButton
                    size="small"
                    onClick={() => onChange('')}
                    edge="end"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                {commonProps.InputProps?.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    );
  };

  const formatValue = (value: FilterValueType): string => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (isScoreRange(value)) {
      return `${value.min}-${value.max}`;
    }
    if (isDate(value)) {
      return value.toLocaleDateString();
    }
    return String(value);
  };

  // Helper function to get color for a filter group
  const getFilterGroupColor = (group: string, theme: any) => {
    switch (group) {
      case 'Details':
        return theme.palette.primary.main;
      case 'Company':
        return theme.palette.success.main;
      case 'Activity':
        return theme.palette.info.main;
      case 'Advanced':
        return theme.palette.warning.main;
      default:
        return theme.palette.grey[500];
    }
  };

  if (!expanded) {
    return (
      <Chip
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography component="span" variant="body2" sx={{ fontWeight: 500 }}>
              {filter.option.label}
            </Typography>
            {filter.value && (
              <Typography
                component="span"
                variant="body2"
                sx={{ 
                  opacity: 0.8,
                  maxWidth: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 400,
                }}
              >
                : {formatValue(filter.value)}
              </Typography>
            )}
          </Box>
        }
        onDelete={!hideDelete ? onDelete : undefined}
        onClick={onClick}
        color={getChipColor()}
        sx={{ 
          height: 32,
          '& .MuiChip-label': { px: 1 },
          '&:hover': { opacity: 0.9 },
          bgcolor: theme => getChipColor() === 'default' ? theme.palette.grey[700] : undefined,
        }}
      />
    );
  }

  return renderInput();
};

export default FilterChip; 
