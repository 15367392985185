// Utility functions for Kanban components

export const formatStageKey = (title: string, index: number): string => {
  // Trim title
  let formattedTitle = title.trim();

  // Ensure the title starts with the index and a hyphen
  const indexHyphen = `${index}-`;
  if (!formattedTitle.startsWith(indexHyphen)) {
    // Remove any leading numbers and hyphens
    formattedTitle = formattedTitle.replace(/^[0-9]+-?\s*/, '');
    // Prepend the correct index and hyphen
    formattedTitle = `${indexHyphen}${formattedTitle}`;
  }

  // Trim any spaces after adjustments
  formattedTitle = formattedTitle.trim();

  return formattedTitle;
};

export const formatColumnTitle = (stageKey: string): string => {
  // Remove numeric prefix and hyphen
  const titleWithoutIndex = stageKey.replace(/^\d+-/, '');
  // Replace underscores with spaces
  const withSpaces = titleWithoutIndex.replace(/_/g, ' ');
  // Capitalize first letter of each word
  const formattedTitle = withSpaces.replace(/\b\w/g, char => char.toUpperCase());
  return formattedTitle;
};
