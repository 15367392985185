/**
 * ContactsKanban component displays contacts in a Kanban board based on their stage.
 * 
 * **Contact Stages and Columns:**
 * - Each column represents a contact stage.
 * - The stage is a string starting with a number (e.g., '1-New Leads'), assigned based on the column's position.
 * - Contacts are grouped by their stage.
 * - Columns are dynamically generated from the contacts' stages.
 * 
 * **Mapping Contacts to Columns and Cards:**
 * - Contacts with the same stage are placed in the same column.
 * - Each contact is represented as a card within its stage's column.
 * - Cards display contact information like name, score, deal size, etc.
 * 
 * **Updating Columns and Contacts:**
 * - **Adding a Column:**
 *   - A new stage is created starting with the next index number.
 *   - No contacts are initially assigned to this stage.
 * - **Deleting a Column:**
 *   - All contacts in the deleted stage have their stage set to an empty string.
 *   - Contacts are updated via `updateContact` dispatch to the store.
 * - **Moving a Column:**
 *   - The stages of all columns are updated to reflect their new indices.
 *   - All contacts within affected columns have their `stage` updated accordingly.
 *   - A bulk update is performed using the `bulk_update` endpoint.
 *   - Column dragging is disabled during the update, and a progress indicator is shown.
 * - **Moving a Card:**
 *   - When a card is moved between columns or within a column:
 *     - The contact's `stage` and `position` fields are updated.
 *     - The `updateContact` action is dispatched for the moved contact.
 * 
 * **kanbanUtils.ts:**
 * - Contains utility functions for formatting stage keys and column titles.
 * - `formatStageKey` ensures that a stage key starts with the correct index number.
 * - `formatColumnTitle` formats stage keys for display by removing the numeric prefix.
 */

import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
} from 'react-beautiful-dnd';
import {
  Paper,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Menu,
  MenuItem,
  LinearProgress,
  Card as MuiCard,
  TextField,
  useTheme,
  alpha,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Contact } from 'src/types/contacts';
import { formatStageKey, formatColumnTitle } from '../Kanban/kanbanUtils';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import { enqueueSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { reorderContactState } from 'src/features/account/contacts.slice';
import ContactCardV2 from './ContactCardV2';
import { selectAllFilteredContacts, selectIsFilterActive } from 'src/features/account/filteredContacts.slice';
import { selectAllContacts } from 'src/features/account/contacts.slice';
import { useColorMode } from 'src/theme/ThemeProvider';

// Define CardData interface
interface CardData {
  id: string;
  title: string;
  description: string;
  data: Contact;
}

// Define Column interface
interface Column {
  id: string;
  stage: string;
  title: string;
  cards: CardData[];
  allow_move?: boolean;
}

interface ContactsKanbanProps {
  contacts: Contact[];
  onSelectionChange: (selectedIds: string[]) => void;
  selectedContactIds: string[]; // Ensure this line is present
}

const ContactsKanban: React.FC<ContactsKanbanProps> = ({
  onSelectionChange,
  selectedContactIds,
}) => {

  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';

  const filteredContacts = useAppSelector(selectAllFilteredContacts);
  const unfilteredContacts = useAppSelector(selectAllContacts);
  const isFilterActive = useAppSelector(selectIsFilterActive);

  const contacts = isFilterActive ? filteredContacts : unfilteredContacts;
  const dispatch = useAppDispatch();
  // Replace the columns state with contactsState
  const [contactsState, setContactsState] = React.useState<Contact[]>([]);

  // State for columns
  const [isUpdatingColumns, setIsUpdatingColumns] = React.useState(false); // Track bulk update status

  // State for adding new column
  const [newColumnTitle, setNewColumnTitle] = React.useState('');
  const [addColumnDialogOpen, setAddColumnDialogOpen] = React.useState(false);

  // State for column menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuColumnId, setMenuColumnId] = React.useState<string>('');
  const menuOpen = Boolean(anchorEl);

  // State for delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [columnToDelete, setColumnToDelete] = React.useState<string>('');

  // Create a ref to store previous contactsState for rollback
  const prevContactsRef = React.useRef<Contact[]>([]);

  // Debounce function to update contactsState from props
  const debouncedMergeContacts = React.useCallback(
    debounce((incomingContacts: Contact[]) => {
      setContactsState(prevContacts => {
        const contactsMap = new Map(prevContacts.map(c => [c.id, c]));
        incomingContacts.forEach(contact => {
          contactsMap.set(contact.id, {
            ...contactsMap.get(contact.id),
            ...contact,
          });
        });
        return Array.from(contactsMap.values());
      });
    }, 2000), // Adjust debounce delay as needed
    []
  );

  // UseEffect to merge incoming contacts props
  useEffect(() => {
    setContactsState(contacts as Contact[]);
    console.log('Contacts updated in ContactsKanban');
  }, [contacts]);

  // Convert contact to card data
  const contactToCard = (contact: Contact): CardData => {
    return {
      id: contact.id!,
      title: contact.name || contact.email || contact.phone_number || 'No Title',
      description: `Score: ${contact.score || 0}, Deal Size: ${contact.deal_size || 0}, Assigned To: ${contact.assigned_to || 'Unassigned'}`,
      data: contact,
    };
  };

  // Define createColumnsFromContacts before using it in useMemo
  const createColumnsFromContacts = (contacts: Contact[], existingColumns: Column[]): Column[] => {
    const contactsByStage = contacts.reduce((acc, contact) => {
      let stage = contact.stage?.trim();
      if (!stage || !/^\d/.test(stage)) {
        stage = '0-Uncategorized';
      } else {
        // Ensure consistent formatting
        const index = parseInt(stage.split('-')[0], 10);
        const title = formatColumnTitle(stage);
        stage = formatStageKey(title, index);
      }
      acc[stage] = acc[stage] || [];
      acc[stage].push(contact);
      return acc;
    }, {} as Record<string, Contact[]>);

    // Convert contacts to cards and create columns
    const allStages = Object.keys(contactsByStage);

    const newColumns = allStages.map((stageKey) => {
      const contactsInStage = contactsByStage[stageKey];

      // Sort contactsInStage by position in descending order
      contactsInStage.sort((a, b) => {
        const posA = typeof a.position === 'string' ? parseFloat(a.position) : (a.position || 0);
        const posB = typeof b.position === 'string' ? parseFloat(b.position) : (b.position || 0);
        return posB - posA;
      });

      const formattedTitle = formatColumnTitle(stageKey);
      return {
        id: `column-${stageKey}`,
        stage: stageKey,
        title: formattedTitle,
        allow_move: stageKey !== '0-Uncategorized',
        cards: contactsInStage.map(contactToCard),
      };
    });

    // Include existing empty columns
    const emptyColumns = existingColumns.filter(col => col.cards.length === 0 && !newColumns.some(newCol => newCol.id === col.id));
    const combinedColumns = [...newColumns, ...emptyColumns];

    // Sort columns by index
    const sortedColumns = combinedColumns.sort((a, b) => {
      const indexA = parseInt(a.stage.split('-')[0], 10);
      const indexB = parseInt(b.stage.split('-')[0], 10);
      return indexA - indexB;
    });

    return sortedColumns;
  };

  // Add 'columnsState' to manage columns order
  const [columnsState, setColumnsState] = useState<Column[]>([]);

  // Initialize 'columnsState' based on 'contactsState'
  React.useEffect(() => {
    const initialColumns = createColumnsFromContacts(contactsState, columnsState);
    setColumnsState(initialColumns);
  }, [contactsState]);

  // Use 'columnsState' directly
  const columns = columnsState;

  // Handle drag end
  const handleDragEnd = React.useCallback((result: DropResult) => {
    const { source, destination, type } = result;
    if (!destination) return;

    if (type === 'column') {
      if (isUpdatingColumns) return;
      handleColumnDragEnd(source.index, destination.index);
    } else {
      const { draggableId } = result;
      handleCardDragEnd(
        draggableId,
        source.droppableId,
        destination.droppableId,
        destination.index
      );
    }
  }, [columns, isUpdatingColumns]);

  // Handle card drag end
  const handleCardDragEnd = (
    cardId: string,
    sourceColumnId: string,
    destinationColumnId: string,
    destinationIndex: number
  ) => {
    const sourceColumn = columns.find(col => col.id === sourceColumnId);
    const destColumn = columns.find(col => col.id === destinationColumnId);
    if (!sourceColumn || !destColumn) return;

    const sourceCards = Array.from(sourceColumn.cards);
    const destCards = Array.from(destColumn.cards);

    // Find and remove the card from the source column
    const movedCardIndex = sourceCards.findIndex(card => card.id === cardId);
    const [movedCard] = sourceCards.splice(movedCardIndex, 1);

    // Insert the card into the destination column
    destCards.splice(destinationIndex, 0, movedCard);

    // Calculate new position
    let newPosition: number;
    const prevCard = destCards[destinationIndex - 1];
    const nextCard = destCards[destinationIndex + 1];

    if (prevCard && nextCard) {
      const prevPos = typeof prevCard.data.position === 'string' ? parseFloat(prevCard.data.position) : (prevCard.data.position || 0);
      const nextPos = typeof nextCard.data.position === 'string' ? parseFloat(nextCard.data.position) : (nextCard.data.position || 0);
      newPosition = (prevPos + nextPos) / 2;
    } else if (prevCard) {
      const prevPos = typeof prevCard.data.position === 'string' ? parseFloat(prevCard.data.position) : (prevCard.data.position || 0);
      newPosition = prevPos - 1;
    } else if (nextCard) {
      const nextPos = typeof nextCard.data.position === 'string' ? parseFloat(nextCard.data.position) : (nextCard.data.position || 0);
      newPosition = nextPos + 1;
    } else {
      newPosition = 1; // Starting position for an empty column
    }

    const newStage = destColumn.stage;

    // Update moved card's data
    const updatedMovedCard = {
      ...movedCard,
      data: {
        ...movedCard.data,
        position: newPosition.toString(), // Convert to string to match Contact type
        stage: newStage,
      },
    };

    // Update columnsState immediately to reflect changes in UI
    setColumnsState(prevColumns =>
      prevColumns.map(column => {
        if (column.id === sourceColumnId) {
          return { ...column, cards: sourceCards };
        }
        if (column.id === destinationColumnId) {
          return { ...column, cards: destCards };
        }
        return column;
      })
    );

    // Update contactsState
    setContactsState(prevContacts =>
      prevContacts.map(contact =>
        contact.id === updatedMovedCard.id
          ? { ...contact, stage: newStage, position: parseFloat(newPosition.toString()) }
          : contact
      )
    );

    // Dispatch actions and update backend
    dispatch(reorderContactState(updatedMovedCard.id, newPosition, newStage));
  };

  // Handle column drag end
  const handleColumnDragEnd = async (sourceIndex: number, destinationIndex: number) => {
    if (sourceIndex === destinationIndex) return;

    const updatedColumns = Array.from(columnsState);
    const [movedColumn] = updatedColumns.splice(sourceIndex, 1);
    updatedColumns.splice(destinationIndex, 0, movedColumn);

    // Update 'columnsState' with new order
    setColumnsState(updatedColumns);

    // **Set isUpdatingColumns to true**
    setIsUpdatingColumns(true);

    // Save previous contactsState for rollback
    prevContactsRef.current = contactsState;

    // Proceed to update contacts
    const contactsToUpdate: Contact[] = [];

    // Update stage keys and contacts for all columns starting from the affected index
    updatedColumns.forEach((column, index) => {
      const newStageIndex = index;
      const newStageKey = formatStageKey(column.title, newStageIndex);

      if (column.stage !== newStageKey) {
        console.log(`Updating column '${column.title}' from stage '${column.stage}' to '${newStageKey}'`);

        // Update column's stage and id
        column.stage = newStageKey;
        column.id = `column-${newStageKey}`;
        column.allow_move = newStageKey !== '0-Uncategorized';

        // Update cards within the column
        column.cards = column.cards.map(card => {
          const updatedCard = {
            ...card,
            data: {
              ...card.data,
              stage: newStageKey,
            },
          };
          contactsToUpdate.push(updatedCard.data);
          console.log(`Prepared to update contact '${updatedCard.data.id}' to new stage '${newStageKey}'`);
          return updatedCard;
        });
      }
    });

    // Update contactsState immediately
    setContactsState(prevContacts => {
      return prevContacts.map(contact => {
        const updatedContact = contactsToUpdate.find(c => c.id === contact.id);
        return updatedContact ? updatedContact : contact;
      });
    });
    console.log('Updated contactsState with new stages');

    try {
      // **Await bulk update and handle response**
      const response = await bulkUpdateContacts(contactsToUpdate);
      console.log('Bulk update successful');
    } catch (error) {
      console.error('Bulk update failed', error);
      enqueueSnackbar(`Error updating contacts: ${error.message}`, { variant: 'error' });
      // Revert contactsState to previous state
      setContactsState(prevContactsRef.current);
    } finally {
      setIsUpdatingColumns(false);
    }
  };

  // Function to bulk update contacts
  const bulkUpdateContacts = async (contactsToUpdate: Contact[]) => {
    try {
      const response = await httpClient.put(
        `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/bulk_update`,
        { contacts: contactsToUpdate }
      );
      if (response.status === 200 && response.data.success) {
        console.log('Contacts bulk updated successfully');
        return response;
      } else {
        throw new Error(response.data.error || 'Bulk update failed');
      }
    } catch (error) {
      console.error('Error in bulk updating contacts', error);
      throw error;
    }
  };

  // Handle adding a new column
  const handleAddColumn = (title: string) => {
    const newIndex = columnsState.length; // Use columnsState to get current length
    const newStageKey = formatStageKey(title, newIndex);

    const newColumn: Column = {
      id: `column-${newStageKey}`,
      stage: newStageKey,
      title: formatColumnTitle(newStageKey),
      cards: [],
    };

    // Update columnsState with the new column
    setColumnsState(prevColumns => [...prevColumns, newColumn]);
    console.log(`Added new column '${newColumn.title}' with stage '${newStageKey}' at index ${newIndex}`);
  };

  // Handle deleting a column
  const handleDeleteColumn = (columnId: string) => {
    console.log('Deleting column:', columnId);

    const columnToDelete = columns.find((col) => col.id === columnId);
    if (!columnToDelete) return;

    // Update contacts in this stage
    const contactsToUpdate: Contact[] = [];
    columnToDelete.cards.forEach(card => {
      const updatedContact = {
        ...card.data,
        stage: '',
      };
      contactsToUpdate.push(updatedContact);
      console.log(`Prepared to update contact '${updatedContact.id}' stage to ''`);
    });

    // Remove the column
    const updatedColumns = columns.filter((col) => col.id !== columnId);

    // Reassign stages to remaining columns
    const reorderedColumns = updatedColumns.map((col, idx) => {
      const newStageKey = formatStageKey(col.title, idx);
      if (col.stage !== newStageKey) {
        console.log(`Updating column '${col.title}' from stage '${col.stage}' to '${newStageKey}'`);
        const updatedCol = {
          ...col,
          stage: newStageKey,
          id: `column-${newStageKey}`,
          cards: col.cards.map(card => {
            const updatedCard = {
              ...card,
              data: { ...card.data, stage: newStageKey },
            };
            contactsToUpdate.push(updatedCard.data);
            console.log(`Prepared to update contact '${updatedCard.data.id}' to new stage '${newStageKey}'`);
            return updatedCard;
          })
        };
        return updatedCol;
      }
      return col;
    });

    // Update contactsState immediately
    setContactsState(prevContacts => {
      return prevContacts.map(contact => {
        const updatedContact = contactsToUpdate.find(c => c.id === contact.id);
        return updatedContact ? updatedContact : contact;
      });
    });

    // Update contacts
    bulkUpdateContacts(contactsToUpdate)
      .then(() => {
        console.log('Contacts updated after deleting column');
      })
      .catch(error => {
        console.error('Error updating contacts after deleting column', error);
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      });
  };

  // Handle Add Column Dialog
  const handleAddColumnClick = () => {
    setAddColumnDialogOpen(true);
  };

  const handleAddColumnConfirm = () => {
    if (newColumnTitle.trim()) {
      handleAddColumn(newColumnTitle.trim());
      setNewColumnTitle('');
    }
    setAddColumnDialogOpen(false);
  };

  const handleAddColumnCancel = () => {
    setNewColumnTitle('');
    setAddColumnDialogOpen(false);
  };

  // Handle column menu open
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, columnId: string) => {
    setAnchorEl(event.currentTarget);
    setMenuColumnId(columnId);
    console.log('Opening menu for column:', columnId);
  };

  // Handle column menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuColumnId('');
  };

  // Handle delete column click
  const handleDeleteColumnClick = () => {
    setColumnToDelete(menuColumnId);
    const column = columns.find((col) => col.id === menuColumnId);
    if (column && column.cards.length > 0) {
      setDeleteDialogOpen(true);
    } else {
      handleDeleteColumn(menuColumnId);
    }
    handleMenuClose();
  };

  // Handle delete confirmation
  const handleDeleteConfirm = () => {
    if (columnToDelete) {
      handleDeleteColumn(columnToDelete);
      console.log('Deleted column:', columnToDelete);
    }
    setDeleteDialogOpen(false);
    setColumnToDelete('');
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setColumnToDelete('');
  };

  // Adjust useEffect cleanup
  React.useEffect(() => {
    return () => {
      debouncedMergeContacts.cancel();
    };
  }, [debouncedMergeContacts]);

  // **State for selected cards**
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedContactIds);

  useEffect(() => {
    // Update selectedIds when selectedContactIds prop changes
    setSelectedIds(selectedContactIds);
  }, [selectedContactIds]);

  // Function to handle card selection
  const handleCardSelection = (cardId: string) => {
    const currentIndex = selectedIds.indexOf(cardId);
    let newSelectedIds = [];

    if (currentIndex === -1) {
      newSelectedIds = [...selectedIds, cardId];
    } else {
      newSelectedIds = selectedIds.filter((id) => id !== cardId);
    }

    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds);
  };

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isUpdatingColumns && <LinearProgress />}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="kanban" type="column" direction="horizontal">
          {(provided: DroppableProvided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: 'flex',
                overflowX: 'auto',
                backgroundColor: isDarkMode ? theme.palette.background.default : theme.palette.background.paper,
                padding: theme.spacing(2),
                height: '100%',
                '&::-webkit-scrollbar': {
                  height: '8px',
                  display: 'block',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: isDarkMode
                    ? alpha(theme.palette.background.paper, 0.3)
                    : alpha(theme.palette.background.default, 0.3),
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: isDarkMode
                    ? alpha(theme.palette.primary.main, 0.2)
                    : alpha(theme.palette.primary.main, 0.2),
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: isDarkMode
                      ? alpha(theme.palette.primary.main, 0.3)
                      : alpha(theme.palette.primary.main, 0.3),
                  },
                },
                scrollbarWidth: 'thin',
              }}
            >
              {columns.map((column, index) => (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                  isDragDisabled={isUpdatingColumns || column.allow_move === false}
                >
                  {(provided: DraggableProvided) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        margin: 1,
                        width: 350,
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: isDarkMode ? 'background.paper' : 'background.default',
                        borderRadius: 1,
                        border: `1px solid ${isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider}`,
                        boxShadow: isDarkMode ? '0 4px 6px rgba(0, 0, 0, 0.2)' : theme.shadows[1],
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'move',
                          position: 'sticky',
                          top: 0,
                          zIndex: 1,
                          backgroundColor: isDarkMode ? 'background.paper' : 'background.default',
                          borderBottom: `1px solid ${isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider}`,
                          borderTopLeftRadius: 1,
                          borderTopRightRadius: 1,
                          p: 1,
                        }}
                        {...provided.dragHandleProps}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            p: 1,
                            color: isDarkMode ? 'text.primary' : 'text.primary',
                            fontWeight: 500
                          }}
                        >
                          {column.title}
                        </Typography>

                        <IconButton
                          onClick={(e) => handleMenuOpen(e, column.id)}
                          size="small"
                          sx={{
                            mr: 0.5,
                            color: isDarkMode ? 'text.secondary' : 'text.primary',
                            '&:hover': {
                              backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.primary.main, 0.05),
                            }
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>

                      <Droppable droppableId={column.id} type="card">
                        {(provided: DroppableProvided) => (
                          <div
                            ref={provided.innerRef}
                            style={{
                              flexGrow: 1,
                              minHeight: 100,
                              padding: theme.spacing(1),
                              overflowY: 'auto',
                              backgroundColor: isDarkMode ? theme.palette.background.paper : theme.palette.background.default,
                              '&::-webkit-scrollbar': {
                                width: '8px',
                                display: 'block',
                              },
                              '&::-webkit-scrollbar-track': {
                                backgroundColor: isDarkMode
                                  ? alpha(theme.palette.background.paper, 0.3)
                                  : alpha(theme.palette.background.default, 0.3),
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: isDarkMode
                                  ? alpha(theme.palette.primary.main, 0.2)
                                  : alpha(theme.palette.primary.main, 0.2),
                                borderRadius: '4px',
                                '&:hover': {
                                  backgroundColor: isDarkMode
                                    ? alpha(theme.palette.primary.main, 0.3)
                                    : alpha(theme.palette.primary.main, 0.3),
                                },
                              },
                              scrollbarWidth: 'thin',
                            }}
                            {...provided.droppableProps}
                          >
                            {column.cards.map((card, index) => (
                              <Draggable key={card.id} draggableId={card.id} index={index}>
                                {(provided: DraggableProvided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      margin: theme.spacing(1),
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <ContactCardV2
                                      contactId={card.id}
                                      expanded={true}
                                      selected={selectedIds.includes(card.id)}
                                      onSelect={() => handleCardSelection(card.id)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}

              <Paper
                sx={{
                  margin: 1,
                  minWidth: 250,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 64,
                  backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.primary.main, 0.05),
                  border: `1px dashed ${isDarkMode ? alpha(theme.palette.primary.main, 0.3) : theme.palette.primary.main}`,
                  transition: 'all 0.3s',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.primary.main, 0.1),
                    borderColor: theme.palette.primary.main,
                  }
                }}
                onClick={handleAddColumnClick}
              >
                <Typography
                  variant="h6"
                  sx={{
                    p: 1,
                    color: isDarkMode ? theme.palette.primary.light : theme.palette.primary.main,
                    fontWeight: 500
                  }}
                >
                  + Contact Stage
                </Typography>
              </Paper>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            backgroundColor: isDarkMode ? 'background.paper' : 'background.default',
            borderRadius: 1,
            boxShadow: isDarkMode ? '0 4px 6px rgba(0, 0, 0, 0.2)' : theme.shadows[1],
          }
        }}
      >
        <MenuItem
          onClick={handleDeleteColumnClick}
          sx={{
            color: theme.palette.error.main,
            '&:hover': {
              backgroundColor: isDarkMode ? alpha(theme.palette.error.main, 0.1) : alpha(theme.palette.error.main, 0.05),
            }
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={addColumnDialogOpen}
        onClose={handleAddColumnCancel}
        PaperProps={{
          sx: {
            backgroundColor: isDarkMode ? 'background.paper' : 'background.default',
            borderRadius: 2,
            boxShadow: isDarkMode ? '0 4px 6px rgba(0, 0, 0, 0.2)' : theme.shadows[1],
          }
        }}
      >
        <DialogTitle sx={{ color: 'text.primary' }}>Add Column</DialogTitle>
        <DialogContent>
          <TextField
            label="Column Title"
            variant="outlined"
            fullWidth
            value={newColumnTitle}
            onChange={(e) => setNewColumnTitle(e.target.value)}
            sx={{
              mt: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddColumnCancel}
            sx={{
              color: isDarkMode ? 'text.secondary' : 'text.primary',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddColumnConfirm}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            backgroundColor: isDarkMode ? 'background.paper' : 'background.default',
            borderRadius: 2,
            boxShadow: isDarkMode ? '0 4px 6px rgba(0, 0, 0, 0.2)' : theme.shadows[1],
          }
        }}
      >
        <DialogTitle sx={{ color: 'text.primary' }}>Delete Column</DialogTitle>
        <DialogContent>
          <Typography sx={{ color: 'text.primary' }}>
            {columns.find((col) => col.id === columnToDelete)?.cards.length > 0
              ? 'This column has cards. All cards will be moved to "Uncategorized". This action cannot be reversed.'
              : 'Are you sure you want to delete this empty column?'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            sx={{
              color: isDarkMode ? 'text.secondary' : 'text.primary',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactsKanban;
