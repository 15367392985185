import { httpClient } from 'src/libs';

const API_URL = `${process.env.REACT_APP_BASE_URL}/comms-api/notes`;

const getNotes = (params: any) => {
  return httpClient.get(API_URL, { params });
};

const createNote = (noteData: any) => {
  return httpClient.post(API_URL, noteData);
};

const updateNote = (noteId: string, noteData: any) => {
  return httpClient.patch(`${API_URL}/${noteId}`, noteData);
};

const deleteNote = (noteId: string) => {
  return httpClient.delete(`${API_URL}/${noteId}`);
};

export default {
  getNotes,
  createNote,
  updateNote,
  deleteNote,
}; 
