import React, { useMemo } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Divider, alpha } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import BadgeIcon from '@mui/icons-material/Badge';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FlagIcon from '@mui/icons-material/Flag';
import SourceIcon from '@mui/icons-material/Source';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import StarIcon from '@mui/icons-material/Star';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleIcon from '@mui/icons-material/People';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateIcon from '@mui/icons-material/Update';
import BlockIcon from '@mui/icons-material/Block';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StorageIcon from '@mui/icons-material/Storage';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import ScoreIcon from '@mui/icons-material/Score';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilterOption, FilterId } from 'src/components/Contacts/types';

interface FilterSelectorProps {
  onFilterSelect: (option: FilterOption | null) => void;
  selectedFilters: FilterId[];
  searchQuery?: string;
}

const FilterSelector: React.FC<FilterSelectorProps> = ({ onFilterSelect, selectedFilters, searchQuery = '' }) => {
  // Define all available filter options inside useMemo to avoid dependency changes
  const filterOptions: FilterOption[] = useMemo(() => [
    // Details group
    { id: 'name', label: 'Name', group: 'Details', icon: <PersonIcon fontSize="small" /> },
    { id: 'first_name', label: 'First Name', group: 'Details', icon: <TextFieldsIcon fontSize="small" /> },
    { id: 'last_name', label: 'Last Name', group: 'Details', icon: <TextFieldsIcon fontSize="small" /> },
    { id: 'phone_number', label: 'Phone', group: 'Details', icon: <PhoneIcon fontSize="small" /> },
    { id: 'email', label: 'Email Contains', group: 'Details', icon: <EmailIcon fontSize="small" /> },
    { id: 'email_not_contains', label: 'Email Excludes', group: 'Details', icon: <MailOutlineIcon fontSize="small" /> },
    { id: 'address', label: 'Address', group: 'Details', icon: <HomeIcon fontSize="small" /> },
    { id: 'tags', label: 'Tags Contains', group: 'Details', icon: <LocalOfferIcon fontSize="small" /> },
    { id: 'tags_not_contains', label: 'Tags Excludes', group: 'Details', icon: <LocalOfferIcon fontSize="small" /> },
    { id: 'stage', label: 'Stage', group: 'Details', icon: <FlagIcon fontSize="small" /> },
    { id: 'source', label: 'Source', group: 'Details', icon: <SourceIcon fontSize="small" /> },
    { id: 'without_phone_number', label: 'Without Phone', group: 'Details', icon: <PhoneDisabledIcon fontSize="small" /> },
    { id: 'without_email', label: 'Without Email', group: 'Details', icon: <MailOutlineIcon fontSize="small" /> },
    { id: 'favorite', label: 'Favorite', group: 'Details', icon: <StarIcon fontSize="small" /> },
    
    // Company group
    { id: 'company_name', label: 'Company Name', group: 'Company', icon: <BusinessIcon fontSize="small" /> },
    { id: 'company_size', label: 'Company Size', group: 'Company', icon: <PeopleIcon fontSize="small" /> },
    { id: 'company_industry', label: 'Company Industry', group: 'Company', icon: <BusinessCenterIcon fontSize="small" /> },
    { id: 'company_website', label: 'Company Website', group: 'Company', icon: <LanguageIcon fontSize="small" /> },
    { id: 'company_address', label: 'Company Address', group: 'Company', icon: <LocationOnIcon fontSize="small" /> },
    
    // Activity group
    { id: 'created_at_before', label: 'Created Before', group: 'Activity', icon: <CalendarTodayIcon fontSize="small" /> },
    { id: 'created_at_after', label: 'Created After', group: 'Activity', icon: <CalendarTodayIcon fontSize="small" /> },
    { id: 'updated_at_before', label: 'Updated Before', group: 'Activity', icon: <UpdateIcon fontSize="small" /> },
    { id: 'updated_at_after', label: 'Updated After', group: 'Activity', icon: <UpdateIcon fontSize="small" /> },
    { id: 'inactive', label: 'Inactive', group: 'Activity', icon: <BlockIcon fontSize="small" /> },
    { id: 'next_task', label: 'Next Task', group: 'Activity', icon: <AssignmentIcon fontSize="small" /> },
    { id: 'last_activity', label: 'Last Activity', group: 'Activity', icon: <NotificationsActiveIcon fontSize="small" /> },
    { id: 'last_communication', label: 'Last Communication', group: 'Activity', icon: <AccessTimeIcon fontSize="small" /> },
    { id: 'timeframe', label: 'Timeframe', group: 'Activity', icon: <DateRangeIcon fontSize="small" /> },
    
    // Advanced group
    { id: 'metadata_contains', label: 'Metadata Contains', group: 'Advanced', icon: <StorageIcon fontSize="small" /> },
    { id: 'metadata_not_contains', label: 'Metadata Excludes', group: 'Advanced', icon: <StorageIcon fontSize="small" /> },
    { id: 'has_no_conversations', label: 'No Conversations', group: 'Advanced', icon: <NotListedLocationIcon fontSize="small" /> },
    { id: 'score', label: 'Score', group: 'Advanced', icon: <ScoreIcon fontSize="small" /> },
    { id: 'limit', label: 'Limit Results', group: 'Advanced', icon: <FormatListNumberedIcon fontSize="small" /> },
  ], []);

  // Filter options based on search query
  const filteredOptions = useMemo(() => {
    if (!searchQuery) return filterOptions;
    
    const lowerQuery = searchQuery.toLowerCase();
    return filterOptions.filter(option => 
      option.label.toLowerCase().includes(lowerQuery) || 
      option.group.toLowerCase().includes(lowerQuery)
    );
  }, [searchQuery, filterOptions]);

  // Group options by category
  const groupedOptions = useMemo(() => {
    const grouped: Record<string, FilterOption[]> = {};
    
    filteredOptions.forEach(option => {
      if (!grouped[option.group]) {
        grouped[option.group] = [];
      }
      grouped[option.group].push(option);
    });
    
    return grouped;
  }, [filteredOptions]);

  // Get icon for group
  const getGroupIcon = (group: string) => {
    switch (group) {
      case 'Details':
        return <PersonIcon />;
      case 'Company':
        return <BusinessIcon />;
      case 'Activity':
        return <DateRangeIcon />;
      case 'Advanced':
        return <SettingsIcon />;
      default:
        return <PersonIcon />;
    }
  };

  const handleSelectFilter = (option: FilterOption) => {
    if (!selectedFilters.includes(option.id)) {
      onFilterSelect(option);
    }
  };

  // Get color for group
  const getGroupColor = (group: string, theme: any) => {
    switch (group) {
      case 'Details':
        return theme.palette.primary.main;
      case 'Company':
        return theme.palette.success.main;
      case 'Activity':
        return theme.palette.info.main;
      case 'Advanced':
        return theme.palette.warning.main;
      default:
        return theme.palette.grey[500];
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {Object.keys(groupedOptions).length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 4, color: 'text.secondary' }}>
          <Typography variant="body1">No matching filters found</Typography>
        </Box>
      ) : (
        Object.entries(groupedOptions).map(([group, options]) => (
          <React.Fragment key={group}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              p: 1, 
              pl: 2,
              mt: 1,
              color: theme => getGroupColor(group, theme)
            }}>
              <Box sx={{ mr: 1 }}>
                {getGroupIcon(group)}
              </Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {group}
              </Typography>
            </Box>
            
            <List dense sx={{ pt: 0, pb: 1 }}>
              {options.map((option) => (
                <ListItem 
                  key={option.id}
                  disablePadding 
                  disabled={selectedFilters.includes(option.id)}
                  sx={{ 
                    opacity: selectedFilters.includes(option.id) ? 0.5 : 1,
                    transition: 'all 0.2s ease',
                    mb: 0.5,
                  }}
                >
                  <ListItemButton 
                    onClick={() => handleSelectFilter(option)}
                    disabled={selectedFilters.includes(option.id)}
                    sx={{
                      borderRadius: 1,
                      mx: 1,
                      py: 1,
                      '&:hover': {
                        bgcolor: theme => 
                          selectedFilters.includes(option.id)
                            ? undefined
                            : alpha(getGroupColor(group, theme), 0.1),
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 48, color: 'inherit' }}>
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText 
                      primary={option.label} 
                      primaryTypographyProps={{ 
                        fontWeight: 500,
                        variant: 'body2',
                        pl: 0.5,
                      }} 
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            
            <Divider sx={{ my: 1 }} />
          </React.Fragment>
        ))
      )}
    </Box>
  );
};

export default FilterSelector; 
