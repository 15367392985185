import React, { useState, useEffect } from 'react';
// import Autocomplete from '@mui/material/Autocomplete';
import useDebounce from 'src/hooks/useDebounce';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import PhoneNumberSelectDialog from './PhoneNumberSelectDialog';
import PhoneNumberPurchaseDialog from './PhoneNumberPurchaseDialog';
import { useAppSelector } from 'src/store';
import { useSnackbar } from 'notistack';

// Define CountrySelect inline rather than importing it
const CountrySelect = ({ onChange }) => {
  const allowedCountries = [
    { code: "US", label: "United States" },
    { code: "CA", label: "Canada" },
    { code: "ES", label: "Spain" },
    { code: "FR", label: "France" }
  ];
  const [selectedCountry, setSelectedCountry] = useState("US");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedCountry(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div style={{ display: 'inline-block', marginRight: 8 }}>
      <label style={{ marginRight: 4 }}>Country:</label>
      <select value={selectedCountry} onChange={handleChange}>
        {allowedCountries.map((option) => (
          <option key={option.code} value={option.code}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const PhoneNumberBrowserDialog = ({ openDialog, handleCloseDialog, channelId }) => {
  const { channel, loading, error } = useAppSelector((state) => state.channels.channels.find((channel) => channel.id === channelId))
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loadingPhoneNumbers, setLoadingPhoneNumbers] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const debouncedAreaCode = useDebounce(areaCode, 1000);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [selectPhoneDialogOpen, setSelectPhoneDialogOpen] = useState(openDialog);
  const [phonePurchaseDialogOpen, setPhonePurchaseDialogOpen] = useState(false);
  const [subAccountStatus, setSubAccountStatus] = useState(null);
  const [a2pApproved, setA2pApproved] = useState(null);
  const [country, setCountry] = useState('US');
  const debouncedCountry = useDebounce(country, 1000);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectPhoneDialogOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  useEffect(() => {
    if ((!debouncedSearchTerm && !debouncedAreaCode && !debouncedCountry)
      || (debouncedAreaCode && debouncedAreaCode.length < 3)) {
      return;
    }
    fetchPhoneNumbers();
  }, [debouncedSearchTerm, debouncedAreaCode, debouncedCountry]);

  const fetchPhoneNumbers = () => {
    setLoadingPhoneNumbers(true);
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.available_phone_numbers}`
    httpClient.get(url, {
      params: {
        searchTerm: debouncedSearchTerm,
        areaCode: debouncedAreaCode,
        country: debouncedCountry,
      },
    })
      .then((response) => {
        setPhoneNumbers(response.data.phone_numbers);
        setSubAccountStatus(response.data.sub_account?.status);
        setA2pApproved(response.data.messaging_service_a2p_approved);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(
          error?.response?.data?.message ||
          'An error occurred while fetching phone numbers.',
          { variant: 'error' }
        );
      })
      .finally(() => {
        setLoadingPhoneNumbers(false);
      });
  }

  const handlePurchasePhoneNumber = () => {
    if (!selectedPhoneNumber || channel.twilio_phone_number) return;
    setPurchaseLoading(true);
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.phone_purchase_success}`
    httpClient.post(url, {
      ...selectedPhoneNumber,
      "channel_id": channel.embed_key,
      "agreedToTerms": true,
    }).then(response => {
      if (response.data.success) {
        enqueueSnackbar("Phone number purchased and assigned to your chat channel!", { variant: 'success' });
        setTimeout(() => {
          handleCloseDialog();
          window.location.reload();
        }, 3000);
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    }).catch(error => {
      console.error(error);
      enqueueSnackbar("An error occurred while making the request.", { variant: 'error' });
    })
      .finally(() => {
        setPurchaseLoading(false);
      });
  };

  return (
    <>
      <PhoneNumberSelectDialog
        open={selectPhoneDialogOpen}
        onClose={handleCloseDialog}
        onPurchaseClick={(phoneRow) => {
          setPhonePurchaseDialogOpen(true)
          setSelectPhoneDialogOpen(false)
          setSelectedPhoneNumber(phoneRow)
        }}
        purchaseLoading={purchaseLoading}
        loadingPhoneNumbers={loadingPhoneNumbers}
        phoneNumbers={phoneNumbers}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        areaCode={areaCode}
        setAreaCode={setAreaCode}
        country={country}
        setCountry={setCountry}
        subAccountStatus={subAccountStatus}
        a2pApproved={a2pApproved}
        channelId={channelId}
      />
      {phonePurchaseDialogOpen &&
        <PhoneNumberPurchaseDialog
          isOpen={phonePurchaseDialogOpen}
          onClose={() => {
            setPhonePurchaseDialogOpen(false);
            setSelectPhoneDialogOpen(true);
          }}
          handlePurchasePhoneNumber={handlePurchasePhoneNumber}
          subAccountStatus={subAccountStatus}
          a2pApproved={a2pApproved}
          channelId={channelId}
        />
      }
    </>
  );
};

export default PhoneNumberBrowserDialog;
