import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';

const update = (data) => {
  return httpClient.put(`${API_ROUTES.message}/${data.messageId}`, { content: data.content, channel_id: data.channelId });
};

const destroy = (data) => {
  return httpClient.delete(`${API_ROUTES.message}/${data.messageId}`, { params: { channel_id: data.channelId } });
};

const deliver = (data) => {
  return httpClient.post(`${API_ROUTES.message}/deliver_message`, { message_id: data.messageId, channel_id: data.channelId });
};

const force_deliver_now = (data) => {
  return httpClient.post(`${process.env.REACT_APP_BASE_URL}/comms-api/messages/${data.messageId}/deliver_now`, { channel_id: data.channelId });
};

const post_message = (data) => {
  return httpClient.post(`${API_ROUTES.message}/post_message`, data);
}

const get = (data) => {
  return httpClient.get(`${API_ROUTES.message}/${data.messageId}`, { params: { channel_id: data.channelId } });
}

const MessagesService = {
  update,
  destroy,
  deliver,
  force_deliver_now,
  post_message,
  get
};

export default MessagesService;
