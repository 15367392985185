// IntegrationsButton component using MUI components
import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle'; // Use AddCircle instead of PlusCircle
import DeleteIcon from '@mui/icons-material/Delete'; // Add DeleteIcon import
import { useSnackbar } from 'notistack';
import { httpClient } from 'src/libs';
import { getUser } from 'src/features/user/user.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import GoogleCalendarDialog from './GoogleCalendarDialog';
import { GoogleAuthorization } from 'src/libs/googleAuthorization';

const ICONS = {
  fub: "https://res.cloudinary.com/kw/image/upload/v1729703593/oppy/fub_icon.svg",
  gcal: "https://res.cloudinary.com/kw/image/upload/v1729703592/oppy/gcal_icon.svg",
  cloze: "https://res.cloudinary.com/kw/image/upload/v1729703593/oppy/cloze_icon.svg"
};

import { Box } from '@mui/system';
import useGoogleAuthV2 from 'src/hooks/useGoogleAuthV2'; // Import the hook

const IntegrationsDialog = ({ open = false, onClose }) => {
  const [openDialog, setOpenDialog] = React.useState(open);
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const integrationOpen = urlParams.get('integration_open');

  // Sync openDialog state with open prop
  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);


  const [openFUBDialog, setOpenFUBDialog] = React.useState(false);
  const [openRequestDialog, setOpenRequestDialog] = React.useState(false);
  const [apiKey, setApiKey] = React.useState('');
  const [requestText, setRequestText] = React.useState('');
  const { user, userCalendar } = useAppSelector((state) => state.user);
  const { isConnecting, userAuthorization } = useGoogleAuthV2();

  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Destructure closeSnackbar
  const [openGoogleCalendarDialog, setOpenGoogleCalendarDialog] = useState(integrationOpen === 'google_calendar');

  const [error, setError] = useState(null); // Add error state

  // Close main integrations dialog
  const handleCloseDialog = () => {
    console.log('Closing integrations dialog');
    setOpenDialog(false);
    if (onClose) onClose(); // Call onClose prop to notify parent
  };

  // Helper function to check if integration is enabled
  const isIntegrationEnabled = (integration) => {
    return user?.account?.integrations?.includes(integration);
  };

  // Helper function to get API key for a scope
  const getApiKey = (scope) => {
    return user?.account?.integrations_api_keys_list?.find(key => key.scope === scope);
  };

  // Submit Follow Up Boss API key
  const handleFUBSubmit = async () => {
    if (!apiKey.trim()) {
      enqueueSnackbar('Please enter a valid API key', { variant: 'error' });
      return;
    }

    try {
      setIsLoading(true);
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_V3_URL}/private/api_keys/follow_up_boss`,
        {}, // Empty body
        {
          headers: {
            'X-Fub-Api-Key': apiKey,
          },
        }
      );
      
      if (response.data.success) {
        enqueueSnackbar('Follow Up Boss API key added successfully', { variant: 'success' });
        setApiKey('');
        dispatch(getUser());
        setOpenFUBDialog(false);
        // Refresh user data or update local state
      } else {
        throw new Error(response.data.error || 'Failed to add API key');
      }
    } catch (error) {
      console.error('Error adding FUB key:', error);
      enqueueSnackbar(error.message || 'Failed to add API key', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Delete Follow Up Boss API key
  const handleDeleteFUBKey = async () => {
    if (!window.confirm('Are you sure you want to delete this API key? This will disable the Follow Up Boss integration.')) {
      return;
    }

    const apiKey = getApiKey('follow_up_boss');
    if (!apiKey?.id) return;

    try {
      setIsLoading(true);
      const response = await httpClient.delete(
        `${process.env.REACT_APP_API_V3_URL}/private/api_keys/follow_up_boss/${apiKey.id}`
      );
      
      if (response.data.success) {
        enqueueSnackbar('Follow Up Boss API key deletion pending, please wait a moment and refresh the page', { variant: 'success' });
        // Refresh user data or update local state
        dispatch(getUser());
        setApiKey('');
        setOpenFUBDialog(false);
      } else {
        throw new Error(response.data.error || 'Failed to delete API key');
      }
    } catch (error) {
      console.error('Error deleting FUB key:', error);
      enqueueSnackbar(error.message || 'Failed to delete API key', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Submit integration request via email
  const handleRequestSubmit = () => {
    console.log('Submitting integration request:', requestText);
    window.location.href = `mailto:integrationrequest@oppy.pro?subject=Integration Request&body=${encodeURIComponent(requestText)}`;
    setRequestText('');
    setOpenRequestDialog(false);
  };

  // Handle Cloze integration request
  const handleClozeRequest = () => {
    console.log('Requesting Cloze integration');
    const emailSubject = "Interest in Cloze Integration";
    const emailBody = "Hello,\n\nI'm interested in the upcoming Cloze integration with Oppy. Please notify me when this integration becomes available.\n\nBest regards";
    window.location.href = `mailto:integrationrequest@oppy.pro?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
  };

  const handleGoogleCalendarClick = (event) => {
    if (!user.google_calendar_connected && userCalendar.length === 0) {
      setOpenGoogleCalendarDialog(false);
      userAuthorization(event); // Use the hook's authorization method
    } else {
      setOpenGoogleCalendarDialog(true);
    }
  };

  return (
    <div>
      {/* Display error if exists */}
      {error && <Alert severity="error">There was an error connecting calendar: {error.message}</Alert>}
      
      {/* Main integrations dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Available Integrations</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Follow Up Boss Integration */}
            <Grid item xs={6}>
              <Card>
                <CardActionArea onClick={() => setOpenFUBDialog(true)}>
                  <CardContent>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: 100, position: 'relative' }}>
                      <img src={ICONS.fub} alt="Follow Up Boss" style={{ height: 64, width: 104 }} />
                      {isIntegrationEnabled('follow_up_boss') && (
                        <Alert 
                          severity="success" 
                          sx={{ 
                            padding: '0px 8px',
                            mt: 1,
                            mb: 1,
                            '& .MuiAlert-message': { 
                              padding: '2px',
                              display: 'flex',
                              alignItems: 'center',
                              minHeight: '24px'
                            },
                            '& .MuiAlert-icon': {
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '8px',
                              padding: '0'
                            }
                          }}
                        >
                        Enabled
                        </Alert>
                      )}
                    </Box>
                    <Typography variant="h6" align="center">Follow Up Boss</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/* Google Calendar Integration */}
            <Grid item xs={6}>
              <Card>
                <CardActionArea onClick={handleGoogleCalendarClick}>
                  <CardContent>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: 100, position: 'relative' }}>
                      <img src={ICONS.gcal} alt="Google Calendar" style={{ height: 54, width: 64 }} />
                      {user?.google_calendar_connected && (
                        <Alert 
                          severity="success" 
                          sx={{ 
                            padding: '0px 8px',
                            mt: 1.5,
                            mb: 1,
                            '& .MuiAlert-message': { 
                              padding: '2px',
                              display: 'flex',
                              alignItems: 'center',
                              minHeight: '24px'
                            },
                            '& .MuiAlert-icon': {
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '8px',
                              padding: '0'
                            }
                          }}
                        >
                          Enabled
                        </Alert>
                      )}
                    </Box>
                    <Typography variant="h6" align="center">Google Calendar</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/* Cloze Integration (Coming Soon) */}
            <Grid item xs={6}>
              <Card>
                <CardActionArea onClick={handleClozeRequest} style={{ opacity: 0.75 }}>
                  <CardContent>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: 100, position: 'relative' }}>
                      <img src={ICONS.cloze} alt="Cloze" style={{ height: 64, width: 114 }} />
                    </Box>
                    <Typography variant="h6" align="center">Cloze</Typography>
                    <Typography variant="caption" align="center" display="block">Coming Soon</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/* Request Integration */}
            <Grid item xs={6}>
              <Card>
                <CardActionArea onClick={() => {
                  console.log('Opening integration request dialog');
                  setOpenRequestDialog(true);
                }}>
                  <CardContent>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                      <AddCircle style={{ height: 64, width: 64, color: 'gray' }} />
                    </Box>
                    <Typography variant="h6" align="center">Request Integration</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Follow Up Boss API Key Dialog */}
      <Dialog open={openFUBDialog} onClose={() => setOpenFUBDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Follow Up Boss Integration</DialogTitle>
        <DialogContent>
          <Typography>This integration will:</Typography>
          <ul>
            <li>Sync all contacts between Follow Up Boss and Oppy</li>
            <li>Keep contact details up to date automatically</li>
            <li>Maintain consistent notes and events</li>
          </ul>
          {/* <a href="#" style={{ color: 'blue', textDecoration: 'underline' }}>Learn more about this integration</a> */}
          
          {getApiKey('follow_up_boss') ? (
            <>
              <TextField
                label="Your Follow Up Boss API Key"
                variant="outlined"
                fullWidth
                disabled
                value={getApiKey('follow_up_boss').key}
                style={{ marginTop: 16 }}
                InputProps={{
                  endAdornment: (
                    <IconButton 
                      onClick={handleDeleteFUBKey}
                      edge="end"
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  ),
                }}
              />
              <Alert severity="info" sx={{ mt: 2 }}>
                <AlertTitle>Integration Active</AlertTitle>
                Your Follow Up Boss integration is currently enabled and syncing contacts. Webhooks are ${user?.account?.metadata?.fub_webhooks_enabled ? 'enabled' : 'disabled'}.
              </Alert>
            </>
          ) : (
            <TextField
              label="Enter your API Key"
              variant="outlined"
              fullWidth
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              style={{ marginTop: 16 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFUBDialog(false)}>Close</Button>
          {!getApiKey('follow_up_boss') && (
            <Button 
              onClick={handleFUBSubmit} 
              variant="contained" 
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? 'Connecting...' : 'Connect'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Request Integration Dialog */}
      <Dialog open={openRequestDialog} onClose={() => {
        console.log('Closing integration request dialog');
        setOpenRequestDialog(false);
      }} maxWidth="sm" fullWidth>
        <DialogTitle>Request an Integration</DialogTitle>
        <DialogContent>
          <Typography>Describe the integration you'd like to see next</Typography>
          <TextField
            label="Integration Request"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={requestText}
            onChange={(e) => setRequestText(e.target.value)}
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            console.log('Canceled integration request');
            setOpenRequestDialog(false);
          }}>Close</Button>
          <Button onClick={handleRequestSubmit} variant="contained" color="primary">Submit Request</Button>
        </DialogActions>
      </Dialog>

      <GoogleCalendarDialog
        open={openGoogleCalendarDialog}
        onClose={() => setOpenGoogleCalendarDialog(false)}
      />
    </div>
  );
};

export default IntegrationsDialog;
