import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

interface NotificationsState {
  notificationsEnabled: boolean;
}

const initialState: NotificationsState = {
  notificationsEnabled: true
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    disableNotifications: (state) => {
      state.notificationsEnabled = false;
    },
    enableNotifications: (state) => {
      state.notificationsEnabled = true;
    }
  }
});

export const { disableNotifications, enableNotifications } = notificationsSlice.actions;

export const selectNotificationsEnabled = (state: RootState) => state.notifications.notificationsEnabled;

export default notificationsSlice.reducer; 
