import { useState, FC, KeyboardEvent, useEffect } from 'react';
import { Box, TextField, Button, Typography, CircularProgress, Stack, Alert, Select, MenuItem, FormControl, InputLabel, Chip, Switch, FormControlLabel, Paper, Avatar, IconButton, Link } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import {
    Google as GoogleIcon,
    BusinessCenter as BusinessIcon,
    EmojiEmotions as FriendlyIcon,
    Coffee as CasualIcon,
    Speed as DirectIcon,
    Add as AddIcon,
    Send as SendIcon
} from '@mui/icons-material';
import getRandomName from '../Channels/RandomDefaultName';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { useSnackbar } from 'notistack';
import { httpClient } from '../../libs';
import { API_ROUTES } from '../../constants/routes';
import { trackAnalytics } from '../../features/analytics/analytics.slice';
import searchService from '../../services/search.service';
import useGoogleAuthV2 from '../../hooks/useGoogleAuthV2';
import AuthService from 'src/services/auth.service';

// Styled components for form steps
const StepContainer = styled(motion.div)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
        backdropFilter: 'blur(8px)',
        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const Message = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    maxWidth: '80%',
    marginBottom: theme.spacing(2),
}));

const AIMessage = styled(Message)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(73, 73, 200, 0.1)' : 'rgba(73, 73, 200, 0.05)',
    marginRight: 'auto',
    display: 'flex',
    gap: theme.spacing(2),
}));

const UserMessage = styled(Message)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: 'auto',
}));

interface StepProps {
    formData: {
        businessUrl: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        otp?: string;
        calendarConnected: boolean;
        crmType?: 'followupboss' | 'cloze' | 'other';
        crmApiKey?: string;
        interests: string[];
        aiAssistantConfig: {
            name: string;
            role: string;
            personality: string;
            use_emojis: boolean;
            goals: string[];
            chatbot_style: string;
            company_name: string;
            knowledge_base: string;
        };
    };
    onUpdate: (field: string, value: any) => void;
    onNext: (embedKey?: string | null) => void;
    onPrev?: () => void;
    loading?: boolean;
    onSearchResults?: (results: any[]) => void;
    embedKey?: string | null;
}

const stepVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 }
};

const handleKeyPress = (
    e: KeyboardEvent<HTMLDivElement>,
    value: string,
    loading: boolean | undefined,
    onNext: (embedKey?: string | null) => void
) => {
    if (e.key === 'Enter' && value && !loading) {
        e.preventDefault();
        onNext();
    }
};

export const BusinessUrlStep: FC<StepProps> = ({ formData, onUpdate, onNext, loading, onSearchResults }) => {
    const [error, setError] = useState<string | null>(null);

    const validateUrl = (url: string) => {
        // URL regex pattern
        const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
        return urlPattern.test(url);
    };

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = e.target.value;
        onUpdate('businessUrl', url);

        if (url && !validateUrl(url)) {
            setError('Please enter a valid URL');
        } else {
            setError(null);
        }
    };

    const handleNext = async () => {
        if (!formData.businessUrl || loading) return;

        if (!validateUrl(formData.businessUrl)) {
            setError('Please enter a valid URL');
            return;
        }

        try {
            // Make the search request here
            const response = await searchService.search(formData.businessUrl);

            // Pass search results to parent component
            if (onSearchResults) {
                onSearchResults(response.chunks);
            }

            // Proceed to next step regardless of search result
            onNext();
        } catch (error) {
            console.error('Search error:', error);
            // Still proceed to next step even if search fails
            onNext();
        }
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Let's start with your business website</Typography>
            <StyledTextField
                fullWidth
                label="Business URL"
                value={formData.businessUrl}
                onChange={handleUrlChange}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && formData.businessUrl && !loading && !error) {
                        e.preventDefault();
                        handleNext();
                    }
                }}
                placeholder="https://yourbusiness.com"
                helperText={error || "Enter your business website URL"}
                error={!!error}
            />
            <Button
                variant="contained"
                onClick={handleNext}
                disabled={!formData.businessUrl || loading || !!error}
                endIcon={loading && <CircularProgress size={20} />}
                fullWidth
            >
                Next
            </Button>
        </StepContainer>
    );
};

export const FullNameStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => (
    <StepContainer
        variants={stepVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.3 }}
    >
        <Typography variant="h6">What's your name?</Typography>
        <Stack spacing={2}>
            <StyledTextField
                fullWidth
                label="First Name"
                value={formData.firstName}
                onChange={(e) => onUpdate('firstName', e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, formData.firstName, loading, onNext)}
                placeholder="John"
                helperText="Enter your first name"
            />
            <StyledTextField
                fullWidth
                label="Last Name"
                value={formData.lastName}
                onChange={(e) => onUpdate('lastName', e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, formData.lastName, loading, onNext)}
                placeholder="Doe"
                helperText="Enter your last name"
            />
        </Stack>
        <ButtonContainer>
            <Button
                variant="outlined"
                onClick={onPrev}
                disabled={loading}
                fullWidth
            >
                Back
            </Button>
            <Button
                variant="contained"
                onClick={(e) => onNext()}
                disabled={!formData.firstName || !formData.lastName || loading}
                endIcon={loading && <CircularProgress size={20} />}
                fullWidth
            >
                Continue
            </Button>
        </ButtonContainer>
    </StepContainer>
);

export const PhoneNumberStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => (
    <StepContainer
        variants={stepVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.3 }}
    >
        <Typography variant="h6">What's your phone number?</Typography>
        <StyledTextField
            fullWidth
            label="Phone Number"
            value={formData.phoneNumber}
            onChange={(e) => onUpdate('phoneNumber', e.target.value)}
            onKeyPress={(e) => handleKeyPress(e, formData.phoneNumber, loading, onNext)}
            placeholder="+1 (555) 555-5555"
            helperText="Enter your phone number for verification"
        />
        <ButtonContainer>
            <Button
                variant="outlined"
                onClick={onPrev}
                disabled={loading}
                fullWidth
            >
                Back
            </Button>
            <Button
                variant="contained"
                onClick={(e) => onNext()}
                disabled={!formData.phoneNumber || loading}
                endIcon={loading && <CircularProgress size={20} />}
                fullWidth
            >
                Verify Phone Number
            </Button>
        </ButtonContainer>
    </StepContainer>
);

export const EmailStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const [error, setError] = useState<string | null>(null);

    const validateEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        onUpdate('email', email);

        if (email && !validateEmail(email)) {
            setError('Please enter a valid email address');
        } else {
            setError(null);
        }
    };

    const handleNext = () => {
        if (validateEmail(formData.email)) {
            onNext();
        } else {
            setError('Please enter a valid email address');
        }
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">What's your email address?</Typography>
            <StyledTextField
                fullWidth
                type="email"
                label="Email"
                value={formData.email}
                onChange={handleEmailChange}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && formData.email && !error && !loading) {
                        e.preventDefault();
                        handleNext();
                    }
                }}
                placeholder="you@example.com"
                helperText={error || "Enter your email address"}
                error={!!error}
            />
            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => onNext()}
                    disabled={!formData.email || !!error || loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Continue
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

export const OtpStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const [timeLeft, setTimeLeft] = useState(10);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (timeLeft === 0) return;

        const timer = setInterval(() => {
            setTimeLeft(prev => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const handleResendCode = async () => {
        try {
            await AuthService.sendSms(formData.phoneNumber);
            setTimeLeft(10);
            setError(null);
        } catch (err) {
            setError('Failed to send verification code. Please try again.');
        }
    };

    const handleVerifyCode = async (code: string) => {
        try {
            const response = await AuthService.validateSms(formData.phoneNumber, code);
            onUpdate('otp', code);
            onUpdate('phone_verification', response.data.token);
            onNext();
        } catch (err) {
            setError('Invalid verification code. Please try again.');
        }
    };

    // Send initial SMS when component mounts
    useEffect(() => {
        AuthService.sendSms(formData.phoneNumber).catch(() => {
            setError('Failed to send verification code. Please try again.');
        });
    }, []);

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Enter verification code</Typography>
            <Typography variant="body2" color="text.secondary">
                We sent a verification code to {formData.phoneNumber}
            </Typography>

            <Stack spacing={2}>
                <StyledTextField
                    fullWidth
                    label="Verification Code"
                    value={formData.otp || ''}
                    onChange={(e) => {
                        const code = e.target.value;
                        onUpdate('otp', code);
                        if (code.length === 6) {
                            handleVerifyCode(code);
                        }
                    }}
                    placeholder="Enter 6-digit code"
                    inputProps={{ maxLength: 6 }}
                    error={!!error}
                    helperText={error}
                />

                {timeLeft > 0 ? (
                    <Typography variant="body2" color="text.secondary" align="center">
                        Resend code in {timeLeft}s
                    </Typography>
                ) : (
                    <Button
                        variant="text"
                        onClick={handleResendCode}
                        disabled={loading}
                    >
                        Resend Code
                    </Button>
                )}
            </Stack>

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => handleVerifyCode(formData.otp || '')}
                    disabled={!formData.otp || formData.otp.length !== 6 || loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Verify
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

export const GoogleCalendarStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const { user, userCalendar } = useAppSelector((state) => state.user);
    const { isConnecting, userAuthorization } = useGoogleAuthV2();
    const [error, setError] = useState<Error | null>(null);
    const [searchParams] = useSearchParams();
    const integrationOpen = searchParams.get('integration_open');

    useEffect(() => {
        // If returning from Google auth and calendar is connected, proceed
        if (integrationOpen === 'google_calendar' && user?.google_calendar_connected) {
            onUpdate('calendarConnected', true);
            onNext();
        }
    }, [integrationOpen, user?.google_calendar_connected]);

    const handleGoogleCalendarConnect = (event: React.MouseEvent) => {
        // Check if user exists and if calendar is not already connected
        if (!user?.google_calendar_connected && (!userCalendar || userCalendar.length === 0)) {
            userAuthorization(event, true);
        }
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Connect your Google Calendar</Typography>
            <Typography variant="body1" color="text.secondary">
                This allows your Oppy to manage your schedule and set up meetings automatically.
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    There was an error connecting calendar: {error.message}
                </Alert>
            )}

            {user?.google_calendar_connected ? (
                <Alert
                    severity="success"
                    sx={{ mb: 2 }}
                >
                    Google Calendar successfully connected!
                </Alert>
            ) : (
                <Button
                    variant="contained"
                    onClick={handleGoogleCalendarConnect}
                    disabled={isConnecting || !user} // Disable if user is not loaded
                    startIcon={<GoogleIcon />}
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    {isConnecting ? 'Connecting...' : 'Connect Google Calendar'}
                </Button>
            )}

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => onNext()}
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    {integrationOpen === 'google_calendar' || user?.google_calendar_connected ? 'Next' : 'Skip'}
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

export const CrmSyncStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const handleCrmTypeChange = (event: any) => {
        onUpdate('crmType', event.target.value);
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Connect your CRM</Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Sync your contacts and leads with your preferred CRM system
            </Typography>

            <FormControl fullWidth>
                <InputLabel>Select CRM</InputLabel>
                <Select
                    value={formData.crmType || ''}
                    onChange={handleCrmTypeChange}
                    label="Select CRM"
                >
                    <MenuItem value="followupboss">Follow Up Boss</MenuItem>
                    <MenuItem value="cloze">Cloze</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl>

            {formData.crmType && (
                <StyledTextField
                    fullWidth
                    label="API Key"
                    value={formData.crmApiKey || ''}
                    onChange={(e) => onUpdate('crmApiKey', e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, formData.crmApiKey || '', loading, onNext)}
                    placeholder="Enter your CRM API key"
                    type="password"
                />
            )}

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => onNext()}
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    {formData.crmType ? 'Connect' : 'Skip'}
                </Button>
            </ButtonContainer>

            <Typography variant="body2" color="text.secondary" align="center">
                You can always connect your CRM later
            </Typography>
        </StepContainer>
    );
};

const SUGGESTED_INTERESTS = [
    'Real Estate',
    'Marketing',
    'Sales',
    'Customer Service',
    'Lead Generation',
    'Appointment Setting',
    'Property Management',
    'Market Analysis',
    'Networking',
    'Business Development',
    'Social Media',
    'Email Marketing'
];

export const InterestsStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const [newInterest, setNewInterest] = useState('');

    const handleAddInterest = (interest: string) => {
        if (!formData.interests.includes(interest)) {
            onUpdate('interests', [...formData.interests, interest]);
        }
        setNewInterest('');
    };

    const handleRemoveInterest = (interest: string) => {
        onUpdate('interests', formData.interests.filter(i => i !== interest));
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && newInterest.trim()) {
            e.preventDefault();
            handleAddInterest(newInterest.trim());
        }
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Select your interests</Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Choose topics that interest you to help us personalize your Oppy
            </Typography>

            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Your Interests
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                    {formData.interests.map((interest) => (
                        <Chip
                            key={interest}
                            label={interest}
                            onDelete={() => handleRemoveInterest(interest)}
                            color="primary"
                        />
                    ))}
                </Box>

                <StyledTextField
                    fullWidth
                    label="Add custom interest"
                    value={newInterest}
                    onChange={(e) => setNewInterest(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type and press Enter"
                    InputProps={{
                        endAdornment: (
                            <Button
                                onClick={(e) => newInterest.trim() && handleAddInterest(newInterest.trim())}
                                disabled={!newInterest.trim()}
                                startIcon={<AddIcon />}
                            >
                                Add
                            </Button>
                        ),
                    }}
                />
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Suggested Interests
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {SUGGESTED_INTERESTS.filter(interest => !formData.interests.includes(interest))
                        .map((interest) => (
                            <Chip
                                key={interest}
                                label={interest}
                                onClick={(e) => handleAddInterest(interest)}
                                variant="outlined"
                                color="primary"
                                sx={{ cursor: 'pointer' }}
                            />
                        ))}
                </Box>
            </Box>

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => onNext()}
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Continue
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

export const BusinessCardStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const [sendGreeting, setSendGreeting] = useState(true);
    const [optInPodcast, setOptInPodcast] = useState(false);

    // Mock phone number - will be replaced with backend data later
    const mockPhoneNumber = "+1 (888) 123-4567";

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Customize your Oppy's business card</Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Personalize how your Oppy presents itself to others
            </Typography>

            <Stack spacing={3}>
                <StyledTextField
                    fullWidth
                    label="Oppy Name"
                    value={formData.aiAssistantConfig.name}
                    onChange={(e) => onUpdate('aiAssistantConfig', {
                        ...formData.aiAssistantConfig,
                        name: e.target.value
                    })}
                    placeholder={`${formData.firstName?.split(' ')[0] || 'Your'}'s AI`}
                />

                <StyledTextField
                    fullWidth
                    label="Phone Number"
                    value={mockPhoneNumber}
                    InputProps={{
                        readOnly: true,
                    }}
                    helperText="This is your Oppy's dedicated phone number"
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={sendGreeting}
                            onChange={(e) => setSendGreeting(e.target.checked)}
                        />
                    }
                    label="Send welcome message to my phone"
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={optInPodcast}
                            onChange={(e) => setOptInPodcast(e.target.checked)}
                        />
                    }
                    label="Subscribe to daily morning podcast"
                />
            </Stack>

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => onNext()}
                    disabled={!formData.aiAssistantConfig.name || loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Continue
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

const AI_PERSONALITIES = [
    {
        type: 'Professional',
        description: 'Formal and business-focused communication style',
        icon: BusinessIcon
    },
    {
        type: 'Friendly',
        description: 'Fun and approachable communication style',
        icon: FriendlyIcon
    },
    {
        type: 'Casual',
        description: 'Relaxed and informal communication style',
        icon: CasualIcon
    },
    {
        type: 'Business',
        description: 'Strategic and results-oriented style',
        icon: BusinessIcon
    },
    {
        type: 'Witty',
        description: 'Sharp and focused, building rapport with subtle humor',
        icon: DirectIcon
    },
    {
        type: 'Serious',
        description: 'Direct and no-nonsense communication style',
        icon: DirectIcon
    }
];

export const AiAssistantStep: FC<StepProps> = ({ formData, onUpdate, onNext, onPrev, loading }) => {
    const handlePersonalitySelect = (event: any) => {
        onUpdate('aiAssistantConfig', {
            ...formData.aiAssistantConfig,
            personality: event.target.value
        });
    };

    const handleEmojiToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        onUpdate('aiAssistantConfig', {
            ...formData.aiAssistantConfig,
            use_emojis: event.target.checked
        });
    };

    const handleStyleChange = (event: any) => {
        onUpdate('aiAssistantConfig', {
            ...formData.aiAssistantConfig,
            chatbot_style: event.target.value
        });
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6">Configure your Oppy</Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Customize your Oppy's personality and capabilities
            </Typography>

            <Stack spacing={3}>
                <Box>
                    <Typography variant="subtitle2" gutterBottom>
                        Basic Settings
                    </Typography>
                    <Stack spacing={2}>
                        <StyledTextField
                            required
                            fullWidth
                            label="Assistant Name"
                            value={formData.aiAssistantConfig.name}
                            onChange={(e) => onUpdate('aiAssistantConfig', {
                                ...formData.aiAssistantConfig,
                                name: e.target.value
                            })}
                            InputProps={{
                                endAdornment: (
                                    <Button
                                        onClick={(e) => onUpdate('aiAssistantConfig', {
                                            ...formData.aiAssistantConfig,
                                            name: getRandomName()
                                        })}
                                    >
                                        Random
                                    </Button>
                                ),
                            }}
                            error={!formData.aiAssistantConfig.name}
                            helperText={!formData.aiAssistantConfig.name ? "Assistant name is required" : ""}
                        />

                        <StyledTextField
                            required
                            fullWidth
                            label="Company Name"
                            value={formData.aiAssistantConfig.company_name}
                            onChange={(e) => onUpdate('aiAssistantConfig', {
                                ...formData.aiAssistantConfig,
                                company_name: e.target.value
                            })}
                            error={!formData.aiAssistantConfig.company_name}
                            helperText={!formData.aiAssistantConfig.company_name ? "Company name is required" : ""}
                        />

                        <FormControl required fullWidth error={!formData.aiAssistantConfig.chatbot_style}>
                            <InputLabel>Assistant Style</InputLabel>
                            <Select
                                value={formData.aiAssistantConfig.chatbot_style}
                                onChange={handleStyleChange}
                                label="Assistant Style"
                            >
                                <MenuItem value="1">Light Mode</MenuItem>
                                <MenuItem value="2">Dark Mode</MenuItem>
                                <MenuItem value="3">Disco Mode</MenuItem>
                            </Select>
                            {!formData.aiAssistantConfig.chatbot_style && (
                                <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                    Assistant style is required
                                </Typography>
                            )}
                        </FormControl>
                    </Stack>
                </Box>

                <Box>
                    <Typography variant="subtitle2" gutterBottom>
                        Personality & Communication
                    </Typography>
                    <Stack spacing={2}>
                        <FormControl required fullWidth error={!formData.aiAssistantConfig.personality}>
                            <InputLabel>Personality Type</InputLabel>
                            <Select
                                value={formData.aiAssistantConfig.personality || ''}
                                onChange={handlePersonalitySelect}
                                label="Personality Type"
                            >
                                {AI_PERSONALITIES.map(({ type, description, icon: Icon }) => (
                                    <MenuItem key={type} value={type}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Icon color="primary" />
                                            <Box>
                                                <Typography variant="subtitle1">
                                                    {type}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                            {!formData.aiAssistantConfig.personality && (
                                <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                    Personality type is required
                                </Typography>
                            )}
                        </FormControl>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.aiAssistantConfig.use_emojis}
                                    onChange={handleEmojiToggle}
                                />
                            }
                            label={formData.aiAssistantConfig.use_emojis ? "🙂 Use emojis" : "🤐 Don't use emojis"}
                        />
                    </Stack>
                </Box>

                <Box>
                    <Typography variant="subtitle2" gutterBottom>
                        Instructions (Optional)
                    </Typography>
                    <StyledTextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Teach your assistant"
                        value={formData.aiAssistantConfig.knowledge_base}
                        onChange={(e) => onUpdate('aiAssistantConfig', {
                            ...formData.aiAssistantConfig,
                            knowledge_base: e.target.value
                        })}
                        placeholder="Add any specific information, do's & don'ts, FAQs, or guidelines for your assistant..."
                        helperText="Help your assistant understand your business better"
                    />
                </Box>
            </Stack>

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => onNext()}
                    disabled={!formData.aiAssistantConfig.personality ||
                        !formData.aiAssistantConfig.name ||
                        !formData.aiAssistantConfig.company_name ||
                        !formData.aiAssistantConfig.chatbot_style ||
                        loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Continue
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

export const SetupCompleteStep: FC<StepProps> = ({ formData, onNext, onPrev, loading }) => {
    const [error, setError] = useState<string | null>(null);
    const [embedKey, setEmbedKey] = useState<string | null>(null);
    const { user } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleCreateAssistant = async () => {
        try {
            const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}`;

            // Build system message similar to CreateChannelDialog
            const system_message = `
      # Your Identity: You are an assistant named ${formData.aiAssistantConfig.name} 

      working for ${formData.aiAssistantConfig.company_name}
      
      # Your Personality/Communication Style is: 
      - Very ${formData.aiAssistantConfig.personality}
      ${formData.aiAssistantConfig.use_emojis ? '- You use emojis in your responses' : ''} \n
      ${formData.businessUrl ? `# Business Website URL: ${formData.businessUrl} \n` : ''}
      ${formData.aiAssistantConfig.knowledge_base ? `#Knowledge Base: ${formData.aiAssistantConfig.knowledge_base} \n` : ''}
      After you fully understand the details above - YOU MUST Execute the following goals listed in order of importance while natrually conversing with the user: \n
      ${formData.aiAssistantConfig.goals.map((goal, index) => `#Goal ${index + 1}: ${goal} \n`).join('')}
      Now continue executing your goals in your conversation with the user.`;

            const config = {
                chatbot_name: formData.aiAssistantConfig.name,
                user_appended_string: ``,
                provider_frontend_url: `${process.env.REACT_APP_BASE_URL}`,
                sms_notifications_enabled: false,
                system_message,
                chatbot_style: formData.aiAssistantConfig.chatbot_style,
                fallback_phone_number: "",
                chatbot_personality: formData.aiAssistantConfig.personality,
                use_emojis: formData.aiAssistantConfig.use_emojis,
                goals: formData.aiAssistantConfig.goals,
                pages: [],
                business_website_url: "",
                knowledge_base: formData.aiAssistantConfig.knowledge_base || "",
                company_name: formData.aiAssistantConfig.company_name,
                booking_enabled: user?.google_calendar_connected || false
            };

            const data = {
                title: formData.aiAssistantConfig.company_name,
                config: config,
                embed_key: ''
            };

            try {
                const response = await httpClient.post(url, { ...data });

                if (response && response.data) {
                    const newEmbedKey = response.data.channel.embed_key;
                    setEmbedKey(newEmbedKey);
                    dispatch(trackAnalytics({
                        action_name: "user_created_assistant_from_onboarding",
                        payload: { description: `Created Oppy during onboarding` }
                    }));
                    enqueueSnackbar('Oppy created successfully!', { variant: 'success' });
                    onNext(newEmbedKey);
                }
            } catch (error: any) {
                console.error('Server error details:', {
                    status: error.response?.status,
                    statusText: error.response?.statusText,
                    data: error.response?.data,
                    headers: error.response?.headers,
                });
                throw error;
            }
        } catch (error: any) {
            console.error('Error creating Oppy:', error);
            setError(error.response?.data?.message || 'Failed to create Oppy. Please try again.');
            enqueueSnackbar(error.response?.data?.message || 'Failed to create Oppy', { variant: 'error' });
        }
    };

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Typography variant="h6" gutterBottom>Review Your Oppy</Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Please review your Oppy's configuration before creating it
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Stack spacing={3}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">Basic Information</Typography>
                            <Typography>Name: {formData.aiAssistantConfig.name}</Typography>
                            <Typography>Company: {formData.aiAssistantConfig.company_name}</Typography>
                            <Typography>Style: {
                                formData.aiAssistantConfig.chatbot_style === "1" ? "Light Mode" :
                                    formData.aiAssistantConfig.chatbot_style === "2" ? "Dark Mode" : "Disco Mode"
                            }</Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">Personality</Typography>
                            <Typography>Type: {formData.aiAssistantConfig.personality}</Typography>
                            <Typography>Emojis: {formData.aiAssistantConfig.use_emojis ? "Enabled" : "Disabled"}</Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">Instructions</Typography>
                            <Typography variant="body2">{formData.aiAssistantConfig.knowledge_base || "No custom instructions provided"}</Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">Integrations</Typography>
                            <Typography>Calendar: {formData.calendarConnected ? "Connected" : "Not Connected"}</Typography>
                            <Typography>CRM: {formData.crmType ? `Connected (${formData.crmType})` : "Not Connected"}</Typography>
                        </Box>
                    </Stack>
                </Paper>
            </Stack>

            <ButtonContainer>
                <Button
                    variant="outlined"
                    onClick={onPrev}
                    disabled={loading}
                    fullWidth
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => handleCreateAssistant()}
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Create Oppy
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

export const FinalStep: FC<StepProps> = ({ loading, embedKey }) => {
    const navigate = useNavigate();
    const startConversationUrl = embedKey ?
        `https://app.oppy.pro/s/${embedKey}` :
        `${process.env.REACT_APP_BASE_URL}/chat`;

    return (
        <StepContainer
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
        >
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Box
                    component="div"
                    sx={{
                        width: 120,
                        height: 120,
                        margin: '0 auto',
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 3,
                        animation: 'pulse 2s infinite'
                    }}
                >
                    <Typography variant="h3" color="primary.contrastText">
                        ✓
                    </Typography>
                </Box>
                <Typography variant="h5" gutterBottom>
                    Setup Complete!
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Your Oppy has been created successfully
                </Typography>
            </Box>

            <ButtonContainer sx={{ flexDirection: 'column', gap: 2 }}>
                <Link
                    href={startConversationUrl}
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={loading}
                        endIcon={<SendIcon />}
                        component="span"
                    >
                        Start Conversation With Your Oppy
                    </Button>
                </Link>
                <Button
                    variant="outlined"
                    onClick={() => navigate('/login')}
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    fullWidth
                >
                    Login to Your Account
                </Button>
            </ButtonContainer>
        </StepContainer>
    );
};

// Export other step components as needed
export const getStepComponent = (step: number): FC<StepProps> | null => {
    const steps: { [key: number]: FC<StepProps> } = {
        1: BusinessUrlStep,
        2: FullNameStep,
        3: PhoneNumberStep,
        4: EmailStep,
        5: OtpStep,
        6: GoogleCalendarStep,
        7: CrmSyncStep,
        8: InterestsStep,
        9: BusinessCardStep,
        10: AiAssistantStep,
        11: SetupCompleteStep,
        12: FinalStep,
    };

    return steps[step] || null;
}; 