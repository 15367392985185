import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { 
  Typography, 
  Box, 
  SwipeableDrawer, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField,
  Paper
} from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { selectConversationById, updateConversation } from 'src/features/account/conversations.slice';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  conversationId: string;
}

interface EditItemState {
  path: string[];
  value: any;
  isOpen: boolean;
}

// Helper function to get a value from a nested object using a path array
const getNestedValue = (obj: any, path: string[]): any => {
  if (!obj || path.length === 0) return obj;
  let current = obj;
  for (const key of path) {
    if (current === null || current === undefined || typeof current !== 'object') {
      return undefined;
    }
    current = current[key];
  }
  return current;
};

// Helper function to set a value in a nested object using a path array
const setNestedValue = (obj: any, path: string[], value: any): any => {
  if (path.length === 0) return value;
  
  const result = { ...obj };
  let current = result;
  const lastKey = path[path.length - 1];
  
  // Navigate to the parent of the property we want to set
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    current[key] = { ...(current[key] || {}) };
    current = current[key];
  }
  
  // Set the property
  current[lastKey] = value;
  return result;
};

const AdvancedConversationDetailsDrawer: React.FC<Props> = ({ conversationId }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editItem, setEditItem] = useState<EditItemState>({ path: [], value: '', isOpen: false });
  const [editValue, setEditValue] = useState<string>('');
  const dispatch = useAppDispatch();
  const conversation = useAppSelector(state => selectConversationById(state, conversationId));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'm') {
        setOpen(!open);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  const handleOpenConfirm = () => {
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleClearMemory = () => {
    if (conversation) {
      dispatch(updateConversation({
        conversationId: conversation.id,
        channelId: conversation.channel_id,
        conversationData: {
          memory: {}
        }
      }));
    }
    setConfirmOpen(false);
  };

  const handleItemClick = (path: string[], value: any) => {
    // Only open edit dialog for leaf nodes (actual values, not objects)
    if (value !== null && typeof value !== 'object') {
      setEditItem({ path, value, isOpen: true });
      setEditValue(String(value));
    }
  };

  const handleCloseEdit = () => {
    setEditItem({ ...editItem, isOpen: false });
  };

  const handleSaveEdit = () => {
    if (!conversation) return;

    // Create new memory object with the updated value
    const updatedMemory = setNestedValue(
      { ...conversation.memory }, 
      editItem.path,
      // Try to preserve the original type
      typeof editItem.value === 'number' ? Number(editValue) :
      typeof editItem.value === 'boolean' ? editValue === 'true' :
      editValue
    );

    // Dispatch action to update the conversation memory
    dispatch(updateConversation({
      conversationId: conversation.id,
      channelId: conversation.channel_id,
      conversationData: {
        memory: updatedMemory
      }
    }));

    // Close the edit dialog
    handleCloseEdit();
  };

  const renderTree = (nodes: any, parentPath: string[] = []) => {
    return Object.keys(nodes).map((key) => {
      const currentPath = [...parentPath, key];
      const itemId = currentPath.join('-');
      const value = nodes[key];

      if (typeof value === 'object' && value !== null) {
        return (
          <TreeItem 
            key={itemId} 
            itemId={itemId} 
            label={key}
            onClick={(e) => {
              e.stopPropagation();
              // For parent nodes, don't do anything special on click
            }}
            sx={{
              '& .MuiTreeItem-label': {
                fontWeight: 'bold'
              }
            }}
          >
            {renderTree(value, currentPath)}
          </TreeItem>
        );
      }

      return (
        <TreeItem 
          key={itemId} 
          itemId={itemId} 
          label={`${key}: ${value} ✏️`}
          onClick={(e) => {
            e.stopPropagation();
            handleItemClick(currentPath, value);
          }}
          sx={{
            '& .MuiTreeItem-label': {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '4px',
              }
            }
          }}
        />
      );
    });
  };

  if (!conversation) return null;

  return (
    <>
      <SwipeableDrawer
        anchor={"bottom"}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        sx={{ width: '100px', zIndex: 9999 }}
      >
        {conversation?.memory && (
          <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Advanced Conversation Details</Typography>
              <Button 
                variant="outlined" 
                color="error" 
                startIcon={<DeleteOutlineIcon />}
                onClick={handleOpenConfirm}
                size="small"
              >
                Clear Memory
              </Button>
            </Box>

            <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: 'rgba(25, 118, 210, 0.08)' }}>
              <Typography variant="body2" gutterBottom>
                <strong>Memory Editing Guide:</strong>
              </Typography>
              <Typography variant="body2">
                • Click on any value (marked with ✏️) to edit it<br />
                • Parent nodes with nested values can be expanded/collapsed<br />
                • Changes are saved immediately when you click "Save"
              </Typography>
            </Paper>

            <SimpleTreeView>
              {renderTree(conversation.memory)}
            </SimpleTreeView>
          </Box>
        )}
      </SwipeableDrawer>

      {/* Confirmation Dialog for Clearing Memory */}
      <Dialog
        open={confirmOpen}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Clear Conversation Memory?
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to clear all memory for this conversation? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClearMemory} color="error" autoFocus>
            Clear Memory
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog for Memory Item */}
      <Dialog
        open={editItem.isOpen}
        onClose={handleCloseEdit}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">
          Edit {editItem.path.length > 0 ? editItem.path.join('.') : ''}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            multiline
            rows={typeof editItem.value === 'string' && editItem.value.length > 50 ? 4 : 1}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdvancedConversationDetailsDrawer;
