import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Skeleton,
  Box,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  Popper,
  MenuItem as MuiMenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Grow,
  LinearProgress
} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useEffect, useState, useRef } from "react";
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { httpClient } from "src/libs";
import { useAppDispatch, useAppSelector } from "src/store";
import { useSnackbar } from 'notistack';
import ContactsSearchAutocomplete from "../Contacts/ContactsSearchAutocomplete";
import { selectContactById, getContact } from "src/features/account/contacts.slice";
import { selectChannelById } from "src/features/account/channels.slice";
import { SelectChangeEvent } from '@mui/material/Select';
import { getBranding } from "src/utils/branding";
import { Contact } from "src/types/contacts";

interface ConversationCreateDialogV2Props {
  open: boolean;
  handleClose: () => void;
  incomingContactId?: string | null;
  incomingDraftMessage?: string;
  incomingChannelId?: string | null;
}

const ConversationCreateDialogV2 = ({
  open,
  handleClose,
  incomingContactId = null,
  incomingDraftMessage = '',
  incomingChannelId = null,
}: ConversationCreateDialogV2Props) => {
  const dispatch = useAppDispatch();
  const { channels = [], channelsLoading } = useAppSelector(state => state.channels);
  const [selectedContactId, setSelectedContactId] = useState(incomingContactId);
  const selectedContact = useAppSelector(state => incomingContactId ? selectContactById(state, incomingContactId) : null);
  const incomingChannel = useAppSelector(state => selectChannelById(state, incomingChannelId));
  const { enqueueSnackbar } = useSnackbar();

  // Basic states
  const [selectedChannel, setSelectedChannel] = useState(incomingChannel || null);
  const [errorMessage, setErrorMessage] = useState("");
  const [postingMessage, setPostingMessage] = useState(false);

  // Tab / method logic
  const [phoneMethodAvailable, setPhoneMethodAvailable] = useState(false);
  const [emailMethodAvailable, setEmailMethodAvailable] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [currentMethod, setCurrentMethod] = useState<'preferred' | 'phone' | 'email' | ''>('preferred');
  const [showMethodTabs, setShowMethodTabs] = useState(true);

  // Additional states that replicate the old MessageInputWithUploader
  const [subjectValue, setSubjectValue] = useState('');
  const [inputValue, setInputValue] = useState(incomingDraftMessage);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [sendingEntityRole, setSendingEntityRole] = useState<'assistant' | 'function'>('assistant');
  const [dateTimeValue, setDateTimeValue] = useState('');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Scheduling popper
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const splitButtonRef = useRef<HTMLButtonElement>(null);

  const toIdentifier = currentMethod === 'email'
    ? selectedContact?.email
    : selectedContact?.phone_number;

  // Fetch contact data if incomingContactId is provided
  useEffect(() => {
    if (incomingContactId && open) {
      dispatch(getContact(incomingContactId));
    }
  }, [incomingContactId, dispatch, open]);

  // Update selectedContactId when incomingContactId changes
  useEffect(() => {
    if (incomingContactId) {
      setSelectedContactId(incomingContactId);
    }
  }, [incomingContactId]);

  /**
   * Handle selecting a contact from the autocomplete
   */
  const handleSelectContact = (contact: Contact) => {
    setSelectedContactId(contact.id);
  };

  /**
   * Update phone/email availability whenever contact or channel changes
   */
  useEffect(() => {
    const phoneAvailable = !!selectedChannel?.twilio_phone_number && !!selectedContact?.phone_number;
    const emailAvailable = !!selectedChannel?.email_address && !!selectedContact?.email;
    setPhoneMethodAvailable(phoneAvailable);
    setEmailMethodAvailable(emailAvailable);

    // Auto-select first available method
    if (phoneAvailable && emailAvailable) {
      setCurrentMethod(tabValue === 0 ? 'phone' : 'email');
    } else if (phoneAvailable) {
      setCurrentMethod('phone');
      setTabValue(0);
    } else if (emailAvailable) {
      setCurrentMethod('email');
      setTabValue(1);
    } else {
      setCurrentMethod('');
    }
  }, [selectedContact, selectedChannel, tabValue, selectedContactId]);

  // Add useEffect to handle contact ID changes
  useEffect(() => {
    // Clear previous contact method states when contact changes
    setPhoneMethodAvailable(false);
    setEmailMethodAvailable(false);
    setCurrentMethod('');
  }, [selectedContactId]);

  /**
   * Change channels
   */
  const handleChannelChange = (event: SelectChangeEvent<string>) => {
    const channelId = event.target.value;
    const channel = channels.find(channel => channel.id === channelId) || null;
    setSelectedChannel(channel);
  };

  /**
   * Handle pressing the tab to flip between SMS and Email
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (phoneMethodAvailable && emailMethodAvailable) {
      setCurrentMethod(newValue === 0 ? 'phone' : 'email');
    } else if (emailMethodAvailable) {
      setCurrentMethod('email');
    } else if (phoneMethodAvailable) {
      setCurrentMethod('phone');
    } else {
      setCurrentMethod('');
    }
  };

  /**
   * Toggle sending entity role between 'assistant' and 'function'
   */
  const entityRoles: ('assistant' | 'function')[] = ['assistant', 'function'];
  const handleToggleSendingEntity = () => {
    const currentIndex = entityRoles.indexOf(sendingEntityRole);
    const nextIndex = (currentIndex + 1) % entityRoles.length;
    setSendingEntityRole(entityRoles[nextIndex]);
  };

  /**
   * Open the scheduling time popper
   */
  const handleMenuClick = () => {
    setAnchorEl(splitButtonRef.current);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * The Cloudinary upload widget invocation
   */
  const handleMediaUpload = () => {
    if (!window.cloudinary) {
      console.error('Cloudinary library not loaded.');
      return;
    }
    window.cloudinary.openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        multiple: true,
        resourceType: 'auto',
        sources: ['local', 'camera'],
        clientAllowedFormats: [
          'png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico',
          'mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'mkv',
          'mp3', 'wav', 'm4a', 'flac',
          'pdf'
        ],
        maxFileSize: 100 * 1024 * 1024,
        cropping: false,
        showPoweredBy: false,
        // If you have theme-based styles, add them here
        // styles: { ... },
      },
      (error: any, result: any) => {
        if (!error && result.event === 'success') {
          const { public_id, resource_type, format } = result.info;
          let transformation = '';
          let adjusted_resource_type = resource_type;
          let adjusted_format = format;

          if (resource_type === 'image') {
            transformation = 'c_limit,w_1600,h_1600,q_auto:eco,f_auto';
          } else if (resource_type === 'video') {
            transformation = 'c_limit,w_1280,h_720,q_auto:eco,f_auto';
          } else if (resource_type === 'raw' && format === 'pdf') {
            transformation = 'c_limit,w_800,q_auto:eco,f_auto,page=1';
            adjusted_resource_type = 'image';
            adjusted_format = 'jpg';
          }
          const transformedUrl = transformation
            ? `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/${adjusted_resource_type}/upload/${transformation}/${public_id}.${adjusted_format}`
            : result.info.secure_url;

          setImageUrls(prev => [...prev, transformedUrl]);
        } else if (error) {
          console.error('Upload error:', error);
        }
      }
    );
  };

  /**
   * Delete a single media item from the preview
   */
  const handleDeleteMedia = (index: number) => {
    setImageUrls(imageUrls.filter((_, i) => i !== index));
  };

  /**
   * Actually send the message
   */
  const handleSendMessage = () => {
    if (!selectedContact || !selectedChannel) return;

    // Determine final method based on tab selection
    const finalMethod = showMethodTabs
      ? tabValue === 0 ? 'phone' : 'email'
      : phoneMethodAvailable ? 'phone' : 'email';

    const toIdentifier = finalMethod === 'email'
      ? selectedContact.email
      : selectedContact.phone_number;

    const toTag = finalMethod === 'email' ? 'email' : 'sms';

    setPostingMessage(true);
    dispatch(trackAnalytics({
      action_name: "user_create_conversation_start",
      payload: { description: "user create conversation start" }
    }));

    const url = `${process.env.REACT_APP_API_V3_URL}/chat/private/messages/post_message_v2`;

    // Validate channel supports the selected method
    if ((finalMethod === 'email' && !selectedChannel?.email_address) ||
      (finalMethod === 'phone' && !selectedChannel?.twilio_phone_number)) {
      setErrorMessage("Selected channel doesn't support this contact method");
      return;
    }

    const data = {
      content: inputValue,
      channel_id: selectedChannel.id,
      to_identifier: toIdentifier,
      to_tag: toTag,
      subject: currentMethod === 'email' ? subjectValue : undefined,
      media_urls: imageUrls.length > 0 ? imageUrls : undefined,
      from_role: sendingEntityRole,
      contact_id: selectedContact?.id,
      deliver_after: dateTimeValue ? `${dateTimeValue}:00${new Date().toTimeString().slice(13)}` : undefined,
    };

    httpClient.post(url, data, { headers: { "Content-Type": "application/json" } })
      .then(() => {
        dispatch(trackAnalytics({
          action_name: "user_create_conversation_success",
          payload: { description: "user create conversation success" }
        }));
        setErrorMessage("");
        // Reset
        setInputValue('');
        setSubjectValue('');
        setImageUrls([]);
        setPostingMessage(false);
        handleClose();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        enqueueSnackbar(`Error sending message: ${error.message}`, {
          variant: 'error',
          autoHideDuration: 3000
        });
        if (error.response) {
          setErrorMessage(`${error.response.data.error_code}: ${error.response.data.message}`);
        } else if (error.request) {
          setErrorMessage(`The request was made, but no response was received.`);
        } else {
          setErrorMessage(`Error: ${error.message}.`);
        }
        setPostingMessage(false);
      });
  };

  const handleCloseDialog = () => {
    // If needed, confirm close logic, e.g. unsaved attachments, etc.
    handleClose();
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">New Conversation</DialogTitle>
          <DialogContent>
            {channelsLoading ? (
              <>
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, gap: 2 }}>
                {/* Contact picker */}
                <ContactsSearchAutocomplete
                  onSelectContact={handleSelectContact}
                  preSelectedContactId={selectedContactId}
                />

                {/* Channel picker */}
                <FormControl fullWidth>
                  <InputLabel id="channels-simple-select-label">Send From</InputLabel>
                  <Select
                    labelId="channels-simple-select-label"
                    id="channels-simple-select"
                    value={selectedChannel?.id || ''}
                    onChange={handleChannelChange}
                    label="Pick sender"
                    required
                  >
                    {channels
                      .filter(channel => channel.email_address || channel.twilio_phone_number)
                      .map((channel) => (
                        <MenuItem
                          key={channel.id}
                          value={channel.id}
                          disabled={
                            (currentMethod === 'phone' && !channel.twilio_phone_number) ||
                            (currentMethod === 'email' && !channel.email_address)
                          }
                        >
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {channel.chatbot_name}
                          </Typography>
                          {channel.twilio_phone_number && (
                            <Typography variant="body2" color="text.secondary">
                              {channel.twilio_phone_number}
                            </Typography>
                          )}
                          {channel.email_address && (
                            <Typography variant="body2" color="text.secondary">
                              {channel.email_address}
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* If both SMS & Email are available, user can switch via tabs */}
                {showMethodTabs && (
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={{
                      mb: 2,
                      '& .MuiTabs-flexContainer': { justifyContent: 'center' }
                    }}
                  >
                    <Tab
                      label="SMS"
                      disabled={!phoneMethodAvailable}
                      sx={{
                        opacity: phoneMethodAvailable ? 1 : 0.5,
                        cursor: phoneMethodAvailable ? 'pointer' : 'not-allowed'
                      }}
                    />
                    <Tab
                      label="Email"
                      disabled={!emailMethodAvailable}
                      sx={{
                        opacity: emailMethodAvailable ? 1 : 0.5,
                        cursor: emailMethodAvailable ? 'pointer' : 'not-allowed'
                      }}
                    />
                  </Tabs>
                )}

                {/* Subject field only if user is sending 'email' */}
                {currentMethod === 'email' && (
                  <TextField
                    label="Subject"
                    fullWidth
                    value={subjectValue}
                    onChange={(e) => setSubjectValue(e.target.value)}
                    disabled={postingMessage}
                  />
                )}

                {/* The big multiline message */}
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    label={
                      sendingEntityRole === 'assistant'
                        ? `Message from ${selectedChannel?.chatbot_name || 'Oppy'}...`
                        : 'Internal note (function role)'
                    }
                    multiline
                    minRows={5}
                    fullWidth
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    disabled={postingMessage || !selectedContact?.id || !selectedChannel || !(phoneMethodAvailable || emailMethodAvailable)}
                    InputProps={{
                      startAdornment: (
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                          <IconButton onClick={handleToggleSendingEntity} disabled={postingMessage}>
                            {sendingEntityRole === 'assistant'
                              ? (
                                <img
                                  src={getBranding('oppy_expressions_no_mouth')}
                                  alt="Assistant Icon"
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : (
                                <TipsAndUpdatesIcon color="info" />
                              )
                            }
                          </IconButton>
                          <IconButton onClick={handleMediaUpload} disabled={postingMessage}>
                            <AttachFileIcon />
                          </IconButton>
                        </Box>
                      ),
                      endAdornment: (
                        <Box sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
                          {Boolean(inputValue) && (
                            <>
                              {/* <Tooltip
                                title={
                                  toIdentifier
                                    ? ''
                                    : 'Please select a contact with a valid phone number or email.'
                                }
                              >
                                <span> */}
                              <IconButton onClick={handleSendMessage} disabled={postingMessage || !selectedContact?.id || !selectedChannel || !(phoneMethodAvailable || emailMethodAvailable)}>
                                <SendIcon />
                              </IconButton>
                              {/* <IconButton
                                size="small"
                                aria-controls={openMenu ? 'split-button-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-label="select send time"
                                aria-haspopup="menu"
                                onClick={handleMenuClick}
                                ref={splitButtonRef}
                                disabled={postingMessage || !selectedContact?.id || !selectedChannel || !(phoneMethodAvailable || emailMethodAvailable)}
                              >
                                <ArrowDropDownIcon />
                              </IconButton> */}
                              {/* </span>
                            </Tooltip> */}
                            </>
                          )}
                        </Box>
                      )
                    }}
                  />

                  {/* Popper for scheduling the send time */}
                  <Popper
                    open={openMenu}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    disablePortal
                    sx={{ zIndex: 1300 }}
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'center top' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem>
                              <MuiMenuItem>
                                <TextField
                                  id="datetime"
                                  label="Send At"
                                  type="datetime-local"
                                  value={dateTimeValue}
                                  onChange={(e) => setDateTimeValue(e.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                    children: <span>Send At (Browser Time: {timezone})</span>
                                  }}
                                  inputProps={{
                                    min: new Date().toLocaleString('sv').slice(0, 16),
                                  }}
                                  fullWidth
                                  helperText="Time is based on your browser's timezone"
                                />
                              </MuiMenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Box>

                {/* Attachment previews */}
                {imageUrls.length > 0 && (
                  <Box
                    sx={{
                      mt: 2,
                      backgroundColor: 'grey.100',
                      borderRadius: 1,
                      p: 2,
                      border: 1,
                      borderColor: 'grey.300'
                    }}
                  >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {imageUrls.map((url, index) => (
                        <Box
                          key={index}
                          sx={{
                            position: 'relative',
                            width: 120,
                            height: 120,
                            mr: 1,
                            mb: 1,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            border: 1,
                            borderColor: 'grey.300'
                          }}
                        >
                          {(() => {
                            if (/\.(png|jpg|jpeg|gif)$/i.test(url)) {
                              return (
                                <img
                                  src={url}
                                  alt={`Uploaded ${index}`}
                                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                />
                              );
                            } else if (/\.(mp4|mov|avi|wmv|flv|webm|mkv)$/i.test(url)) {
                              return (
                                <video
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: 'contain' }}
                                  controls
                                >
                                  <source src={url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              );
                            } else if (/\.(pdf)$/i.test(url)) {
                              return (
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'grey.200',
                                    color: 'grey.700'
                                  }}
                                >
                                  <PictureAsPdfIcon sx={{ fontSize: 48 }} />
                                </Box>
                              );
                            } else {
                              return (
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'grey.200',
                                    color: 'grey.700'
                                  }}
                                >
                                  <InsertDriveFileIcon sx={{ fontSize: 48 }} />
                                </Box>
                              );
                            }
                          })()}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 4,
                              right: 4
                            }}
                          >
                            <IconButton
                              size="small"
                              sx={{
                                bgcolor: 'rgba(0,0,0,0.7)',
                                color: 'white',
                                '&:hover': {
                                  bgcolor: 'rgba(0,0,0,0.85)',
                                },
                              }}
                              onClick={() => handleDeleteMedia(index)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}

                {/* "Send" button for clarity if user wants to press here instead of the text-field's send icon */}
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Tooltip
                    title={
                      toIdentifier
                        ? ''
                        : 'Please select a contact with a valid phone number or email.'
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SendIcon />}
                        onClick={handleSendMessage}
                        disabled={postingMessage || !selectedContact?.id || !selectedChannel || !(phoneMethodAvailable || emailMethodAvailable)}
                      >
                        Send
                      </Button>
                    </span>
                  </Tooltip>
                </Box> */}

                {/* Optional: display an error message or progress */}
                {postingMessage && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">Sending...</Typography>
                    <LinearProgress />
                  </Box>
                )}

                {errorMessage && !postingMessage && (
                  <Typography variant="body2" color="error">
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ConversationCreateDialogV2;
