import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Typography, Card, CardContent, Grid, Tooltip, Chip, Divider, Checkbox, Button, CircularProgress } from '@mui/material';
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import CustomMarkdown from "../CustomMarkdown";
import ConversationOptionsMenu from "./ConversationOptionsMenu";
import CountdownTimerButton from "../CountdownTimerButton";
import CloseConversationButton from "./CloseConversationButton";
import ConversationContacts from "./ConversationContacts";
import { useAppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { toggleOpenConversation, conversationAutocompleteNow, updateConversationDeliveryState } from "src/features/account/conversations.slice";
import pulsatingBorderStyle from "src/libs/pulsatingBorderStyle";
import { useTheme } from '@mui/material/styles';
import { AutoAwesome, Done, Schedule, Pending, PlayCircleOutline, Person, Error, PauseCircleOutline, CheckCircle, PriorityHigh, PlayArrow } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { isPriorityConversation, getPriorityBorderStyle } from '../../utils/conversationUtils';
import { alpha } from '@mui/material/styles';

interface ConversationCardProps {
  conversation: any;
  handleConversationsListToggleClick?: () => void;
  variant?: "full" | "small";
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: (conversationId: string) => void;
  focused?: boolean;
}

// Helper function to safely render content
const safeRender = (content: any): string => {
  if (content === null || content === undefined) return '';
  if (typeof content === 'string') return content;
  if (typeof content === 'number') return content.toString();
  if (typeof content === 'object') return JSON.stringify(content);
  return String(content);
};

// Helper function to get the conversation summary
const getConversationSummary = (conversation: any) => {
  if (conversation?.memory?.summary) {
    return conversation.memory.summary;
  }
  return null;
};

const getStatusColor = (status, theme) => {
  const colors = {
    active: theme.palette.success.main,
    standby: theme.palette.info.main,
    processing_autocomplete: theme.palette.secondary.main,
    awaiting_reply: theme.palette.warning.main,
    pending_user_reply: theme.palette.info.main,
    pending_assistant_reply: theme.palette.info.main,
    needs_attention: theme.palette.error.main,
    complete: theme.palette.success.main,
    closed: theme.palette.error.main,
    error: theme.palette.error.main,
    paused_pending: theme.palette.error.main,
    unknown: theme.palette.grey[500],
  };
  // Make sure we have a valid color or default to grey
  return colors[status?.toLowerCase()] || theme.palette.grey[500];
};

const getStatusIcon = (status, theme) => {
  const iconSize = 18;
  const color = getStatusColor(status, theme);

  const icons = {
    standby: <Schedule sx={{ fontSize: iconSize, color }} />,
    processing_autocomplete: <CircularProgress size={iconSize} thickness={5} sx={{ color }} />,
    awaiting_reply: <Person sx={{ fontSize: iconSize, color }} />,
    pending_user_reply: <Person sx={{ fontSize: iconSize, color }} />,
    pending_assistant_reply: <PlayCircleOutline sx={{ fontSize: iconSize, color }} />,
    needs_attention: <Error sx={{ fontSize: iconSize, color }} />,
    complete: <Done sx={{ fontSize: iconSize, color }} />,
    closed: <CheckCircle sx={{ fontSize: iconSize, color }} />,
    error: <Error sx={{ fontSize: iconSize, color }} />,
    paused_pending: <PauseCircleOutline sx={{ fontSize: iconSize, color: theme.palette.error.main }} />,
    unknown: <Pending sx={{ fontSize: iconSize, color: theme.palette.grey[500] }} />,
  };

  return icons[status?.toLowerCase()] || icons.unknown;
};

// Let's add a function to dynamically calculate card heights with 10% increase
const getCardHeight = (variant) => {
  // Fixed heights without calculation
  return variant === 'full' ? '303px' : '150px';
};

const ConversationCard: React.FC<ConversationCardProps> = React.memo(({
  conversation,
  handleConversationsListToggleClick = () => { },
  variant = "full",
  isSelectable = false,
  isSelected = false,
  onSelect,
  focused = false
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [isHovered, setIsHovered] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showEvents, setShowEvents] = useState(true);
  const [isDeliveryStateLoading, setIsDeliveryStateLoading] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const subjectRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  // Check if this is a priority conversation
  const isPriority = isPriorityConversation(conversation);

  // Handle window resize to detect mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Add a function to toggle between play/pause
  const handleToggleDeliveryState = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // Set loading state
    setIsDeliveryStateLoading(true);

    const newState = conversation?.delivery_state === 'pause' ? 'play' : 'pause';

    dispatch(updateConversationDeliveryState({
      conversationId: conversation.id,
      deliveryState: newState,
      channelId: conversation.channel_id
    })).then(() => {
      // Set loading state back to false after the dispatch is complete
      setTimeout(() => {
        setIsDeliveryStateLoading(false);
      }, 1000); // Allow animation to be visible
    }).catch(() => {
      setIsDeliveryStateLoading(false);
    });
  };

  const unreadAnimation = useCallback(() => {
    if (!conversation?.read) {
      return "pulse 1s ease infinite";
    }
    return "none";
  }, [conversation]);

  useEffect(() => {
    const subject = subjectRef.current;
    if (subject) {
      setIsOverflowing(subject.scrollWidth > subject.clientWidth);
    }
  }, [conversation?.subject]);

  // Check if conversation has any pending assistant messages
  const hasPendingAssistantMessages = conversation?.messages?.some(
    (message: any) => message.status === 'pending_delivery' && message.sender_role === 'assistant'
  );

  if (!conversation) {
    return null;
  }

  const styles = {
    card: {
      position: 'relative',
      cursor: 'pointer',
      width: variant === "full" ? "calc(33.333% - 16px)" : "calc(20% - 16px)",
      minWidth: variant === "full" ? "280px" : "220px",
      height: variant === "full" ? "370px" : "180px",
      maxHeight: variant === "full" ? "370px" : "180px",
      display: 'flex',
      flexDirection: 'column',
      wordBreak: 'break-word',
      background: darkMode ? theme.palette.grey[800] : '#ffffff',
      borderRadius: '8px',
      border: isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : focused
          ? `2px solid ${theme.palette.secondary.main}`
          : conversation?.delivery_state === 'pause'
            ? `2px dashed ${theme.palette.error.main}`
            : `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
      boxShadow: isSelected || focused
        ? `0 4px 12px ${darkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.15)'}`
        : conversation?.delivery_state === 'pause'
          ? `0 0 0 1px ${theme.palette.error.main}`
          : darkMode
            ? 'none'
            : '0 2px 4px rgba(0, 0, 0, 0.05)',
      animation: conversation?.delivery_state === 'pause'
        ? 'pauseBorder 2s infinite'
        : unreadAnimation(),
      transition: 'all 200ms ease',
      overflow: 'hidden',
      '&:hover': {
        borderColor: conversation?.delivery_state === 'pause'
          ? theme.palette.error.main
          : theme.palette.primary.main,
        boxShadow: `0 6px 16px ${darkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'}`,
        transform: 'translateY(-2px)',
      },
      // Add pause animation
      '@keyframes pauseBorder': {
        '0%, 100%': { borderColor: theme.palette.error.main, opacity: 0.7 },
        '50%': { borderColor: theme.palette.error.main, opacity: 1 }
      },
      // Responsive adjustments based on screen size
      [theme.breakpoints.down('xl')]: {
        width: variant === "full" ? "calc(33.333% - 16px)" : "calc(25% - 16px)",
      },
      [theme.breakpoints.down('lg')]: {
        width: variant === "full" ? "calc(50% - 16px)" : "calc(33.333% - 16px)",
      },
      [theme.breakpoints.down('md')]: {
        width: variant === "full" ? "calc(50% - 16px)" : "calc(50% - 16px)",
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        minWidth: "100%",
      },
      ...(conversation.call_status === 'active' && {
        animation: 'pulse-green 2s infinite',
        '@keyframes pulse-green': {
          '0%, 100%': { borderColor: darkMode ? 'rgba(0, 200, 0, 0.5)' : 'rgba(0, 200, 0, 0.3)' },
          '50%': { borderColor: darkMode ? 'rgba(0, 255, 0, 0.8)' : 'rgba(0, 255, 0, 0.6)' },
        },
      }),
      ...(conversation.call_status === 'ringing' && {
        animation: 'ring-glow 1s infinite',
        '@keyframes ring-glow': {
          '0%, 100%': { borderColor: darkMode ? 'rgba(255, 165, 0, 0.7)' : 'rgba(255, 165, 0, 0.5)' },
          '50%': { borderColor: darkMode ? 'rgba(255, 165, 0, 1)' : 'rgba(255, 165, 0, 1)' },
        },
      }),
      // Add priority flashing border
      ...(isPriority && getPriorityBorderStyle(darkMode))
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 1.5,
      paddingTop: 6,
      position: 'relative',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 1,
      position: 'relative',
    },
    headerTop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
      marginBottom: 0.5,
    },
    contactInfo: {
      backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.03)',
      padding: '6px 10px',
      borderRadius: '6px',
      mb: 1,
      width: '100%',
      border: `1px solid ${darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.06)'}`,
      fontSize: variant === "small" ? '0.75rem' : '0.875rem',
    },
    time: {
      fontSize: '0.75rem',
      color: darkMode ? theme.palette.grey[400] : theme.palette.grey[600],
      display: 'flex',
      alignItems: 'center',
    },
    subject: {
      fontSize: variant === "small" ? '0.875rem' : '1rem',
      fontWeight: 600,
      color: darkMode ? theme.palette.grey[100] : theme.palette.grey[900],
      marginBottom: 0.5,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    body: {
      flex: 1,
      overflowY: 'auto',
      marginBottom: 1,
      padding: '0 2px',
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: darkMode ? theme.palette.grey[700] : theme.palette.grey[400],
        borderRadius: '4px',
      },
    },
    messageContent: {
      fontSize: variant === "small" ? '0.75rem' : '0.875rem',
      color: darkMode ? theme.palette.grey[300] : theme.palette.grey[800],
      lineHeight: 1.5,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 10px',
      marginTop: 'auto',
      borderTop: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'}`,
      backgroundColor: darkMode ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)',
    },
    generateReplyBtn: {
      fontSize: '0.75rem',
      padding: '4px 8px',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    selectionCheckbox: {
      position: 'absolute',
      top: 10,
      left: 10,
      zIndex: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: isSelected
        ? theme.palette.primary.main
        : darkMode
          ? 'rgba(0, 0, 0, 0.6)'
          : 'rgba(255, 255, 255, 0.95)',
      width: 24,
      height: 24,
      borderRadius: '4px',
      border: isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : `1px solid ${darkMode ? 'rgba(255, 255, 255, 0.4)' : theme.palette.grey[400]}`,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
      '&:hover': {
        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
      }
    },
    statusContainer: {
      position: 'absolute',
      top: 10,
      left: 10,
      display: 'flex',
      gap: '6px',
      zIndex: 5,
      alignItems: 'center',
      maxWidth: 'calc(100% - 80px)', // Ensure it doesn't overlap with the options menu
    },
    statusIndicator: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      outline: 'none',
      border: 'none',
    },
    cornerDecoration: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '16px',
      height: '16px',
      backgroundColor: hasPendingAssistantMessages
        ? (theme.palette.warning ? theme.palette.warning.main : '#ED6C02')
        : (theme.palette.primary ? theme.palette.primary.main : '#1976D2'),
      borderBottomLeftRadius: '4px',
      zIndex: 1,
    },
    summaryChips: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: '8px',
      marginTop: 1,
      maxHeight: '120px',
      overflowY: 'auto',
      padding: '4px 2px',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: darkMode ? theme.palette.grey[700] : theme.palette.grey[400],
        borderRadius: '4px',
      },
    },
    summaryChip: {
      height: '18px',
      fontSize: '0.65rem',
      backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
      color: darkMode ? theme.palette.grey[300] : theme.palette.grey[700],
      border: `1px solid ${darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.08)'}`,
    },
  };

  function snakeToTitle(snakeString: string) {
    if (!snakeString) {
      return '';
    }
    return snakeString.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }

  // Helper function to check if summary field has meaningful content
  const hasContent = (field: any): boolean => {
    if (field === null || field === undefined) return false;
    if (typeof field === 'string') return field.trim().length > 0;
    if (Array.isArray(field)) return field.length > 0 && field.some(item => item !== null && item !== undefined && item !== '');
    if (typeof field === 'object') return Object.keys(field).length > 0;
    return true;
  };

  // Helper function to format array values for display
  const formatArrayValue = (value: any[]): string => {
    if (!Array.isArray(value) || value.length === 0) return '';

    // If it's a short array, just join it
    if (value.length <= 2) {
      return value.join(', ');
    }

    // For longer arrays, show first item + count
    return `${value[0]} +${value.length - 1}`;
  };

  // Helper function to safely truncate content with longer defaults for important fields
  const truncate = (content: string, maxLength: number = 30): string => {
    if (!content || content.length <= maxLength) return content;
    return `${content.substring(0, maxLength)}...`;
  };

  // Special truncate function for high-priority fields like Next and Info
  const truncatePriority = (content: string, maxLength: number = 100): string => {
    if (!content || content.length <= maxLength) return content;
    return `${content.substring(0, maxLength)}...`;
  };

  // Helper to format event logs for display
  const formatEvents = (events: any): React.ReactNode[] => {
    if (!events) return [];

    let eventArray = events;
    if (typeof events === 'string') {
      try {
        // Try to parse if it's a stringified array
        eventArray = JSON.parse(events);
      } catch {
        // If it can't be parsed, treat it as a single event
        eventArray = [events];
      }
    }

    if (!Array.isArray(eventArray)) {
      eventArray = [String(eventArray)];
    }

    // Return formatted event items, limited to latest 3
    return eventArray.slice(0, 3).map((event, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          mb: index < eventArray.length - 1 ? 1 : 0,
          position: 'relative',
          pl: 3,
          '&:before': {
            content: '""',
            position: 'absolute',
            left: '6px',
            top: '8px',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: darkMode ? 'rgba(146, 146, 255, 0.8)' : 'rgba(86, 86, 255, 0.7)',
            zIndex: 1,
          },
          '&:after': {
            content: index < eventArray.length - 1 ? '""' : 'none',
            position: 'absolute',
            left: '8px',
            top: '14px',
            width: '2px',
            height: 'calc(100% - 6px)',
            backgroundColor: darkMode ? 'rgba(146, 146, 255, 0.3)' : 'rgba(86, 86, 255, 0.2)',
          }
        }}
      >
        <Box
          sx={{
            fontSize: '0.825rem',
            color: darkMode ? 'rgba(200, 200, 255, 0.9)' : 'rgba(60, 60, 200, 0.9)',
            lineHeight: 1.3
          }}
        >
          {typeof event === 'string' ? event : JSON.stringify(event)}
        </Box>
      </Box>
    ));
  };

  // Get the card height - increased for better content display
  const getUpdatedCardHeight = (variant) => {
    return variant === 'full' ? '420px' : '180px';  // Increased from 363px to 420px
  };

  const summary = getConversationSummary(conversation);

  // Extract emojis for prominent display
  const userEmoji = summary?.user_sentiment_emoji || null;
  const scoreEmoji = summary?.assistant_score_emoji || null;

  // Check if we have events to display
  const hasEvents = summary?.events_log && hasContent(summary.events_log);

  // Toggle events display
  const handleToggleEvents = (e) => {
    e.stopPropagation();
    setShowEvents(prev => !prev);
  };

  // Generate reply button component
  interface GenerateReplyButtonProps {
    conversation: any;
  }

  const GenerateReplyButton: React.FC<GenerateReplyButtonProps> = ({ conversation }) => {
    const dispatch = useAppDispatch();

    const handleGenerateReply = (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch(conversationAutocompleteNow({
        conversationId: conversation.id,
        channelId: conversation.channel_id
      }));
      enqueueSnackbar('Generating reply...', { variant: 'info' });
    };

    return (
      <Button
        variant="contained"
        size="small"
        color="secondary"
        startIcon={<AutoAwesome />}
        onClick={handleGenerateReply}
        sx={styles.generateReplyBtn}
      >
        Generate Reply
      </Button>
    );
  };

  // Add this component inside ConversationCard
  interface StatusWithSelectionProps {
    conversationStatus: string;
    isSelectable: boolean;
    isSelected: boolean;
    onSelectClick: (e: React.MouseEvent) => void;
    isHovered: boolean;
  }

  const StatusWithSelection: React.FC<StatusWithSelectionProps> = React.memo(({
    conversationStatus,
    isSelectable,
    isSelected,
    onSelectClick,
    isHovered
  }) => {
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';
    const color = getStatusColor(conversationStatus, theme);
    // Use the parent component's state hooks
    const shouldShowCheckbox = isSelectable && (isMobile || isHovered || showCheckbox || isSelected);

    const handleMouseEnter = () => {
      if (isSelectable) {
        setShowCheckbox(true);
      }
    };

    const handleMouseLeave = () => {
      if (!isMobile) {
        setShowCheckbox(false);
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          cursor: isSelectable ? 'pointer' : 'default'
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(e) => {
          if (isSelectable) {
            e.preventDefault();
            e.stopPropagation();
            onSelectClick(e);
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            bgcolor: color || theme.palette.grey[500],
            pl: shouldShowCheckbox ? 1.5 : 1,
            pr: 1,
            py: 0.55,
            borderRadius: 1,
            border: 'none',
            boxShadow: `0 1px 3px ${alpha(color || theme.palette.grey[500], 0.5)}`,
            transition: 'all 0.2s ease',
            position: 'relative',
            overflow: 'hidden',
            '&:hover': isSelectable ? {
              boxShadow: `0 2px 4px ${alpha(color || theme.palette.grey[500], 0.7)}`,
              transform: 'translateY(-1px)',
            } : {},
          }}
        >
          {/* Selection Checkbox - animated entry from left */}
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: shouldShowCheckbox ? '24px' : '0px',
              opacity: shouldShowCheckbox ? 1 : 0,
              pl: 0.75,
            }}
          >
            {isSelected ? (
              <Box
                component="svg"
                viewBox="0 0 24 24"
                width={16}
                height={16}
                sx={{
                  color: 'white',
                  filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.3))',
                }}
              >
                <path
                  fill="currentColor"
                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: 14,
                  height: 14,
                  borderRadius: '2px',
                  border: '1.5px solid rgba(255, 255, 255, 0.9)',
                  '&:hover': {
                    borderColor: 'white',
                  },
                }}
              />
            )}
          </Box>

          {/* Status text */}
          <Box
            component="span"
            sx={{
              fontSize: '0.825rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              color: 'white',
              ml: shouldShowCheckbox ? 2 : 0.5,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              transition: 'margin-left 0.25s ease',
            }}
          >
            {conversationStatus?.toLowerCase().replace(/_/g, ' ') || 'Unknown'}
          </Box>
        </Box>
      </Box>
    );
  });

  // Add display name to StatusWithSelection component
  StatusWithSelection.displayName = 'StatusWithSelection';

  return (
    <Card
      sx={{
        maxWidth: '100%',
        width: '100%',
        minHeight: getUpdatedCardHeight(variant),
        maxHeight: variant === 'full' ? getUpdatedCardHeight(variant) : '180px',
        backgroundColor: darkMode ? theme.palette.grey[800] : '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        border: `1px solid ${darkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
        borderRadius: 1,
        boxShadow: darkMode ? 1 : 2,
        mb: 2,
        ...styles.card,
        '&:hover': {
          boxShadow: darkMode ? '0 4px 12px rgba(0,0,0,0.3)' : '0 4px 12px rgba(0,0,0,0.1)',
          transform: 'translateY(-2px)',
        },
      }}
      onClick={(e) => {
        if (e.defaultPrevented) return;
        dispatch(toggleOpenConversation(conversation.id));
        navigate(`?conversation_id=${conversation.id}&channel_id=${conversation.channel_id}`);
        if (handleConversationsListToggleClick) handleConversationsListToggleClick();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Priority indicator - display a distinct badge for priority conversations */}
      {isPriority && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            borderBottomLeftRadius: '8px',
            // Safely access color properties with fallbacks
            bgcolor: theme.palette.error ? theme.palette.error.main : '#D32F2F',
            color: theme.palette.error ? theme.palette.error.contrastText : '#FFFFFF',
            animation: 'pulse-opacity 1.5s infinite',
            '@keyframes pulse-opacity': {
              '0%, 100%': { opacity: 0.8 },
              '50%': { opacity: 1 }
            }
          }}
        >
          <PriorityHigh fontSize="small" />
        </Box>
      )}

      {/* Corner decoration - only show when needed for non-priority messages */}
      {hasPendingAssistantMessages && !isPriority && <Box sx={styles.cornerDecoration} />}

      {/* Status indicators */}
      <Box sx={{
        ...styles.statusContainer,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1.5,
      }}>
        {/* Status with selection indicator */}
        <Box
          onClick={(e) => e.stopPropagation()}
        >
          <StatusWithSelection
            conversationStatus={conversation.status}
            isSelectable={isSelectable}
            isSelected={isSelected}
            isHovered={isHovered}
            onSelectClick={(e) => {
              if (onSelect) {
                onSelect(conversation.id);
              }
            }}
          />
        </Box>

        {/* Pause/Play indicator now positioned NEXT TO status indicator */}
        {conversation?.delivery_state !== undefined && conversation?.delivery_state !== 'play' && (
          <Box
            onClick={(e) => handleToggleDeliveryState(e)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 6,
            }}
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
          >
            <Tooltip title={conversation?.delivery_state === 'pause' ? "Click to resume conversation" : "Click to pause conversation"}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: conversation?.delivery_state === 'pause'
                    ? theme.palette.error.main
                    : theme.palette.success.main,
                  color: conversation?.delivery_state === 'pause'
                    ? theme.palette.error.contrastText
                    : theme.palette.success.contrastText,
                  borderRadius: '4px',
                  width: 'auto',
                  height: '28px',
                  px: 1,
                  cursor: 'pointer',
                  border: `1px solid ${conversation?.delivery_state === 'pause'
                    ? theme.palette.error.dark
                    : theme.palette.success.dark}`,
                  boxShadow: `0 1px 3px ${alpha(
                    conversation?.delivery_state === 'pause'
                      ? theme.palette.error.main
                      : theme.palette.success.main, 0.5)}`,
                  '&:hover': {
                    bgcolor: conversation?.delivery_state === 'pause'
                      ? alpha(theme.palette.error.dark, 0.9)
                      : alpha(theme.palette.success.dark, 0.9),
                    transform: 'translateY(-1px)',
                    boxShadow: `0 2px 4px ${alpha(
                      conversation?.delivery_state === 'pause'
                        ? theme.palette.error.main
                        : theme.palette.success.main, 0.7)}`,
                  },
                  animation: conversation?.delivery_state === 'pause' ? 'pulse 2s infinite' : 'none',
                  '@keyframes pulse': {
                    '0%': {
                      boxShadow: `0 1px 3px ${alpha(theme.palette.error.main, 0.5)}`
                    },
                    '70%': {
                      boxShadow: `0 1px 6px ${alpha(theme.palette.error.main, 0.8)}`
                    },
                    '100%': {
                      boxShadow: `0 1px 3px ${alpha(theme.palette.error.main, 0.5)}`
                    }
                  },
                  transition: 'all 0.2s ease, transform 0.15s ease-out',
                  transform: buttonHovered ? 'translateY(-1px) scale(1.02)' : 'translateY(0)',
                }}
              >
                {isDeliveryStateLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', px: 0.5 }}>
                    <CircularProgress size={18} thickness={5} sx={{ color: 'white', mr: 0.5 }} />
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      {conversation?.delivery_state === 'pause' ? 'Resuming...' : 'Pausing...'}
                    </Typography>
                  </Box>
                ) : conversation?.delivery_state === 'pause' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PlayArrow fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>Resume</Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PauseCircleOutline fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>Pause</Typography>
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Prominent Emoji Display - New Feature */}
      {(userEmoji || scoreEmoji) && (
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 50, // Position before options menu
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            zIndex: 5,
          }}
        >
          {userEmoji && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px',
                fontSize: '24px',
                borderRadius: '50%',
                backgroundColor: darkMode ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.primary.main, 0.08),
                border: `1px solid ${darkMode ? alpha(theme.palette.primary.main, 0.3) : alpha(theme.palette.primary.main, 0.2)}`,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            >
              {safeRender(userEmoji)}
            </Box>
          )}

          {scoreEmoji && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px',
                fontSize: '24px',
                borderRadius: '50%',
                backgroundColor: darkMode ? alpha(theme.palette.success.main, 0.15) : alpha(theme.palette.success.main, 0.08),
                border: `1px solid ${darkMode ? alpha(theme.palette.success.main, 0.3) : alpha(theme.palette.success.main, 0.2)}`,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            >
              {safeRender(scoreEmoji)}
            </Box>
          )}
        </Box>
      )}

      {/* Options menu now at the top right */}
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 6,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ConversationOptionsMenu
          conversation={conversation}
        />
      </Box>

      {/* Main content with proper padding */}
      <Box sx={{
        ...styles.contentWrapper,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 1.5,
        paddingTop: 6,
        position: 'relative',
      }}>
        {/* Header section */}
        <Box sx={styles.header}>
          <Box sx={styles.headerTop}>
            <Typography sx={styles.time}>
              {dateTimeFormatter(conversation.updated_at, "day_month_ordinal_humanized_time_period")}
            </Typography>

            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {conversation.next_autocomplete_scheduled_at && (
                <CountdownTimerButton
                  currentTime={new Date().toISOString()}
                  futureTime={conversation.next_autocomplete_scheduled_at}
                  onClick={() => {
                    dispatch(conversationAutocompleteNow({
                      channelId: conversation.channel_id,
                      conversationId: conversation.id
                    }));
                  }}
                />
              )}
            </Box>
          </Box>

          <Typography
            variant={variant === "small" ? "subtitle1" : "h6"}
            sx={{
              ...styles.subject,
              fontSize: variant === "small" ? '0.875rem' : '1rem',
              fontWeight: 600,
              color: darkMode ? theme.palette.grey[100] : theme.palette.grey[900],
              marginBottom: 0.5,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {conversation.subject || "Messages"}
          </Typography>

          {conversation.status === 'awaiting_reply' && (
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                mt: 0.5,
                px: 1.2,
                py: 0.55,
                borderRadius: '4px',
                fontSize: '0.825rem',
                fontWeight: 600,
                bgcolor: theme.palette.warning ? theme.palette.warning.main : '#ED6C02',
                color: 'white',
                boxShadow: '0 1px 3px rgba(237, 108, 2, 0.5)',
              }}
            >
              Awaiting User Reply
            </Box>
          )}
        </Box>

        {/* Contact info */}
        <Box sx={styles.contactInfo}>
          <ConversationContacts conversation_id={conversation.id} conversation={conversation} />
        </Box>

        {/* Message content */}
        <Box sx={{
          ...styles.body,
          flex: 1,
          overflowY: 'auto',
          marginBottom: 1,
          padding: '0 2px',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: darkMode ? theme.palette.grey[700] : theme.palette.grey[400],
            borderRadius: '4px',
          },
        }}>
          {conversation && conversation.messages && conversation.messages.length > 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {conversation.messages
                .filter(message => message.sender_role !== 'function')
                .slice(-3)
                .reverse()
                .map((message, index) => (
                  <Box key={index} sx={{ mb: 0.5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 600,
                          fontSize: '0.75rem',
                          color: message.sender_role === 'assistant' ? theme.palette.primary.main : theme.palette.info.main
                        }}
                      >
                        {message.sender_role === 'assistant' ? 'Assistant: ' : 'User: '}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: '0.7rem',
                          color: darkMode ? theme.palette.grey[500] : theme.palette.grey[600],
                          fontStyle: message.status === 'pending_delivery' ? 'italic' : 'normal',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {message.status === 'delivered' ? (
                          dateTimeFormatter(message.delivered_at, "day_month_ordinal_humanized")
                        ) : message.status === 'pending_delivery' ? (
                          <Box component="span" sx={{
                            color: darkMode ? theme.palette.warning.light : theme.palette.warning.main,
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <Box component="span" sx={{ fontSize: '0.6rem', mr: 0.5 }}>⏱️</Box>
                            Pending: {dateTimeFormatter(message.deliver_after, "day_month_ordinal_humanized_time_period")}
                          </Box>
                        ) : (
                          dateTimeFormatter(message.created_at, "time_only")
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{
                      fontSize: '0.875rem', // Typography body2 font size
                      fontFamily: 'inherit',
                      lineHeight: 1.43, // Typography body2 line height
                      letterSpacing: '0.01071em', // Typography body2 letter spacing
                      ...styles.messageContent
                    }}>
                      <CustomMarkdown>
                        {safeRender(message.content)}
                      </CustomMarkdown>
                    </Box>
                  </Box>
                ))
              }
            </Box>
          ) : (
            <Box
              sx={{
                fontSize: '0.875rem',
                fontFamily: 'inherit',
                lineHeight: 1.43,
                ...styles.messageContent
              }}
            >
              {snakeToTitle(conversation.status)}
            </Box>
          )}

          {/* Next and Info fields - always visible if they exist */}
          {summary && variant !== "small" && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              mt: 2,
            }}>
              {/* Events section - now at the top and expanded by default */}
              {hasEvents && (
                <Box sx={{ width: '100%' }}>
                  <Box
                    onClick={handleToggleEvents}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: darkMode ? 'rgba(146, 146, 255, 0.15)' : 'rgba(146, 146, 255, 0.08)',
                      color: darkMode ? 'rgb(170, 170, 255)' : 'rgb(80, 80, 200)',
                      borderRadius: '8px',
                      padding: '10px 14px',
                      cursor: 'pointer',
                      border: `1px solid ${darkMode ? 'rgba(146, 146, 255, 0.25)' : 'rgba(146, 146, 255, 0.2)'}`,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: darkMode ? 'rgba(146, 146, 255, 0.2)' : 'rgba(146, 146, 255, 0.12)',
                      }
                    }}
                  >
                    <Typography component="div" sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
                      <strong>Events Timeline</strong> {!showEvents && '(click to view)'}
                    </Typography>
                    {showEvents ?
                      <Box sx={{ transform: 'rotate(180deg)' }}>▼</Box> :
                      <Box>▼</Box>
                    }
                  </Box>

                  {/* Collapsible events content */}
                  {showEvents && (
                    <Box
                      sx={{
                        mt: 1,
                        ml: 1,
                        p: 1.5,
                        borderLeft: `2px solid ${darkMode ? 'rgba(146, 146, 255, 0.3)' : 'rgba(146, 146, 255, 0.2)'}`,
                        borderBottomLeftRadius: '8px',
                        backgroundColor: darkMode ? 'rgba(20, 20, 40, 0.3)' : 'rgba(245, 245, 255, 0.5)',
                      }}
                    >
                      {formatEvents(summary.events_log)}
                    </Box>
                  )}
                </Box>
              )}

              {/* High-priority information panels - Next comes right after events */}
              {summary.next_steps && hasContent(summary.next_steps) && (
                <Tooltip title={safeRender(summary.next_steps)} placement="top-start">
                  <Box
                    sx={{
                      backgroundColor: darkMode ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.primary.main, 0.08),
                      color: darkMode ? theme.palette.primary.light : theme.palette.primary.dark,
                      borderRadius: '8px',
                      padding: '10px 14px',
                      width: '100%',
                      fontWeight: 500,
                      fontSize: '0.9rem',
                      border: `1px solid ${darkMode ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.primary.main, 0.15)}`,
                      boxShadow: `0 1px 2px ${alpha(theme.palette.primary.main, 0.1)}`,
                    }}
                  >
                    <strong>Next:</strong> {truncatePriority(safeRender(summary.next_steps))}
                  </Box>
                </Tooltip>
              )}

              {/* Info section now comes after Next */}
              {summary.information && hasContent(summary.information) && (
                <Tooltip title={safeRender(summary.information)} placement="top-start">
                  <Box
                    sx={{
                      backgroundColor: darkMode ? alpha(theme.palette.grey[500], 0.15) : alpha(theme.palette.grey[500], 0.08),
                      color: darkMode ? theme.palette.grey[300] : theme.palette.grey[800],
                      borderRadius: '8px',
                      padding: '10px 14px',
                      width: '100%',
                      fontWeight: 500,
                      fontSize: '0.9rem',
                      border: `1px solid ${darkMode ? alpha(theme.palette.grey[500], 0.2) : alpha(theme.palette.grey[500], 0.15)}`,
                      boxShadow: `0 1px 2px ${alpha(theme.palette.grey[500], 0.1)}`,
                    }}
                  >
                    <strong>Info:</strong> {truncatePriority(safeRender(summary.information))}
                  </Box>
                </Tooltip>
              )}

              {/* Other context fields in a 2 or 3 column grid layout */}
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
                gap: '8px',
                mt: 1,
              }}>
                {/* User communication style */}
                {summary.user_communication_style && hasContent(summary.user_communication_style) && (
                  <Tooltip title={safeRender(summary.user_communication_style)} placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.info.main, 0.15) : alpha(theme.palette.info.main, 0.08),
                        color: darkMode ? theme.palette.info.light : theme.palette.info.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.info.main, 0.2) : alpha(theme.palette.info.main, 0.15)}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        Style: {truncate(safeRender(summary.user_communication_style))}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Issues */}
                {summary.potential_issues && hasContent(summary.potential_issues) && (
                  <Tooltip title={safeRender(summary.potential_issues)} placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.error.main, 0.15) : alpha(theme.palette.error.main, 0.08),
                        color: darkMode ? theme.palette.error.light : theme.palette.error.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.error.main, 0.2) : alpha(theme.palette.error.main, 0.15)}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        Issues: {truncate(safeRender(summary.potential_issues))}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Completed items */}
                {summary.completed_items && hasContent(summary.completed_items) && (
                  <Tooltip title={safeRender(summary.completed_items)} placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.success.main, 0.15) : alpha(theme.palette.success.main, 0.08),
                        color: darkMode ? theme.palette.success.light : theme.palette.success.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.success.main, 0.2) : alpha(theme.palette.success.main, 0.15)}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        Done: {truncate(safeRender(summary.completed_items))}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Admin help needed */}
                {summary.human_admin_help_needed && (
                  <Tooltip title="Human admin assistance is needed" placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.warning.main, 0.15) : alpha(theme.palette.warning.main, 0.08),
                        color: darkMode ? theme.palette.warning.light : theme.palette.warning.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 600,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.warning.main, 0.2) : alpha(theme.palette.warning.main, 0.15)}`,
                      }}
                    >
                      <Typography component="span" sx={{ fontSize: '1.2rem', lineHeight: 1, mr: 0.5 }}>⚠️</Typography>
                      <Typography component="span">Admin Help</Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Outstanding TODOs */}
                {summary.outstanding_to_dos && hasContent(summary.outstanding_to_dos) && (
                  <Tooltip title={safeRender(summary.outstanding_to_dos)} placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.secondary.main, 0.15) : alpha(theme.palette.secondary.main, 0.08),
                        color: darkMode ? theme.palette.secondary.light : theme.palette.secondary.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.secondary.main, 0.2) : alpha(theme.palette.secondary.main, 0.15)}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        TODOs: {truncate(safeRender(summary.outstanding_to_dos))}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Function calls */}
                {summary.outstanding_function_calls && hasContent(summary.outstanding_function_calls) && (
                  <Tooltip title={safeRender(summary.outstanding_function_calls)} placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.info.main, 0.15) : alpha(theme.palette.info.main, 0.08),
                        color: darkMode ? theme.palette.info.light : theme.palette.info.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.info.main, 0.2) : alpha(theme.palette.info.main, 0.15)}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        Fn Calls: {truncate(safeRender(summary.outstanding_function_calls))}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}

                {/* Help needed */}
                {summary.help_needed_reason && hasContent(summary.help_needed_reason) && (
                  <Tooltip title={safeRender(summary.help_needed_reason)} placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: darkMode ? alpha(theme.palette.warning.main, 0.15) : alpha(theme.palette.warning.main, 0.08),
                        color: darkMode ? theme.palette.warning.light : theme.palette.warning.dark,
                        borderRadius: '6px',
                        padding: '6px 10px',
                        fontWeight: 500,
                        fontSize: '0.85rem',
                        border: `1px solid ${darkMode ? alpha(theme.palette.warning.main, 0.2) : alpha(theme.palette.warning.main, 0.15)}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        Help: {truncate(safeRender(summary.help_needed_reason))}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          )}
        </Box>

        {/* Footer with proper event prevention */}
        <Box
          sx={{
            ...styles.footer,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 10px',
            marginTop: 'auto',
            borderTop: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'}`,
            backgroundColor: darkMode ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CloseConversationButton conversation={conversation} />
          <GenerateReplyButton conversation={conversation} />
        </Box>
      </Box>
    </Card>
  );
});

ConversationCard.displayName = "ConversationCard";

export default ConversationCard;
