import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { Contact } from 'src/types';

interface ContactsParams {
  limit?: number;
  [key: string]: any;
}

const fetch_contacts = (params: ContactsParams = {}) => {
  // **Exclude deal_size and score if they are 0**
  if (params.deal_size === 0) {
    delete params.deal_size;
  }
  if (params.score === 0) {
    delete params.score;
  }

  // Process the 'stage' parameter
  if (params.stage) {
    let stage = params.stage;
    // Remove leading numbers and hyphens
    stage = stage.replace(/^\s*\d+-?\s*/, '');
    // Convert to snake_case
    stage = stage.trim().toLowerCase().replace(/\s+/g, '_');
    params = { ...params, stage };
  }
  const url = `${process.env.REACT_APP_BASE_URL}/comms-api/contacts`
  return httpClient.get(url, { params });
}

const show = (contactId) => {
  const url = `${process.env.REACT_APP_BASE_URL}/comms-api/contacts/${contactId}`
  return httpClient.get(url);
}

const update = (data: { id: string, contact?: any }) => {
  const url = `${process.env.REACT_APP_BASE_URL}/comms-api/contacts/${data.id}`
  // Send the updates directly without nesting under 'contact'
  return httpClient.patch(url, data.contact);
};

const destroy = (contactId) => {
  const url = `${process.env.REACT_APP_BASE_URL}/comms-api/contacts/${contactId}`
  return httpClient.delete(url);
};

const create = (data) => {
  const url = `${process.env.REACT_APP_BASE_URL}/comms-api/contacts/`
  return httpClient.post(url, data);
}

const syncWithFub = () => {
  const url = `${process.env.REACT_APP_BASE_URL}/comms-api/contacts/sync_with_fub`;
  return httpClient.post(url);
};

const ContactsService = {
  update,
  destroy,
  fetch_contacts,
  create,
  show,
  syncWithFub // Add syncWithFub to the exported service
};

export default ContactsService;
