import { createAsyncThunk, createSlice, PayloadAction, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { serializeErrorMessage } from 'src/utils';
import ContactsService from 'src/services/contacts.services';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'src/store';
import { debounce } from 'lodash';
import { Contact } from 'src/types/contacts';
import { parseISO, isValid } from 'date-fns';

const contactsAdapter = createEntityAdapter<Contact>({
  selectId: (contact) => contact.id,
  sortComparer: (a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateB - dateA;
  },
});

interface ContactsState extends EntityState<Contact> {
  totalContacts: number;
  contactsLoading: boolean;
  error: string | null;
  creatingContact: boolean;
  creatingContactError: string | null;
  loadingContactsIds: string[];
  updatingContactsIds: string[];
  initialLoad: boolean;
  hasMore: boolean;
  lastCursor: null;
  limit: number;
}

const initialState: ContactsState = contactsAdapter.getInitialState({
  totalContacts: 0,
  contactsLoading: false,
  error: null,
  creatingContact: false,
  creatingContactError: null,
  loadingContactsIds: [],
  updatingContactsIds: [],
  initialLoad: false,
  hasMore: false,
  lastCursor: null,
  limit: 10
});

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async (params: { limit: number; cursor?: string | null }, thunkAPI) => {
    try {
      const response = await ContactsService.fetch_contacts({
        limit: params.limit,
        created_at_before: params.cursor // Using created_at as cursor
      } as any);

      if (!response.data) {
        throw new Error('Invalid response format: no data');
      }

      // Extract pagination info from the response
      const { contacts: contactsData, has_more, total_count } = response.data;

      // Get the last contact's created_at to use as the next cursor
      const lastContact = contactsData[contactsData.length - 1];
      const lastCursor = lastContact ? (
        typeof lastContact.created_at === 'object'
          ? lastContact.created_at.toISOString()
          : lastContact.created_at
      ) : null;

      return {
        contacts: contactsData,
        hasMore: has_more,
        totalCount: total_count,
        lastCursor
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getContact = createAsyncThunk(
  // gets a single contact by id  
  'contacts/getContact', // Ensure the action type string is unique and correctly used in the reducer
  async (contactId: string, thunkAPI) => {
    try {
      const response = await ContactsService.show(contactId);
      if (response.data.success && response.data.contact) {
        return response.data.contact;
      } else {
        return thunkAPI.rejectWithValue('No contacts found');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateContact = createAsyncThunk(
  'contacts/update',
  async ({ contactId, updates }: { contactId: string; updates: Partial<Contact> }, thunkAPI) => {
    try {
      // Restructure the payload to match the API expectations
      // updatingcontactids push this contact id

      const response = await ContactsService.update({
        id: contactId,
        contact: updates  // This will be sent directly to the API
      });

      if (response.data.success && response.data.contact) {
        return response.data.contact;
      } else {
        return thunkAPI.rejectWithValue('Failed to update contact');
      }
    } catch (error) {
      enqueueSnackbar('Error updating contact', { variant: 'error' });
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/delete',
  async (contactId: string, thunkAPI) => {
    try {
      const response = await ContactsService.destroy(contactId);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createContact = createAsyncThunk(
  'contacts/create',
  async (contactData: Partial<Contact>, thunkAPI) => {
    try {
      const response = await ContactsService.create(contactData);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Exported function to reorder contact
export const reorderContactState = (contactId: string, position: number, stage: string) => (dispatch: any) => {
  // Dispatch updateContact with the new position and stage
  dispatch(updateContact({ contactId, updates: { position, stage } }));
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
    },
    resetPagination(state) {
      state.lastCursor = null;
      contactsAdapter.removeAll(state);
    },
    updateContactState: (state, action: PayloadAction<Contact>) => {
      contactsAdapter.upsertOne(state, action.payload);
    },
    removeContactState: (state, action: PayloadAction<string>) => {
      contactsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContacts.pending, (state) => {
        state.contactsLoading = true;
        state.error = null;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contactsLoading = false;
        state.error = null;
        state.initialLoad = true;

        // Update contacts using the adapter
        if (action.meta.arg.cursor) {
          // If we have a cursor, append the new contacts
          contactsAdapter.upsertMany(state, action.payload.contacts);
        } else {
          // If no cursor, replace all contacts
          contactsAdapter.setAll(state, action.payload.contacts);
        }

        // Update pagination state
        state.totalContacts = action.payload.totalCount;
        state.hasMore = action.payload.hasMore;
        state.lastCursor = action.payload.lastCursor;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
      })
      .addCase(createContact.pending, (state) => {
        state.creatingContact = true;
        state.creatingContactError = null;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.creatingContact = false;
        contactsAdapter.addOne(state, action.payload);
      })
      .addCase(createContact.rejected, (state, action) => {
        state.creatingContact = false;
        state.creatingContactError = action.payload as string;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        const contact = action.payload;
        const normalizedContact = {
          ...contact,
          name: contact.name ?? '',
          phone_number: contact.phone_number ?? '',
          email: contact.email ?? '',
          first_name: contact.first_name ?? '',
          last_name: contact.last_name ?? '',
          // Ensure dates are stored as ISO strings
          created_at: contact.created_at ?
            (contact.created_at instanceof Date ? contact.created_at.toISOString() : contact.created_at) :
            undefined,
          updated_at: contact.updated_at ?
            (contact.updated_at instanceof Date ? contact.updated_at.toISOString() : contact.updated_at) :
            undefined,
          last_contacted: contact.last_contacted ?
            (contact.last_contacted instanceof Date ? contact.last_contacted.toISOString() : contact.last_contacted) :
            undefined
        };
        state.loadingContactsIds = state.loadingContactsIds.filter(id => id !== contact.id);
        contactsAdapter.upsertOne(state, normalizedContact);
        state.error = null;
      })
      .addCase(getContact.pending, (state, action) => {
        state.loadingContactsIds.push(action.meta.arg);
        state.error = null;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.loadingContactsIds = state.loadingContactsIds.filter(id => id !== action.meta.arg);
        state.error = action.payload as string;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        // based on the contact id in payload, set the contact to loading false
        const contact = action.payload?.id ? state.entities[action.payload.id] : null;
        if (contact) {
          contact.loading = false;
        }
        if (action.payload?.id) {
          contactsAdapter.upsertOne(state, action.payload);
        }
        state.error = null;
      })
      .addCase(updateContact.pending, (state, action) => {
        // based on the contact id in payload, set the contact to loading true
        const contact = action.meta.arg.contactId ? state.entities[action.meta.arg.contactId] : null;
        if (contact) {
          contact.loading = true;
        }
        if (action.meta.arg.contactId) {
          state.updatingContactsIds.push(action.meta.arg.contactId);
        }
        state.error = null;
      })
      .addCase(updateContact.rejected, (state, action) => {
        // based on the contact id in payload, set the contact to loading false
        const contact = action.meta.arg.contactId ? state.entities[action.meta.arg.contactId] : null;
        if (contact) {
          contact.loading = false;
        }
        state.error = action.payload as string;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        // based on the contact id in payload, set the contact to deleting false
        const contact = state.entities[action.payload.id];
        if (contact) {
          contact.deleting = false;
        }
        contactsAdapter.removeOne(state, action.payload);
        state.error = null;
      })
      .addCase(deleteContact.pending, (state, action) => {
        // find the contact in the state
        const contact = state.entities[action.meta.arg];
        if (contact) {
          contact.deleting = true;
        }
        state.error = null;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        const contact = state.entities[action.meta.arg];
        if (contact) {
          contact.deleting = false;
        }
        state.error = action.payload as string;
      });
  },
});

export const { setLimit, resetPagination, updateContactState, removeContactState } = contactsSlice.actions;

export const {
  selectById: selectContactById,
  selectAll: selectAllContacts,
  selectEntities: selectContactEntities,
} = contactsAdapter.getSelectors<RootState>((state) => state.contacts);

// Additional selectors
export const selectHasMore = (state: RootState) => state.contacts.hasMore;
export const selectLastCursor = (state: RootState) => state.contacts.lastCursor;
export const selectLimit = (state: RootState) => state.contacts.limit;

export default contactsSlice.reducer;

export { contactsAdapter };
