import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchFilteredContacts, selectAllFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { selectContactById } from 'src/features/account/contacts.slice';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { ContactCreateDialog } from './ContactCreateDialog';
import ContactDialog from './ContactDialog';
import IconButton from '@mui/material/IconButton';
import ContactsIcon from '@mui/icons-material/Contacts';
import type { Contact } from '../../types';

interface ContactsSearchAutocompleteProps {
  onSelectContact: (contact: Contact) => void;
  preSelectedContactId?: string | null;
}

const ContactsSearchAutocomplete = ({ 
  onSelectContact, 
  preSelectedContactId = null 
}: ContactsSearchAutocompleteProps) => {
  const dispatch = useAppDispatch();
  const filteredContacts = useAppSelector(selectAllFilteredContacts);
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading);
  const preSelectedContact = useAppSelector(state => 
    preSelectedContactId ? selectContactById(state, preSelectedContactId) : null
  );
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Contact[]>([]);
  const [createContactOpen, setCreateContactOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  // Set initial value if preSelectedContact is provided
  useEffect(() => {
    if (preSelectedContact) {
      setSelectedContact(preSelectedContact);
      if (onSelectContact) {
        onSelectContact(preSelectedContact);
      }
      
      // Add to options if not already there
      setOptions(prev => {
        if (!prev.some(contact => contact.id === preSelectedContact.id)) {
          return [preSelectedContact, ...prev];
        }
        return prev;
      });
    }
  }, [preSelectedContact, onSelectContact]);

  const handleInputChange = useCallback((newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  const fetch = useMemo(
    () =>
      debounce((input) => {
        dispatch(fetchFilteredContacts({ query: input }));
      }, 800),
    [dispatch]
  );

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return;
    }

    fetch(inputValue);
  }, [inputValue, fetch]);

  useEffect(() => {
    setOptions(filteredContacts.filter(contact => contact.email || contact.phone_number));
  }, [filteredContacts]);

  const handleContactCreate = (contact: Partial<Contact>) => {
    // Handle contact creation
    setCreateContactOpen(false);
  };

  const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
    const { children, ...other } = props;
    return (
      <div ref={ref} {...other}>
        {children}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="outlined" color="primary" onClick={() => setCreateContactOpen(true)}>
            + Contact
          </Button>
        </Box>
      </div>
    );
  });
  ListboxComponent.displayName = 'ListboxComponent';

  return (
    <>
      <Autocomplete
        id="search-autocomplete"
        sx={{ width: '100%' }}
        getOptionLabel={(option) => `${option.name || `${option.first_name || ''} ${option.last_name || ''}`} ${option.phone_number ? `(${option.phone_number})` : ''} ${option.email ? `(${option.email})` : ''}`}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        loading={contactsLoading}
        fullWidth
        value={selectedContact}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'input') {
            handleInputChange(newInputValue);
          }
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedContact(newValue);
            if (onSelectContact) {
              onSelectContact(newValue);
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Contacts"
            placeholder="Phone a friend..."
            fullWidth
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {contactsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {option.name}
                </Typography>
                {option.phone_number && (
                  <Typography variant="body2" color="text.secondary">
                    {option.phone_number}
                  </Typography>
                )}
                {option.email && (
                  <Typography variant="body2" color="text.secondary">
                    {option.email}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedContact(option);
                    setContactDialogOpen(true);
                  }}
                >
                  <ContactsIcon />
                </IconButton>
              </Grid>
            </Grid>
          </li>
        )}
        ListboxProps={{
          style: { maxHeight: '300px', overflow: 'auto' },
        }}
        ListboxComponent={ListboxComponent}
      />
      {createContactOpen && (
        <ContactCreateDialog open={createContactOpen} onClose={() => setCreateContactOpen(false)} handleContactUpdate={handleContactCreate} />
      )}
      {selectedContact && (
        <ContactDialog
          contactId={selectedContact.id}
          open={contactDialogOpen}
          onClose={() => setContactDialogOpen(false)}
          handleContactUpdate={(updatedContact) => {
            // Handle contact update if needed
          }}
        />
      )}
    </>
  );
};

const MemoizedContactsSearchAutocomplete = React.memo(ContactsSearchAutocomplete);
MemoizedContactsSearchAutocomplete.displayName = 'ContactsSearchAutocomplete';

export default MemoizedContactsSearchAutocomplete;
