import { Dialog, DialogTitle, DialogContent, Button, CircularProgress, DialogContentText, Box, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Skeleton, Popper, Fade, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'src/store';

interface PhoneNumberSelectDialogProps {
  open: boolean;
  onClose: () => void;
  onPurchaseClick: (phoneRow: any) => void;
  purchaseLoading: boolean;
  loadingPhoneNumbers: boolean;
  phoneNumbers: any[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  areaCode: string;
  setAreaCode: React.Dispatch<React.SetStateAction<string>>;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  subAccountStatus: any;
  a2pApproved: any;
  channelId: any;
}

const PhoneNumberSelectDialog = ({ 
  open, 
  onClose, 
  onPurchaseClick, 
  purchaseLoading,
  loadingPhoneNumbers, 
  searchTerm, 
  setSearchTerm,
  areaCode, 
  setAreaCode,
  country,
  setCountry,
  phoneNumbers, 
  channelId,
  subAccountStatus,
  a2pApproved
}: PhoneNumberSelectDialogProps) => {

  const channel = useAppSelector((state) => state.channels.channels.find(channel => channel.id === channelId));
  const {user} = useAppSelector((state) => state.user);
  const { activeProSubscriber } = useAppSelector((state) => state.auth);

  function getPhonePurchaseUrl() {
    let purchaseUrl;

    // Check if the user has already completed the A2P approval process or started verification
    if (a2pApproved || subAccountStatus !== "verification_not_started") {
      // User has completed A2P process or started verification
      // Select the appropriate subscription link based on whether the user is on the Pro plan
      purchaseUrl = activeProSubscriber
        ? process.env.REACT_APP_STRIPE_PRODUCT_PRO_USER_PHONE_SUBSCRIPTION
        : process.env.REACT_APP_STRIPE_PRODUCT_BASE_PHONE_SUBSCRIPTION;
    } else {
      // User is subscribing to a phone line for the first time and needs to pay the one-time fee
      // Select the appropriate first-time subscription link based on whether the user is on the Pro plan
      purchaseUrl = activeProSubscriber
        ? process.env.REACT_APP_STRIPE_PRODUCT_PRO_PHONE_A2P_SETUP_AND_MONTHLY_SUBSCRIPTION
        : process.env.REACT_APP_STRIPE_PRODUCT_BASE_PHONE_A2P_SETUP_AND_MONTHLY_SUBSCRIPTION;
    }

    return purchaseUrl;
  }

  const columns = [
    // do this datagrid field for id but make it hidden to frontend { field: 'id', headerName: 'ID', width: 150 },
    { field: 'id', headerName: 'ID', width: 150, hidden: true },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'friendlyName', headerName: 'Phone Number', width: 125 },

    { field: 'locality', headerName: 'Locality/City', width: 150 },
    // { field: 'region', headerName: 'Region', width: 70 },
    // { field: 'postalCode', headerName: 'Postal Code', width: 100 },
    { field: 'isoCountry', headerName: 'Country', width: 80 },
    {
      field: 'purchase',
      headerName: '',
      width: 108,
      renderCell: (params) => {
        const selectNumber = () => {
          // onPurchaseClick(params.row);
          // navigate(`https://buy.stripe.com/test_aEUaHB0fY4rvaqseV3?prefilled_email=${user.email}?client_reference_id=${params.row.id + '-' + channel.id}`)
          window.location.assign(`${getPhonePurchaseUrl()}?client_reference_id=${params.row.id + '_' + channel.id}&prefilled_email=${user.email}`);
        }
        return (
          <Button
            variant="contained"
            onClick={selectNumber}
            disabled={purchaseLoading}
            sx={{ width: '108px', height: '40px', borderRadius: '100px', gap: '10px' }}
          >
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '20px', textAlign: 'center', color: '#FFFFFF' }}
            >
              {purchaseLoading ? <CircularProgress size={24} /> : `Purchase`}
            </Typography>
          </Button>
        )
      },
    },
    // { field: 'addressRequirements', headerName: 'Address Requirements', width: 200 },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth

    >
      <DialogTitle id="form-dialog-title">

        <Typography gutterBottom variant="h2" component="div" sx={{fontSize: '20px',fontWeight: 500,lineHeight: '19px',letterSpacing: '0em',textAlign: 'left'}} >
          Register phone number
        </Typography>

        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" sx={{ position: 'absolute', right: '12px', top: '2px' }}>
          <CloseIcon color="action" />
        </IconButton>

        <Typography variant="body1" sx={{  }}>
          Search for a local phone number to assign to your Oppy.
        </Typography>

      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', margin: '10px 0px' }}>
          <FormControl variant="outlined" sx={{ minWidth: 120, marginTop: 1 }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              label="Country"
            >
              <MenuItem value="US">United States</MenuItem>
              <MenuItem value="CA">Canada</MenuItem>
              <MenuItem value="ES">Spain</MenuItem>
              <MenuItem value="FR">France</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ marginTop: 1 }}
            label="Area Code"
            value={areaCode}
            onChange={(event) => setAreaCode(event.target.value)}
            
          />
          <TextField
            sx={{ marginTop: 1 }}
            label="Phrase"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Box>

        {loadingPhoneNumbers &&
          <>
            <CircularProgress />
            <p style={{ margin: 1 }} >Loading Phone Numbers</p>
          </>
        }

        {phoneNumbers &&
          <>
            <DialogContentText sx={{ marginTop: 2 }}>
              <Typography
                variant="body1"
                sx={{
                }}
              >
                {phoneNumbers.length} phone numbers found.
                {phoneNumbers.length > 0 ? `Select a phone number from the list below then click "Purchase" to assign it to your chatbot channel.` : `Type above and phone numbers will appear.`}
              </Typography>
            </DialogContentText>
            <Box
              sx={{
                width: '100%',
                height: 'auto',
                minHeight: '200px',
                borderRadius: '10px',
                border: '0.5px solid #21212114',
                margin: "20px 0px"
              }}

            >
              { !loadingPhoneNumbers ?
                <DataGrid
                  columnVisibilityModel={{
                    id: false,
                    phoneNumber: false,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  rows={phoneNumbers}
                  columns={columns}
                  // onRowClick={(rowParams) => setSelectedPhoneNumber(rowParams.row)}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: 'bold',
                    },
                    minHeight: '200px',
                  }}
                />
                  :
                  <>
                  <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
                  <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
                  <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
                  </>
              } 
            </Box>
          </>
        }

      </DialogContent>


      {/* <DialogActions
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%', justifyContent:'flex-end',alignItems: 'flex-end', mb:3 }}>
          <Button
            onClick={onClose}
            sx={{width: '80px',height: '40px',padding: '10px 24px',borderRadius: '100px',border: '1px solid #79747E',gap: '8px',color: '#6750A4'
            }}
          >
            back
          </Button>
          <Button
            variant="contained"
            onClick={() => handlePurchaseClick()}
            disabled={purchaseLoading || !selectedPhoneNumber}
            sx={{width: '108px',height: '40px',padding: '10px 24px',borderRadius: '100px',gap: '10px',color: '#6750A4'}}
          >
            <Typography
              sx={{fontSize: '14px',fontWeight: 500,lineHeight: '20px', textAlign: 'center',color: '#FFFFFF'}}
            >
              {purchaseLoading ? <CircularProgress size={24} /> : `Purchase`}
            </Typography>
          </Button>
        </Box>



      </DialogActions> */}
    </Dialog >
  );
};

export default PhoneNumberSelectDialog;
