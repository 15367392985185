import { createAsyncThunk, createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  Conversation,
  fetchConversationsWithMessages,
  fetchConversationWithMessages,
  sendMessage,
  updateConversationStatus,
} from './conversations.slice';
import { serializeErrorMessage } from '../../utils';
import { httpClient } from 'src/libs';

export const priorityStatuses = ['needs_attention', 'error'];

interface FilteredConversationsState extends EntityState<Conversation> {
  conversationsLoading: boolean;
  conversationsPresent: boolean;
  error: string | null;
  filterObject: any;
  conversationsUpdatedSinceFilterSet: boolean;
  isFilterActive: boolean;
  totalConversations: number;
  hasMore: boolean;
  lastCursor: string | null;
  limit: number;
  priorityConversations: Conversation[];
  priorityConversationsLoading: boolean;
}

// Create entity adapter for conversations
const filteredConversationsAdapter = createEntityAdapter<Conversation>({
  selectId: (conversation) => conversation.id,
  sortComparer: (a, b) => {
    const dateA = a.latest_message_at || a.updated_at;
    const dateB = b.latest_message_at || b.updated_at;
    return dateB.localeCompare(dateA);
  },
});

// Initial state using the adapter's getInitialState method
const initialState: FilteredConversationsState = filteredConversationsAdapter.getInitialState({
  conversationsLoading: false,
  conversationsPresent: false,
  error: null,
  filterObject: {},
  conversationsUpdatedSinceFilterSet: false,
  isFilterActive: false,
  totalConversations: 0,
  hasMore: false,
  lastCursor: null,
  limit: 15,
  priorityConversations: [],
  priorityConversationsLoading: false,
});

// Async thunk to fetch filtered conversations
export const fetchFilteredConversations = createAsyncThunk(
  'filteredConversations/fetch',
  async (queryParams: any, thunkAPI) => {
    try {
      const apiParams: any = { ...queryParams };

      const response = await thunkAPI.dispatch(fetchConversationsWithMessages(apiParams));
      if (response.payload && response.payload.conversations) {
        return response.payload.conversations;
      } else {
        return thunkAPI.rejectWithValue('Failed to fetch conversations');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchPriorityConversations = createAsyncThunk(
  'filteredConversations/fetchPriority',
  async (_, { rejectWithValue }) => {
    try {
      // Fetch priority status conversations
      const statusQueryParams = new URLSearchParams({
        limit: '50',
        messages_limit: '3',
        status: priorityStatuses.join(',')
      }).toString();

      const statusResponse = await httpClient.get(`${process.env.REACT_APP_BASE_URL}/comms-api/conversations/?${statusQueryParams}`);

      if (!statusResponse.data?.success) {
        return rejectWithValue('Failed to fetch priority conversations');
      }

      const statusConversations = statusResponse.data.conversations || [];

      // Sort conversations by latest message time
      const sortedConversations = statusConversations.sort((a: Conversation, b: Conversation) => {
        const timeA = new Date(a.latest_message_at || a.updated_at).getTime();
        const timeB = new Date(b.latest_message_at || b.updated_at).getTime();
        return timeB - timeA;
      });

      return sortedConversations;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const filteredConversationsSlice = createSlice({
  name: 'filteredConversations',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filterObject = action.payload;
      // Determine if the filter is active
      const values = Object.values(action.payload);
      state.isFilterActive = values.some((value) => {
        if (Array.isArray(value)) return value.length > 0;
        return value !== '' && value != null;
      });
      state.conversationsUpdatedSinceFilterSet = false;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchFilteredConversations actions
    builder
      .addCase(fetchFilteredConversations.pending, (state) => {
        state.conversationsLoading = true;
        state.error = null;
      })
      .addCase(fetchFilteredConversations.fulfilled, (state, action) => {
        filteredConversationsAdapter.setAll(state, action.payload);
        state.conversationsLoading = false;
        state.conversationsPresent = action.payload.length > 0;
        state.error = null;
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(fetchFilteredConversations.rejected, (state, action) => {
        state.conversationsLoading = false;
        state.error = action.error.message as string;
        console.error('Error fetching filtered conversations:', state.error);
      })
      // Handle actions from conversations.slice.tsx
      .addCase(fetchConversationsWithMessages.fulfilled, (state, action) => {
        if (!state.isFilterActive) {
          filteredConversationsAdapter.setAll(state, action.payload.conversations);
        } else {
          state.conversationsUpdatedSinceFilterSet = true;
        }
      })
      .addCase(fetchConversationWithMessages.fulfilled, (state, action) => {
        filteredConversationsAdapter.upsertOne(state, action.payload);
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const conversationId = action.payload.conversation_id;
        const message = action.payload.message;
        const existingConversation = state.entities[conversationId];
        if (existingConversation) {
          const messageExists = existingConversation.messages.some((msg) => msg.id === message.id);
          if (!messageExists) {
            // Create a new array with all messages plus the new one
            // This avoids mutating state.entities[conversationId].messages directly
            state.entities[conversationId].messages = [
              ...existingConversation.messages,
              { ...message } // Create a copy of the message to avoid reference issues
            ];
          }
        }
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(updateConversationStatus.fulfilled, (state, action) => {
        const conversation = action.payload.conversation;
        filteredConversationsAdapter.upsertOne(state, conversation);
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(fetchPriorityConversations.pending, (state) => {
        state.priorityConversationsLoading = true;
      })
      .addCase(fetchPriorityConversations.fulfilled, (state, action) => {
        state.priorityConversationsLoading = false;
        // Store priority conversations separately
        state.priorityConversations = action.payload;
        // We don't use upsertMany here anymore - keeps priority separate from adapter state
      })
      .addCase(fetchPriorityConversations.rejected, (state) => {
        state.priorityConversationsLoading = false;
        state.priorityConversations = [];
      });
  },
});

export const { setFilter } = filteredConversationsSlice.actions;

export const {
  selectById: selectFilteredConversationById,
  selectAll: selectAllFilteredConversations,
} = filteredConversationsAdapter.getSelectors(
  (state: RootState) => state.filteredConversations
);

export const selectIsFilterActive = (state: RootState) =>
  state.filteredConversations.isFilterActive;

export const selectPriorityConversations = (state: RootState) => state.filteredConversations.priorityConversations;

export default filteredConversationsSlice.reducer;
