import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColDef, GridRowsProp, GridToolbar, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteContact, selectLimit, updateContact } from 'src/features/account/contacts.slice';
import ContactDialog from './ContactDialog';
import { ContactCreateDialog } from './ContactCreateDialog';
import ConversationCreateDialogV2 from '../Channels/ConversationCreateDialogV2';
import {
  Box,
  IconButton,
  Button,
  useTheme,
  alpha,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { contactsAdapter, selectContactById } from 'src/features/account/contacts.slice';
import { selectAllFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { setOpenDialog } from 'src/features/theme/theme.slice';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import ConversationCallOptions from '../Channels/ConversationCallOptions';
import { useColorMode } from 'src/theme/ThemeProvider';

interface ContactsDataGridProps {
  onSelectionChange: (selectedIds: string[]) => void;
  selectedContactIds: string[];
  currentPage: number;
}

const ContactsDataGrid: React.FC<ContactsDataGridProps> = ({
  onSelectionChange,
  selectedContactIds,
  currentPage
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';
  const { totalContacts } = useAppSelector(state => state.contacts);
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading);
  const contacts = useAppSelector(selectAllFilteredContacts);
  const [createContactDialog, setCreateContactDialog] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [createConversationOpen, setCreateConversationOpen] = useState(false);
  const [rows, setRows] = useState<GridRowsProp>([]);  // Define state for rows
  const selectedContact = useAppSelector(state => selectContactById(state, selectedContactId));
  const [callDialogOpen, setCallDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const limit = useAppSelector(selectLimit);

  useEffect(() => {
    // Initialize rows when contacts are fetched
    setRows(contacts.map(contact => ({ ...contact, id: contact.id })));
  }, [contacts]);

  useEffect(() => {
    // Update the selection model when selectedContactIds prop changes
    setRowSelectionModel(selectedContactIds as GridRowId[]);
  }, [selectedContactIds]);

  const handleContactUpdate = (updatedContact) => {
    // Update the row in the rows state
    const updatedRows = rows.map((row) => {
      if (row.id === updatedContact.id) {
        return { ...row, ...updatedContact };
      }
      return row;
    });
    setRows(updatedRows);  // Update rows state
  };

  const handleRowClick = (params) => {
    setSelectedContactId(params.id.toString());
    setCreateContactDialog(true);
  };

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      // Dispatch the updateContact action with the updated row data
      await dispatch(updateContact(newRow)).unwrap();
      return newRow;
    } catch (error) {
      console.error('Failed to update contact', error);
      // Return the old row data to revert the changes in the UI
      return oldRow;
    }
  };

  const handleOpenCreateConversation = (params: GridCellParams) => {
    setSelectedContactId(params.id.toString());
    setCreateConversationOpen(true);
  }

  const handleCloseCreateConversation = () => {
    setSelectedContactId(null);
    setCreateConversationOpen(false);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      renderCell: (params) => (
        <>
          <img src={params.row.picture_url} alt={params.value} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
          {params.value || 'Unknown'}
        </>
      )
    },
    { field: 'first_name', headerName: 'First Name', width: 150, editable: true },
    { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
    { field: 'email', headerName: 'Email', width: 200, editable: true },
    {
      field: 'phone_number', headerName: 'Phone Number', width: 150, editable: true, renderCell: (params) => (
        <Button onClick={() => handleOpenCreateConversation(params)}>
          {params.value}
        </Button>
      ),
    },
    { field: 'company_name', headerName: 'Company Name', width: 150, editable: true },
    { field: 'status', headerName: 'Status', width: 100, editable: true },
    { field: 'notes', headerName: 'Notes', width: 200, editable: true },
    {
      field: 'last_contacted', headerName: 'Last Contacted', width: 200, editable: true, renderCell: (params) => (
        <>{params.value ? new Date(params.value).toLocaleString() : ''}</>
      )
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (window.confirm(`Are you sure you want to delete ${params.row.first_name} ${params.row.last_name} - ${params.row.phone_number}, ${params.row.email}?`)) {
              dispatch(deleteContact(params.id.toString()));
            }
          }}
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: 'created_at', headerName: 'Created At', width: 200, editable: true, renderCell: (params) => (
        <>{params.value ? new Date(params.value).toLocaleString() : ''}</>
      )
    },
    {
      field: 'updated_at', headerName: 'Updated At', width: 200, editable: true, renderCell: (params) => (
        <>{params.value ? new Date(params.value).toLocaleString() : ''}</>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => {
              setSelectedContactId(params.id.toString());
              setCallDialogOpen(true);
            }}
            color="primary"
            size="small"
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleOpenCreateConversation(params)
            }}
            color="primary"
            size="small"
          >
            <MessageIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'metadata', headerName: 'Metadata', width: 200, editable: true, renderCell: (params) => (
        <>{JSON.stringify(params.value)}</>
      )
    },
  ];

  const styles = {
    mainContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      flexGrow: 1,
      borderRadius: 3,
      height: '100%',
      position: 'relative',
      backgroundColor: isDarkMode ? theme.palette.background.default : theme.palette.background.paper,
    },
    contactsContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '0px',
      p: 0,
      alignItems: 'stretch',
      flexWrap: 'wrap',
      transition: 'all 0.3s ease',
      backgroundColor: isDarkMode ? theme.palette.background.default : theme.palette.background.paper,
      flex: 1,
      minHeight: 0, // Important for flex child to respect parent height
    },
    contactsGrid: {
      height: '100%',
      width: '100%',
      '& .MuiDataGrid-root': {
        backgroundColor: isDarkMode ? theme.palette.background.paper : theme.palette.background.default,
        color: isDarkMode ? theme.palette.text.primary : theme.palette.text.primary,
        borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
        height: '100%',
      },
      '& .MuiDataGrid-cell': {
        borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.primary.main, 0.05),
        color: isDarkMode ? theme.palette.text.primary : theme.palette.text.primary,
        borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          backgroundColor: isDarkMode ? alpha(theme.palette.action.hover, 0.1) : theme.palette.action.hover,
        },
        '&.Mui-selected': {
          backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.primary.main, 0.1),
          '&:hover': {
            backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.3) : alpha(theme.palette.primary.main, 0.2),
          },
        },
      },
      '& .MuiDataGrid-main': {
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.3) : alpha(theme.palette.background.default, 0.3),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.primary.main, 0.2),
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.3) : alpha(theme.palette.primary.main, 0.3),
          },
        },
        overflow: 'auto',
        scrollbarWidth: 'thin',
      },
      '& .MuiDataGrid-virtualScrollerContent': {
        minHeight: '100%',
      },
      '& .MuiDataGrid-virtualScroller': {
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          display: 'block',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.3) : alpha(theme.palette.background.default, 0.3),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.primary.main, 0.2),
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.3) : alpha(theme.palette.primary.main, 0.3),
          },
        },
        overflow: 'auto !important',
        scrollbarWidth: 'thin',
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: isDarkMode ? theme.palette.background.paper : theme.palette.background.default,
        borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
      },
      '& .MuiDataGrid-toolbarContainer': {
        backgroundColor: isDarkMode ? theme.palette.background.paper : theme.palette.background.default,
        borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
        '& .MuiButton-root': {
          color: isDarkMode ? theme.palette.text.secondary : theme.palette.text.primary,
        },
      },
      '& .MuiCheckbox-root': {
        color: isDarkMode ? theme.palette.text.secondary : theme.palette.text.primary,
      },
      '& .MuiTablePagination-root': {
        color: isDarkMode ? theme.palette.text.primary : theme.palette.text.primary,
      },
    },
  };

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.contactsContainer}>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: {
                  page: 1,
                  pageSize: limit
                }
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  picture_url: false,
                },
              },
            }}
            sx={styles.contactsGrid}
            loading={contactsLoading}
            processRowUpdate={processRowUpdate}
            onRowClick={handleRowClick}
            paginationMode="server"
            paginationModel={{
              page: currentPage,
              pageSize: limit
            }}
            rowCount={totalContacts}
            slots={{
              toolbar: GridToolbar,
              pagination: () => null,
            }}
            checkboxSelection
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
              const selectedIds = newRowSelectionModel.map((id) => id.toString());
              onSelectionChange(selectedIds);
            }}
            pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
            disableRowSelectionOnClick
            hideFooter
          />
        </Box>
      </Box>

      {selectedContactId && (
        <ContactDialog
          open={createContactDialog}
          onClose={() => setCreateContactDialog(false)}
          contactId={selectedContact.id}
          handleContactUpdate={handleContactUpdate}
        />
      )}

      {selectedContact && createConversationOpen && (
        <ConversationCreateDialogV2
          open={createConversationOpen}
          handleClose={handleCloseCreateConversation}
          incomingDraftMessage={""}
          incomingContactId={selectedContact.id}
          incomingChannelId={selectedChannelId || ""}
        />
      )}

      <ConversationCallOptions
        open={callDialogOpen}
        channelId={selectedChannelId}
        incomingContactId={selectedContactId}
        onClose={() => setCallDialogOpen(false)}
      />
    </Box>
  );
};

export default ContactsDataGrid;
