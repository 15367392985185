import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { serializeErrorMessage } from '../../utils';
import { Contact } from '../../types';
import ContactsService from '../../services/contacts.services';
import { RootState } from '../../store';
import { createContact, updateContact, deleteContact, getContacts, getContact } from './contacts.slice';

const filteredContactsAdapter = createEntityAdapter<Contact>({
  selectId: (contact) => contact.id,
  sortComparer: (a, b) => {
    if (a.similarity_score !== undefined && b.similarity_score !== undefined) {
      const scoreA = typeof a.similarity_score === 'string' ? parseFloat(a.similarity_score) : a.similarity_score;
      const scoreB = typeof b.similarity_score === 'string' ? parseFloat(b.similarity_score) : b.similarity_score;
      return scoreB - scoreA; // Sort by score descending
    }
    // Convert date strings to Date objects for comparison
    const dateA = new Date(a.created_at || 0);
    const dateB = new Date(b.created_at || 0);
    return dateB.getTime() - dateA.getTime(); // Sort by most recently created first
  },
});

const initialState = filteredContactsAdapter.getInitialState({
  contactsLoading: false,
  contactsPresent: false,
  error: null,
  filterObject: {},
  contactsUpdatedSinceFilterSet: false,
  isFilterActive: false, // Add isFilterActive to state
  hasMore: false,
  lastCursor: null,
  totalContacts: 0
});

export const fetchFilteredContacts = createAsyncThunk(
  'filteredContacts/fetch',
  async (queryParams: { query?: string; cursor?: string | null } & Partial<Contact>, thunkAPI) => {
    try {
      const response = await ContactsService.fetch_contacts({
        ...queryParams,
        created_at_before: queryParams.cursor // Using created_at as cursor
      });

      if (response.data.success) {
        const contacts = response.data.contacts;
        const lastContact = contacts[contacts.length - 1];
        return {
          contacts,
          hasMore: response.data.has_more,
          lastCursor: lastContact ? lastContact.created_at : null,
          totalCount: response.data.total_count
        };
      } else {
        return thunkAPI.rejectWithValue('Failed to fetch contacts');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const filteredContactsSlice = createSlice({
  name: 'filteredContacts',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filterObject = action.payload;
      state.lastCursor = null; // Reset cursor when filter changes
      state.hasMore = false; // Reset hasMore when filter changes
      state.totalContacts = action.payload.totalCount;
      state.hasMore = action.payload.hasMore;

      // Update isFilterActive with proper null checks
      state.isFilterActive = Object.values(state.filterObject).some((value: any) => {
        if (value === null || value === undefined || value === '') {
          return false;
        }
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return true;
      });

      state.contactsUpdatedSinceFilterSet = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contactsLoading = false;
        state.error = null;
        filteredContactsAdapter.setAll(state, action.payload.contacts);
        state.totalContacts = action.payload.totalCount;
      })
      .addCase(fetchFilteredContacts.pending, (state) => {
        state.contactsLoading = true;
        state.error = null;
      })
      .addCase(fetchFilteredContacts.fulfilled, (state, action) => {
        filteredContactsAdapter.setAll(state, action.payload.contacts);
        state.contactsLoading = false;
        state.contactsPresent = action.payload.contacts.length > 0;
        state.error = null;
        state.contactsUpdatedSinceFilterSet = false;
        state.totalContacts = action.payload.totalCount;
        state.hasMore = action.payload.hasMore;
      })
      .addCase(fetchFilteredContacts.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        filteredContactsAdapter.addOne(state, action.payload);
        state.contactsUpdatedSinceFilterSet = false;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        filteredContactsAdapter.upsertOne(state, action.payload);
        state.contactsUpdatedSinceFilterSet = false;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        filteredContactsAdapter.removeOne(state, action.meta.arg);
        state.contactsUpdatedSinceFilterSet = false;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        // Update the filteredContacts state with the fetched contact
        filteredContactsAdapter.upsertOne(state, action.payload);
        state.contactsUpdatedSinceFilterSet = false;
      });
  },
});

export const { setFilter } = filteredContactsSlice.actions;

export const selectAllFilteredContacts = (state: RootState) =>
  filteredContactsAdapter.getSelectors().selectAll(state.filteredContacts);

export const selectFilteredContactById = (state: RootState, id: string) =>
  filteredContactsAdapter.getSelectors().selectById(state.filteredContacts, id);

export const selectIsFilterActive = (state: RootState) => state.filteredContacts.isFilterActive;

// Additional selectors
export const selectHasMore = (state: RootState) => state.filteredContacts.hasMore;
export const selectLastCursor = (state: RootState) => state.filteredContacts.lastCursor;

export default filteredContactsSlice.reducer;
