import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  Checkbox,
  Button,
  FormControlLabel,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  TableSortLabel,
  Tooltip,
  MenuItem,
  Select,
  SelectChangeEvent,
  Menu,
  Paper,
  Chip,
  Divider,
  alpha,
  Badge,
  Stack,
  InputBase,
  TextField,
  InputAdornment,
  Toolbar,
  useTheme,
  Popper,
  Fade,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactDialog from './ContactDialog';
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteContact, selectContactById, updateContact } from 'src/features/account/contacts.slice';
import { selectAllFilteredContacts, fetchFilteredContacts, selectIsFilterActive } from 'src/features/account/filteredContacts.slice';
import { Contact } from 'src/types/contacts';
import { styled } from '@mui/material/styles';
// Import the conversation dialog
import {
  Star,
  MoreVert,
  FilterList,
  Search,
  CheckCircle,
  MailOutline,
  Phone,
  Delete,
  Close as CloseIcon,
  ChatBubbleOutline,
  Message,
  Person,
  ImportExport,
  Label,
} from '@mui/icons-material';
// Import the conversation dialog
import ConversationCreateDialogV2 from '../Channels/ConversationCreateDialogV2';

// Define sorting types
type SortDirection = 'asc' | 'desc';
type SortField = 'created_at' | 'updated_at' | 'has_conversations' | 'name';

interface ContactsListProps {
  onSelectionChange: (selectedIds: string[]) => void;
  selectedContactIds: string[];
}

interface ContactListItemProps {
  contact: Contact;
  selectedIds: string[];
  handleToggle: (contactId: string) => void;
  setEditDialogOpen: (open: boolean) => void;
  setSelectedContactId: (contactId: string) => void;
}

// Table column width definitions (to maintain consistent column sizes)
const ColumnWidths = {
  checkbox: '28px',  // Further reduced from 30px
  favorite: '28px',  // Further reduced from 30px
  info: '480px',     // Increased from 450px to accommodate shared notes
  date: '120px',
  conversations: '120px',
  actions: '120px', // Aligned right
};

// Styling for table
const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderCollapse: 'collapse',
  '& .MuiTableCell-root': {
    padding: theme.spacing(1, 1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.mode === 'dark'
      ? alpha(theme.palette.divider, 0.05)
      : alpha(theme.palette.divider, 0.08)}`,
    '&.MuiTableCell-head': {
      fontWeight: 500,
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : theme.palette.background.default
    }
  },
  '& .MuiTableHead-root': {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.background.default,
    zIndex: 2
  },
  '& .MuiTableBody-root': {
    position: 'relative'
  },
  '& .MuiTableRow-root': {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? alpha(theme.palette.action.hover, 0.1)
        : alpha(theme.palette.action.hover, 0.05)
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.main, 0.15)
        : alpha(theme.palette.primary.main, 0.05),
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark'
          ? alpha(theme.palette.primary.main, 0.2)
          : alpha(theme.palette.primary.main, 0.08)
      }
    }
  }
}));

// Styled table rows
const StyledTableRow = styled(TableRow)<{
  selected?: boolean;
  hover?: boolean;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
}>(({ theme }) => ({
  cursor: 'pointer',
  height: '64px', // Increased row height
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.action.hover, 0.1)
      : alpha(theme.palette.action.hover, 0.05),
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.primary.main, 0.15)
      : alpha(theme.palette.primary.main, 0.05),
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.main, 0.2)
        : alpha(theme.palette.primary.main, 0.08),
    }
  }
}));

// Table wrapper
const TableWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.mode === 'dark'
    ? alpha(theme.palette.divider, 0.1)
    : theme.palette.divider}`,
}));

// Action toolbar
const ActionsToolbar = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.05)}`, // Even more subtle
  minHeight: '64px',
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.7)
    : theme.palette.background.paper,
}));

// Status dot for contacts
const StatusDot = styled('span')<{ status: string }>(({ theme, status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'active': return theme.palette.success.main;
      case 'pending': return theme.palette.warning.main;
      case 'inactive': return theme.palette.grey[400];
      default: return theme.palette.info.main; // new
    }
  };

  return {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: getStatusColor(),
    display: 'inline-block',
    marginRight: 8,
  };
});

// Animated delete action container
const AnimatedDeleteAction = styled('div')({
  position: 'relative',
  display: 'inline-flex',
  width: '32px', // Fixed width to prevent layout shift when confirming delete
});

// Action buttons container
const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(0.5),
}));

const ContactListItem: React.FC<ContactListItemProps> = ({
  contact,
  selectedIds,
  handleToggle,
  setEditDialogOpen,
  setSelectedContactId,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
  const [showNotesPreview, setShowNotesPreview] = useState(false);
  const [notesAnchorEl, setNotesAnchorEl] = useState<null | HTMLElement>(null);

  const isSelected = selectedIds.indexOf(contact.id) !== -1;
  let confirmTimeout: NodeJS.Timeout;
  let notesPreviewTimeout: NodeJS.Timeout;

  // Get the full contact name
  const contactName = contact.name || `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || 'Unknown';

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    showSharedNotesPreview(e);
    setSelectedContactId(contact.id);
    setEditDialogOpen(true);
  };

  const handleRowClick = () => {
    setSelectedContactId(contact.id);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    showSharedNotesPreview(e);
    setIsConfirming(true);
    confirmTimeout = setTimeout(() => {
      setIsConfirming(false);
    }, 3000);
  };

  const handleConfirmDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(confirmTimeout);
    setIsConfirming(false);
    dispatch(deleteContact(contact.id)).then(() => {
      dispatch(fetchFilteredContacts({ query: '' }));
    });
  };

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    showSharedNotesPreview(e);
    if (contact) {
      const updatedTags = contact.tags?.includes('favorite')
        ? contact.tags.filter(tag => tag !== 'favorite')
        : [...(contact.tags || []), 'favorite'];
      setIsFavoriting(true);
      dispatch(updateContact({
        contactId: contact.id.toString(),
        updates: {
          favorite: !contact.favorite,
          tags: updatedTags
        }
      })).then(() => {
        setIsFavoriting(false);
      });
    }
  };

  const handleOpenConversation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    showSharedNotesPreview(e);
    setConversationDialogOpen(true);
  };

  // Function to show shared notes preview
  const showSharedNotesPreview = (e: React.MouseEvent) => {
    setNotesAnchorEl(e.currentTarget as HTMLElement);
    setShowNotesPreview(true);

    // Auto-hide the preview after 5 seconds
    clearTimeout(notesPreviewTimeout);
    notesPreviewTimeout = setTimeout(() => {
      setShowNotesPreview(false);
    }, 5000);
  };

  // Function to hide shared notes preview
  const hideSharedNotesPreview = () => {
    setShowNotesPreview(false);
    clearTimeout(notesPreviewTimeout);
  };

  // Get a truncated preview of shared notes
  const getNotesPreview = () => {
    if (!contact.shared_notes) return 'No shared notes';
    return contact.shared_notes.length > 100
      ? `${contact.shared_notes.substring(0, 100)}...`
      : contact.shared_notes;
  };

  useEffect(() => {
    return () => {
      if (confirmTimeout) clearTimeout(confirmTimeout);
      if (notesPreviewTimeout) clearTimeout(notesPreviewTimeout);
    };
  }, []);

  if (!contact) return null;

  // Get contact status - this could be based on tags, activity, etc.
  const getContactStatus = (): string => {
    if (contact.last_contacted) {
      const lastActive = new Date(contact.last_contacted);
      const now = new Date();
      const daysSinceActive = Math.floor((now.getTime() - lastActive.getTime()) / (1000 * 60 * 60 * 24));

      if (daysSinceActive < 7) return 'active';
      if (daysSinceActive < 30) return 'pending';
      return 'inactive';
    }
    return 'new';
  };

  // Get tooltip text for status dot
  const getStatusTooltip = (status: string): string => {
    switch (status) {
      case 'active':
        return 'Active: Contacted within the last 7 days';
      case 'pending':
        return 'Pending: Contacted between 7-30 days ago';
      case 'inactive':
        return 'Inactive: No contact in over 30 days';
      case 'new':
        return 'New: Never contacted';
      default:
        return 'Unknown status';
    }
  };

  // Format dates for display
  const formatDate = (dateString?: Date) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);

    // If it's today, show the time, otherwise show the date
    const today = new Date();
    const isToday = date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    if (isToday) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    // If it's this year, don't show the year
    if (date.getFullYear() === today.getFullYear()) {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }

    return date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' });
  };

  return (
    <StyledTableRow
      key={contact.id}
      selected={isSelected}
      onClick={handleRowClick}
      hover
    >
      <TableCell padding="checkbox" sx={{
        width: ColumnWidths.checkbox,
        pl: 1, // Further reduced left padding
        pr: 0, // No right padding
      }}>
        <Checkbox
          color="primary"
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation();
            handleToggle(contact.id);
          }}
          onClick={(e) => e.stopPropagation()}
          disabled={contact.deleting}
          size="small"
          sx={{ p: 0.25 }} // Smaller padding for the checkbox itself
        />
      </TableCell>

      <TableCell padding="checkbox" sx={{
        width: ColumnWidths.favorite,
        px: 0, // No horizontal padding
      }}>
        <Tooltip
          title={
            <Box>
              <Typography variant="subtitle2">{contact.favorite ? 'Remove from Favorites' : 'Add to Favorites'}</Typography>
              {contact.shared_notes && (
                <Box sx={{ mt: 1, pt: 1, borderTop: `1px solid ${alpha('#fff', 0.3)}` }}>
                  <Typography variant="caption" fontWeight="bold" display="block">Shared Notes:</Typography>
                  <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap', display: 'block', maxHeight: '150px', overflow: 'auto' }}>
                    {contact.shared_notes}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          placement="top"
          arrow
        >
          <IconButton
            edge="end"
            aria-label="favorite"
            onClick={handleFavoriteClick}
            disabled={contact.deleting}
            size="small"
            sx={{
              color: contact.favorite ? 'gold' : alpha(theme.palette.text.primary, 0.5),
              '&:hover': {
                color: 'gold',
              },
              p: 0.25, // Smaller padding for the icon
            }}
          >
            <Star fontSize="small" sx={{
              fill: contact.favorite ? 'gold' : 'none',
              stroke: contact.favorite ? 'none' : 'currentColor',
              animation: isFavoriting ? 'pulse 0.5s ease' : 'none',
              '@keyframes pulse': {
                '0%': { transform: 'scale(1)' },
                '50%': { transform: 'scale(1.3)' },
                '100%': { transform: 'scale(1)' },
              },
            }} />
          </IconButton>
        </Tooltip>
      </TableCell>

      <TableCell sx={{
        width: ColumnWidths.info,
        maxWidth: ColumnWidths.info,
        overflow: 'hidden',
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" noWrap fontWeight={500} sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={getStatusTooltip(getContactStatus())} placement="top-start">
              <Box component="span">
                <StatusDot status={getContactStatus()} />
              </Box>
            </Tooltip>
            <Tooltip title={contactName}>
              <span>{contactName}</span>
            </Tooltip>
          </Typography>

          {/* Contact info row with email, phone and tags */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 0.75,
              width: '100%',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                height: '3px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme => alpha(theme.palette.divider, 0.3),
                borderRadius: '3px',
              },
            }}
          >
            {/* Email */}
            {contact.email && (
              <Tooltip title={contact.email}>
                <Typography variant="body2" color="text.secondary" noWrap display="flex" alignItems="center" sx={{ minWidth: 'fit-content' }}>
                  <MailOutline fontSize="small" sx={{ mr: 0.5, opacity: 0.7, fontSize: '1rem' }} />
                  {contact.email}
                </Typography>
              </Tooltip>
            )}

            {/* Phone */}
            {contact.phone_number && (
              <Tooltip title={contact.phone_number}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  noWrap
                  display="flex"
                  alignItems="center"
                  ml={contact.email ? 2 : 0}
                  sx={{ minWidth: 'fit-content' }}
                >
                  <Phone fontSize="small" sx={{ mr: 0.5, opacity: 0.7, fontSize: '1rem' }} />
                  {contact.phone_number}
                </Typography>
              </Tooltip>
            )}

            {/* Tags */}
            {contact.tags?.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.75,
                  ml: (contact.phone_number || contact.email) ? 2 : 0,
                  minWidth: 'fit-content',
                }}
              >
                {contact.tags.map((tag, index) => (
                  <Tooltip key={index} title={tag}>
                    <Chip
                      label={tag}
                      size="small"
                      sx={{
                        height: 20,
                        fontSize: '0.7rem',
                        opacity: 0.8,
                        '& .MuiChip-label': { px: 1 },
                      }}
                    />
                  </Tooltip>
                ))}
              </Box>
            )}
          </Box>

          {/* Display shared notes in subdued styling */}
          {contact.shared_notes && (
            <Box sx={{
              width: '100%',
              mt: 0.5,
              mb: 0.5
            }}>
              <Tooltip
                title={contact.shared_notes}
                placement="top-start"
                arrow
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme => alpha(theme.palette.text.secondary, 0.75),
                    fontStyle: 'italic',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    fontSize: '0.75rem',
                    lineHeight: 1.3,
                    padding: '3px 5px',
                    backgroundColor: theme => alpha(theme.palette.divider, 0.08),
                    borderRadius: '4px',
                  }}
                >
                  {contact.shared_notes.length > 60
                    ? `${contact.shared_notes.substring(0, 60)}...`
                    : contact.shared_notes}
                </Typography>
              </Tooltip>
            </Box>
          )}
        </Box>
      </TableCell>

      <TableCell align="center" sx={{ width: ColumnWidths.date }}>
        {formatDate(contact.created_at)}
      </TableCell>

      <TableCell align="center" sx={{ width: ColumnWidths.date }}>
        {formatDate(contact.updated_at)}
      </TableCell>

      <TableCell align="center" sx={{ width: ColumnWidths.conversations }}>
        {contact.has_conversations ? (
          <Chip
            label="Yes"
            size="small"
            color="success"
            variant="outlined"
            sx={{ minWidth: 55, fontSize: '0.75rem', fontWeight: 500 }}
          />
        ) : (
          <Chip
            label="No"
            size="small"
            color="default"
            variant="outlined"
            sx={{ minWidth: 55, fontSize: '0.75rem', fontWeight: 500, opacity: 0.6 }}
          />
        )}
      </TableCell>

      <TableCell align="right" sx={{ width: ColumnWidths.actions, pr: 3, textAlign: 'right' }}>
        <ActionButtons>
          {isConfirming ? (
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmDelete}
              disabled={contact.deleting}
              size="small"
              sx={{
                fontSize: '0.75rem',
                py: 0.5,
                minWidth: 'auto',
                position: 'absolute',
                right: 0,
                animation: 'slideIn 0.2s forwards',
                '@keyframes slideIn': {
                  '0%': { opacity: 0, transform: 'translateX(20px)' },
                  '100%': { opacity: 1, transform: 'translateX(0)' }
                }
              }}
            >
              Confirm Delete
            </Button>
          ) : (
            <>
              <Tooltip
                title={
                  <Box>
                    <Typography variant="subtitle2">Edit Contact</Typography>
                    {contact.shared_notes && (
                      <Box sx={{ mt: 1, pt: 1, borderTop: `1px solid ${alpha('#fff', 0.3)}` }}>
                        <Typography variant="caption" fontWeight="bold" display="block">Shared Notes:</Typography>
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap', display: 'block', maxHeight: '150px', overflow: 'auto' }}>
                          {contact.shared_notes}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                }
                placement="top"
                arrow
              >
                <IconButton
                  size="small"
                  onClick={handleEditClick}
                  sx={{
                    color: alpha(theme.palette.primary.main, 0.6),
                    '&:hover': {
                      color: theme.palette.primary.main,
                      backgroundColor: alpha(theme.palette.primary.main, 0.1)
                    }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={
                  <Box>
                    <Typography variant="subtitle2">New Conversation</Typography>
                    {contact.shared_notes && (
                      <Box sx={{ mt: 1, pt: 1, borderTop: `1px solid ${alpha('#fff', 0.3)}` }}>
                        <Typography variant="caption" fontWeight="bold" display="block">Shared Notes:</Typography>
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap', display: 'block', maxHeight: '150px', overflow: 'auto' }}>
                          {contact.shared_notes}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                }
                placement="top"
                arrow
              >
                <IconButton
                  size="small"
                  onClick={handleOpenConversation}
                  sx={{
                    color: alpha(theme.palette.success.main, 0.6),
                    '&:hover': {
                      color: theme.palette.success.main,
                      backgroundColor: alpha(theme.palette.success.main, 0.1)
                    }
                  }}
                >
                  <ChatBubbleOutline fontSize="small" />
                </IconButton>
              </Tooltip>

              <AnimatedDeleteAction>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="subtitle2">Delete Contact</Typography>
                      {contact.shared_notes && (
                        <Box sx={{ mt: 1, pt: 1, borderTop: `1px solid ${alpha('#fff', 0.3)}` }}>
                          <Typography variant="caption" fontWeight="bold" display="block">Shared Notes:</Typography>
                          <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap', display: 'block', maxHeight: '150px', overflow: 'auto' }}>
                            {contact.shared_notes}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={handleDeleteClick}
                    sx={{
                      color: alpha(theme.palette.error.main, 0.6),
                      '&:hover': {
                        color: theme.palette.error.main,
                        backgroundColor: alpha(theme.palette.error.main, 0.1)
                      }
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </AnimatedDeleteAction>
            </>
          )}
        </ActionButtons>
      </TableCell>

      {/* New Conversation Dialog */}
      {conversationDialogOpen && <ConversationCreateDialogV2
        open={conversationDialogOpen}
        handleClose={() => setConversationDialogOpen(false)}
        incomingContactId={contact.id}
      />}

      {/* Shared Notes Popover */}
      <Popper
        open={showNotesPreview}
        anchorEl={notesAnchorEl}
        placement="top"
        transition
        sx={{ zIndex: 1500 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={4}
              sx={{
                p: 2,
                maxWidth: '400px',
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                backgroundColor: alpha(theme.palette.background.paper, 0.95),
              }}
              onMouseEnter={() => clearTimeout(notesPreviewTimeout)}
              onMouseLeave={() => {
                notesPreviewTimeout = setTimeout(() => {
                  setShowNotesPreview(false);
                }, 1000);
              }}
            >
              <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" color="primary" fontWeight={600}>
                  Shared Notes
                </Typography>
                <IconButton size="small" onClick={hideSharedNotesPreview}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
              <Divider sx={{ mb: 1.5 }} />
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                {getNotesPreview()}
              </Typography>
              {contact.shared_notes && contact.shared_notes.length > 100 && (
                <Button
                  size="small"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    mt: 1,
                    fontSize: '0.75rem',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }
                  }}
                  onClick={handleEditClick}
                >
                  View Complete Notes →
                </Button>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </StyledTableRow>
  );
};

const ContactsList: React.FC<ContactsListProps> = ({ onSelectionChange, selectedContactIds }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const contacts = useAppSelector(selectAllFilteredContacts);
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedContactIds);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Sorting state
  const [sortField, setSortField] = useState<SortField>('updated_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const handleToggle = (contactId: string) => {
    const id = contactId?.toString() || '';
    const currentIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (currentIndex === -1) {
      newSelectedIds = [...selectedIds, id];
    } else {
      newSelectedIds = selectedIds.filter((sid) => sid !== id);
    }

    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds); // Pass selection up
  };

  // Handler for 'Select All' checkbox change event
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let newSelectedIds: string[] = [];
    if (isChecked) {
      // Select all contacts
      newSelectedIds = filteredContacts.map(contact => contact.id?.toString() || '').filter(Boolean);
    } else {
      // Deselect all contacts
      newSelectedIds = [];
    }
    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds); // Pass selection up
  };

  const handleOpenBulkMenu = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(event.currentTarget);
  };

  const handleCloseBulkMenu = () => {
    setActionMenuAnchorEl(null);
  };

  const handleDeleteSelected = () => {
    handleCloseBulkMenu();
    if (window.confirm(`Are you sure you want to delete ${selectedIds.length} selected contacts?`)) {
      Promise.all(selectedIds.map((contactId) => dispatch(deleteContact(contactId))))
        .then(() => {
          setSelectedIds([]);
          onSelectionChange([]); // Reset selection

          if (isFilterActive) {
            dispatch(fetchFilteredContacts({}));
          }
        });
    }
  };

  const handleBulkEmail = () => {
    handleCloseBulkMenu();
    // Implement bulk email
    console.log('Bulk email for', selectedIds);
  };

  const handleBulkTag = () => {
    handleCloseBulkMenu();
    // Implement bulk tagging
    console.log('Bulk tag for', selectedIds);
  };

  useEffect(() => {
    // Update selectedIds when selectedContactIds prop changes
    setSelectedIds(selectedContactIds);
  }, [selectedContactIds]);

  // Sorting handler
  const handleSortRequest = (field: SortField) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  // Apply search filter
  const filteredContacts = searchTerm
    ? contacts.filter(contact =>
    (contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.phone_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (contact.first_name + ' ' + contact.last_name)?.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    : contacts;

  // Apply sorting
  const sortedContacts = [...filteredContacts]
    .sort((a, b) => {
      if (sortField === 'name') {
        const nameA = a.name || `${a.first_name || ''} ${a.last_name || ''}`.trim() || 'Unknown';
        const nameB = b.name || `${b.first_name || ''} ${b.last_name || ''}`.trim() || 'Unknown';
        return sortDirection === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }

      if (sortField === 'created_at') {
        const dateA = a.created_at ? new Date(a.created_at).getTime() : 0;
        const dateB = b.created_at ? new Date(b.created_at).getTime() : 0;
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }

      if (sortField === 'updated_at') {
        const dateA = a.updated_at ? new Date(a.updated_at).getTime() : 0;
        const dateB = b.updated_at ? new Date(b.updated_at).getTime() : 0;
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }

      if (sortField === 'has_conversations') {
        const valueA = a.has_conversations ? 1 : 0;
        const valueB = b.has_conversations ? 1 : 0;
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      }

      return 0;
    });

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    }}>
      <TableWrapper>
        {/* Actions Toolbar */}
        <ActionsToolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedIds.length > 0 ? (
              <>
                <Checkbox
                  indeterminate={selectedIds.length > 0 && selectedIds.length < filteredContacts.length}
                  checked={selectedIds.length > 0 && selectedIds.length === filteredContacts.length}
                  onChange={handleSelectAllChange}
                />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {selectedIds.length} selected
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleOpenBulkMenu}
                  endIcon={<MoreVert />}
                  sx={{ ml: 2 }}
                >
                  Actions
                </Button>
              </>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  placeholder="Search contacts..."
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setSearchTerm('')}
                          edge="end"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ minWidth: 240 }}
                />
              </Box>
            )}
          </Box>
        </ActionsToolbar>

        {/* Table */}
        <Box sx={{
          overflowX: 'auto',
          maxWidth: '100%',
          flex: 1,
          overflow: 'auto',
        }}>
          <StyledTable stickyHeader aria-label="contacts table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" sx={{ width: ColumnWidths.checkbox, pl: 1, pr: 0 }} align="center">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedIds.length > 0 && selectedIds.length < filteredContacts.length}
                    checked={filteredContacts.length > 0 && selectedIds.length === filteredContacts.length}
                    onChange={handleSelectAllChange}
                  />
                </TableCell>
                <TableCell padding="checkbox" sx={{ width: ColumnWidths.favorite }} />
                <TableCell sx={{ width: ColumnWidths.info }}>
                  <TableSortLabel
                    active={sortField === 'name'}
                    direction={sortField === 'name' ? sortDirection : 'asc'}
                    onClick={() => handleSortRequest('name')}
                  >
                    Contact Info
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" sx={{ width: ColumnWidths.date }}>
                  <TableSortLabel
                    active={sortField === 'created_at'}
                    direction={sortField === 'created_at' ? sortDirection : 'asc'}
                    onClick={() => handleSortRequest('created_at')}
                  >
                    Created
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" sx={{ width: ColumnWidths.date }}>
                  <TableSortLabel
                    active={sortField === 'updated_at'}
                    direction={sortField === 'updated_at' ? sortDirection : 'asc'}
                    onClick={() => handleSortRequest('updated_at')}
                  >
                    Updated
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" sx={{ width: ColumnWidths.conversations }}>
                  <TableSortLabel
                    active={sortField === 'has_conversations'}
                    direction={sortField === 'has_conversations' ? sortDirection : 'asc'}
                    onClick={() => handleSortRequest('has_conversations')}
                  >
                    Has Conversations
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" sx={{ width: ColumnWidths.actions }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedContacts.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 6 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                      <Person sx={{ fontSize: 48, opacity: 0.3 }} />
                      <Typography variant="h6" sx={{ opacity: 0.8 }}>
                        No contacts found
                      </Typography>
                      {searchTerm && (
                        <Typography variant="body2" color="text.secondary">
                          Try adjusting your search terms
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                sortedContacts.map((contact) => (
                  <ContactListItem
                    key={contact.id}
                    contact={contact}
                    selectedIds={selectedIds}
                    handleToggle={handleToggle}
                    setEditDialogOpen={setEditDialogOpen}
                    setSelectedContactId={setSelectedContactId}
                  />
                ))
              )}
            </TableBody>
          </StyledTable>
        </Box>
      </TableWrapper>

      {selectedContactId && (
        <ContactDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          contactId={selectedContactId}
        />
      )}

      <Menu
        anchorEl={actionMenuAnchorEl}
        open={Boolean(actionMenuAnchorEl)}
        onClose={handleCloseBulkMenu}
      >
        <MenuItem onClick={handleBulkEmail}>
          <MailOutline fontSize="small" sx={{ mr: 1.5 }} /> Send Email
        </MenuItem>
        <MenuItem onClick={handleBulkTag}>
          <Label fontSize="small" sx={{ mr: 1.5 }} /> Add Tags
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteSelected} sx={{ color: 'error.main' }}>
          <Delete fontSize="small" sx={{ mr: 1.5 }} /> Delete Selected
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ContactsList;
