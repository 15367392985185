import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteForm from './NoteForm';
import { Note } from 'src/features/notes/notes.slice';
import Markdown from 'markdown-to-jsx';

interface NoteItemProps {
  note: Note;
  onUpdate: (noteId: string, updatedData: any) => void;
  onDelete: (noteId: string) => void;
}

const NoteItem: React.FC<NoteItemProps> = ({ note, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    try {
      // If the content is too large, truncate it
      if (note.body.length > 10000) {
        setContent(note.body.substring(0, 10000) + '... (content truncated)');
      } else {
        setContent(note.body);
      }
      setError(null);
    } catch (err) {
      setError('Error processing note content');
      setContent('Error displaying content');
    }
  }, [note.body]);

  const handleUpdate = async (updatedNote: any) => {
    await onUpdate(note.id, updatedNote);
    setIsEditing(false);
  };

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const renderContent = () => {
    if (error) {
      return (
        <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      );
    }

    try {
      return (
        <Typography variant="body1" style={{ marginTop: '1rem' }}>
          <Markdown
            options={{
              forceBlock: true,
              wrapper: 'div',
              createElement: (type, props, children) => {
                // Additional safety check for potentially dangerous elements
                if (typeof type === 'string' &&
                  ['script', 'style', 'iframe', 'object', 'embed', 'form', 'input', 'textarea', 'select', 'button'].includes(type.toLowerCase())) {
                  return null;
                }
                return React.createElement(type, props, children);
              },
              overrides: {
                // Override potentially problematic elements
                script: () => null,
                style: () => null,
                iframe: () => null,
                object: () => null,
                embed: () => null,
                form: () => null,
                input: () => null,
                textarea: () => null,
                select: () => null,
                button: () => null,
              }
            }}
          >
            {content}
          </Markdown>
        </Typography>
      );
    } catch (err) {
      return (
        <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
          Error rendering content
        </Typography>
      );
    }
  };

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{note.subject}</Typography>
          <Box>
            <IconButton onClick={() => setIsEditing(!isEditing)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(note.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {new Date(note.created_at).toLocaleString()}
        </Typography>
        {!isEditing ? (
          renderContent()
        ) : (
          <NoteForm
            note={note}
            onSave={handleUpdate}
            onCancel={handleCancelEdit}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default NoteItem;
