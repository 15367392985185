import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import "../../styles/components/light_chat_popup.scss";
import "../../styles/components/dark_chat_popup.scss";
import "../../styles/components/chat_popup.scss";
import { API_ROUTES } from "src/constants/routes";
import { httpClient } from "src/libs";
import LinearProgress from '@mui/material/LinearProgress';
import { TextField, Typography, useMediaQuery } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useAppDispatch } from "src/store";
import { closeConversation } from "src/features/chatbot/chat.slice";
import Markdown from "markdown-to-jsx"
import { ReactComponent as PaperPlaneIcon } from 'src/assets/svg/send.svg'
import { ReactComponent as ChatIcon } from 'src/assets/svg/chat_icon.svg'
import { Dropdown } from "react-bootstrap";
import { getChatbotTheme } from "src/libs/utils";
import { trackAnalytics } from "src/features/analytics/analytics.slice";

type ChatPopupProps = {
  embedKey: string;
  open?: boolean;
  headingText?: string;
  chatbotName: string;
  sendInitialMessage?: boolean;
  initialMessageFromRole?: string;
  customClass?: string;
  initialMessage?: string;
  chatbotTheme?: number;
  setChatbotTheme?: any;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;Secure`;
};

const ChatPopup: React.FC<ChatPopupProps> = ({ embedKey, open = true, headingText = "Live Chat", chatbotName, sendInitialMessage = true, initialMessageFromRole = 'assistant', chatbotTheme = 2, customClass = "", initialMessage = `Hey my name is ${chatbotName}, how can I help?`, setChatbotTheme = () => { } }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(open);
  const [messages, setMessages] = useState<Array<{ sender_role: string; content: string }>>([]);
  const [uid, setUid] = useState<string | undefined>();
  const [OppyConversationId, setOppyConversationId] = useState(getCookie("OppyConversationId"));
  const [inputValue, setInputValue] = useState("");
  const [lastMessageCount, setLastMessageCount] = useState(0);
  const lastMessageCountRef = useRef(lastMessageCount);
  const [loading, setLoading] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(15000);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const [postingMessage, setPostingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [heading, setHeading] = useState(headingText);
  const [sentMessage, setSentMessage] = useState(null);
  const [replyLoading, setReplyLoading] = useState(false);
  const [deletingChat, setDeletingChat] = useState(false);
  const [theme, setTheme] = useState(chatbotTheme);
  const [showWindowSizeMenu, setShowWindowSizeMenu] = useState(false);
  const [selectedWindowSize, setSelectedWindowSize] = useState(2);
  const [showMenu, setShowMenu] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({ xRate: 0, yRate: 0 });
  const [currentSize, setCurrentSize] = useState({ width: 0, height: 0 });
  const [resizing, setResizing] = useState(false);
  const [conversation, setConversation] = useState(null)
  const [initiatedConversation, setInitiatedConversation] = useState(false)
  const [conversationLoading, setConversationLoading] = useState(false)
  const [initialMessageSent, setInitialMessageSent] = useState(false)

  useEffect(() => {
    setChatSession();
    if (OppyConversationId) {
      setCookie("OppyConversationId", OppyConversationId, 1);

      fetchConversation(OppyConversationId);
    } else {
      setInitiatedConversation(true)
    }
  }, []);

  useEffect(() => {
    const initiateConversation = async () => {
      const isConversationFetched = await fetchConversation(OppyConversationId);

      if (isConversationFetched) {
        fetchMessages(OppyConversationId);
      }
    };

    initiateConversation();

  }, [OppyConversationId, initiatedConversation]);

  useEffect(() => {
    setTheme(chatbotTheme)
  }, [chatbotTheme]);

  const setChatSession = () => {
    const existingUid = getCookie("uid");
    const existingOppyConversationId = getCookie("OppyConversationId");
    if (existingUid) {
      setUid(existingUid);
    } else {
      const newUid = uuidv4();
      setCookie("uid", newUid, 365);
      setUid(newUid);
    }

    if (existingOppyConversationId) {
      setOppyConversationId(OppyConversationId);
    }
  }

  useEffect(() => {
    setHeading(headingText)
  }, [headingText])

  useEffect(() => {
    if (sendInitialMessage && isOpen && uid && !initialMessageSent) {
      setInitialMessageSent(true)
      handleSendMessage(initialMessage, initialMessageFromRole)
    }
  }, [uid, isOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessages();
    }, pollingInterval);
    return () => clearInterval(interval);
  }, [OppyConversationId, pollingInterval]);


  useEffect(() => {
    lastMessageCountRef.current = lastMessageCount;
    scrollToBottom();
  }, [lastMessageCount]);

  const fetchConversation = async (id) => {
    let conversationFetched = false;
    if (!id) {
      setConversation(null);
      setInitiatedConversation(true)
      return;
    }

    setConversationLoading(true);
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.public_conversations}/${id}`;
    const body = { channel_embed_key: embedKey };

    try {
      const response = await httpClient.get(url, { params: body });
      const res = response.data.conversation;

      // Check if a conversation was found
      if (res) {
        setConversation(res);
        conversationFetched = true; // Set flag to true as conversation fetched successfully
      } else {
        setCookie("OppyConversationId", '', 1);
        setConversation(null);
      }

    } catch (error) {
      if (error.response && error.response.status === 400) {
        setCookie("OppyConversationId", '', 1);
      }
      setConversation(null)
    } finally {
      setInitiatedConversation(true)
      setConversationLoading(false)
    }
    return conversationFetched; // Return the flag indicating whether the conversation was fetched successfully
  };

  const fetchMessages = (overrideOppyConversationId = null) => {
    if (!uid || !(OppyConversationId || overrideOppyConversationId)) {
      setChatSession();
    }

    if (!uid || !(OppyConversationId || overrideOppyConversationId)) return;
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.chat_message_thread}/?embed_key=${embedKey}&conversation_id=${overrideOppyConversationId || OppyConversationId}`;

    httpClient.get(url).then(({ data }) => {
      setSentMessage(null);

      const apiConversation = data.conversation;
      let apiMessages = apiConversation.messages;

      // Sort messages by created_at timestamp
      apiMessages = apiMessages.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

      setConversation(apiConversation);
      setMessages(apiMessages);

      if (apiConversation.status === 'processing_autocomplete' || apiConversation.status === 'pending_assistant_reply') {
        setReplyLoading(true);
        setPollingInterval(5000);
      } else {
        setReplyLoading(false);
        setPollingInterval(15000);
      }

      if (lastMessageCountRef.current !== 0 && apiMessages.length > lastMessageCountRef.current) {
        if (apiMessages[apiMessages.length - 1].sender_role === "assistant") {
          playSound("new_reply");
        }
      }

      setLastMessageCount(prevCount => apiMessages.length);
      setErrorMessage("");
    }).catch(error => {
      setSentMessage(null);
      setErrorMessage(error.message);
    });
  };
  const playSound = (sound) => {
    if (sound === "new_reply") {
      const audio = new Audio("/audio/message_received.mp3");
      audio.play();

    } else if (sound === "sent_message") {
      const audio = new Audio("/audio/message_sent.mp3");
      audio.play();
    }
  };

  // set default from_role to user
  const handleSendMessage = (message: string, from_role: string = 'user') => {
    if (!message.trim()) {
      return;
    }
    setPostingMessage(true);
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.chat_post_message}`;
    // if from_role is assistant, from uid is not added to data
    const showFromUid = from_role === 'assistant' ? false : true
    const data = { text: message, embed_key: embedKey, from_role: from_role }
    if (showFromUid) {
      data['from_uid'] = uid
    }
    httpClient.post(url, data, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        const conversation_id = response.data.conversation_id;
        setOppyConversationId(conversation_id);
        setCookie("OppyConversationId", OppyConversationId, 1);
        playSound("sent_message");
        fetchMessages(conversation_id);
        setSentMessage(message)
        setErrorMessage("");
        setInputValue("");
      })
      .catch((error) => {
        setErrorMessage("Error sending message.");
        dispatch(trackAnalytics({ action_name: "user_send_message_error", payload: { error: error } }))
        setPostingMessage(false);
        setSentMessage(null)
        setInputValue(message);
      })
      .finally(() => {
        setPostingMessage(false);
      });
    setLastMessageCount(prevCount => prevCount + 1);
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };
  const toggleChat = (isOpen) => {
    if (isOpen) {
      setPollingInterval(1000);
    } else {
      setPollingInterval(15000);
    }
    setIsOpen(isOpen);
  };

  const resetChat = (e) => {
    e.stopPropagation();

    setDeletingChat(true);

    dispatch(closeConversation({ conversation_id: OppyConversationId, embed_key: embedKey }))
      .unwrap()
      .then(() => {
        setMessages([]);
        setCookie("OppyConversationId", '', 1);
        setOppyConversationId(null);
        setReplyLoading(false);
        setDeletingChat(false);
      })
      .catch((error) => {
        console.error("Error closing conversation:", error);
        setErrorMessage(error);
        setDeletingChat(false);
      });
  };


  const handleThemeChange = (e, selectedTheme) => {
    e.stopPropagation();
    setTheme(selectedTheme);
    setChatbotTheme(selectedTheme);
    setShowMenu(false);
  };

  return (
    <div className={`d-flex ${isOpen ? "justify-content-center" : "justify-content-end"} flex-column align-items-center`}>
      <div className={`chat-popup-wrapper ${customClass}`}>
        <div className={`${getChatbotTheme(theme)} chat-popup ${isOpen ? "open" : "closed"}`}>
          <div id="header" className="chat-header" onClick={() => toggleChat(true)}>
            {isOpen ?
              <>
                <Typography variant="h6">{heading}</Typography>
                <div className="d-flex justify-content-center gap-2" style={{ position: "absolute", right: "16px" }}>

                  <div title="Reset">
                    <CachedIcon className={deletingChat ? "revolving-icon" : ''} onClick={resetChat} />
                  </div>
                  <Dropdown.Menu show={showMenu}>
                    <Dropdown.Header>Theme</Dropdown.Header>
                    <Dropdown.Item onClick={(e) => { handleThemeChange(e, 1) }} className={theme === 1 ? 'active' : ''}>Light</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { handleThemeChange(e, 2) }} className={theme === 2 ? 'active' : ''}>Dark</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { handleThemeChange(e, 3) }} className={theme === 3 ? 'active' : ''}>Default</Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              </>
              : <ChatIcon />
            }
          </div>
          <div className="messages-container" ref={messagesContainerRef} style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            {messages.map((message, index) => (
              <div key={index} className={`message message-${message.sender_role}`}>
                <Markdown>{message.content}</Markdown>
              </div>
            ))}
            {sentMessage && <div className={`message message-user`}>
              <Markdown>{sentMessage}</Markdown>
            </div>}
            {replyLoading && <div className={`message message-assistant`} style={{ width: "45px" }}>
              <div className="dot-typing"></div>
            </div>}

            {postingMessage && <div className={`message message-user`}>
              {/* <CircularProgress color="inherit" size="1rem" /> */}
            </div>}
          </div>

          <div className="input-container">
            {(postingMessage || deletingChat) && <LinearProgress sx={{ mb: -.1, position: 'absolute', top: 0, marginBottom: "-.8px", width: "100%" }} />}

            <TextField
              type="text"
              multiline
              placeholder="Type your message..."
              value={inputValue}
              autoComplete="off"
              autoFocus={true}
              onChange={(e) => setInputValue(e.target.value)}
              sx={{
                width: '100%',
                '& textarea': {
                  position: 'relative',
                  color: theme == 2 || theme == 3 ? '#FFFFFF' : '#0D082C',
                  fontSize: '16px',
                  background: theme == 2 || theme == 3 ? 'transparent' : '#FFFFFF',
                  width: '90%',
                  zIndex: 1,
                },
                '& fieldset': {
                  border: '1px solid rgba(255, 255, 255, 0.23)',
                  backgroundColor: 'inherit',
                  borderOpacity: .2,
                  zIndex: 0,
                  width: '100%',
                },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (e.metaKey || e.ctrlKey) {
                    // Create a newline
                    setInputValue(prev => prev + '\n');
                  } else {
                    e.preventDefault();
                    handleSendMessage(inputValue);
                  }
                }
              }}
            />
            <button
              className={`send-button ${inputValue ? "enabled" : "disabled"}`}
              onClick={() => {
                handleSendMessage(inputValue);
              }}
              disabled={!inputValue || postingMessage || deletingChat}
            >
              <PaperPlaneIcon />
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ChatPopup;
