import { useEffect, useState, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  fetchConversationWithMessages,
  removeConversationById,
  removeMessageFromConversation
} from 'src/features/account/conversations.slice';
import {
  getContact,
  removeContactState,
  selectAllContacts,
} from 'src/features/account/contacts.slice';
import {
  fetchChannel,
} from 'src/features/account/channels.slice';
import useAuth from 'src/hooks/useAuth';
import { getPusherInstance } from 'src/services/auth.service';
import LiveTranscriptionDialog from '../Lottie/LiveTranscriptionDialog';
import { getMessage } from 'src/features/account/messages.slice';
import IconButton from '@mui/material/IconButton';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import CloseIcon from '@mui/icons-material/Close';
import AuthService from 'src/services/auth.service';
import { fetchNotes } from 'src/features/notes/notes.slice';
import { debounce } from 'lodash';
import { selectNotificationsEnabled } from 'src/features/account/notifications.slice';

const AccountNotifications = () => {
  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user);
  const contacts = useAppSelector(selectAllContacts);
  const notificationsEnabled = useAppSelector(selectNotificationsEnabled);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pusherSubscriptionSucceeded, setPusherSubscriptionSucceeded] = useState(false);
  const [transcriptionDialogOpen, setTranscriptionDialogOpen] = useState(false);
  const [latestTranscriptionChunk, setLatestTranscriptionChunk] = useState(null);

  // Track recent requests to prevent duplicates
  const recentContactRequests = useRef(new Set());
  const recentConversationRequests = useRef(new Set());

  // Debounced functions for API requests
  const debouncedGetContact = useRef(
    debounce((contactId) => {
      // Skip if notifications are disabled
      if (!notificationsEnabled) {
        return;
      }

      const requestKey = `contact_${contactId}`;
      if (!recentContactRequests.current.has(requestKey)) {
        recentContactRequests.current.add(requestKey);
        dispatch(getContact(contactId));

        // Remove from tracking after 1s
        setTimeout(() => {
          recentContactRequests.current.delete(requestKey);
        }, 1000);
      }
    }, 1000)
  ).current;

  const debouncedFetchConversation = useRef(
    debounce((conversationId, messagesLimit = 0) => {
      // Skip if notifications are disabled
      if (!notificationsEnabled) {
        return;
      }

      const requestKey = `conversation_${conversationId}`;
      if (!recentConversationRequests.current.has(requestKey)) {
        recentConversationRequests.current.add(requestKey);
        dispatch(fetchConversationWithMessages({
          conversationId,
          messagesLimit
        }));

        // Remove from tracking after 1s
        setTimeout(() => {
          recentConversationRequests.current.delete(requestKey);
        }, 1000);
      }
    }, 1000)
  ).current;

  // Effect to update debounced functions when notificationsEnabled changes
  useEffect(() => {
    // Update the closure of the debounced functions when notificationsEnabled changes
    debouncedGetContact.cancel();
    debouncedFetchConversation.cancel();
  }, [notificationsEnabled, debouncedGetContact, debouncedFetchConversation]);

  // Snackbar action for new messages
  const snackbarNewMessageAction = (key, message) => (
    <>
      <IconButton onClick={() => { navigate(`/conversations?conversation_id=${message.conversation_id}&channel_id=${message.channel_id}&message_id=${message.id}`); }}>
        <MarkUnreadChatAltIcon sx={{ color: 'white' }} />
      </IconButton>
      <IconButton onClick={() => closeSnackbar(key)}>
        <CloseIcon />
      </IconButton>
    </>
  );

  // Function to handle actions when a new message is received
  const newMessageActions = (conversation, message) => {
    if (!notificationsEnabled) return;

    const senderEntity = conversation?.participating_entities?.find(entity => entity?.id === message?.sender_id);

    const truncatedMessageContent = message.content.length > 100 ? message.content.substring(0, 100) + '...' : message.content;
    const senderString = senderEntity?.name ? `${senderEntity.name}: ` : '';
    const notiMessage = `${senderString}${truncatedMessageContent}`;

    // Play appropriate sound based on sender role
    if (senderEntity?.role === 'function') {
      new Audio('/audio/keyboard.mp3').play();
    } else {
      new Audio("/audio/message_received.mp3").play();
    }

    // Display snackbar notification
    enqueueSnackbar(notiMessage, {
      hideIconVariant: true,
      autoHideDuration: 4000,
      action: key => snackbarNewMessageAction(key, message)
    });
  };

  // for a new onlineUser add a enqueueSnackbar
  const newOnlineUserSnackbar = (onlineUser) => {
    if (!notificationsEnabled) return;

    enqueueSnackbar(`${onlineUser.name} is online`, {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
    });
  }

  useEffect(() => {
    if (notificationsEnabled && onlineUsers.length > 0) {
      newOnlineUserSnackbar(onlineUsers[0]);
    }
  }, [onlineUsers, notificationsEnabled]);

  // Function to play sound for message creation
  const playSoundForMessageCreation = (senderRole) => {
    if (!notificationsEnabled) return;

    if (senderRole === 'function') {
      new Audio('/audio/keyboard.mp3').play();
    } else {
      new Audio("/audio/message_received.mp3").play();
    }
  };

  // Function to show snackbar notification
  const showSnackbarNotification = (notification) => {
    if (!notificationsEnabled || !notification || !notification.notification) return;

    const { title, body } = notification.notification;
    if (title && body) {
      const notiMessage = `${title}: ${body}`;

      enqueueSnackbar(notiMessage, {
        hideIconVariant: true,
        autoHideDuration: 4000,
        action: key => snackbarNewMessageAction(key, { title, body })
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn && user) {
      // Initialize Pusher and ensure we're signed in
      AuthService.pusherSignin();

      // Get the Pusher instance
      const pusherInstance = getPusherInstance();

      if (pusherInstance && user.account?.uuid) {
        // Subscribe to presence channel for the account
        const channel = pusherInstance.subscribe('presence-account-' + user.account.uuid);

        // Handle subscription success
        channel.bind('pusher:subscription_succeeded', () => {
          setPusherSubscriptionSucceeded(true);
        });

        // Handle subscription error
        channel.bind('pusher:subscription_error', (error) => {
          console.error('Presence channel subscription error:', error);
          setPusherSubscriptionSucceeded(false);
          if (notificationsEnabled) {
            enqueueSnackbar('Check your internet connection', {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
            });
          }
        });

        // Bind all the event handlers
        bindEvents(channel);

        // Cleanup function
        return () => {
          try {
            channel.unbind_all();
            pusherInstance.unsubscribe('presence-account-' + user.account.uuid);
          } catch (error) {
            console.error('Error cleaning up Pusher channel:', error);
          }
        };
      }
    }
  }, [isLoggedIn, user, notificationsEnabled]);

  // Separate bindEvents into its own function for clarity
  const bindEvents = (channel) => {
    // Channel events
    ['channel_created', 'channel_updated', 'channel_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if ((eventName === 'channel_created' || eventName === 'channel_updated') && notificationsEnabled) {
          dispatch(fetchChannel(data.channel.id));
        }
      });
    });

    // Conversation events
    ['conversation_created', 'conversation_updated', 'conversation_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if ((eventName === 'conversation_created' || eventName === 'conversation_updated') && notificationsEnabled) {
          debouncedFetchConversation(data.conversation.id, 0);
        }
        if (eventName === 'conversation_deleted' && notificationsEnabled) {
          dispatch(removeConversationById(data.conversation.id));
        }
      });
    });

    // Message events
    ['message_created', 'message_updated', 'message_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if ((eventName === 'message_created' || eventName === 'message_updated') && notificationsEnabled) {
          dispatch(getMessage({ messageId: data.message.id }));
        }

        if (eventName === 'message_created' && notificationsEnabled) {
          // Only play sounds and show notifications if notifications are enabled
          const senderRole = data?.message?.role;
          playSoundForMessageCreation(senderRole);

          if (senderRole !== 'function' && data?.message?.notification) {
            showSnackbarNotification(data.message.notification);
          }
        }

        if (eventName === 'message_deleted' && notificationsEnabled) {
          dispatch(removeMessageFromConversation({
            conversationId: data.message.conversation_id,
            messageId: data.message.id
          }));
        }
      });
    });

    ['transcript_updated'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if (notificationsEnabled) {
          setLatestTranscriptionChunk(data.transcription); // Pass the new chunk
          if (!transcriptionDialogOpen) {
            setTranscriptionDialogOpen(true);
          }
        }
      });
    });

    ['contact_created', 'contact_updated', 'contact_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if (data.contact && data.contact.id) {
          if (eventName === 'contact_created' && notificationsEnabled) {
            debouncedGetContact(data.contact.id);
          } else if (eventName === 'contact_updated' && notificationsEnabled) {
            // Only update contact if it already exists in the store
            const contactExists = contacts.some(contact => contact.id === data.contact.id);
            if (contactExists) {
              debouncedGetContact(data.contact.id);
            }
          } else if (eventName === 'contact_deleted' && notificationsEnabled) {
            dispatch(removeContactState(data.contact.id));
          }
        }
      });
    });

    ['entity_created', 'entity_updated', 'entity_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        // Only handle if notifications enabled
        // setLatestChannelPusherEvent({ eventName, data });
      });
    });

    ['faq_created', 'faq_updated', 'faq_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        // Only handle if notifications enabled
        // setLatestChannelPusherEvent({ eventName, data });
      });
    });

    // Add note events handling
    ['note_created', 'note_updated', 'note_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if (data?.note && notificationsEnabled) {
          // Fetch updated notes for the owner
          dispatch(fetchNotes({
            ownerType: data.note.owner_type,
            ownerId: data.note.owner_id
          }));

          // Show notification if present (only if notifications are enabled)
          if (data.note?.notification?.notification) {
            const { title, body } = data.note.notification.notification;
            enqueueSnackbar(`${title}: ${body}`, {
              variant: 'info',
              autoHideDuration: 3000,
            });
          }
        }
      });
    });
  };

  return (
    <>
      <LiveTranscriptionDialog
        open={transcriptionDialogOpen && notificationsEnabled}
        onClose={() => setTranscriptionDialogOpen(false)}
        latestTranscriptionChunk={latestTranscriptionChunk}
      />
    </>
  );
};

export default AccountNotifications;
