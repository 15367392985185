// ChannelEditor component
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
} from '@mui/material';
import WYSIWYGEditor from 'src/components/WYSIWYGEditor';
import PromptPicker from './PromptPicker'
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateChannelPrompt } from 'src/features/account/channels.slice';


const ChannelSystemMessageDialog = ({ channelId }) => {
  // find channel by channelId in state/store
  const channel = useAppSelector((state) => state.channels.channels.find(channel => channel.id === channelId));

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [systemMessage, setSystemMessage] = useState('');
  const [initialSystemMessage, setInitialSystemMessage] = useState('');
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [chatbotName, setChatbotName] = useState('');

  useEffect(() => {
    if (channel && channel.config && channel.config.system_message) {
      setSystemMessage(channel.config.system_message);
      setInitialSystemMessage(channel.config.system_message);
      // remember the initial state of the system message
    }
    if (channel && channel.config && channel.config.chatbot_name) {
      setChatbotName(channel.config.chatbot_name);
    }
  }, [channel]);

  const handleClickOpen = () => {
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);

  };

  const handlePromptClicked = (selectedPrompt) => {
    setSystemMessage(selectedPrompt);
    // setUpdatedChannel({ ...updatedChannel, system_message: selectedPrompt });
  };

  const handleSave = async () => {
    setIsSaving(true);
    setSaveError(null);
    // dispatch(updateChannel({ channelData: { id:channel.embed_key, config: { system_message: systemMessage } } }));
    dispatch(updateChannelPrompt({ channelId: channel.id, prompt: systemMessage }));
    setIsSaving(false);
  };

  const handlePromptChange = (newPrompt) => {
    setSystemMessage(newPrompt);
    // setUpdatedChannel({ ...updatedChannel, system_message: newPrompt });
  };

  return (
    <div>
      <Button variant="text" color="warning" onClick={() => {
        // confirm they want to proceed to edit the prompt. 
        // if they click yes, open the dialog if no return
        if (window.confirm('WARNING! Editing the prompt may void some or all of the previous input changes. Connections like calendar, notifications and imported web pages will not be affected. After editing the prompt, do not use the inputs to edit name, goals or knowledge fine tunings or your custom prompt may be overwritten. Feel free to proceed with caution - just a heads up before you click the save button!')) {
          handleClickOpen()
        }
      }}>
        Edit Prompt
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit {chatbotName}</DialogTitle>
        <PromptPicker
          onPromptSelect={handlePromptClicked}
          initialPrompt={initialSystemMessage}
          channel={channel}
          chatbotName={chatbotName}
        />
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Edit chatbot handbook
          </Typography>
          <WYSIWYGEditor
            initialValue={systemMessage}
            onChange={handlePromptChange}
            maxLength={6000}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={isSaving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={isSaving}>
            {isSaving ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
        {saveError && (
          <p style={{ color: 'red', textAlign: 'center' }}>
            Error: {saveError}
          </p>
        )}
      </Dialog>
    </div>
  );
};

export default ChannelSystemMessageDialog;
