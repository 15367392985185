import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { Mail, Phone, CallMade, Settings, Edit, AdminPanelSettings, Web, Description as DescriptionIcon } from '@mui/icons-material';
import AssistantIcon from '../ThemeElements/AssistantIcon';
import LavaLampBackground from '../ThemeElements/LavaLampBackground';
import ChannelDescriptionTextInput from './ChannelDescriptionTextInput';
import ConversationCreateDialogV2 from './ConversationCreateDialogV2';
import OutboundOptionsMenu from './OutboundOptionsMenu';
import ConversationCallOptions from './ConversationCallOptions';
import QrCodeDialog from './QrCodeDialog';
import ChannelEditDialog from './ChannelEditDialog';
import UpdateAdminSettings from './UpdateAdminSettings';
import EmbedKeyInput from './EmbedKeyInput';
import PhoneNumberBrowserDialog from './PhoneNumberBrowserDialog';
import { useAppSelector, useAppDispatch } from 'src/store';
import ReleasePhoneNumber from './ReleasePhoneNumber';
import parsePhoneNumberFromString from 'libphonenumber-js';
import ChannelEmbedDialog from './ChannelEmbedDialog';
import Code from '@mui/icons-material/Code';
import ChannelOptionsMenu from './ChannelOptionsMenu';
import { updateChannelDelivery, selectChannelById } from 'src/features/account/channels.slice';
import ChannelFollowUpSettings from './ChannelFollowUpSettings';
import ChannelPagesSettings from './ChannelPagesSettings';
import PagesForm from '../PagesForm';
import { updateChannel } from 'src/features/account/channels.slice';
import { useSnackbar } from 'notistack';
import { Pages } from 'src/types/pages';
import { useColorMode } from 'src/theme/ThemeProvider';

const AvatarWrapper = styled(Box)({
  position: 'relative',
  width: 125,
  height: 125,
  marginRight: 3,
});

const Channel = ({ channelId }) => {
  const channel = useAppSelector(state => state.channels.entities[channelId]);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { mode } = useColorMode();
  const { darkMode } = useAppSelector(state => state.theme);
  const isDarkMode = mode === 'dark' || darkMode;
  const { allChannelReplies, leads, bookings } = useAppSelector(state => state.channels);
  const channelLoading = useAppSelector(state => state.channels.channelsLoading);
  const [showOutboundIcon, setShowOutboundIcon] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [conversationCallOptionsOpen, setConversationCallOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [phoneEditorOpen, setPhoneEditorOpen] = useState(false);
  const [embedDialogOpen, setEmbedDialogOpen] = useState(false);
  const [pagesSettingsOpen, setPagesSettingsOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleIconClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({ top: rect.bottom, left: rect.left });
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleAdminMenuOpenToggle = () => {
    setAdminMenuOpen(!adminMenuOpen);
    handleSettingsClose();
  };

  const handleChannelDeliveryStateChange = (deliveryState) => {
    dispatch(updateChannelDelivery({ channelId: channel.id, data: { delivery_state: deliveryState } }))
  }

  const handleChannelDeliveryDelayChange = (delay) => {
    dispatch(updateChannelDelivery({ channelId: channel.id, data: { delivery_delay: delay } }))
  }

  const formatPhoneNumber = (phone_number) => {
    const phoneNumber = parsePhoneNumberFromString(phone_number);
    if (!phoneNumber) {
      return null;
    }
    return phoneNumber.formatInternational();
  };

  if (!channel) {
    return <Box sx={{
      color: theme.palette.text.primary,
      bgcolor: theme.palette.background.default
    }}>Loading...</Box>;
  }

  return (
    <Box sx={{
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minHeight: '100vh'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        height: '100vh',
        maxWidth: '100vw',
        padding: 2,
      }}>
        <Box>
          <Box display="flex" alignItems="center" mt={2} ml={-2}>
            <AvatarWrapper
              onMouseEnter={() => setShowOutboundIcon(true)}
              onMouseLeave={() => setShowOutboundIcon(false)}
            >
              <AssistantIcon size={125} movementSpeed={0.05} />
              {showOutboundIcon && (
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    transition: '0.3s ease-in-out',
                    opacity: 0,
                    '&:hover': {
                      opacity: 1,
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                  }}
                  onClick={handleIconClick}
                >
                  <CallMade sx={{ color: 'white' }} />
                </IconButton>
              )}
            </AvatarWrapper>
            <Box>
              <Typography variant="h4" fontWeight="bold" mb={1} color="text.primary">
                {channel.chatbot_name}
              </Typography>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Box display="flex" alignItems="center">
                  <Mail sx={{ mr: 1, fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.primary">
                    {channel?.email_address}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Phone sx={{ mr: 1, fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.primary">
                    {channel?.twilio_phone_number ?
                      formatPhoneNumber(channel?.twilio_phone_number?.toString()) :
                      <Typography
                        variant="body2"
                        color="secondary"
                        component="span"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setPhoneEditorOpen(true)}
                      >
                        Get Phone Number
                      </Typography>
                    }
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Web sx={{ mr: 1, fontSize: 16, color: theme.palette.text.secondary }} />
                  <EmbedKeyInput style={{ fontSize: 16 }} channelId={channel.id} initialEmbedKey={channel.embed_key || ''} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={4} mb={4}>
            <Typography variant="h6" fontWeight="bold" mb={1} color="text.primary">
              Description
            </Typography>
            <ChannelDescriptionTextInput channel={channel} />
          </Box>

          <Box display="flex" gap={4} alignItems="flex-start" mt={4} sx={{
            maxWidth: '100vw',
            height: 'auto',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: isDarkMode ? theme.palette.grey[800] : theme.palette.grey[100],
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: isDarkMode ? theme.palette.grey[700] : theme.palette.grey[400],
              borderRadius: '4px',
            },
          }}>
            <Box sx={{
              height: '75px',
              display: 'flex',
              flexDirection: 'row',
              width: 'auto',
              flexWrap: 'nowrap',
              minWidth: '700px',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: 2,
            }}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                <Box display="flex" gap={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    href={`${window.location.origin}/s/${channel.embed_key}?s=${channel.style}`}
                    target="_blank"
                  >
                    View Public Page
                  </Button>
                  <QrCodeDialog
                    defaultPhoneNumber={channel.twilio_phone_number}
                    defaultEmail={channel.email_address}
                    defaultBaseUrl={`${window.location.origin}/s/${channel.embed_key}`}
                    defaultUrl={`${window.location.origin}/s/${channel.embed_key}?s=${channel.style}`}
                    defaultInitialMessage={`Hey ${channel.chatbot_name}, I'd love to learn more about ${channel.config?.company_name}. My name is `}
                  />
                </Box>
              </Box>
              <Button
                startIcon={<Edit />}
                variant="contained"
                color="secondary"
                onClick={() => setEditorOpen(true)}
              >
                Edit this Oppy
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Code />}
                onClick={() => setEmbedDialogOpen(true)}
              >
                Embed
              </Button>
            </Box>
          </Box>
          <PagesForm channelId={channel.id} />
          <ChannelFollowUpSettings channelId={channel.id} />
          <Box
            mt={4}
            mb={-8}
            sx={{
              position: 'absolute',
              bottom: theme.spacing(2),
              right: theme.spacing(2),
              opacity: 0.5,
              transition: 'opacity 0.3s',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <Typography variant="caption" color="white">
              Messages: {allChannelReplies} | Leads: {leads} | Bookings: {bookings}
            </Typography>
            {channel.twilio_phone_number && (
              <ReleasePhoneNumber
                channelId={channel.id}
                formatPhoneNumber={formatPhoneNumber}
              />
            )}
          </Box>
        </Box>


        {conversationCallOptionsOpen &&
          <ConversationCallOptions
            open={conversationCallOptionsOpen}
            channelId={channel.id}
            onClose={() => setConversationCallOptionsOpen(false)}
          />
        }
        {phoneEditorOpen && (
          <PhoneNumberBrowserDialog
            openDialog={phoneEditorOpen}
            handleCloseDialog={() => setPhoneEditorOpen(false)}
            channelId={channel.id}
          />
        )}
      </Box>

      <OutboundOptionsMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onCreateDialogOpen={() => {
          setCreateDialogOpen(true);
          setAnchorEl(null);
        }}
        onPlaceCallDialogOpen={() => {
          setConversationCallOptionsOpen(true);
          setAnchorEl(null);
        }}
        position={menuPosition}
      />

      <ConversationCreateDialogV2
        open={createDialogOpen}
        handleClose={() => setCreateDialogOpen(false)}
        incomingChannelId={channel.id}
      />

      <IconButton
        sx={{
          position: 'absolute',
          top: theme.spacing(2),
          right: theme.spacing(2)
        }}
        onClick={handleSettingsClick}
      >
        <Settings />
      </IconButton>

      <Menu
        anchorEl={settingsAnchorEl}
        open={Boolean(settingsAnchorEl)}
        onClose={handleSettingsClose}
      >
        <MenuItem>
          <ChannelOptionsMenu channel={channel} handleChannelDeliveryStateChange={handleChannelDeliveryStateChange} handleChannelDeliveryDelayChange={handleChannelDeliveryDelayChange} loading={channelLoading} />
        </MenuItem>
        <MenuItem onClick={() => { setEditorOpen(true); handleSettingsClose(); }}>
          <Edit sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleAdminMenuOpenToggle}>
          <AdminPanelSettings sx={{ mr: 1 }} /> Admin Settings
        </MenuItem>
      </Menu>

      {editorOpen && <ChannelEditDialog open={editorOpen} channelId={channel.id} handleClose={() => setEditorOpen(false)} handleBack={() => setEditorOpen(false)} />}
      {adminMenuOpen && <UpdateAdminSettings styles={{
        fontSize: 16,
        maxWidth: "500px",
        mt: 2
      }} channel={channel} open={adminMenuOpen} setOpen={handleAdminMenuOpenToggle} />}
      {embedDialogOpen && (
        <ChannelEmbedDialog
          open={embedDialogOpen}
          onClose={() => setEmbedDialogOpen(false)}
          channelId={channel.id}
        />
      )}
      <IconButton
        sx={{
          position: 'absolute',
          top: theme.spacing(2),
          right: theme.spacing(8),
        }}
        onClick={() => setPagesSettingsOpen(true)}
      >
        <DescriptionIcon />
      </IconButton>

      {pagesSettingsOpen && (
        <ChannelPagesSettings
          channelId={channel.id}
          open={pagesSettingsOpen}
          onClose={() => setPagesSettingsOpen(false)}
        />
      )}
    </Box>
  );
};

export default Channel;
